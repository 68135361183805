import React from 'react'
import { User } from '../types'
import { InputLabel, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

export type UserSelectProps = {
  users: User[];
  selectedUserId: any;
  changeHandler(entityId): void;
};

//FIXME: selectedOption is always "all"
export const UserSelect = ({
  users,
  selectedUserId,
  changeHandler,
}: UserSelectProps) => {
  const handleChange = (event, value) => changeHandler(value?.id || 'all')

  //   FIXME: need number but sending string
  // @ts-ignore
  const defaultValue: User =
    selectedUserId === 'all'
      ? {
        firstName: 'users',
        lastName: '',
        id: 'all',
        nickname: 'All',
      }
      : users.filter((user) => user.id === Number(selectedUserId))[0]

  const options = [defaultValue, ...users]

  const getOptionSelected = (option, value) => option.id === value.id
  const getOptionLabel = (user) => user.nickname === 'All' ? user.nickname : `${user.nickname} - ${user.last_name} ${user.first_name}`

  return (
    <div>
      <InputLabel>Select a person</InputLabel>

      <Autocomplete
        // FIXME: comapre number a string
        defaultValue={defaultValue}
        options={options}
        onChange={handleChange}
        getOptionLabel={getOptionLabel} // getOptionLabel is for proper display of selected value
        getOptionSelected={getOptionSelected}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
          />
        )}
      />
    </div>
  )
}
