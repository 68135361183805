import React from 'react'
import { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const SyncIcon: FC<IconSVGProps> = ({ width = 24, height = 24 }) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M17.655 7.665a7.333 7.333 0 00-12.988 4.664v2.002M6.667 17.36a7.333 7.333 0 0012.667-5.031v-1.334"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M2 11.662l2.667 2.667 2.666-2.667M22 13.662l-2.666-2.667-2.667 2.667"
    ></path>
  </svg>
)
