import React, { FC } from 'react'
import { FormControl, FormLabel } from '@material-ui/core'
import { transactionCategories } from '../utils'
import { FormSelect } from '../../../components/inputs/FormSelect'
import { FormikProps, FormikValues } from 'formik'
import { theme } from '../../../styles/muiThemes'
import styled from 'styled-components'

interface Props {
  formikProps: FormikProps<FormikValues>
  isAllTransactions: boolean
}

export const ScopeFilter: FC<Props> = ({ formikProps }) => {
  return (
    <StyledFormControl fullWidth>
      <FormLabel>Type</FormLabel>
      <FormSelect
        name={'scope'}
        value={formikProps.values.scope}
        onChange={(value) => {
          formikProps.handleChange(value)
          formikProps.handleSubmit()
        }}
        values={transactionCategories}
      />
    </StyledFormControl>
  )
}

const StyledFormControl = styled(FormControl)`
  ${theme.breakpoints.up('xl')} {
    flex: 1 0 160px;
  }
`
