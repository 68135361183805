import React, { FC } from 'react'
import { Enums, Project } from '../../types'
import { DataLine } from '../../../../types'
import { TextFieldData } from '../../../../types/UpdatableFormFieldData/TextFieldData'
import { SelectFieldData } from '../../../../types/UpdatableFormFieldData/SelectFieldData'
import { SwitchFieldData } from '../../../../types/UpdatableFormFieldData/SwitchFieldData'
import { UpdatableItemsGroup } from '../../../../components/ui/UpdatableFormField/UpdatableItemsGroup'

type Props = {
  project: Project;
  enums: Enums;
};
export const ProjectInfoTab: FC<Props> = (props) => {
  const { project, enums } = props
  const items: DataLine[] = [
    {
      label: 'Name (group: name)',
      updatableFields: [new TextFieldData(project.fullName || '', 'name')],
    },
    {
      label: 'Is billable',
      updatableFields: [new SwitchFieldData(project.isBillable, 'is_billable')],
    },
    {
      label: 'Is opportunity',
      updatableFields: [new SwitchFieldData(project.isOpportunity, 'is_opportunity')],
    },
    {
      label: 'Owned by tribe',
      updatableFields: [
        new SelectFieldData(
          project.ownedByTribeId,
          'owned_by_tribe_id',
          enums.tribes
        ),
      ],
    },
    {
      label: 'Guru',
      updatableFields: [
        new SelectFieldData(
          project.guruId,
          'guru_id',
          enums.users
        ),
      ],
    },
    {
      label: 'Team Lead',
      updatableFields: [
        new SelectFieldData(
          project.teamLeadId,
          'team_lead_id',
          enums.users
        ),
      ],
    },
    {
      label: 'Support',
      updatableFields: [
        new SelectFieldData(
          project.supportId,
          'support_id',
          enums.users
        ),
      ],
    },
    {
      label: 'Contracted FTEs',
      updatableFields: [
        new TextFieldData(project.contractedFte, 'contracted_fte', 'number'),
      ],
    },
  ]
  return (
    <UpdatableItemsGroup
      items={items}
      updatePaths={[`/api/projects/${project.id}`]}
    />
  )
}
