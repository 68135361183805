import { FormControl, FormLabel } from '@material-ui/core'
import React, { FC } from 'react'
import styled from 'styled-components'
import { FormDatePicker } from '../../../components/inputs/FormDatePicker'
import { format } from 'date-fns'
import { FormikProps, FormikValues } from 'formik'
import { theme } from '../../../styles/muiThemes'

interface Props {
  formikProps: FormikProps<FormikValues>
}

export const DateFilter: FC<Props> = ({ formikProps }) => {

  const handleDateChange = (
    filterName: string,
    date: Date | 'Invalid Date' | null
  ) => {
    if (date != 'Invalid Date' && date != null) formikProps.setFieldValue(filterName,format(date, 'yyyy-MM-dd'), true)
  }

  return (
    <>
      <StyledFormControl fullWidth>
        <FormLabel>Date from</FormLabel>
        <StyledFormDatePicker
          fullWidth
          inputVariant="outlined"
          placeholder={'Date from'}
          name='date_from'
          onChange={(date)=>{
            handleDateChange('date_from',date)
            formikProps.handleSubmit()
          }}
          value={formikProps.values.date_from ? new Date(formikProps.values.date_from) : null}
        />
      </StyledFormControl>
      <StyledFormControl fullWidth>
        <FormLabel>Date to</FormLabel>
        <StyledFormDatePicker
          fullWidth
          inputVariant="outlined"
          placeholder={'Date to'}
          name='date_to'
          onChange={(date)=>{
            handleDateChange('date_to',date)
            formikProps.handleSubmit()
          }}
          value={formikProps.values.date_to ? new Date(formikProps.values.date_to) : null}
        />
      </StyledFormControl>
    </>
  )
}

const StyledFormControl = styled(FormControl)`
  ${theme.breakpoints.up('xl')} {
    flex: 1 0 160px;
  }
`

const StyledFormDatePicker = styled(FormDatePicker)`
& > div{
  padding: 0;
}
`
