import React, { FC } from 'react'
import { FormikProps } from 'formik'
import { Accordion, AccordionDetails } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import { hasError, helperText } from '../../../../utils/helpers'
import { StyledTextField } from '../../../ProjectsPage/components/AddNewProject/styles'
import { StyledAccordionSummary, BoldText, DetailWrapper, StyledCorpSelect } from '../styles'
import { filterCompetencies, getAmountByLevel, getLevelsFromCompetency } from '../utils'
import { COMPETENCIES, COMPETENCY_TYPE, Competency, Rate, RateFormValues } from '../types'
import { User } from '../../../UsersPage/types'

interface Props {
  rateTitle: COMPETENCIES;
  formikProps: FormikProps<RateFormValues>;
  competencies: Competency[];
  activeRate?: Rate;
  user: User;
}

export const RateBaseView: FC<Props> = ({ rateTitle, formikProps, competencies, activeRate, user }) => {
  const competencyData = filterCompetencies(competencies, rateTitle)
  const { values, handleChange } = formikProps

  const levels = getLevelsFromCompetency(competencyData, user.billingCurrency) ?? []
  const isActiveRate = activeRate && 'rateComponentName' in activeRate && activeRate.rateComponentName === rateTitle
  const rateByLevel = activeRate && 'competencyLevel' in activeRate && getAmountByLevel(competencies, activeRate?.competencyLevel, user.billingCurrency)
  const isNonStandardRate = rateByLevel !== values[`${COMPETENCY_TYPE[rateTitle]}_rate`]
  const nonStandardRate = isNonStandardRate ? values[`${COMPETENCY_TYPE[rateTitle]}_rate`] : ''

  return (
    <Accordion expanded={isActiveRate ? isActiveRate : undefined}>
      <StyledAccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls='rateTitle'
        id={rateTitle}
      >
        <BoldText>{rateTitle}</BoldText>
      </StyledAccordionSummary>
      <AccordionDetails>
        <DetailWrapper>
          <StyledCorpSelect
            name={`${COMPETENCY_TYPE[rateTitle]}_level`}
            values={levels}
            defaultValue={values[`${COMPETENCY_TYPE[rateTitle]}_level`]}
            onChange={
              handleChange
            }
            label={'Competency level'}
            error={hasError(formikProps, `${COMPETENCY_TYPE[rateTitle]}_level`)}
            helperText={helperText(formikProps, `${COMPETENCY_TYPE[rateTitle]}_level`)}
          />
          <StyledTextField
            fullWidth
            value={nonStandardRate}
            name={`${COMPETENCY_TYPE[rateTitle]}_rate`}
            label="Non-standard rate"
            onChange={handleChange}
            error={hasError(formikProps, `${COMPETENCY_TYPE[rateTitle]}_rate`)}
            helperText={helperText(formikProps, `${COMPETENCY_TYPE[rateTitle]}_rate`)}
          />
          <StyledTextField
            required={!!values[`${COMPETENCY_TYPE[rateTitle]}_rate`]}
            fullWidth
            name={`${COMPETENCY_TYPE[rateTitle]}_note`}
            label="Comment"
            onChange={handleChange}
            error={hasError(formikProps, `${COMPETENCY_TYPE[rateTitle]}_note`)}
            helperText={helperText(formikProps, `${COMPETENCY_TYPE[rateTitle]}_note`)}
          />
        </DetailWrapper>
      </AccordionDetails>
    </Accordion>
  )
}
