import styled from 'styled-components'
import { theme } from '../../styles/muiThemes'

export const TabContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 30px;
  //padding-top: 20px;
  ${theme.breakpoints.down('md')} {
    display: block;
  }
  > * {
    flex-basis: calc(50% - 25px);
    flex-grow: 1;
  }
`
