import { Form } from 'formik'
import styled from 'styled-components'

import { theme } from '../../../styles/muiThemes'
import { Button } from '../../../components/buttons/Button'

export const FormWrapper = styled.div`
  --row-gap: 12px;
  --column-gap-mobile: 16px;
  --column-gap-desktop: 20px;

  display: flex;
  flex-direction: column;
  row-gap: var(--row-gap);
  column-gap: var(--column-gap-mobile);

  ${theme.breakpoints.up('2xl')} {
    flex-direction: row;
    column-gap: var(--column-gap-desktop);
    align-items: end;
  }
`

export const StyledForm = styled(Form)`
  display: contents;

  label {
    margin-bottom: 4px
  }

  ${theme.breakpoints.up('xl')} {
    display: flex;
    flex-direction: column;
    row-gap: var(--row-gap);
  }

  ${theme.breakpoints.up('2xl')} {
    flex-direction: row;
    column-gap: var(--column-gap-desktop);
    width: 100%;

    & > * {
      flex: 1 0 160px;
    }
  }
`

export const RowWrapper = styled.div`
  display: contents;

  ${theme.breakpoints.up('xl')} {
    display: flex;
    column-gap: var(--column-gap-desktop);
  }

  ${theme.breakpoints.up('2xl')} {
    display: contents;
  }
`

export const AmountWrapper = styled.div`
  display: contents;

  ${theme.breakpoints.up('md')} {
    display: flex;
    column-gap: var(--column-gap-desktop);
  }

  ${theme.breakpoints.up('2xl')} {
    display: contents;
  }
`

export const UsersAndScopeWrapper = styled.div`
  ${theme.breakpoints.up('md')} {
    display: flex;
    column-gap: var(--column-gap-desktop);
  }

  ${theme.breakpoints.up('xl')} {
    display: contents;
  }
`

export const DateWrapper = styled.div`
  display: contents;

  ${theme.breakpoints.up('sm')} {
    display: flex;
    column-gap: var(--column-gap-desktop);
  }

  ${theme.breakpoints.up('xl')} {
    display: contents;
  }
`

export const MobileClearButton = styled(Button)`
  width: fit-content;
`
