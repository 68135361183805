import React, { FC, useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'
import { useQuery, useQueryClient } from 'react-query'
import { AssignUserForm } from './AssignUserForm'
import { AssignUserVariables, User, Users } from './types'
import { TopLinearProgressBar } from '../../components/ui/TopLinearProgressBar'
import { Mutation, useCustomMutation } from '../../hooks/useCustomMutation'

import { useSnackbar } from 'notistack'
import ReactQueryKeys from '../../utils/ReactQueryKeys'
import { useQueryNotification } from '../UserInfo/utils'

type Props = {
  userId: number;
  projectId: number;
  projectName: string;
  isAssigningUserForm: boolean;
  done?: () => void;
};

export const AssignUserModalContent: FC<Props> = (props) => {
  const { userId, projectId, projectName, isAssigningUserForm, done } = props
  const { errorNotification } = useQueryNotification()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const [userToFetchId, setUserToFetchId] = useState<number | undefined>(
    undefined
  )

  const reloadPageOnSnackClose = () => (done ? done() : location.reload())
  // TODO: move fetchUser to parent component to prefetch data if got userID

  const {
    data: user,
    isFetching: loadingUser,
    refetch,
  } = useQuery<User>(
    ReactQueryKeys.userDetail(Number(userId || userToFetchId)),
    {
      enabled: !!userId || !!userToFetchId,
    }
  )

  const { data: users, isFetching: loadingUsers } = useQuery<Users>(
    `/api/projects/${projectId}/users/assignable`,
    {
      enabled: !userId && !userToFetchId,
    }
  )

  const createPostMutation: Mutation<AssignUserVariables> = (variables) => ({
    path: `/api/users/${user?.id}/projects/${projectId}/assign`,
    params: {
      ...variables,
    },
  })

  const mutation = useCustomMutation<{}, Error, AssignUserVariables>(
    createPostMutation,
    {
      onSuccess: async () => {
        queryClient.refetchQueries(
          `/api/users/${user?.id}/projects/assigned?state=active`
        )
        queryClient.refetchQueries(
          `/api/users/${user?.id}/projects/assignable`
        )
        await enqueueSnackbar(
          `${user?.firstName} ${user?.lastName} was assigned to ${projectName}`,
          {
            variant: 'success',
            autoHideDuration: 1000,
            onClose: reloadPageOnSnackClose,
          }
        )
      },
      onError: (error) => {
        errorNotification(error.message || 'Something went wrong')
      },
    }
  )

  useEffect(() => {
    if (userToFetchId) {
      refetch()
    }

    if (userToFetchId && mutation.isSuccess) {
      mutation.reset()
    }
  }, [userToFetchId])

  if (!isAssigningUserForm && (loadingUser || loadingUsers))
    return <div>LOADING</div>

  return (
    <>
      {/*showing loading on isSuccess before page reload*/}
      {(mutation.isLoading || loadingUser || mutation.isSuccess) && (
        <TopLinearProgressBar />
      )}
      <Typography variant="overline">
        {isAssigningUserForm ? 'Assign person to project' : 'Join project'}
      </Typography>

      <Typography variant="h3">{projectName}</Typography>

      <AssignUserForm
        user={user}
        users={users}
        projectId={projectId}
        mutation={mutation}
        isAssigningUserForm={isAssigningUserForm}
        setUserToFetchId={setUserToFetchId}
      />
    </>
  )
}
