import React, { FC } from 'react'
import { FormControl, TextField } from '@material-ui/core'

import {
  CheckboxWrapper,
  LabelTextForCheckbox,
  StyledCheckbox,
  StyledFormSelect,
} from './styles'
import { Props } from './types'
import { getValuesWithNoValuePossibilities } from './utils'
import { FormPartWrapper } from './FormPartWrapper'
import { hasError, helperText } from '../../../../utils/helpers'

export const BasicInfoFormPart: FC<
  Omit<Props, 'categories' | 'billingType'>
> = ({ formikProps, tribes }) => {
  const { values, handleChange } = formikProps
  return (
    <FormPartWrapper sectionTitle="Basic info">
      <FormControl fullWidth>
        <TextField
          fullWidth
          required
          label="Name"
          type="text"
          name="name"
          value={values.name}
          variant="outlined"
          onChange={handleChange}
          error={hasError(formikProps, 'name')}
          helperText={helperText(formikProps, 'name')}
          placeholder={'Project name'}
        ></TextField>
      </FormControl>

      <StyledFormSelect
        name={'owned_by_tribe_id'}
        value={values.owned_by_tribe_id}
        label={'Owned by tribe'}
        values={getValuesWithNoValuePossibilities(tribes)}
        error={hasError(formikProps, 'owned_by_tribe_id')}
        helperText={helperText(formikProps, 'owned_by_tribe_id')}
      />

      <CheckboxWrapper>
        <LabelTextForCheckbox
          control={<StyledCheckbox
            name="is_billable"
            color="primary"
            onChange={handleChange}
          />}
          label="Is billable" />
      </CheckboxWrapper>

      <CheckboxWrapper>
        <LabelTextForCheckbox
          control={<StyledCheckbox
            name="is_opportunity"
            color="default"
            onChange={handleChange}
          />}
          label="Is opportunity" />
      </CheckboxWrapper>
    </FormPartWrapper>
  )
}
