import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const PinIcon: FC<IconSVGProps> = ({ width = 24, height = 24, color }) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.25781 9.97913L14.0208 16.7421C14.4914 16.2323 14.8132 15.6033 14.9511 14.9234C15.0891 14.2435 15.038 13.5388 14.8034 12.8859L19.9899 7.70017L20.1438 7.8532C20.3488 8.05124 20.6233 8.16087 20.9083 8.15848C21.1932 8.15608 21.4659 8.04185 21.6674 7.84039C21.869 7.63893 21.9834 7.36636 21.986 7.08139C21.9885 6.79641 21.879 6.52183 21.6811 6.31679L17.6814 2.31708C17.4772 2.11357 17.2005 1.99951 16.9122 2C16.6239 2.00049 16.3476 2.11549 16.1441 2.31969C15.9406 2.52389 15.8266 2.80057 15.827 3.08887C15.8275 3.37716 15.9425 3.65346 16.1467 3.85697L16.3006 4.01087L11.1141 9.19745C10.4612 8.96299 9.7566 8.91189 9.07676 9.0497C8.39691 9.18751 7.76783 9.50896 7.25781 9.97913Z"
      fill={color || 'none'}
      stroke="#151B36"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"/>
    <path
      d="M10.6394 13.3606L2 22"
      fill="none"
      stroke="#151B36"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"/>
  </svg>
)
