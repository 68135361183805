import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const QrCode: FC<IconSVGProps> = ({
  width = 24,
  height = 24,
  color = '#14141F',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 12.666V14h1.333M2 2h5.333v5.333H2V2zM16.667 2H22v5.333h-5.333V2zM2 16.666h5.333V22H2v-5.334zM12 2h2M10 2v2.667h2M14 4.667v2.666h-4M2 10h5.333v1.333M6 14h1.333M22 22h-2.667v-2.667H22v-2.666h-2.667M14 22h-4v-5.334M16.667 16.666V22M12.667 16.666H14v2.667h-1.333M10 12.666V14h1.333M14 14h2.667M15.333 14v-2.666M10 10h2.667v1.333M22 10v1.333M22 13.334V14M18 10v1.333h1.333v2"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
