import { SelectItem, UserInProject } from '../../types'
import { Project } from '../ProjectInfo/types'
import Color from 'color'
import { GraphData } from './types'

export type SortedUsers = {
  inactive: UserInProject[];
  active: UserInProject[];
  deactivated: UserInProject[];
};
export const sortUsersByActivityInProject = (users?: UserInProject[]) => {
  const defaultState: SortedUsers = {
    active: [],
    inactive: [],
    deactivated: [],
  }
  return (
    users?.reduce((acc, user) => {
      acc[user.projectStatus].push(user)
      return acc
    }, defaultState) ?? defaultState
  )
}

export const paginateUsers = (
  users: UserInProject[],
  currentPage: number,
  usersPerPage: number
) => {
  const from = currentPage * usersPerPage
  return users.slice(from, currentPage * usersPerPage + usersPerPage)
}

export const isUserInProject = (project: Project, userId: number): boolean => {
  if (!project.users) return false
  return !!project.users.find((user) => user.id === userId && user.projectStatus !== 'inactive')
}

export const colorChartJSDataset = (data: GraphData): GraphData => {
  const colors = {
    hours_budget: '#4BC0C0FF',
    hours_budget_actual: '#B8137DFF',
    hours_used: '#CC940BFF',
  }
  data.datasets.forEach((dataset) => {
    // @ts-ignore
    const color = Color(colors[String(dataset.key)])
    const backgroundColor = color.fade(0.95).hsl().string()
    dataset.borderColor = color.string()
    dataset.fill = {
      below: 'rgb(184,19,125)',
      above: backgroundColor,
      target: 'origin',
    }
    dataset.backgroundColor = color.string()
    dataset.tension = 0.4
  })
  return data
}

export const computeUserStates = (
  activeUsers: number,
  inactiveUsers: number
): SelectItem[] => [
  {
    label: `Active (${activeUsers})`,
    value: 'active',
  },
  {
    label: `Inactive (${inactiveUsers})`,
    value: 'inactive',
  },
]
