import React, { FC, useMemo, useState } from 'react'
import { UsersPageView } from './UsersPageView'
import { StatusFilter } from '../../enum'
import { useQuery } from 'react-query'
import { Users } from './types'
import { useUserFiltering, selectableTribes, getSortedUsers } from './utils'
import { useTribes } from '../../hooks/useTribes'
import { SelectItem } from '../../types'

type Props = {
  userRoles: SelectItem[];
};

export const UsersPage: FC<Props> = ({ userRoles }) => {
  const [selectedUserStatus, setSelectedUserStatus] = useState<StatusFilter>(
    StatusFilter.Active
  )

  const { data, isLoading, error, refetch } = useQuery<Users>(
    `/api/users?state=${selectedUserStatus}`
  )

  const sortedUsers = getSortedUsers(data?.users)

  const { tribes } = useTribes()

  const tribesSelectable = useMemo(() => selectableTribes(tribes), [tribes])

  const {
    searchString,
    filteredUsers,
    handleTribeChange,
    owningTribe,
    recalculateUsersBalance,
  } = useUserFiltering(sortedUsers)

  return (
    <UsersPageView
      selectedUsersStatus={selectedUserStatus}
      setSelectedUsersStatus={setSelectedUserStatus}
      users={filteredUsers}
      isLoading={isLoading}
      error={error}
      searchString={searchString}
      owningTribe={owningTribe}
      handleTribeChange={handleTribeChange}
      metadata={data?.metadata}
      refetchUsers={refetch}
      tribes={tribesSelectable}
      recalculateUsersBalance={recalculateUsersBalance}
      userRoles={userRoles}
    />
  )
}
