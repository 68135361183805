import React from 'react'
import { FC } from 'react'
import { colors } from '../../../styles/colors'
import { IconSVGProps } from '../Icon'

export const WarningIcon: FC<IconSVGProps> = ({
  width = 24,
  height = 24,
  color = colors.grey1,
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6879_23137)">
      <path
        d="M12 18.2993C11.9258 18.2993 11.8533 18.3198 11.7917 18.3583C11.73 18.3968 11.6819 18.4514 11.6535 18.5154C11.6252 18.5793 11.6177 18.6497 11.6322 18.7176C11.6467 18.7855 11.6824 18.8479 11.7348 18.8968C11.7873 18.9458 11.8541 18.9791 11.9268 18.9926C11.9996 19.0061 12.075 18.9992 12.1435 18.9727C12.212 18.9462 12.2706 18.9013 12.3118 18.8438C12.353 18.7862 12.375 18.7185 12.375 18.6493C12.375 18.5565 12.3355 18.4675 12.2652 18.4018C12.1948 18.3362 12.0995 18.2993 12 18.2993Z"
        stroke={`${color}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M12 15.501V8.50098"
        stroke={`${color}`}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round" />
      <path
        d="M13.621 2.44244C13.4722 2.15966 13.2414 1.92146 12.9549 1.75488C12.6684 1.5883 12.3376 1.5 12 1.5C11.6624 1.5 11.3316 1.5883 11.0451 1.75488C10.7586 1.92146 10.5278 2.15966 10.379 2.44244L0.90601 20.4539C0.79198 20.6704 0.738946 20.9105 0.751916 21.1514C0.764886 21.3923 0.843432 21.6262 0.980137 21.831C1.11684 22.0357 1.3072 22.2046 1.53324 22.3216C1.75927 22.4386 2.01353 22.4999 2.27201 22.4998H21.728C21.9865 22.4999 22.2407 22.4386 22.4668 22.3216C22.6928 22.2046 22.8832 22.0357 23.0199 21.831C23.1566 21.6262 23.2351 21.3923 23.2481 21.1514C23.2611 20.9105 23.208 20.6704 23.094 20.4539L13.621 2.44244Z"
        stroke={`${color}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_6879_23137">
        <rect
          width="24"
          height="24"
          fill="white" />
      </clipPath>
    </defs>
  </svg>

)
