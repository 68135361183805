import React, { FC } from 'react'
import { DataLine } from '../../../types'
import { Address } from '../types'
import { TextFieldData } from '../../../types/UpdatableFormFieldData/TextFieldData'
import { UpdatableItemsGroup } from '../../../components/ui/UpdatableFormField/UpdatableItemsGroup'
import { SelectFieldData } from '../../../types/UpdatableFormFieldData/SelectFieldData'
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { canUpdateUser } from './Tabs/UserInfoTab'

type Props = {
  address: Address;
  userId: number;
  enums: any;
  deleteAddress: (addressId: number) => void;
};
export const AddressTable: FC<Props> = (props) => {
  const { address, userId, enums, deleteAddress } = props
  const items: DataLine[] = [
    {
      label: 'Address type',
      updatableFields: [
        new SelectFieldData(
          address.addressType,
          'addressType',
          enums.addressTypes
        ),
      ],
    },
    {
      label: 'Street 1',
      updatableFields: [new TextFieldData(address.street1, 'street1')],
    },
    {
      label: 'Street 2',
      updatableFields: [new TextFieldData(address.street2, 'street2')],
    },
    {
      label: 'Town',
      updatableFields: [new TextFieldData(address.town, 'town')],
    },
    {
      label: 'Country',
      updatableFields: [
        new SelectFieldData(
          address.countryCode,
          'countryCode',
          enums.countries
        ),
      ],
    },
    {
      label: 'Post code',
      updatableFields: [new TextFieldData(address.postCode, 'postCode')],
    },
  ]
  return (
    <>
      <UpdatableItemsGroup
        updatePaths={[`/api/users/${userId}/addresses/${address.id}`]}
        items={items}
        updatable={canUpdateUser()}
      />
      {canUpdateUser() && (
        <IconButton
          size={'small'}
          color={'primary'}
          onClick={() => deleteAddress(address.id)}
        >
          <DeleteIcon fontSize={'small'} />
        </IconButton>
      )}
    </>
  )
}
