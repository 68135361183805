import React, { FC } from 'react'
import { formatISO } from 'date-fns'
import { useQueryClient } from 'react-query'

import { Button, ButtonType } from '../../components/buttons/Button'
import { TestingDevices } from './types'
import { Mutation, useCustomMutation } from '../../hooks/useCustomMutation'
import { useQueryNotification } from '../UserInfo/utils'
import { GENERIC_BE_ERROR_MSG } from '../../constants'

type Props = {
  device: TestingDevices;
  buttonType: ButtonType;
};

export const ReturnDeviceButton: FC<Props> = ({ device, buttonType }) => {
  const queryClient = useQueryClient()
  const { successNotification, errorNotification } = useQueryNotification()

  const createReturnDeviceSubjectMutation: Mutation<TestingDevices> = (
    initiVals
  ) => ({
    path: `/api/testing_devices/${device.id}/release`,
    method: 'POST',
    params: initiVals,
  })

  const returnDeviceSubjectMutation = useCustomMutation(
    createReturnDeviceSubjectMutation,
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(
          `/api/testing_devices/${device.id}/release`
        )
        await queryClient.refetchQueries(`/api/testing_devices/${device.id}`)
        await queryClient.refetchQueries('/api/testing_devices')
        successNotification('Device was returned')
      },
      onError: () => {
        errorNotification(GENERIC_BE_ERROR_MSG)
      },
    },
    true
  )

  const updateDate = formatISO(new Date())

  const handleReturnDevice = async () => {
    const returnValues = {
      serial: device.serial,
      brand: device.brand,
      name: device.name,
      systemVersion: device.systemVersion,
      inventoryNumber: device.inventoryNumber,
      inventoryUrl: device.inventoryUrl,
      createdAt: device.createdAt,
      location: null,
      sublocation: '',
      updatedAt: updateDate,
      taken: false,
      currentUser: null,
    }

    await returnDeviceSubjectMutation.mutateAsync(returnValues)
  }

  return (
    <Button
      fullWidth
      kind={buttonType}
      onClick={handleReturnDevice}
    >
      Return
    </Button>
  )
}
