import React, { useState, FC, memo, useEffect } from 'react'
import { CorpSelect } from '../../../components/inputs/CorpSelect'
import styled from 'styled-components'
import { SearchField } from '../../../components/inputs/SearchField'
import { SelectItem } from '../../../types'
import { handleQueryParams } from '../../../utils/urlHelpers'
import { atMinWidth } from '../../../styles/helpers'

type Props = {
  tribes?: SelectItem[];
  owningTribe: number;
  handleTribeChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string
  ) => void;
  handleFilterChange: (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string
  ) => void;
};

export const ProjectFilter: FC<Props> = ({
  tribes,
  owningTribe,
  handleTribeChange,
  handleFilterChange,
}) => {
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    const params = new URLSearchParams(document.location.search)
    const tribe = params.get('tribe')
    const searchedString = params.get('search')
    if (tribe) {
      handleTribeChange(tribe)
    }
    if (searchedString) {
      setInputValue(searchedString)
      handleFilterChange(searchedString)
    }
  })
  return (
    <StyledContainer>
      {tribes && (
        <CorpSelect
          values={tribes}
          value={owningTribe}
          onChange={(
            e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            handleTribeChange(e)
            handleQueryParams(e, 'tribe')
          }}
          label={'Tribe'}
        />
      )}
      <StyledSearchField
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value)
          handleFilterChange(e)
          handleQueryParams(e, 'search')
        }}
      />
    </StyledContainer>
  )
}

export const StyledContainer = memo(styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 2em;

  ${atMinWidth.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`)

const StyledSearchField = styled(SearchField)`
  width: 100%;

  ${atMinWidth.tablet} {
    width: auto;
  }
`
