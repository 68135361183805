import React, {
  ChangeEvent,
  MouseEvent,
  FC,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react'
import { Typography } from '@material-ui/core'
import { UsersTable } from './components/UsersTable'
import { User, Users } from './types'
import { PageLayoutWrapper } from '../../components/ui/PageLayoutWrapper'
import { ContentPaper } from '../../components/ContentPaper'
import { SelectItem } from '../../types'
import { StatusFilter } from '../../enum'
import { StatusSwitcher } from '../ProjectsPage/components/StatusSwitcher'
import { QueryObserverResult, RefetchOptions } from 'react-query'
import { Metadata } from '../ProjectsPage/types'
import { RowFlex } from '../ProjectsPage/ProjectsPageView'
import { AdminButtons } from './components/AdminButtons'
import { TribeFilter } from '../../components/TribeFilter'
import { useSearchFieldState } from '../../components/inputs/SearchField'
import styled from 'styled-components'
import { atMaxWidth } from '../../styles/helpers'

type Props = {
  setSelectedUsersStatus: Dispatch<SetStateAction<StatusFilter>>;
  selectedUsersStatus: StatusFilter;
  handleTribeChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  recalculateUsersBalance: (event: MouseEvent<HTMLButtonElement>) => void;
  refetchUsers: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<Users, unknown>>;
  users?: User[];
  metadata?: Metadata;
  searchString: string;
  owningTribe: number;
  tribes?: SelectItem[];
  error;
  isLoading;
  userRoles: SelectItem[];
};

export const UsersPageView: FC<Props> = ({
  selectedUsersStatus,
  setSelectedUsersStatus,
  users,
  metadata,
  handleTribeChange,
  tribes,
  refetchUsers,
  owningTribe,
  recalculateUsersBalance,
  isLoading,
  userRoles,
}) => {
  const { searchString, handleFilterChange, compare } = useSearchFieldState()

  const filteredUsers = useMemo(() => {
    return searchString
      ? users?.filter((user) =>
        compare([user.firstName, user.lastName, user.nickname, user.role, user.email])
      )
      : users
  }, [users, searchString])

  return (
    <PageLayoutWrapper>
      <ContentPaper>
        <Typography variant="h3">People</Typography>
        <AdminButtons
          recalculateUsersBalance={recalculateUsersBalance}
          refetchUsers={refetchUsers}
          userRoles={userRoles}
        />
        <StyledRowGrid>
          <StatusSwitcher
            selectedProjectsStatus={selectedUsersStatus}
            setSelectedProjectsStatus={setSelectedUsersStatus}
            isLoading={isLoading}
            metadata={metadata}
          />

          <TribeFilter
            handleFilterChange={handleFilterChange}
            handleTribeChange={handleTribeChange}
            tribes={tribes}
            owningTribe={owningTribe}
            searchString={searchString}
          />
        </StyledRowGrid>
        <UsersTable
          users={filteredUsers}
          isLoading={isLoading}
        />
      </ContentPaper>
    </PageLayoutWrapper>
  )
}

const StyledRowGrid = styled(RowFlex)`
  justify-content: flex-start;
  align-items: start;
  
  ${atMaxWidth.desktop} {
    display: flex;
    flex-direction: column;
  }
`
