import { csrfToken } from '../services/api'
import { UserStateAction } from '../bundles/UserDetailPage/types'

export const fetchHeaders = new Headers({
  'X-CSRF-Token': csrfToken() as string,
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
})

export const getInit: RequestInit = {
  method: 'GET',
  headers: fetchHeaders,
}

export const postInit = {
  method: 'POST',
  headers: fetchHeaders,
}

export const fetchHelper = async (url: string) => {
  const response = await fetch(url, getInit)
  return response.json()
}

export const removeUserFromProject = async (
  userId: number,
  projectId: number
) => {
  const response = await fetch(
    `/api/users/${userId}/projects/${projectId}/unassign`,
    {
      method: 'PUT',
      headers: fetchHeaders,
    }
  )
  return response.json()
}

export const addUserToProject = async (
  userId: number,
  projectId: number,
  togglProjectId: number,
  togglProjectName: string,
  tribeId: number
) => {
  const body = { 'toggl_project_id': togglProjectId, 'toggl_project_name': togglProjectName, 'tribe_id': tribeId }
  const response = await fetch(
    `/api/users/${userId}/projects/${projectId}/assign`,
    {
      method: 'POST',
      headers: fetchHeaders,
      body: JSON.stringify(body)
    }
  )
  return response.json()
}

export const pinProject = async (projectId: number): Promise<unknown> => {
  return fetch(`/api/projects/${projectId}/pin_to_dashboard.json`, {
    method: 'POST',
    headers: fetchHeaders,
  })
}

export const deactivateProject = async (
  projectId: number
): Promise<unknown> => {
  return fetch(`/api/projects/${projectId}/deactivate`, {
    method: 'PUT',
    headers: fetchHeaders,
  })
}

export const reactivateProject = async (
  projectId: number
): Promise<unknown> => {
  return fetch(`/api/projects/${projectId}/activate`, {
    method: 'PUT',
    headers: fetchHeaders,
  })
}

export const handleUserActivation = async (
  userId: number,
  isActivated: boolean
): Promise<unknown> => {
  return fetch(
    `/admin/users/${userId}/${isActivated ? UserStateAction.deactivate : UserStateAction.activate
    }`,
    {
      method: 'PUT',
      headers: fetchHeaders,
    }
  )
}
