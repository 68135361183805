import React, { FC } from 'react'
import { Typography } from '@material-ui/core'

import { ContentPaper } from '../../components/ContentPaper'
import { TestingDevices } from '../TestingDevicesPage/types'
import { HistoryTable } from './DeviceHistoryTable'

type Props = {
  device: TestingDevices;
  isLoading: boolean;
};

export const DeviceHistory: FC<Props> = ({ device, isLoading }) => (
  <ContentPaper>
    <Typography variant={'h3'}>Holder history</Typography>
    <HistoryTable
      device={device}
      isLoading={isLoading} />
  </ContentPaper>
)
