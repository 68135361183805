import React, { FC } from 'react'
import { TabsLayout } from '../../components/ui/TabsLayout'
import { UserInfoTab } from './components/Tabs/UserInfoTab'
import { ContentPaper } from '../../components/ContentPaper'
import { TribesTab } from './components/Tabs/TribesTab'
import { ContactsTab } from './components/Tabs/ContactsTab'
import { PageLayoutWrapper } from '../../components/ui/PageLayoutWrapper'
import { useQuery } from 'react-query'
import { ContractsTab } from './components/Tabs/ContractsTab'
import { BaseUser } from '../../types'
import { UserInfoProvider } from './components/UserInfoProvider'
import { UserTribe } from './types'
import { useCurrentUserInfo } from '../../components/CurrentUserProvider'

type Props = {
  user: UserInfo;
  enums: any;
};

export interface UserInfo extends BaseUser {
  contracts: UserTribe[];
  pairingCode: string;
  togglHours3monthAverage: { hours: number }
}

export const UserInfo: FC<Props> = (props) => {
  const queryOptions = {
    initialData: props.user,
  }
  const queryKey = `/api/users/${props.user?.id}`
  const { data: user } = useQuery<UserInfo>(queryKey, queryOptions)

  const { currentUser, userPermissions } = useCurrentUserInfo()

  const sensitiveDataAccess =
    user?.id === currentUser.id ||
    userPermissions?.all?.manage === true ||
    userPermissions?.user?.manage === true

  const headings = ['Personal', 'Tribes', 'Contracts', 'Contacts']
  const tabs = [
    <UserInfoTab
      key={0}
      enums={props.enums}
    />,
    ...user ? [
      <TribesTab
        key={2}
        enums={props.enums}
        user={user}
        sensitiveDataAccess={sensitiveDataAccess}
      />
    ] : [],
    ...sensitiveDataAccess && user ? (
      [
        <ContractsTab
          key={1}
          user={user}
        />,
        <ContactsTab
          key={3}
          user={user}
        />
      ]
    ) : [],
  ]

  return (
    <PageLayoutWrapper>
      <ContentPaper>
        <UserInfoProvider
          user={user}
          sensitiveDataAccess={sensitiveDataAccess}
        >
          <TabsLayout
            headings={headings}
            tabsContent={tabs}
            startTab={0}
          />
        </UserInfoProvider>
      </ContentPaper>
    </PageLayoutWrapper>
  )
}
