import React, { FC, useCallback } from 'react'
import { InvoiceStat } from '../types'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import styled from 'styled-components'
import { HoursFormatter, MoneyFormatter } from '../../../utils/formatters'

type Props = {
  tribe: InvoiceStat;
};
export const InvoiceDetailTribe: FC<Props> = ({ tribe }) => {
  const formatMoney = useCallback(
    (value: number) => {
      const moneyFormatter = new MoneyFormatter(tribe.currency)
      return moneyFormatter.format(value)
    },
    [tribe]
  )

  const formatHours = useCallback((value: number) => {
    const formatter = new HoursFormatter()
    return formatter.format(value)
  }, [])
  return (
    <Box mt={5}>
      <StyledHeading variant={'h3'}>{tribe.tribe}</StyledHeading>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Rate</TableCell>
            <TableCell align="center">Hours</TableCell>
            <TableCell align="right">Billable amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tribe.items.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{formatMoney(row.rate)}</TableCell>
              <TableCell align={'center'}>{formatHours(row.hours)}</TableCell>
              <TableCell align={'right'}>{formatMoney(row.amount)}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
              <Typography variant={'overline'}>Total</Typography>
            </TableCell>
            <TableCell align={'center'}></TableCell>
            <TableCell align={'right'}>
              <StyledTotal>{formatMoney(tribe.total)}</StyledTotal>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  )
}

const StyledHeading = styled(Typography)`
  margin-bottom: 5px !important;
`

const StyledTotal = styled(Typography)`
  font-weight: bold;
  font-size: 14px;
`
