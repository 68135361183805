import React, { FC } from 'react'
import { PageLayoutWrapper } from '../../components/ui/PageLayoutWrapper'
import { Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import styled from 'styled-components'
import { TableBodySkeleton } from '../../components/skeletons/TableBodySkeleton'
import { Table, TableBody, TableContainer } from '@material-ui/core'
import { TypographySkeleton } from '../../components/skeletons/TypographySkeleton'

type Props = {};
export const PaymentDetailPageSkeleton: FC<Props> = () => {
  return (
    <StyledPageLayoutWrapper>
      <Grid
        container
        justify={'space-between'}
        alignItems={'center'}>
        <Grid item>
          <Skeleton
            width={400}
            height={58} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={6}>
        <Grid
          item
          xs={12}
          xl={6}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableBodySkeleton
                  rows={8}
                  colspan={8} />
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <StyledGrid item>
        <TypographySkeleton variant={'h3'} />
      </StyledGrid>
      <Grid
        container
        spacing={6}>
        <Grid
          item
          xs={12}
          xl={12}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableBodySkeleton
                  rows={2}
                  colspan={8} />
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </StyledPageLayoutWrapper>
  )
}

const StyledPageLayoutWrapper = styled(PageLayoutWrapper)`
  margin-top: 6rem;
`

const StyledGrid = styled(Grid)`
  margin: 3rem 0 1rem 0;
`
