import React, { FC } from 'react'
import { Button } from '../../../../components/buttons/Button'
import { FormSelect } from '../../../../components/inputs/FormSelect'
import { FormikProps } from 'formik'
import { FormGrid } from '../../../../components/ui/FormGrid'
import styled from 'styled-components'
import { colors } from '../../../../styles/colors'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import DatePicker, {
  Day,
  DayRange,
  DayValue,
} from 'react-modern-calendar-datepicker'
import {
  getPreviewLink,
  getSelectedDateRangeLabel,
  todayDate,
} from './helpers'
import { InputLabel } from '@material-ui/core'
import { ExportToCsvFormValues, SelectSetup } from './types'
import { SelectItem } from '../../../../types'

type Props = {
  formikProps: FormikProps<ExportToCsvFormValues>;
  templates: SelectItem[];
  selectSetup: SelectSetup;
};
type DateValue = DayValue | Day[] | DayRange;

export const ExportToCSVFormView: FC<Props> = (props) => {
  const { formikProps, templates, selectSetup } = props
  const { isSubmitting, handleSubmit, values, setFieldValue } = formikProps

  const handleDateChange = (value: DateValue) => {
    setFieldValue('date', value)
  }
  const previewLink = getPreviewLink(values)

  return (
    <StyledFormGrid onSubmit={handleSubmit}>
      <DatePickerWrapper>
        <InputLabel>date</InputLabel>

        <DatePicker
          value={values.date}
          calendarClassName="calendar"
          onChange={handleDateChange}
          colorPrimary={colors.brand}
          colorPrimaryLight={colors.brandBlue}
          maximumDate={todayDate}
          renderInput={({ ref }) => {
            return (
              <input
                readOnly
                // @ts-ignore
                ref={ref} // necessary
                placeholder="I'm a custom input"
                value={getSelectedDateRangeLabel(values.date)}
                style={{
                  textAlign: 'center',
                }}
                className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-fullWidth" // a styling class
              />
            )
          }}
        />
      </DatePickerWrapper>

      <FormSelect
        name={selectSetup.name}
        label={selectSetup.label}
        values={selectSetup.values}
        disabled={isSubmitting}
      />

      <FormSelect
        name="template"
        label="template"
        values={templates}
        disabled={isSubmitting}
      />

      <ButtonsRow>
        <a
          href={previewLink}
          target="_blank"
          rel="noreferrer"
        >
          <Button kind="secondary">Preview</Button>
        </a>

        <Button
          kind="primary"
          type="submit"
        >
          Export CSV
        </Button>
      </ButtonsRow>
    </StyledFormGrid>
  )
}

const ButtonsRow = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: end;
  grid-column-gap: 16px;
`

const DatePickerWrapper = styled.div`
  width: 100%;

  .DatePicker {
    width: 100%;
  }

  .calendar {
    position: absolute;
    bottom: -170px;
    right: -165px;
    box-shadow: 0px 4px 12px rgba(8, 35, 48, 0.05),
      0px 24px 32px rgba(8, 35, 48, 0.12);
  }
`

const StyledFormGrid = styled(FormGrid)`
  margin-top: 24px;
`
