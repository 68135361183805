import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const AllProjects: FC<IconSVGProps> = ({ width = 20, height = 42 }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.667 3.667H21"
      stroke="#C8C6C5"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M7.667 11.666H21M7.667 19.666H21M4.333 1H1.667A.667.667 0 001 1.667v2.666c0 .369.298.667.667.667h2.666A.667.667 0 005 4.333V1.667A.667.667 0 004.333 1zM4.333 9H1.667A.667.667 0 001 9.667v2.666c0 .368.298.667.667.667h2.666A.667.667 0 005 12.333V9.667A.667.667 0 004.333 9zM4.333 17H1.667a.667.667 0 00-.667.667v2.666c0 .369.298.667.667.667h2.666A.667.667 0 005 20.333v-2.666A.667.667 0 004.333 17z"
      stroke="#C8C6C5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
