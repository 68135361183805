import React, { FC, useMemo } from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { theme } from '../../../../styles/muiThemes'

type Props = {
  onClick: () => void;
};
export const ViewBagButton: FC<Props> = ({ onClick }) => {
  return useMemo(
    () => (
      <StyledButton
        variant={'text'}
        onClick={onClick}
      >
        View bag
      </StyledButton>
    ),
    []
  )
}

const StyledButton = styled(Button)`
  ${theme.breakpoints.down('md')} {
    display: none;
  }
`
