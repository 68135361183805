import React, { FC } from 'react'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { Formik } from 'formik'
import { ChangeTribeFormView } from './ChangeTribeFormView'
import { FormikSubmitHandler, UserInProject } from '../../../../types'
import { Mutation, useCustomMutation } from '../../../../hooks/useCustomMutation'
import { UserInTribe } from '../../../UserInfo/components/AddNewTribeButton'
import { useSnackbar } from 'notistack'
import { getErrorsString } from '../../../ProjectInfo/utils'
import { colors } from '../../../../styles/colors'
import { format } from 'date-fns'
import { useQuery } from 'react-query'
import ReactQueryKeys from '../../../../utils/ReactQueryKeys'
import { User } from '../../../UsersPage/types'

type Props = {
  done?: () => void;
  user: UserInProject;
  projectId: number;
  refetchProject: () => void;
};

export type ChangeTribeFormValues = {
  tribeId: string | '';
  validFrom: string;
};

export const ChangeTribeModal: FC<Props> = ({ user, projectId, done, refetchProject }) => {
  const { enqueueSnackbar } = useSnackbar()

  const now = new Date()
  const startOfMonth = format(now, 'yyyy/MM')
  const initialValues = {
    tribeId: '',
    validFrom: startOfMonth
  }

  const {
    data: userDetail,
    isFetching,
    refetch,
  } = useQuery<User>(
    ReactQueryKeys.userDetail(Number(user.id)),
    {
      enabled: !!user.id,
    }
  )

  const createUserInTribeMutation: Mutation<UserInTribe> = (userInTribe) => ({
    path: `/api/users/${user.id}/projects/${projectId}/update_tribe`,
    method: 'PUT',
    params: userInTribe,
  })

  const userInTribeMutation = useCustomMutation<Response, any, UserInTribe>(
    createUserInTribeMutation,
    {
      onSuccess: async () => {
        done ? done() : null
        await refetchProject()
        enqueueSnackbar('Tribe changed', {
          variant: 'success',
          preventDuplicate: true,
        })
      },
      onError: (error) => {
        if (error.message.tribe.length || error.message.valid_from.length) {
          enqueueSnackbar(getErrorsString(error.message.tribe ?? error.message.valid_from), {
            variant: 'error',
            title: 'Update error',
            preventDuplicate: true,
            persist: true,
          })
        }
      },
    },
    true
  )

  const handleSubmit: FormikSubmitHandler<ChangeTribeFormValues> = async (
    values,
    { setErrors, setSubmitting }
  ) => {
    const validFromDate = new Date(`${values.validFrom}/01`)
    userInTribeMutation
      .mutateAsync({
        tribe_id: values.tribeId,
        valid_from: format(validFromDate, 'yyyy-MM-dd')
      })
      .catch((err) => {
        setErrors(err.errors)
      })

    setSubmitting(false)
  }

  return (
    <>
      <StyledTitle variant="h3">Change tribe</StyledTitle>
      <p>
        Select tribe you will issue your invoices to.
      </p>

      {isFetching ? <p>Loading...</p> :
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {(formikProps) => {
            return (
              <>
                <ChangeTribeFormView
                  formikProps={formikProps}
                  userTribes={userDetail?.tribes} />
              </>
            )
          }}
        </Formik>
      }
    </>
  )
}

const StyledTitle = styled(Typography)`
  font-family: 'Ginto Normal Regular', serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.04em;
  color: ${colors.brand};
`
