import { Alert } from '@material-ui/lab'
import React, { FC } from 'react'
import styled from 'styled-components'

import { atMinWidth } from '../../../../styles/helpers'
import { PaymentQRCodeData } from '../types'
import { TabContentWrapper } from './QRCodeDialog.styles'
import { QRCodeDialogBankDetails } from './QRCodeDialogBankDetails'
import { QRCodeDialogTabSection } from './QRCodeDialogTabSection'

type Props = {
  qrData: PaymentQRCodeData | undefined;
  pairingCode: string;
};

export const QRCodeDialogInternationalTabContent: FC<Props> = ({ qrData, pairingCode }) => {
  return (
    <TabContentWrapper>
      <StyledAlert
        severity={'info'}
        variant={'outlined'}
        color={'warning'}
      >For international payments only. You can transfer any amount of money.</StyledAlert>
      <QRCodeDialogTabSection title="International payment instructions">
        <QRCodeDialogBankDetails
          bankDetails={{
            'IBAN': 'CZ2420100000002601592813',
            'SWIFT': 'FIOBCZPPXXX',
            'Your pairing code (vs)': qrData?.pairing_code ?? pairingCode,
          }}
        />
      </QRCodeDialogTabSection>
    </TabContentWrapper>
  )
}

const StyledAlert = styled(Alert)`
  margin-bottom: 16px;

  ${atMinWidth.mobile} {
    margin-bottom: 32px;
  }
`
