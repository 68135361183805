/* eslint-disable max-lines */
import React, { FC, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { TabsLayout } from '../../../components/ui/TabsLayout'
import { MyRatesTab } from './MyRateTab'
import { ProjectRateTab } from './ProjectRateTab'
import { ScheduledChangesTab } from './ScheduledChangesTab'
import { HistoryTab } from './HistoryRateTab'
import { ProjectRate, Rate } from './types'
import { UserDetail } from '../types'
import { NewRateModal } from './CreateRateModal/NewRateModal'
import { ModalWidth, ModalWrapper, useModalState } from '../../../components/ModalWrapper'
import { DeleteRateModal } from './DeleteRateModal'
import { EndValidityModal } from './EndValidityModal'

type Props = {
  user: UserDetail;
};

export enum RATE_SCOPE {
  GLOBAL = 'global',
  PROJECT = 'project',
  SCHEDULED = 'scheduled',
  HISTORY = 'history'
}

const TABS_TO_RATES = Object.values(RATE_SCOPE)

export const Rates: FC<Props> = ({ user }) => {
  const [scope, setScope] = useState<RATE_SCOPE>(RATE_SCOPE.GLOBAL)
  const [activeTab, setActiveTab] = useState(0)
  const { isModalOpen, closeModal, openModal } = useModalState()
  const { isModalOpen: isEndValidityModalOpen, closeModal: endValidityCloseModal, openModal: openEndValidityModal } = useModalState()
  const { isModalOpen: isDeleteModalOpen, closeModal: closeDeleteModal, openModal: openDeleteOpenModal } = useModalState()
  const [activeRate, setActiveRate] = useState<Rate>()
  const [modalWidth, setModalWidth] = useState<number>()

  useEffect(() => {
    setModalWidth(ModalWidth.SMALL)
  }, [isModalOpen])

  const handleShowModal = (rate: Rate) => {
    if (rate) {
      setActiveRate(rate)
    }
    openModal()
  }

  const handleEndValidityModal = (rate: Rate) => {
    if (rate) {
      setActiveRate(rate)
    }
    openEndValidityModal()
  }

  const handleDeleteRateModal = (rate: Rate) => {
    if (rate) {
      setActiveRate(rate)
    }
    openDeleteOpenModal()
  }

  const { data: rates, isLoading: loading, refetch: refetchRates } = useQuery<Rate[]>(
    `/api/users/${user.id}/rates?scope=${scope}`,
    {
      enabled: scope !== RATE_SCOPE.PROJECT
    }
  )

  const { data: groupCounts, refetch: refetchGroupCounts } = useQuery<any>(
    `/api/users/${user.id}/rates/group_counts`
  )

  const { data: projects , refetch: refetchProjects } = useQuery<ProjectRate[]>(
    `/api/users/${user.id}/rates/projects`
  )

  const handleChangeTab = (tab: number) => {
    setScope(TABS_TO_RATES[tab])
    setActiveTab(tab)
  }

  const handleError = (isError: boolean) => {
    if (isError) {
      window.innerWidth <= 501 ?  setModalWidth(ModalWidth.SMALL) : setModalWidth(ModalWidth.BIG)
    }
  }

  const refetchEndpoints = () => {
    refetchGroupCounts()
    refetchRates()
    refetchProjects()
  }

  const headings = [`My rate${groupCounts?.global ? ` ( ${groupCounts?.global} )` : ''}`, `Project rate${groupCounts?.project_specific ? ` ( ${groupCounts?.project_specific} )` : ''}`, `Scheduled changes${groupCounts?.scheduled ? ` ( ${groupCounts?.scheduled} )` : ''}`, `History${groupCounts?.history ? ` ( ${groupCounts?.history} )` : ''}`]
  const tabs = [
    <MyRatesTab
      key={0}
      rates={rates}
      billingCurrency={user.billingCurrency}
      showModal={handleShowModal}
      openEndValidityModal={handleEndValidityModal}
      openDeleteRateModal={handleDeleteRateModal}
    />,
    <ProjectRateTab
      key={1}
      projects={projects ?? []}
      billingCurrency={user.billingCurrency}
      showModal={handleShowModal}
      openEndValidityModal={handleEndValidityModal}
      openDeleteRateModal={handleDeleteRateModal}
    />,
    <ScheduledChangesTab
      key={2}
      rates={rates}
      openEndValidityModal={handleEndValidityModal}
      openDeleteRateModal={handleDeleteRateModal}
    />,
    <HistoryTab
      key={3}
      rates={rates}
      showModal={handleShowModal}
      openEndValidityModal={handleEndValidityModal}
      openDeleteRateModal={handleDeleteRateModal}
    />
  ]

  return <>
    <h3>Money</h3>
    <div className="mt-4">
      {loading && !rates ? '...Loading' :
        <TabsLayout
          headings={headings}
          tabsContent={tabs}
          scrollButtons='off'
          startTab={activeTab}
          setActiveTab={handleChangeTab}
        />}
    </div>
    <ModalWrapper
      isOpen={isModalOpen}
      closeModal={closeModal}
      width={modalWidth && modalWidth}
    >
      <NewRateModal
        user={user}
        done={closeModal}
        activeRate={activeRate}
        handleError={handleError}
        projects={user.projects ?? []}
        refetchRates={refetchEndpoints}
        isProjectTabActive={scope === RATE_SCOPE.PROJECT}
      />
    </ModalWrapper>

    <ModalWrapper
      isOpen={isEndValidityModalOpen}
      closeModal={endValidityCloseModal}
      width={ModalWidth.LARGE}
    >
      <EndValidityModal
        rate={activeRate}
        done={endValidityCloseModal}
        userId={user.id}
        refetchRates={refetchEndpoints} />
    </ModalWrapper>

    <ModalWrapper
      isOpen={isDeleteModalOpen}
      closeModal={closeDeleteModal}
    >
      <DeleteRateModal
        userId={user.id}
        rate={activeRate}
        refetchRates={refetchEndpoints}
        done={closeDeleteModal} />
    </ModalWrapper>
  </>
}
