import React from 'react'
import { FC } from 'react'
import { colors } from '../../../styles/colors'
import { IconSVGProps } from '../Icon'

export const VerifiedIcon: FC<IconSVGProps> = ({
  width = 24,
  height = 24,
  color = colors.grey1,
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7062_22642)">
      <path
        d="M21 12.75C21 19.5 12 22.5 12 22.5C12 22.5 3 19.5 3 12.75V3L12 0.75L21 3V12.75Z"
        stroke={`${color}`}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square" />
      <path
        d="M7.5 11.2502L10.5 14.2502L16.5 7.25049"
        stroke={`${color}`}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square" />
    </g>
    <defs>
      <clipPath id="clip0_7062_22642">
        <rect
          width="24"
          height="24"
          fill="white" />
      </clipPath>
    </defs>
  </svg>

)
