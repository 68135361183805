import React from 'react'
import { FC } from 'react'
import { colors } from '../../../styles/colors'
import { IconSVGProps } from '../Icon'

export const InfoSecondIcon: FC<IconSVGProps> = ({
  width = 26,
  height = 26,
  color = colors.danger,
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25 12.7946C25.008 15.9988 23.7529 19.077 21.5066 21.3619C19.2602 23.6468 16.2038 24.9543 13 25.0007C11.4353 25.0243 9.88141 24.7369 8.42857 24.1553C6.97573 23.5738 5.65282 22.7095 4.53655 21.6127C3.42029 20.5159 2.53288 19.2085 1.9258 17.7661C1.31873 16.3237 1.00406 14.7752 1.00005 13.2102C0.991097 10.0055 2.24589 6.92645 4.4923 4.64083C6.73871 2.35522 9.7956 1.04733 13 1.00083C14.565 0.977434 16.119 1.265 17.572 1.84684C19.025 2.42868 20.348 3.29323 21.4643 4.39033C22.5806 5.48743 23.4679 6.79525 24.0749 8.23793C24.6818 9.68062 24.9962 11.2295 25 12.7946Z"
      stroke={`${color}`}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M13 15.1881V7.55176"
      stroke={`${color}`}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M12.9902 18.9707C12.9545 18.9713 12.9193 18.9789 12.8867 18.9933C12.854 19.0077 12.8246 19.0284 12.8001 19.0544C12.7756 19.0803 12.7565 19.1109 12.744 19.1443C12.7315 19.1777 12.7258 19.2132 12.7273 19.2489C12.7298 19.3205 12.76 19.3883 12.8114 19.4381C12.8629 19.488 12.9316 19.5159 13.0033 19.5162V19.5162C13.0389 19.5155 13.074 19.5077 13.1066 19.4933C13.1392 19.4788 13.1686 19.4581 13.193 19.4322C13.2175 19.4063 13.2366 19.3758 13.2491 19.3424C13.2617 19.3091 13.2675 19.2736 13.2662 19.238C13.264 19.168 13.2353 19.1014 13.1859 19.0518C13.1364 19.0021 13.07 18.9732 13 18.9707H12.9945"
      stroke={`${color}`}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round" />
  </svg>
)
