import {
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from '@material-ui/core'
import React, { FC } from 'react'
import { MyTransactionTableBody } from './MyTransactionTableBody'
import { useTransactionContext } from './MyTransactionContext'
import styled from 'styled-components'

export const MyTransactionsTable: FC = () => {
  const { isAllTransactions, showActionColumn } = useTransactionContext()

  return (
    <StyledTableContainer>
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell>Transaction&nbsp;ID</TableCell>
            <TableCell>Date</TableCell>
            {isAllTransactions && <TableCell>Name</TableCell>}
            <TableCell>Source</TableCell>
            <TableCell>Amount</TableCell>
            {showActionColumn && (
              <TableCell>Action</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <MyTransactionTableBody />
        </TableBody>
      </StyledTable>
    </StyledTableContainer>
  )
}

const StyledTableContainer = styled(TableContainer)`
  /* NOTE: display grid & min-width 0 (on the child) are needed to make sure the table will not cause
   * horizontal overflow and will really be scrollable instead. Don't ask me why only this solved it.
   * (The issue only happened in the desktop layout with the menu on the left. On mobile it was fine.) */
  display: grid;
`

const StyledTable = styled(Table)`
  min-width: 0;
`
