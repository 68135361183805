import React, { FC, useEffect, useState } from 'react'
import { PaymentsPageView } from './PaymentsPageView'
import { PaymentsStatus, Payment, Filter } from './types'
import { initialState, normalizeQuery } from './utils'
import { usePaginatedQuery } from '../../hooks/usePaginatedQuery'
import { useSearchFieldState } from '../../components/inputs/SearchField'

export const PaymentsPage: FC = () => {
  const [selectedPaymentsStatus, setSelectedPaymentsStatus] = useState<PaymentsStatus>(
    PaymentsStatus.ALL
  )

  const [filters, setFilters] = useState<Filter>(initialState)

  const { startDate, endDate, amountFrom, amountTo, searchString } = filters

  const isUnpairedPayment = selectedPaymentsStatus === PaymentsStatus.ALL ? '0' : '1'

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const query = queryParameters.get('query')
    if (query) {
      setFilters({ ...filters, searchString: normalizeQuery(query) })
    }
  }, [])

  const urlParams = new URLSearchParams({
    query: searchString,
    unpaired_payments: isUnpairedPayment,
    date_from: startDate,
    date_to: endDate,
    amount_from: amountFrom,
    amount_to: amountTo,
  })

  const {
    isLoading,
    data: payments,
    pagination,
    loadPage,
  } = usePaginatedQuery<Payment[]>('/api/payments', urlParams, 1)

  const { resetSearchString, searchField: searchText } = useSearchFieldState()

  return (
    <PaymentsPageView
      payments={payments}
      filters={filters}
      setFilters={setFilters}
      isLoading={isLoading}
      selectedPaymentsStatus={selectedPaymentsStatus}
      setSelectedPaymentsStatus={setSelectedPaymentsStatus}
      loadPage={loadPage}
      pagination={pagination}
      resetSearchString={resetSearchString}
      searchText={searchText}
    />
  )
}
