import React from 'react'
import styled from 'styled-components'
import { TextField } from '@material-ui/core'
import { FormikProps } from 'formik'

import { NewUserType, SelectItem } from '../../../types'
import { FormSelect } from '../../../components/inputs/FormSelect'
import { hasError, helperText } from '../../../utils/helpers'

type Props = {
  userRoles: SelectItem[];
  formikProps: FormikProps<NewUserType>;
};

export const AddNewUserForm: React.FC<Props> = ({ formikProps, userRoles }) => {
  const { values, handleChange } = formikProps

  return (
    <>
      <StyledTextField
        fullWidth
        required
        value={values.email}
        name={'email'}
        type={'text'}
        label={'Email'}
        onChange={handleChange}
        error={hasError(formikProps, 'email')}
        helperText={helperText(formikProps, 'email')}
      />
      <StyledTextField
        fullWidth
        value={values.firstName}
        name={'firstName'}
        type={'text'}
        label={'First name'}
        onChange={handleChange}
        error={hasError(formikProps, 'firstName')}
        helperText={helperText(formikProps, 'firstName')}
      />
      <StyledTextField
        fullWidth
        value={values.lastName}
        name={'lastName'}
        type={'text'}
        label={'Last name'}
        onChange={handleChange}
        error={hasError(formikProps, 'lastName')}
        helperText={helperText(formikProps, 'lastName')}
      />
      <StyledTextField
        fullWidth
        value={values.nickname}
        name={'nickname'}
        type={'text'}
        label={'Nick name'}
        onChange={handleChange}
        error={hasError(formikProps, 'nickname')}
        helperText={helperText(formikProps, 'nickname')}
      />
      <StyledTextField
        fullWidth
        value={values.slack_user_id}
        name={'slack_user_id'}
        type={'text'}
        label={'Slack user id'}
        onChange={handleChange}
        error={hasError(formikProps, 'slack_user_id')}
        helperText={helperText(formikProps, 'slack_user_id')}
      />
      <StyledFormSelect
        label={'User role'}
        value={values.role}
        name={'role'}
        values={userRoles}
        error={hasError(formikProps, 'role')}
        helperText={helperText(formikProps, 'role')}
      />
    </>
  )
}

const StyledTextField = styled(TextField)`
  margin-bottom: 20px;
`

const StyledFormSelect = styled(FormSelect)`
  margin: 10px 0 40px;
`
