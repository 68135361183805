import React, { MouseEvent, FC, useState } from 'react'
import { useUserPermissions } from '../../../components/CurrentUserProvider'
import { Users } from '../types'
import { Button } from '../../../components/buttons/Button'
import styled from 'styled-components'
import { QueryObserverResult, RefetchOptions } from 'react-query'
import { AddNewUser } from './AddNewUser'
import { SelectItem } from '../../../types'
import { atMaxWidth } from '../../../styles/helpers'

type Props = {
  recalculateUsersBalance: (event: MouseEvent<HTMLButtonElement>) => void;
  refetchUsers: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<Users, unknown>>;
  userRoles: SelectItem[];
};

export const AdminButtons: FC<Props> = ({
  refetchUsers,
  recalculateUsersBalance,
  userRoles,
}) => {
  const { resolvePermissions } = useUserPermissions()
  const userCanManage = resolvePermissions('user', 'manage')
  const [dialogOpened, setDialogOpened] = useState(false)

  const handleBalance = (event) => {
    recalculateUsersBalance(event)
    refetchUsers()
  }

  if (!userCanManage) return <></>
  return (
    <>
      <AddNewUser
        setDialogOpened={setDialogOpened}
        dialogOpened={dialogOpened}
        userRoles={userRoles}
      />
      <AdminRow>
        <AdminButton
          kind="primary"
          onClick={() => setDialogOpened(true)}
        >
          + New person
        </AdminButton>
        <AdminButton
          kind="primary"
          onClick={handleBalance}
        >
          Recalculate balance
        </AdminButton>
      </AdminRow>
    </>
  )
}

const AdminRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
  gap: 20px;

  ${atMaxWidth.tablet} {
    justify-content: flex-start;
  }
`

const AdminButton = styled(Button)`
  max-width: 350px;
`
