export enum ReportFields {
  user = 'user_name',
  project = 'project_name',
  description = 'description',
  sum = 'sum',
}

export type DataRow = {
  user_name: string | null;
  project_name: string | null;
  description: string | null;
  sum: number;
  user_id: number;
  project_id: number;
};

export type User = {
  id: number;
  lastName: string;
  firstName: string;
  nickname: string;
};

export type Project = {
  id: number;
  name: string;
};

export enum ReportType {
  user = 'user',
  project = 'project',
}

export enum EntityType {
  user = 'user_id',
  project = 'project_id',
  report = 'report_type',
  month = 'month',
}

export type RowGroup = Record<string, DataRow[]>;

export type RequestParams = {
  user_id: number | 'all';
  project_id: number | 'all';
  month: string;
  report_type: string;
};

export type csvRequestParams = {
  user_id?: number | 'all';
  project_id?: number | 'all';
  month?: string;
  report_type?: string;
  template?: string;
  date?: string;
  pick_user?: number | 'all';
};

export type ReportHandlers = {
  monthChangeHandler: (month: string) => void;
  entityChangeHandler: (entityId: number, type: ReportType) => void;
  reportTypeChangeHandler: (newValue: ReportType) => void;
  csvDownloader: (params?: csvRequestParams) => void;
};

export type TogglReportData = {
  items: DataRow[];
  users: User[];
  projects: Project[];
  month_picker_options: string[];
  report_type: ReportType;
  params: any;
};
