import React, { FC } from 'react'

import { ButtonWrapper } from './styles'
import { Rate } from './types'
import { useQueryNotification } from '../../UserInfo/utils'
import { formatDate } from './utils'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { Button } from '../../../components/buttons/Button'

interface Props {
    rate?: Rate;
    userId: number;
    refetchRates: () => void;
    done: () => void;
}

export const DeleteRateModal: FC<Props> = ({ userId, rate, refetchRates, done }) => {
  const { successNotification, errorNotification } = useQueryNotification()
  const endValidityMutation = useCustomMutation(
    () => ({
      path: `/api/users/${userId}/rates/${rate?.id}`,
      method: 'DELETE',
    }),
    {
      onSuccess: () => {
        successNotification('Rate has been successfully deleted')
        done()
        refetchRates()

      },
      onError: () => {
        errorNotification('Something went wrong')
      }
    },
  )

  const handleDelete = () => {
    endValidityMutation.mutateAsync({}).catch((err) => {
      console.error(err.errors)
    })
  }
  return (
    <>
      <h2>Delete</h2>
      {rate &&
        <>
          <h5>{`Do you want to delete ${rate.rateComponentName} (${rate.competencyLevel} - ${rate.rate} ${rate.currency}) from ${formatDate(rate.validFrom)}?`}</h5>
          <p>Previous {rate.rateComponentName} will be restored.</p>
        </>
      }

      <ButtonWrapper>
        <Button
          kind="primary"
          id="endValidity"
          type="submit"
          onClick={handleDelete}
        >
            Delete
        </Button>
      </ButtonWrapper>
    </>
  )
}