import React, { FC, useMemo } from 'react'
import { DotsMenu, DotsMenuItem } from '../../../components/DotsMenu'

export const DotsButtons: FC = () => {
  const dotMenuItems: DotsMenuItem[] = useMemo(() => {
    const arr: DotsMenuItem[] = [
      {
        label: 'Import Multisport',
        onClick: () => {
          window.location.href = '/admin/purchases/import_multisport'
        },
      }
    ]

    return arr
  }, [])

  return (
    <DotsMenu
      items={dotMenuItems}
      heavy
    />
  )
}
