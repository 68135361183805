import React, { FC } from 'react'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'

import { atMinWidth } from '../../../styles/helpers'
import { GetSnackStepsInfo } from './GetSnackStepsInfo'
import { IconButton } from '@material-ui/core'

interface Props {
  closeGetSnackPaper: () => void;
}

export const GetSnackSteps: FC<Props> = ({ closeGetSnackPaper }) => {
  return (
    <GetSnackStepsWrapper>
      <GetSnackContent>
        <StyledTitle>Set up your GetSnack!
          <IconButton
            aria-label="hide paper"
            onClick={closeGetSnackPaper}>
            <StyledCloseIcon />
          </IconButton>
        </StyledTitle>
        <p>Our GetSnack app allows you to quickly purchase food and drinks from the snackbar. You can also buy there our merch.</p>
        <GetSnackStepsInfo />
      </GetSnackContent>
    </GetSnackStepsWrapper>
  )
}

const StyledCloseIcon = styled(CloseIcon)`
  &:hover {
    cursor: pointer;
  }
`

const GetSnackStepsWrapper = styled.div`
  position: relative;
`

const GetSnackContent = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 16px;
    line-height: 20px;
    width: 70%;

    ${atMinWidth.tablet} {
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 20px;
    }

    ${atMinWidth.desktop} {
      font-size: 18px;
      line-height: 30px;
      width: 50%;
    }

    ${atMinWidth.desktopLarge} {
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 30px;
      width: 423px;

    }

    ${atMinWidth.custom(1440)} {
      margin-bottom: 80px;
      width: 538px;
    }
  }
`

const StyledTitle = styled.div`
  display: flex;
  font-family: 'Ginto Nord';
  font-size: 22px;
  font-weight: 800;
  justify-content: space-between;
  letter-spacing: 0.04em;
  line-height: 24px;
  margin-bottom: 5px;

  ${atMinWidth.tablet} {
    font-size: 27px;
    line-height: 35px;
    margin-bottom: 20px;
  }

  ${atMinWidth.desktop} {
    margin-bottom: 20px;
  }

  ${atMinWidth.desktopLarge} {
    line-height: 37px;
    margin-bottom: 32px;
  }
`
