import React, { FC } from 'react'

export const ArrowIcon: FC = () => (
  <svg
    width="67"
    height="11"
    viewBox="0 0 67 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.974 6.00002H0V5.00002H64.974L61.7592 1.02751L62.5366 0.398438L66.4105 5.18547L66.665 5.50001L66.4105 5.81455L62.5366 10.6016L61.7592 9.97251L64.974 6.00002Z"
      fill="white"
    />
  </svg>
)
