import React, { FC } from 'react'
import { colors } from '../../../styles/colors'
import { IconSVGProps } from '../Icon'

export const CopyIcon: FC<IconSVGProps> = ({ width = 16, height = 16, color = colors.white, strokeWidth = '1.5' }) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6527_20514)">
      <path
        d="M10.6666 5.33398H14.6666V14.6673H5.33325V10.6673"
        stroke={`${color}`}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M10.6666 1.33398H1.33325V10.6673H10.6666V1.33398Z"
        stroke={`${color}`}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="square"
        strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_6527_20514">
        <rect
          width="16"
          height="16"
          fill="white" />
      </clipPath>
    </defs>
  </svg>
)
