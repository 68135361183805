import React, {FC, useEffect, useState} from 'react'
import styled from 'styled-components'
import {Button} from "@material-ui/core";

type Props = {
    userId: number;
    togglRounderUrl: string;
};

export const TogglCheck: FC<Props> = ({userId, togglRounderUrl}) => {
    return (
        <>
            <StyledButton
                variant={'outlined'}
                color={'primary'}
                href={togglRounderUrl}
            >
                Go to Toggl Rounder
            </StyledButton>
        </>
    )
}

const StyledButton = styled(Button)`
  margin-top: 2rem;
`
