import React, { FC, memo } from 'react'

export const CartIcon: FC = memo(() => (
  <svg
    width="38"
    height="42"
    viewBox="0 0 38 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37 40.7H1.182l3.581-28.655h28.655L37 40.7z"
      stroke="#14141F"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.926 17.418V8.463A7.185 7.185 0 0119.089 1.3v0a7.185 7.185 0 017.164 7.163v8.955"
      stroke="#14141F"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))
