import React, { FC } from 'react'
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { CurrentUser, TestingDevices } from '../TestingDevicesPage/types'
import { HistoryTableRow } from './HistoryTableRow'
import { getSortedHoldersViaDate } from './utils'
import { DeviceHistoryTableSkeleton } from './DeviceHistoryTableSkeleton'

type Props = {
  device: TestingDevices;
  isLoading: boolean;
};

export const HistoryTable: FC<Props> = ({ device, isLoading }) => {
  const isNotPreviousUser =
    !device?.testingDeviceUsers?.length || !device?.testingDeviceUsers

  if (!isLoading && isNotPreviousUser) {
    return (
      <Alert
        variant={'outlined'}
        severity={'info'}
      >
        No previous user
      </Alert>
    )
  }

  const sortedHolders = !isLoading ? getSortedHoldersViaDate({
    dates: device?.testingDeviceUsers as CurrentUser[],
  }) : []

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Project</TableCell>
          <TableCell align={'right'}>From</TableCell>
          <TableCell align={'right'}>To</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {isLoading ?
          <DeviceHistoryTableSkeleton rows={5} /> :
          sortedHolders.map((holder) => (
            <HistoryTableRow
              key={holder.user.id}
              holder={holder}
            />
          ))}
      </TableBody>
    </Table>
  )
}
