export const newDeviceFieldData = [
  {
    id: 1,
    require: true,
    name: 'name',
    labelText: 'Device',
    placeholder: 'Enter device name',
  },
  {
    id: 2,
    require: true,
    name: 'brand',
    labelText: 'Brand',
    placeholder: 'Enter brand name',
  },
  {
    id: 3,
    require: true,
    name: 'serial',
    labelText: 'Serial number',
    placeholder: 'Enter device serial number',
  },
  {
    id: 4,
    require: false,
    name: 'systemVersion',
    labelText: 'Device system version',
    placeholder: 'Enter device system version',
  },
  {
    id: 5,
    require: false,
    name: 'location',
    labelText: 'Location',
    disabled: true
  },
  {
    id: 6,
    require: false,
    name: 'sublocation',
    labelText: 'Sub location',
    placeholder: 'Enter device location (room)',
  },
  {
    id: 7,
    require: false,
    name: 'inventoryNumber',
    labelText: 'Inventory number',
    placeholder: 'Enter inventory number',
  },
  {
    id: 8,
    require: false,
    name: 'inventoryUrl',
    labelText: 'Notion url',
    placeholder: 'Enter notion inventory URL',
  },
]
