import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { AmountOperation, BasketProduct, ShopProductsData } from './types'
import { getBasketProducts } from './utils'
import { ShopConfig } from './ShopConfig'

type ContextType = {
  products: BasketProduct[];
  config: ShopConfig;
  setProducts: (products: BasketProduct[]) => void;
};
const ShopContext = createContext<ContextType | undefined>(undefined)

const useShopContext = () => {
  const { products, setProducts, config } = useContext(ShopContext)!
  const handleAmountOperation = useCallback(
    (productId: number, amountOperation: AmountOperation) => {
      const newArr = [...products]
      newArr.forEach((product) => {
        if (product.product.id === productId) {
          switch (amountOperation) {
          case AmountOperation.PLUS:
            product.amount++
            break
          case AmountOperation.MINUS:
            product.amount--
            break
          }
        }
      })
      setProducts(newArr)
    },
    []
  )
  const selectedProducts = useMemo(
    () => products.filter((product) => product.amount > 0),
    [products]
  )
  const selectedProductsAmount = useMemo(
    () =>
      selectedProducts.reduce((amount, product) => {
        amount += product.amount
        return amount
      }, 0),
    [selectedProducts]
  )
  const totalCost = useMemo(
    () =>
      products.reduce<number>((price, product) => {
        return price + product.product.currentCost * product.amount
      }, 0),
    [products]
  )
  const emptyBasket = useCallback(() => setProducts([]), [])
  return {
    products,
    selectedProducts,
    selectedProductsAmount,
    totalCost,
    emptyBasket,
    handleAmountOperation,
    config,
  }
}

type Props = {
  data: ShopProductsData;
  config: ShopConfig;
};
const ShopContextProvider: FC<Props> = ({ children, data, config }) => {
  useEffect(() => {
    setAllProducts(getBasketProducts(data))
  }, [data])
  const [allProducts, setAllProducts] = useState<BasketProduct[]>([])
  const value: ContextType = {
    products: allProducts,
    setProducts: setAllProducts,
    config,
  }
  if (allProducts.length === 0) return <></>
  return <ShopContext.Provider value={value}>{children}</ShopContext.Provider>
}

export { ShopContext, ShopContextProvider, useShopContext }
