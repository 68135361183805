import { Breadcrumbs, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { BreadcrumbsData } from '../types/index'
import Link from '@material-ui/core/Link'
import styled from 'styled-components'
import { colors } from '../styles/colors'
import { Skeleton } from '@material-ui/lab'
import { atMinWidth } from '../styles/helpers'

type Props = {
  data: BreadcrumbsData;
  isLoading?: boolean;

};

export const BreadcrumbsNav: FC<Props> = ({ data, isLoading = false }) => {
  const { links, currentPageTitle } = data
  return (
    <BreadcrumsContainer>
      {isLoading ?
        <Skeleton
          variant="rect"
          width={300}
          height={24}
          animation="wave" /> :
        <Breadcrumbs>
          {links.map((link) => {
            const { id, href, title } = link
            return (
              <StyledLink
                key={id}
                href={href}
                underline="none"
                color={'primary'}
              >
                {title}
              </StyledLink>
            )
          })}
          <Typography>{currentPageTitle}</Typography>
        </Breadcrumbs>
      }
    </BreadcrumsContainer>
  )
}

const StyledLink = styled(Link)`
  &:hover {
    color: ${colors.black};
  }
`

const BreadcrumsContainer = styled.div`
  ${atMinWidth.desktop} {
    margin-bottom: 1rem;
  }

  margin-bottom: 3rem;
`
