import React, { FC, ChangeEvent, useCallback, Dispatch, SetStateAction } from 'react'
import { Box, debounce, TextField } from '@material-ui/core'
import { PaymentData } from '../types'

type Props = {
  paymentData: PaymentData;
  setPaymentData: Dispatch<SetStateAction<PaymentData>>;
};

export const QRCodeDialogFields: FC<Props> = ({
  paymentData,
  setPaymentData,
}) => {
  const debouncer = useCallback(
    debounce((searchValue: PaymentData) => {
      setPaymentData(searchValue)
    }, 300),
    []
  )

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target) {
      debouncer({ ...paymentData, [event.target.name]: event.target.value })
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridRowGap="12px"
    >
      <TextField
        fullWidth
        defaultValue={paymentData.amount}
        inputProps={{ pattern: '([^0-9]*)', min: 0 }}
        type={'number'}
        label={'Amount'}
        name={'amount'}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        disabled
        type={'number'}
        label={'Your pairing code (v.s.)'}
        name={'pairingCode'}
        value={paymentData.pairingCode}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        type={'text'}
        label={'Note for recipient'}
        name={'note'}
        defaultValue={paymentData.note}
        onChange={handleChange}
      />
    </Box>
  )
}
