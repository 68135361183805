import React, { FC, memo } from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button/Button'
import { theme } from '../../styles/muiThemes'

type Props = ButtonProps;

export const BubbleButton: FC<Props> = memo((props) => {
  return <StyledButton {...props}>{props.children}</StyledButton>
})
// TODO: @PEM remove     margin-right: 16px; for first of type
const StyledButton = memo(styled(Button)`
  border-radius: 43px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.05em;
  font-family: 'Ginto Normal Regular';
  text-transform: capitalize;
  padding: 4px 8px;
  margin-bottom: 6px;
  line-height: 15.4px;
  text-transform: uppercase;

  ${theme.breakpoints.up('md')} {
    padding: 12px 16px;
    margin-bottom: 0;
  }
`)
