import { BaseUser } from '../../types'

export type Account = {
  id: number;
  owner: BaseUser;
};

export type AccountSelectValues = {
  value: number;
  label: string;
};

export type Transaction = {
  account_id: number | '';
  amount: number | '';
};

export enum TransactableType {
  payment = 'payment',
  purchase = 'purchase',
}

export enum Label {
  accountName = 'Account name',
  amount = 'Amount',
}
