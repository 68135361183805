import React, { useState, FC } from 'react'
import { Form, Formik } from 'formik'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'

import { CreatePayment, DirectionType, ErrorPayments } from './types'
import { Mutation, useCustomMutation } from '../../../hooks/useCustomMutation'
import { FormikSubmitHandler } from '../../../types'
import { useQueryNotification } from '../../UserInfo/utils'
import { CreatePaymentForm } from './CreatePaymentForm'
import { ModalWrapper } from '../../../components/ModalWrapper'
import { Button } from '../../../components/buttons/Button'
import { GENERIC_BE_ERROR_MSG } from '../../../constants'

type Props = {
  dialogOpened: boolean;
  setDialogOpened: (dialogOpened: boolean) => void;
};

export const CreatePaymentModal: FC<Props> = ({
  dialogOpened,
  setDialogOpened,
}) => {
  const { successNotification, errorNotification } = useQueryNotification()
  const initVals: CreatePayment = {
    direction: 'deposit',
    user_id: '',
    amount: '',
    note: '',
    transaction_date: new Date().toString()
  }

  const closeDialog = () => setDialogOpened(false)

  const createPaymentMutation: Mutation<CreatePayment> = (data) => ({
    path: '/admin/payments',
    method: 'POST',
    params: data
  })

  const [directionType, setDirectionType] = useState<DirectionType>(DirectionType[DirectionType.deposit])

  const mutation = useCustomMutation(
    createPaymentMutation,
    {
      onSuccess: async (data: Record<string, number | any>) => {
        successNotification('Payment was created')
        const dataJson = await data.json()

        const paymentId = dataJson.id
        const baseUrl = window.location.origin
        window.location.replace(`${baseUrl}/admin/payments/${paymentId}`)
      },
      onError: (error: ErrorPayments) => {
        if (error.status != 422) {
          errorNotification(
            error.error ?? GENERIC_BE_ERROR_MSG
          )
        }
      },
    },
    true
  )

  const formikSubmit: FormikSubmitHandler<CreatePayment> = async (values, { setErrors }) => {
    const cleanedValues = JSON.parse(JSON.stringify(values), (_, value) => {
      return value === '' ? null : value
    })

    const preparedValues = {
      ...cleanedValues,
      amount: cleanedValues.direction === DirectionType.withdrawal ? -cleanedValues.amount : cleanedValues.amount
    }

    await mutation.mutateAsync(preparedValues).catch((err) => {
      setErrors(err.errors)
    })
  }

  return (
    <ModalWrapper
      isOpen={dialogOpened}
      closeModal={closeDialog}
    >
      <Typography variant="h3">Cash transaction</Typography>
      <Formik
        initialValues={initVals}
        onSubmit={formikSubmit}>
        {formikProps => (
          <Form>
            <CreatePaymentForm
              formikProps={formikProps}
              setDirectionType={setDirectionType}
              directionType={directionType}
            />

            <StyledButton
              type='submit'
              kind={'primary'}
              onClick={() => formikSubmit}
            >
              Create
            </StyledButton>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  )
}


const StyledButton = styled(Button)`
  margin-top: 20px;
`
