import React, { FC } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { Contract } from '../types'
import { ItemField } from '../../../components/ui/UpdatableFormField/ItemField'
import { DateFieldData } from '../../../types/UpdatableFormFieldData/DateFieldData'
import { TextFieldData } from '../../../types/UpdatableFormFieldData/TextFieldData'
import {
  UpdatableGroupContainer,
  UpdatableGroupHeading,
} from '../../../components/ui/UpdatableFormField/UpdatableItemsGroup'
import { Alert } from '@material-ui/lab'
import styled from 'styled-components'
import { useUserInfo } from './UserInfoProvider'
import { AddNewContract } from './AddNewContract'
import { canUpdateUser } from './Tabs/UserInfoTab'

export type ContractsProps = {
  contracts: Contract[] | undefined;
  isLoading: boolean;
  contractTypes: any[];
  subjects: any[];
  userTribes: any[];
};
export const Contracts: FC<ContractsProps> = ({
  contracts,
  isLoading,
  contractTypes,
  userTribes,
  subjects,
}) => {
  if (isLoading) return <p>Loading...</p>

  const { user } = useUserInfo()

  if (!user) return null

  return (
    <UpdatableGroupContainer>
      <UpdatableGroupHeading>Contracts</UpdatableGroupHeading>
      {canUpdateUser() && (
        <AddNewContract
          contractTypes={contractTypes}
          subjects={subjects || []}
          userTribes={userTribes || []}
          userId={user.id}
        />
      )}
      {contracts?.length === 0 && (
        <Alert
          variant={'outlined'}
          severity={'info'}
        >
          No contracts
        </Alert>
      )}
      {contracts?.length !== 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tribe</TableCell>
              <TableCell>Contract type</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Wage ID</TableCell>
              <TableCell>Position</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contracts?.map((contract, index) => (
              <TableRow key={index}>
                <TableCell>{contract.tribeName}</TableCell>
                <TableCell>{contract.contractType}</TableCell>
                <TableCell>{contract.subjectName}</TableCell>
                <DateTableCell>
                  <ItemField
                    updatableField={
                      new DateFieldData(contract.startDate, 'startDate')
                    }
                    updatePath={`/api/users/${user.id}/contracts/${contract.id}`}
                    error={''}
                    noPadding={true}
                    updatable={canUpdateUser()}
                  />
                </DateTableCell>
                <DateTableCell>
                  <ItemField
                    updatableField={
                      new DateFieldData(contract.endDate, 'endDate')
                    }
                    updatePath={`/api/users/${user.id}/contracts/${contract.id}`}
                    error={''}
                    noPadding={true}
                    updatable={canUpdateUser()}
                  />
                </DateTableCell>
                <TableCell>
                  <ItemField
                    updatableField={
                      new TextFieldData(
                        contract.wagePersonalId,
                        'wagePersonalId'
                      )
                    }
                    updatePath={`/api/users/${user.id}/contracts/${contract.id}`}
                    error={''}
                    noPadding={true}
                    updatable={canUpdateUser()}
                  />
                </TableCell>
                <TableCell>
                  <ItemField
                    updatableField={
                      new TextFieldData(
                        contract.contractPosition,
                        'contractPosition'
                      )
                    }
                    updatePath={`/api/users/${user.id}/contracts/${contract.id}`}
                    error={''}
                    noPadding={true}
                    updatable={canUpdateUser()}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </UpdatableGroupContainer>
  )
}

const DateTableCell = styled(TableCell)`
  width: 200px;
`
