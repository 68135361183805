import React, { FC } from 'react'
import { useTransactionContext } from './MyTransactionContext'
import { MyTransactionTableRow } from './MyTransactionTableRow'
import { MyTransactionAlertRow } from './MyTransactionAlertRow'
import { TableBodySkeleton } from '../../../components/skeletons/TableBodySkeleton'

export const MyTransactionTableBody: FC = () => {
  const { isAmountFromBigger, data, isNotDataAvailable, isLoading, isError } = useTransactionContext()

  if (isLoading) {
    return <TableBodySkeleton
      rows={10}
      colspan={7} />
  }

  if (isError) return <MyTransactionAlertRow
    message='Error when fetching data from backend'
    severity='error' />

  if (isAmountFromBigger) return <MyTransactionAlertRow
    message='Bad amount range'
    severity='error' />

  if (isNotDataAvailable) return <MyTransactionAlertRow
    message='No data available'
    severity='info' />

  return <>
    {data?.map(transaction => <MyTransactionTableRow
      key={transaction.transactableId}
      transaction={transaction} />)}
  </>

}
