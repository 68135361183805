import React, { FC } from 'react'
import { useAssertedContext } from '../../../utils/context'
import { UserInfo } from '../index'

type UserInfoData = {
  user?: UserInfo;
  sensitiveDataAccess: boolean;
};

const UserInfoContext = React.createContext<UserInfoData | undefined>(undefined)

export const useUserInfo = (): UserInfoData => useAssertedContext(UserInfoContext)

export const UserInfoProvider: FC<UserInfoData> = (props) => {
  const { children, user, sensitiveDataAccess } = props
  const store = {
    user: user,
    sensitiveDataAccess: sensitiveDataAccess,
  }

  return (
    <UserInfoContext.Provider value={store}>
      {children}
    </UserInfoContext.Provider>
  )
}
