import React, { FC, useEffect } from 'react'
import styled from 'styled-components'
import { Button } from '../../components/buttons/Button'
import { initialState } from './utils'
import { Filter } from './types'
import { DateFilter } from './components/DateFilter'
import { AmountFilter } from './components/AmountFilter'
import { useSearchFieldState } from '../../components/inputs/SearchField'

type Props = {
  filters: Filter;
  setFilters: (filters: Filter) => void;
  resetSearchString: () => void;
};

export const PaymentFilters: FC<Props> = ({
  filters,
  setFilters,
  resetSearchString,
}) => {
  const {
    resetSearchString: resetAmountFrom,
    searchString: amountFromString,
    searchField: amountFromField,
    handleFilterChange: handleAmountFromChange,
  } = useSearchFieldState()
  const {
    resetSearchString: resetAmountTo,
    searchField: amountToField,
    searchString: amountToString,
    handleFilterChange: handleAmountToChange,
  } = useSearchFieldState()

  const clearFilters = () => {
    resetSearchString()
    resetAmountFrom()
    resetAmountTo()
    setFilters(initialState)
  }

  useEffect(() => {
    amountFromString &&
      setFilters({ ...filters, amountFrom: amountFromString })
    amountToString && setFilters({ ...filters, amountTo: amountToString })
  }, [amountFromString, amountToString])

  return (
    <FilterContainer>
      <DateFilter
        filters={filters}
        setFilters={setFilters}
      />
      <AmountFilter
        amountFromField={amountFromField}
        amountToField={amountToField}
        handleAmountFromChange={handleAmountFromChange}
        handleAmountToChange={handleAmountToChange}
      />
      <StyledButton
        kind={'primary'}
        onClick={clearFilters}
      >
        Clear filters
      </StyledButton>
    </FilterContainer>
  )
}

const FilterContainer = styled.div`
  padding-top: 0.9rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const StyledButton = styled(Button)`
  width: 100%;
`
