import { format } from 'date-fns'

import { COMPETENCIES, CURRENCY, Competency, Rate } from './types'

export const getRatesSum = (rates: Rate[]): number => {
  const summary = rates.reduce((totalValue, currentValue) => totalValue + (currentValue.rate ?? 0), 0)

  return Number(summary.toFixed(2))
}

export const filterCompetencies = (allCompetencies: Competency[], competency: COMPETENCIES): Competency[] => {
  return allCompetencies?.filter(oneCompetency => oneCompetency.name === competency)
}

export const getLevelsFromCompetency = (competencies: Competency[], billingCurrency: string): {label: string, value: number | string}[] => {
  const levels = competencies?.map(competency => ({value: competency.id, label: `${competency.level} (${getAmountWithCurrency(competency, billingCurrency)})`}))

  return [{value: '', label: 'Choose level ...'}, ...levels]
}

export const getAmountWithCurrency = (competency: Competency, billingCurrency: string): string => {
  let amount = ''
  if (billingCurrency === CURRENCY.CZK) {
    amount = `${competency.amountCzk} CZK`
  } else if (billingCurrency === CURRENCY.EUR) {
    amount = `${competency.amountEur} €`
  } else if (billingCurrency === CURRENCY.GBP) {
    amount = `£ ${competency.amountGbp}`
  }

  return amount ?? 'Missing currency or amount'
}

export const formatDate = (date: string): string | null => {
  if (!date) {
    return null
  }
  return format(new Date(date), 'dd. MM. yyyy')
}

export const getLevelNicename = (competencies: Competency[], id: number): string => {
  return competencies.find(competency => competency.id === id)?.level ?? ''
}

export const getIdFromLevel = (competencies: Competency[], competencyLevel: string): number | string => {
  return competencies.find((competency) => competency.level === competencyLevel)?.id ?? ''
}

export const getAmountByLevel = (competencies: Competency[] | undefined, competencyLevel: string, billingCurrency: string): number => {
  if (!competencies?.length) {
    return 0
  }
  const competency = competencies?.find((competency) => competency.level === competencyLevel)

  if (competency) {
    getAmountWithCurrency(competency, billingCurrency)
  }

  return 0
}

export const getDefaultValueFromActiveRate = (activeRate: Rate, competency: COMPETENCIES, competencies: Competency[] | undefined): number | string => {
  const competencyLevel = 'rateComponentName' in activeRate && activeRate.rateComponentName === competency ? activeRate.competencyLevel : ''
  return competencyLevel && competencies ? getIdFromLevel(competencies, competencyLevel) : ''
}