export enum StatusFilter {
  Active = 'active',
  Inactive = 'inactive',
  Opportunity = 'opportunity',
}

export enum SearchInputType {
  text = 'text',
  number = 'number',
}
