import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core'
import styled from 'styled-components'

import { atMinWidth } from '../../../../styles/helpers'

export const StyledDialog = styled(Dialog)`
  .MuiDialog-container {
    align-items: start;

    ${atMinWidth.mobile} {
      padding-top: 10vh;
    }
  }
`

export const StyledDialogTitle = styled(DialogTitle)`
  padding: 20px;

  ${atMinWidth.mobile} {
    padding: 40px;
  }
`

export const StyledDialogContent = styled(DialogContent)`
  padding: 0 20px 20px;

  ${atMinWidth.mobile} {
    padding: 0 40px 40px;
  }
`

export const StyledDialogActions = styled(DialogActions)`
  padding: 0;
  justify-content: start;
  align-items: start;
`

export const StyledIconButton = styled(IconButton)`
  margin: -12px;
`

export const StyledTitle = styled(Typography)`
  font-family: 'Ginto Normal Regular', serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.04em;
`

export const TabContentWrapper = styled.div`
  margin-top: 32px;
`
