import { FieldOptions, UpdatableField } from './UpdatableField'
import { format } from 'date-fns'

export class DateFieldData extends UpdatableField {
  keepVisible: false;

  constructor(defaultValue: string, fieldName: string, fieldOptions?: FieldOptions) {
    super(defaultValue, fieldName, fieldOptions)
  }

  getLabel(): string {
    if (this.defaultValue) {
      const date = new Date(String(this.defaultValue))
      return format(date, 'dd. MM. yyyy')
    } else {
      return '-'
    }
  }
}
