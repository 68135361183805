import React, { FC } from 'react'
import styled from 'styled-components'

import { CreditCard } from '../../components/Icon/icons/CreditCard.icon'
import { InfoIcon } from '../../components/Icon/icons/Info.icon'
import { InfoSecondIcon } from '../../components/Icon/icons/InfoSecond.icon'
import { LockIcon } from '../../components/Icon/icons/Lock.icon'
import { MoneyShieldIcon } from '../../components/Icon/icons/MoneyShield.icon'
import { QuestionIcon } from '../../components/Icon/icons/Question.icon'
import { VerifiedIcon } from '../../components/Icon/icons/Verified.icon'
import { WarningIcon } from '../../components/Icon/icons/Warning.icon'
import { atMinWidth } from '../../styles/helpers'
import { InstructionTwoRowsContent, InstructionContent, StyledLink, QuestionWrapper, QuestionTitle, QuestionContent } from './styles'

export const GetCardInstructions: FC = () => {
  return <InstructionWrapper>
    <InfoClaimerWrapper>
      <InfoSecondIcon />
      <InfoClaimer><b>Every expense above 5.000,- CZK</b> must be discussed with at least one council member.</InfoClaimer>
    </InfoClaimerWrapper>

    <InfoClaimerWrapper>
      <InfoSecondIcon />
      <InfoClaimer>After you pay for something, a message should appear in{' '}
        <BoldLink href="https://applifting.slack.com/channels/revolut" target="_blank" rel="noopener noreferrer">
          #revolut
        </BoldLink>{' '} channel. Please claim your payment ownership by 👋 emoji the payment purpose and who did you consult with to the thread. Also post an invoice or, if not possible at that moment, write a date on which you will post the invoice. This step is absolutely necessary for the whole system to work. By not informing you risk eroding the trust which is the base of the system - and you may be prohibited from using the system at all.</InfoClaimer>
    </InfoClaimerWrapper>

    <InstructionsWrapper>
      <InstructionPart>
        <InfoIcon />
        <InstructionTitle>How does it work?</InstructionTitle>
        <InstructionTwoRowsContent>You can pay directly with a virtual card from our Revolut business account and bring the invoice afterwards. Please be careful with these sensitive information. The card numbers will be reset once in a while to reduce a chance that a leaked card will be misused.</InstructionTwoRowsContent>
      </InstructionPart>

      <InstructionPart>
        <CreditCard />
        <InstructionTitle>Disposable cards</InstructionTitle>
        <InstructionContent>If you are not 100% sure with the payment gateway used, please contact <StyledLink href="mailto:filip.kirschner@applifting.cz">filip.kirschner@applifting.cz</StyledLink> with request for one-time disposable card. It&apos;s safe and a really easy way to increase our payment security.</InstructionContent>
      </InstructionPart>

      <InstructionPart>
        <LockIcon />
        <InstructionTitle>Limits</InstructionTitle>
        <InstructionContent>All cards have reasonable limits and the accounts have limited disposable balance which will be increased as we move forward.</InstructionContent>
      </InstructionPart>

      <InstructionPart>
        <MoneyShieldIcon />
        <InstructionTitle>Security</InstructionTitle>
        <InstructionContent>Having an open way how to pay is important for the independence, but also brings a big chunk of responsibility. Please be reasonable when manipulating with shared cards.</InstructionContent>
      </InstructionPart>

      <InstructionPart>
        <WarningIcon />
        <InstructionTitle>Security incidents</InstructionTitle>
        <InstructionContent>If you think the card info was stolen, do not hesitate and contact Filip Kirschner (<StyledLink href="tel:00420739627797">+420 739 627 797</StyledLink>) or <StyledLink href="mailto:security@applifting.cz">security@applifting.cz</StyledLink> immediately.</InstructionContent>
      </InstructionPart>

      <InstructionPart>
        <VerifiedIcon />
        <InstructionTitle>Verification</InstructionTitle>
        <InstructionContent>Some payment providers require payment to be verified by second factor - if this is the case, contact Cardholder Filip Kirschner by slack or phone and he&apos;ll confirm the payment.</InstructionContent>
      </InstructionPart>
    </InstructionsWrapper>
    <QuestionWrapper>
      <QuestionTitle><QuestionIcon /> Having troubles?</QuestionTitle>
      <QuestionContent> If you are still not sure about payment, please read the <StyledLink href="https://www.notion.so/applifting/Decision-making-The-advice-process-8edbe3a10d744006945a75b437d9aef5">Decision making - The advice process</StyledLink> or contact our BO. </QuestionContent>
    </QuestionWrapper>
  </InstructionWrapper>
}

const InfoClaimerWrapper = styled.div`
  border: 1px solid #FFB545;
  display: flex;
  margin-bottom: 32px;
  padding: 10px;

  ${atMinWidth.tablet} {
    padding: 20px;
  }
`

const InfoClaimer = styled.div`
  padding-left: 20px;
`

const InstructionWrapper = styled.div`
  margin-top: 32px;
`

const InstructionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 45px;

  ${atMinWidth.desktopLarge} {
    column-gap: 40px;
    display: grid;
    grid-template-columns: auto auto auto;
  }
`

const InstructionPart = styled.div`
  svg {
    margin-bottom: 12px;
  }
`
const InstructionTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
  margin-bottom: 8px;
`

const BoldLink = styled.a`
  font-weight: bold;
  font-family: Avenir;
`

