import React, { FC } from 'react'
import { TableRow, TableCell, Chip } from '@material-ui/core'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { Payment } from './types'
import { MoneyFormatter, dateFormatter } from '../../utils/formatters'

type Props = {
  payment: Payment;
};

export const TablePaymentRow: FC<Props> = ({ payment }) => {
  const {
    id,
    createdAt,
    counterpartyAccountName,
    note,
    amount,
    source,
    pairedTo,
  } = payment
  const amountFormatted = new MoneyFormatter('CZK').format(amount)
  const parsedDate = dateFormatter(createdAt)
  const pairingStatus = pairedTo ?? (
    <Chip
      label="Unpaired"
      color="secondary"
    />
  )
  const url = `/admin/payments/${id}`
  return (
    <TableRow>
      <TableCell>
        <StyledLink href={url}>{id}</StyledLink>
      </TableCell>
      <TableCell>{parsedDate}</TableCell>
      <TableCell>
        <p>{counterpartyAccountName}</p>
        <StyledNote>Note: {note}</StyledNote>
      </TableCell>
      <TableCell>{amountFormatted}</TableCell>
      <TableCell>{source}</TableCell>
      <TableCell>{pairingStatus}</TableCell>
    </TableRow>
  )
}

const StyledNote = styled.span`
  color: ${colors.grey0};
`

const StyledLink = styled.a`
  text-decoration: underline;
`
