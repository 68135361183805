import React, { FC, useState } from 'react'
import { Card, Fade, IconButton, Modal } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import styled, { css } from 'styled-components'

export enum ModalWidth {
  BIG = 1400,
  LARGE = 1000,
  MEDIUM = 800,
  SMALL = 500,
}

type UseModalReturnType = {
  isModalOpen: boolean;
  openModal(): void;
  closeModal(): void;
  toggleIsModalOpen: () => void;
};
export type UseModalType = (initialState?: boolean) => UseModalReturnType;

export const useModalState: UseModalType = (initialState = false) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(initialState)
  const toggleIsModalOpen = () => setIsModalOpen(!isModalOpen)
  const closeModal = () => setIsModalOpen(false)
  const openModal = () => setIsModalOpen(true)

  return {
    isModalOpen,
    closeModal,
    openModal,
    toggleIsModalOpen,
  }
}

type Props = {
  isOpen: boolean;
  closeModal(): void;
  width?: ModalWidth;
  padding?: number;
};

export const ModalWrapper: FC<Props> = (props) => {
  const { children, isOpen, closeModal, width, padding } = props

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
    >
      <Fade in={isOpen}>
        <ModalCard
          $width={width}
          $padding={padding}>
          <StyledCloseButton
            color="primary"
            onClick={closeModal}
          >
            <CloseIcon />
          </StyledCloseButton>

          {children}
        </ModalCard>
      </Fade>
    </Modal>
  )
}

const ModalCard = styled(Card) <{ $width?: ModalWidth, $padding?: number }>`
  ${(props) =>
    css`
      position: relative;
      padding: ${props.$padding
    ? `${props.$padding}px`
    : '58px 48px'};
      max-width: ${props.$width
    ? `${props.$width}px`
    : `${ModalWidth.SMALL}px`};
      margin: 5vh auto;
      max-height: 90vh;
      overflow-y: auto;
    `}
`

const StyledCloseButton = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  outline: none;

  &:focus {
    outline: none;
    cursor: pointer;
  }
`
