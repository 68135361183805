import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { PageLayoutWrapper } from '../../../../components/ui/PageLayoutWrapper'
import { Subjects } from '../Subjects'
import { Contracts } from '../Contracts'
import styled from 'styled-components'
import { BaseSubject, Contract } from '../../types'
import { Button } from '@material-ui/core'
import { UserInfo } from '../..'

type ContractResponse = {
  contracts: Contract[];
  enums: any;
};

type SubjectsResponse = {
  subjects: BaseSubject[] | undefined;
  enums: any;
};

type Props = {
  user: UserInfo;
}

export const ContractsTab: FC<Props> = ({ user }) => {
  const contractsQueryKey = `/api/users/${user.id}/contracts`
  const { data: response, isLoading: contractsLoading } =
    useQuery<ContractResponse>(contractsQueryKey)
  const enums = response?.enums || {}

  const queryKey = `/api/users/${user.id}/contract_subjects`
  const { data, isLoading: subjectsLoading } =
    useQuery<SubjectsResponse>(queryKey)
  const { subjects } = data || {}
  if (contractsLoading || subjectsLoading) return <p>Loading...</p>
  return (
    <PageLayoutWrapper>
      <StyledContainer>
        <Contracts
          contracts={response?.contracts}
          isLoading={contractsLoading}
          contractTypes={enums.contractTypes}
          subjects={enums.contractSubjects || []}
          userTribes={enums.userTribes || []}
        />
        <Subjects
          subjects={subjects}
          enums={data?.enums}
          userId={user.id}
        />
      </StyledContainer>
    </PageLayoutWrapper>
  )
}

export const AddNewButton = styled(Button)`
  margin-bottom: 1.5em;
`

const StyledContainer = styled.div`
  display: block;
  margin-top: 2.5em;
  @media (min-width: 1441px){{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    margin-top: 2em;
  }
`
