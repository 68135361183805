import React, { FC } from 'react'
import { TableCell } from '@material-ui/core'
import { ProductCategory, Product } from '../types'
import { TextFieldData } from '../../../types/UpdatableFormFieldData/TextFieldData'
import { ItemField } from '../../../components/ui/UpdatableFormField/ItemField'
import styled from 'styled-components'
import { SelectFieldData } from '../../../types/UpdatableFormFieldData/SelectFieldData'
import { fridgeTypesSelectable, merchTypesSelectable } from '../utils'

type Props = {
  product: Product;
  selectedProductCategory: ProductCategory;
};

export const FridgeMerchTableCells: FC<Props> = ({ product, selectedProductCategory }) => {
  const { id, flavor, count, productType } = product

  const productTypes =
    selectedProductCategory === ProductCategory.fridge
      ? fridgeTypesSelectable
      : merchTypesSelectable

  return (
    <>
      <StyledTableCell>
        <ItemField
          updatableField={new TextFieldData(flavor, 'flavor')}
          updatePath={`/api/products/${id}`}
          error={''}
          noPadding={true}
          updatable={true}
        />
      </StyledTableCell>
      <StyledTableCell>
        <ItemField
          updatableField={new TextFieldData(count, 'count')}
          updatePath={`/api/products/${id}`}
          error={''}
          noPadding={true}
          updatable={true}
        />
      </StyledTableCell>
      <StyledTableCell>
        <ItemField
          updatableField={
            new SelectFieldData(productType, 'productType', productTypes)
          }
          updatePath={`/api/products/${id}`}
          error={''}
          noPadding={true}
          updatable={true}
        />
      </StyledTableCell>
    </>
  )
}

const StyledTableCell = styled(TableCell)`
  padding: 8px;
  .jdFAxY {
    min-width: 50px;
  }
`
