import React, { FC, useEffect } from 'react'
import { useQuery } from 'react-query'
import { ProjectInfoTab } from './components/Tabs/ProjectInfoTab'
import { Project, PropsData } from './types'
import { BudgetNotificationTab } from './components/Tabs/BudgetNotificationTab'
import { ReportingDataTab } from './components/Tabs/ReportingDataTab'
import { unifyEnums } from './utils'
import { TabsLayout } from '../../components/ui/TabsLayout'

type Props = {
  id: number;
  propsData: PropsData;
};
export const ProjectInfo: FC<Props> = (props) => {
  const { id, propsData } = props
  const queryKey = `/api/projects/${id}.json`
  const queryOptions = {
    initialData: propsData.project,
  }
  const { data: project, isLoading } = useQuery<Project>(
    queryKey,
    queryOptions
  )

  if (isLoading) {
    return <p>Loading</p>
  } else {
    const enums = propsData.enums

    useEffect(() => {
      unifyEnums(enums)
    }, [])

    const headings = [
      'Info',
      'Budget notification',
      'Reporting data',
    ]
    const content = [
      <ProjectInfoTab
        key={0}
        project={project!}
        enums={enums}
      />,
      <BudgetNotificationTab
        key={1}
        project={project!}
        enums={enums}
      />,
      <ReportingDataTab
        key={2}
        project={project!}
        enums={enums}
      />,
    ]
    return (
      <TabsLayout
        headings={headings}
        tabsContent={content}
      />
    )
  }
}
