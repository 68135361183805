import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { ReportFields as Field } from '../types'
import { Grid, TableCell } from '@material-ui/core'
import { SortProps } from './TogglReport'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const reportFieldNameMapper = {
  [Field.user]: 'Person',
  //TODO using project, but getting back project_name
  [Field.project]: 'Project',
  [Field.description]: 'Description',
  [Field.sum]: 'Sum',
}

type ReportTableHeaderProps = {
  field: string;
  align: 'right' | 'left' | 'center';
  clickHandler: (field: string) => void;
  sort?: SortProps;
};

export const ReportTableHeader = ({
  sort,
  field,
  align,
  clickHandler,
}: ReportTableHeaderProps) => {
  const SortIcon: FC = () => {
    return <StyledArrowDropDownIcon descending={!!sort?.descending} />
  }

  return (
    <HeaderCell
      align={align}
      onClick={() => clickHandler(field)}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justify={align === 'right' ? 'flex-end' : 'flex-start'}
      >
        {reportFieldNameMapper[field]}
        {sort?.field === field && <SortIcon />}
      </Grid>
    </HeaderCell>
  )
}

const HeaderCell = styled(TableCell)`
  cursor: pointer;
`

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)<{
  descending?: boolean;
}>`
  transition: transform 0.3s;

  ${(props) =>
    !props.descending &&
    css`
      transform: rotate(180deg);
    `}
`
