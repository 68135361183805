import React from 'react'
import { FC } from 'react'
import { IconSVGProps } from '../Icon'

interface HeartIconProps extends IconSVGProps {
  filled: boolean;
  disabled?: boolean;
}

export const HeartIcon: FC<HeartIconProps> = ({
  filled = false,
  disabled = false,
  width = 22,
  height = 20,
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 22 20"
    fill={filled ? 'black' : 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 18.498L2.478 9.61a5.042 5.042 0 015.114-8.366c.76.245 1.452.668 2.016 1.233L11 3.867l1.392-1.39a5.043 5.043 0 017.132 7.13L11 18.498z"
      stroke={disabled ? 'rgba(0,0,0, 0.11)' : '#14141F'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
