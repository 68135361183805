import React, { FC } from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import styled from 'styled-components'
import { Dots } from './Icon/icons/Dots'

export type DotsMenuItem = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
};
type Props = {
  items: DotsMenuItem[];
  heavy?: boolean;
  small?: boolean;
  transparent?: boolean;
};
export const DotsMenu: FC<Props> = ({ items, heavy, small, transparent }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }
  const handleItemClick = (item: DotsMenuItem) => {
    return () => {
      item.onClick()
      closeMenu()
    }
  }
  return (
    <>
      <StyledIconButton
        onClick={openMenu}
        size={small ? 'small' : 'medium'}
        transparent={transparent}
      >
        <Dots />
      </StyledIconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closeMenu}
      >
        {items.map((item) => (
          <StyledMenuItem
            key={item.label}
            onClick={handleItemClick(item)}
            disabled={item.disabled}
            heavy={heavy}
            small={small}
          >
            {item.label}
          </StyledMenuItem>
        ))}
      </Menu>
    </>
  )
}

const StyledIconButton = styled(IconButton)<{ transparent?: boolean }>`
  border-radius: 0;
  background: ${({ transparent }) => transparent ? 'transparent' : 'white'};
`

const StyledMenuItem = styled(MenuItem)<{ heavy?: boolean; small?: boolean }>`
  ${({ heavy }) => heavy && `
    font-family: 'Ginto Nord', serif;
    justify-content: center;
    text-transform: uppercase;
    font-size: 13px;
  `}

  ${({ small }) => small && `
    font-size: 14px;
  `}
`
