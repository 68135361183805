import { CurrentUser } from '../TestingDevicesPage/types'

export const getSortedHoldersViaDate = ({
  dates,
}: {
  dates: CurrentUser[];
}): CurrentUser[] =>
  dates.sort((objA: any, objB: any) => {
    const startDayB = objB.startDate && new Date(objB.startDate).getTime()
    const startDayA = objA.startDate && new Date(objA.startDate).getTime()
    return startDayB - startDayA
  })
