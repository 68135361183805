import { round } from 'lodash'
import { omit } from 'lodash'
import {
  DataRow,
  EntityType,
  ReportFields,
  ReportType,
  RequestParams,
} from './types'

export const onlyUnique = (value, index, self) => self.indexOf(value) === index

export const mapUniqueColumnValues = (object, key) => {
  const vals = object.map((i) => i[key])
  return vals.filter(onlyUnique)
}

export const formatSum = (number: number) => {
  return round(number, 2).toFixed(2)
}

export const removeColumn = (column: ReportType, rows: DataRow[]) => {
  const newRows = rows.map((row) =>
    omit(row, ReportFields[ReportType[column]])
  )
  return newRows
}

const parseNumberFromUrlParam = (params, name): number | 'all' => {
  const string = params.get(name)
  return string === null ? 'all' : parseInt(string)
}

export const parseUrlParams = (urlParams: string): RequestParams => {
  const getInitialDate = () =>
    new Date().toISOString().split('T')[0].slice(0, -3).replace('-', '/')

  const params = new URLSearchParams(urlParams)
  return {
    user_id: parseNumberFromUrlParam(params, EntityType.user),
    project_id: parseNumberFromUrlParam(params, EntityType.project),
    month: params.get(EntityType.month) || getInitialDate(),
    report_type: params.get(EntityType.report) || ReportType.user,
  }
}

export const calculateSum = (groupRows: DataRow[]): number => {
  return groupRows.reduce((sum, item) => sum + item.sum, 0)
}

export const encodeUrlParams = (params) => {
  const urlParams = Object.entries(params)
    .map(([key, val]) => {
      return val === 'all' ? '' : `${key}=${val}`
    })
    .filter((item) => item !== '')
    .join('&')
  return '?' + urlParams
}
