import { FormikValues } from 'formik'
import { Transaction, Account, AccountSelectValues } from './types'

export const isAmountFilled = (
  formikTransactionValues: Transaction[]
): boolean => {
  return formikTransactionValues.some((transaction) => transaction.amount)
}

export const calculatePairedSum = (
  formikTransactionValues: Transaction[]
): number => {
  return formikTransactionValues.reduce((sum, currentValue) => {
    if (currentValue.amount) sum = sum + Number(currentValue.amount)
    return sum
  }, 0)
}

export const hasDuplicates = (
  transactions: Transaction[] | undefined
): boolean => {
  const selectedAccounts = transactions
    ?.filter((transaction) => transaction.account_id !== '')
    .map((transaction) => transaction.account_id)
  if (selectedAccounts) {
    return new Set(selectedAccounts).size != selectedAccounts?.length
  }
  return false
}

export const getValidTransactions = (
  transactions: Transaction[]
): Transaction[] => {
  if (isAmountFilled(transactions)) {
    const validTransactions = transactions
      .filter((transaction) => transaction.account_id && transaction.amount)
      .map((result) => ({ ...result, amount: Number(result.amount) }))
    return validTransactions
  } else {
    return []
  }
}

export const createAccountSelect = (
  accounts: Account[]
): AccountSelectValues[] => {
  const selectAccounts = accounts?.map((account) => {
    const label = account.owner.nickname
      ? `${account.owner.nickname} - ${account.owner.fullName}`
      : `${account.owner.fullName}`
    return { value: account.id, label: label }
  })
  return selectAccounts
}

export const isFormReadyToSubmit = (
  values: FormikValues,
  amountLeft: string
): boolean => {
  return (
    !hasDuplicates(getValidTransactions(values.transactions)) &&
    Number(amountLeft) >= 0 &&
    values.transactions[0].amount > 0 &&
    values.transactions[0].account_id > 0 &&
    values?.note != ''
  )
}
