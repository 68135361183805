import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const RemoveUser: FC<IconSVGProps> = ({ width = 22, height = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 15.6673C1.00068 14.6753 1.24716 13.699 1.71742 12.8256C2.18767 11.9522 2.86704 11.2089 3.69478 10.6623C4.52252 10.1156 5.47282 9.78259 6.46073 9.693C7.44863 9.6034 8.44335 9.76001 9.35593 10.1488"
        stroke="#14141F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00033 8.33366C9.02546 8.33366 10.6672 6.69196 10.6672 4.66683C10.6672 2.6417 9.02546 1 7.00033 1C4.97519 1 3.3335 2.6417 3.3335 4.66683C3.3335 6.69196 4.97519 8.33366 7.00033 8.33366Z"
        stroke="#14141F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6676 20.9999C18.6132 20.9999 21.0011 18.612 21.0011 15.6663C21.0011 12.7207 18.6132 10.3328 15.6676 10.3328C12.7219 10.3328 10.334 12.7207 10.334 15.6663C10.334 18.612 12.7219 20.9999 15.6676 20.9999Z"
        stroke="#14141F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0005 15.6665H18.3341"
        stroke="#14141F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
