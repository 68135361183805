import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const PaymentsIcon: FC<IconSVGProps> = ({ width = 20, height = 42 }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.667 16.544v1.333A2.667 2.667 0 0117 20.544H3.667A2.667 2.667 0 011 17.877V8.544a2.667 2.667 0 012.667-2.667H17a2.667 2.667 0 012.667 2.667v1.333"
      stroke="#C8C6C5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 5.878l-2.07-4.14a1.334 1.334 0 00-1.79-.597L3.668 5.878M19.667 16.544A1.334 1.334 0 0021 15.211v-4a1.333 1.333 0 00-1.333-1.334h-3.334a3.333 3.333 0 100 6.667h3.334z"
      stroke="#C8C6C5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.333 12.877a.333.333 0 110 .667.333.333 0 010-.667"
      stroke="#C8C6C5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
