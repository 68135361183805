import React, { FC, useCallback, useEffect, useState } from 'react'
import { IconButton } from '@material-ui/core'
import styled from 'styled-components'

import { CopyIcon } from '../Icon/icons/CopyIcon.icon'
import { SuccessCheckmark } from '../Icon/icons/SuccessCheckmark.icon'

interface Props {
  text: string;
  color?: string;
  width?: number;
  height?: number;
  strokeWidth?: string;
  padding?: string;
  margin?: string;
}

export const CopyButton: FC<Props> = ({ text, color, width = 13, height = 13, strokeWidth, padding, margin }) => {
  const [copied, setCopied] = useState(false)

  const copyToClipboard = useCallback((text: string) => {
    navigator.clipboard.writeText(text)
    setCopied(true)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }, [copied])

  return (
    <StyledIconButton
      aria-label="copy"
      onClick={() => copyToClipboard(text)}
      padding={padding}
      margin={margin}
    >
      {copied ? (
        <SuccessCheckmark
          width={width}
          height={height}
        />
      ) : (
        <CopyIcon
          width={width}
          height={height}
          color={color}
          strokeWidth={strokeWidth}
        />
      )}
    </StyledIconButton>
  )
}

const StyledIconButton = styled(IconButton)<{ padding?: string; margin?: string }>`
  margin-left: 18px;
  ${({ margin }) => margin && `margin: ${margin};` }
  ${({ padding }) => padding && `padding: ${padding};` }
`
