import React, { FC } from 'react'
import { TableCell } from '@material-ui/core'
import styled from 'styled-components'
import { ShopProductRowVariant } from '../types'
import { Skeleton } from '@material-ui/lab'
import { NameCell, ProductRow } from './ShopProductRow'

export const ShopProductRowSkeleton: FC = () => {
  return (
    <ProductRow
      $selected={false}
      $variant={ShopProductRowVariant.CLASSIC}
    >
      <NameCell className="px-0">
        <Skeleton
          variant={'text'}
          width={'80%'}
        />
        <Skeleton variant={'text'} />
      </NameCell>
      <PriceCell align={'right'}>
        <PriceContent>
          <Skeleton variant={'text'} />
        </PriceContent>
      </PriceCell>
      {/*<TableCell align={"right"}>*/}
      {/*  <FavouriteCell>*/}
      {/*    <IconButton disabled={true}>*/}
      {/*      <HeartIcon disabled filled={false} />*/}
      {/*    </IconButton>*/}
      {/*  </FavouriteCell>*/}
      {/*</TableCell>*/}
      <SelectionTableCell align={'right'}>
        <SelectionContent>
          <Skeleton
            variant={'circle'}
            width={'2.5em'}
            height={'2.5em'}
          />
        </SelectionContent>
      </SelectionTableCell>
    </ProductRow>
  )
}

const SelectionTableCell = styled(TableCell)`
  padding-right: 0;
  padding-left: 0;
`

const SelectionContent = styled.div`
  width: 7.5em;
  font-size: 1em;
  display: flex;
  justify-content: flex-end;
`

const FavouriteCell = styled.div`
  width: 44px;
`

const PriceCell = styled(TableCell)`
  padding-right: 0;
`

const PriceContent = styled.div`
  width: 65px;
`
