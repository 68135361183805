import React, { FC } from 'react'
import { IconButton } from '@material-ui/core'
import styled from 'styled-components'
import RemoveCircleIcon from '@material-ui/icons//RemoveCircle'
import AddCircle from '@material-ui/icons/AddCircle'
import { FieldArrayRenderProps, useFormikContext, FormikValues } from 'formik'

type Props = {
  arrayHelpers: FieldArrayRenderProps;
};

export const IconButtonGroup: FC<Props> = ({ arrayHelpers }) => {
  const { values } = useFormikContext<FormikValues>()
  const { remove, push } = arrayHelpers

  return (
    <IconButtonContainer>
      {values.transactions.length <= 4 && (
        <StyledIconButton
          color="primary"
          onClick={() => push({ account_id: '', amount: '' })}
        >
          <AddCircle />
        </StyledIconButton>
      )}

      {values.transactions.length > 1 && (
        <StyledIconButton
          color="primary"
          onClick={() => remove(values.transactions.length - 1)}
        >
          <RemoveCircleIcon />
        </StyledIconButton>
      )}
    </IconButtonContainer>
  )
}

const IconButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
`

export const StyledIconButton = styled(IconButton)`
  padding: 18px 0 0 0;
  align-self: center;
  left: 0px;
`
