import React, { FC, useEffect, useState } from 'react'
import { Typography, TextField, Tooltip } from '@material-ui/core'
import styled from 'styled-components'
import { BarCode } from '../components/BarCode'
import { useCustomMutation, Mutation } from '../../../hooks/useCustomMutation'
import { ValidatedBarcodes } from '../types'
import { areAllBarcodesValid } from '../utils'
import InfoIcon from '@material-ui/icons/Info'
import { colors } from '../../../styles/colors'

type Props = {
  newBarcode: string;
  setNewBarcode: (newBarcode: string) => void;
  productBarcodes: string[];
  setProductBarcodes: (productBarcodes: string[]) => void;
  productId?: number;
  setBarcodeValidationError: (barcodeValidationError: boolean) => void;
};
export const BarCodeContainer: FC<Props> = ({
  newBarcode,
  setNewBarcode,
  productBarcodes,
  setProductBarcodes,
  productId,
  setBarcodeValidationError,
}) => {
  const [validatedBarcodes, setValidatedBarcodes] =
    useState<ValidatedBarcodes | null>(null)

  const createBarcodeValidationMutation: Mutation<void> = (vals) => ({
    path: productId
      ? `/api/products/${productId}/validate_barcodes`
      : '/api/products/validate_barcodes',
    method: 'POST',
    params: vals,
  })

  const newBarcodeValidationMutation = useCustomMutation(
    createBarcodeValidationMutation,
    {
      onSuccess: async (data: Response) => {
        const validatedBarcodes = await data.json()
        setValidatedBarcodes(validatedBarcodes)
      },
    },
    true
  )

  const validateBarcodes = async () => {
    const values = { barcodes: [...productBarcodes] }
    productBarcodes.length > 0 && newBarcodeValidationMutation.mutate(values)
  }

  if (validatedBarcodes) {
    areAllBarcodesValid(validatedBarcodes) || productBarcodes.length == 0
      ? setBarcodeValidationError(false)
      : setBarcodeValidationError(true)
  }

  const addNewBarcode = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNewBarcode(e.target.value)
  }

  const addBarcodeToArray = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter') {
      const newBarcodes = [...productBarcodes, newBarcode]
      setProductBarcodes(newBarcodes)
      setNewBarcode('')
    }
  }

  useEffect(() => {
    productBarcodes && validateBarcodes()
  }, [productBarcodes])

  return (
    <>
      <StyledTypography variant={'overline'}>Barcodes</StyledTypography>
      <Tooltip title="Type barcode number and press Enter to add">
        <StyledInfoIcon />
      </Tooltip>

      <StyledTextField
        fullWidth
        value={newBarcode || ''}
        name="newBarcode"
        label="New barcode"
        onChange={addNewBarcode}
        onKeyDown={addBarcodeToArray}
        placeholder={'Enter barcode and press Enter'}
      />

      <ChipContainer>
        {productBarcodes.map((barcode, index) => (
          <BarCode
            key={index}
            barcode={barcode}
            productBarcodes={productBarcodes}
            setProductBarcodes={setProductBarcodes}
            validatedBarcodes={validatedBarcodes}
          />
        ))}
      </ChipContainer>
    </>
  )
}

const StyledTypography = styled(Typography)`
  display: inline-block;
  margin-top: 1rem;
  font-size: 1.1rem;
`

const StyledTextField = styled(TextField)`
  margin-bottom: 20px;
`

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`

const StyledInfoIcon = styled(InfoIcon)`
  color: ${colors.grey0};
  margin-left: 1rem;
`
