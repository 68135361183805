import { PaginatedQueryResponse } from '../../../hooks/usePaginatedQuery'
import { BaseUser } from '../../../types'

export type Transaction = {
  accountHolder: BaseUser
  amount: number
  createdAt: string
  createdBy: BaseUser
  direction: string
  id: number
  note: string
  transactableId: number
  transactableType: string
  transactableUrl: string
}

export type TransactionFilters = {
  scope: string
  date_from: string
  date_to: string
  amount_from: string
  amount_to: string
  direction: string
  per_page: string
  user_id?: number
}

export type MyTransactionContextType = PaginatedQueryResponse<Transaction[]> & {
  readonly children: React.ReactNode
  readonly isAmountFromBigger: boolean
  readonly users?: BaseUser[]
  readonly isNotDataAvailable: boolean
  readonly isUsersLoading: boolean
  readonly isAllTransactions: boolean
  filterTransactions: (filters: Record<string, string | number>) => void
  showActionColumn: boolean
}

export type CreatePayment = {
  direction: string
  user_id: number | ''
  amount: number | ''
  note: string
  transaction_date: string
}

export enum DirectionType {
  deposit = 'deposit',
  withdrawal = 'withdrawal',
}

export interface ErrorPayments {
  status: number;
  error?: string;
  errors?: {
    amount?: string;
    user_id?: string;
    transaction_date?: string;
    note?: string;
  };
}
