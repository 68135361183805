import React from 'react'
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import styled from 'styled-components'
import { format, getMonth } from 'date-fns'

type MonthPickerProps = {
  selectOptions: string[];
  selectedMonth: string;
  changeHandler: (event: any) => void;
  label?: string;
};

export const MonthPicker = ({
  changeHandler,
  selectOptions,
  selectedMonth,
  label
}: MonthPickerProps) => {
  const anchorRef = React.useRef(null)

  const setMonth = (step: number) => {
    const currentMonthIndex = selectOptions.indexOf(selectedMonth)
    const nextMonthIndex = currentMonthIndex + step

    if (nextMonthIndex < selectOptions.length - 1) {
      if (nextMonthIndex !== -1) return changeHandler(selectOptions[nextMonthIndex])
    }

    return
  }
  const [open, setOpen] = React.useState(false)

  const handleMenuItemClick = (yearMonth) => {
    setOpen(false)
    changeHandler(yearMonth)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    setOpen(false)
    // @ts-ignore
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
  }

  return (
    <div>
      <InputLabel>{label ? label : 'Date'}</InputLabel>
      <StyledButtonGroup
        style={{ width: '100%' }}
        variant="outlined"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button onClick={() => setMonth(+1)}>
          <ChevronLeftIcon fontSize="small" />
        </Button>

        <Button
          fullWidth
          color="primary"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          {selectedMonth}
        </Button>

        <Button onClick={() => setMonth(-1)}>
          <ChevronRightIcon fontSize="small" />
        </Button>
      </StyledButtonGroup>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {selectOptions.map((yearMonth, id) => (
                    <MenuItem
                      key={id}
                      selected={yearMonth === selectedMonth}
                      onClick={() => handleMenuItemClick(yearMonth)}
                    >
                      {yearMonth}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

const StyledButtonGroup = styled(ButtonGroup)`
  width: 100%;

  button:nth-of-type(2) {
    width: 100%;
  }
`
