import styled from 'styled-components'

export const FormGrid = styled.form`
  display: grid;
  grid-row-gap: 24px;

  button {
    justify-self: end;
  }

  > :last-child {
    margin-top: 16px;
  }
`
