export enum ProjectTableItem {
  Tribe,
  Info,
  TLActions,
  Join,
}

export type ProjectTableConfigItem = {
  type: ProjectTableItem;
  align?: 'right';
};

export type ProjectTableConfig = ProjectTableConfigItem[];
