import React, { FC } from 'react'
import styled from 'styled-components'
import { Button } from '../../../components/buttons/Button'
import { ModalWrapper } from '../../../components/ModalWrapper'

type Props = {
  isToBeConfirmed: boolean;
  closeModal: () => void;
  confirm: () => void;
};

export const ConfirmationDialog: FC<Props> = ({ isToBeConfirmed, closeModal, confirm }) => {
  return (
    <ModalWrapper
      isOpen={isToBeConfirmed}
      closeModal={closeModal}>
      <>
        <p>
          By switching to another product category you will lose your current changes. Continue?
        </p>
        <ButtonContainer>
          <Button
            kind={'primary'}
            onClick={confirm}>
            Continue
          </Button>
          <Button
            kind={'secondary'}
            onClick={closeModal}>
            Cancel
          </Button>
        </ButtonContainer>
      </>
    </ModalWrapper>
  )
}

const ButtonContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
`
