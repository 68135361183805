import React, { FC } from 'react'
import { Skeleton } from '@material-ui/lab'
import _ from 'lodash'
import { TableCell, TableRow } from '@material-ui/core'

type Props = {
  rows: number;
};

export const DeviceHistoryTableSkeleton: FC<Props> = ({ rows }) => {
  const skeletonRows = _.range(rows)

  return (
    <>
      {skeletonRows.map((item) => (
        <TableRow key={item}>
          <TableCell>
            <Skeleton
              variant="rect"
              width={150}
              height={30}
              animation="wave" />
          </TableCell>
          <TableCell>
            <Skeleton
              variant="rect"
              width={155}
              height={30}
              animation="wave" />
          </TableCell>
          <TableCell>
            <Skeleton
              variant="rect"
              width={62}
              height={30}
              animation="wave" />
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}

