import React, { FC } from 'react'
import { Divider } from '@material-ui/core'
import styled from 'styled-components'

export const TabButtonsContainer: FC = ({ children }) => {
  return (
    <StyledContainer>
      <Content>{children}</Content>
      <Divider />
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  margin-bottom: 1.2em;
`

const Content = styled.div`
  margin: 1.5em 0 1em;
  > button {
    margin-right: 1em;
  }
`
