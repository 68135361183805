import React, { FC } from 'react'
import { Box, Grid, Hidden, TableBody, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { ShopProductRowSkeleton } from '../ShopProductRowSkeleton'
import _ from 'lodash'
import styled from 'styled-components'
import {
  FilterSkeletonContainer,
  ProductsListContainer,
  ProductsTable,
} from './ProductsListView'

export const ProductListSkeleton: FC = () => {
  const skeletonRows: JSX.Element[] = _.range(10).map((i) => (
    <ShopProductRowSkeleton key={i} />
  ))

  return (
    <>
      <Box mb={4}>
        <SearchSkeleton variant={'rect'} />
      </Box>
      <Hidden mdDown>
        <Grid container>
          <BubbleSkeleton
            width={120}
            height={36}
            variant={'rect'}
          />
          <BubbleSkeleton
            width={120}
            height={36}
            variant={'rect'}
          />
          <BubbleSkeleton
            width={120}
            height={36}
            variant={'rect'}
          />
        </Grid>
      </Hidden>
      <ProductsListContainer>
        <div>
          <Hidden mdDown>
            <Typography variant={'overline'}>Filter</Typography>
            <FilterSkeletonContainer>
              <Skeleton variant={'rect'} />
              <Skeleton
                variant={'text'}
                width={'40%'}
              />
              <Skeleton variant={'rect'} />
              <Skeleton
                variant={'text'}
                width={'35%'}
              />
            </FilterSkeletonContainer>
          </Hidden>
        </div>
        <div>
          <Typography variant={'overline'}>{'Products'}</Typography>
          <ProductsTable>
            <TableBody>{skeletonRows}</TableBody>
          </ProductsTable>
        </div>
      </ProductsListContainer>
    </>
  )
}

const SearchSkeleton = styled(Skeleton)`
  height: 28px;
  padding: 10px 0;
`

const BubbleSkeleton = styled(Skeleton)`
  border-radius: 43px;
  margin-right: 1em;
  margin-bottom: 1em;
`
