import React, { FC, useState, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import {
  Product,
  ProductCategory,
  ProductCreate,
  ErrorProduct,
  ProductStatus,
} from './types'
import { useQueryClient } from 'react-query'
import { Formik } from 'formik'
import { useCustomMutation, Mutation } from '../../hooks/useCustomMutation'
import { useQueryNotification } from '../UserInfo/utils'
import { createInitValues, editInitValues } from './utils'
import { FormikSubmitHandler } from '../../types'
import { ModalWrapper } from '../../components/ModalWrapper'
import { CreateProductForm } from './components/CreateProductForm'
import { ProductForm } from './components/ProductForm'

type Props = {
  dialogOpened: boolean;
  closeModal: () => void;
  product?: Product;
  selectedProductStatus: ProductStatus;
  selectedProductCategory?: ProductCategory;
};

export const ProductModal: FC<Props> = ({
  dialogOpened,
  closeModal,
  product,
  selectedProductStatus,
  selectedProductCategory,
}) => {
  const queryClient = useQueryClient()
  const { successNotification, errorNotification } = useQueryNotification()
  const [barcodeValidationError, setBarcodeValidationError] = useState(false)
  const [productBarcodes, setProductBarcodes] = useState<string[]>([])
  const [selectedCategoryForm, setSelectedCategoryForm] =
    useState<ProductCategory>(ProductCategory.fridge)
  const [newBarcode, setNewBarcode] = useState('')

  const createNewSubjectMutation: Mutation<Product> = (initVals) => ({
    path: product ? `/api/products/${product.id}` : '/api/products',
    method: product ? 'PUT' : 'POST',
    params: initVals,
  })

  const newSubjectMutation = useCustomMutation<Response, any, Product>(
    createNewSubjectMutation,
    {
      onSuccess: async (data: Response) => {
        await queryClient.refetchQueries(
          `/api/products?product_group=${selectedCategoryForm}&state=${selectedProductStatus}`
        )
        const createdOrUpdatedProduct = await data.json()
        const { name } = createdOrUpdatedProduct
        closeModal()
        successNotification(
          `Product ${name} was successfully ${product ? 'updated' : 'created'}`
        )
      },
      onError: (error: ErrorProduct) => {
        if (!error.errors) {
          errorNotification(
            error.message ?? 'Sorry, something went wrong. Try again later'
          )
        }
      },
    },
    true
  )

  const formikSubmit: FormikSubmitHandler<ProductCreate> = async (
    values,
    { setErrors }
  ) => {
    if (barcodeValidationError) {
      errorNotification('Some barcodes are already used in other products')
    } else {
      const barcodesString =
        productBarcodes.length > 0 ? productBarcodes.join(', ') : null
      const postValues = {
        ...values,
        count: values.count ? Number(values.count) : null,
        currentCost: values.currentCost ? Number(values.currentCost) : null,
        externalProductId: values.externalProductId
          ? Number(values.externalProductId)
          : null,
        barCode: barcodesString,
        group: selectedCategoryForm,
      }
      await newSubjectMutation.mutateAsync(postValues).catch((err) => {
        setErrors(err.errors)
      })
    }
  }

  const initVals =
    product && selectedProductCategory
      ? editInitValues(selectedProductCategory, product)
      : createInitValues(selectedCategoryForm)

  useEffect(() => {
    product && setProductBarcodes(product.barcodes)
  }, [product])

  return (
    <ModalWrapper
      isOpen={dialogOpened}
      closeModal={() => {
        closeModal()
      }}
    >
      <Typography variant={'h3'}>
        {product ? product.name : 'New product'}
      </Typography>
      <Formik
        initialValues={initVals}
        onSubmit={formikSubmit}
      >
        {(formikProps) => {
          return product && selectedProductCategory ? (
            <ProductForm
              formikProps={formikProps}
              selectedProductCategory={selectedProductCategory}
              newBarcode={newBarcode}
              setNewBarcode={setNewBarcode}
              productBarcodes={productBarcodes}
              setProductBarcodes={setProductBarcodes}
              setBarcodeValidationError={setBarcodeValidationError}
              closeModal={closeModal}
              productId={product.id}
            />
          ) : (
            <CreateProductForm
              formikProps={formikProps}
              selectedCategoryForm={selectedCategoryForm}
              setSelectedCategoryForm={setSelectedCategoryForm}
              productBarcodes={productBarcodes}
              setProductBarcodes={setProductBarcodes}
              setBarcodeValidationError={setBarcodeValidationError}
              closeModal={closeModal}
            />
          )
        }}
      </Formik>
    </ModalWrapper>
  )
}
