import React, { FC, useMemo, useState } from 'react'
import { Alert } from '@material-ui/lab'
import styled from 'styled-components'
import { Grid, Switch, Typography } from '@material-ui/core'

import { GraphData } from '../types'
import { ProjectBudgetGraph } from './ProjectBudgetGraph'
import { Button } from '../../../components/buttons/Button'
import { SelectItem } from '../../../types'
import { GraphTypes } from '../../ProjectInfo/types'
import { useUserPermissions } from '../../../components/CurrentUserProvider'
import { theme } from '../../../styles/muiThemes'
import { PigIcon } from '../../../components/Icon/icons/Pig.icon'
import { CorpSelect } from '../../../components/inputs/CorpSelect'
import { colors } from '../../../styles/colors'

type Props = {
  years: GraphData[];
  projectId: number;
  totalHours: number;
};

export const ProjectBudget: FC<Props> = ({ years, projectId, totalHours }) => {
  const { resolvePermissions } = useUserPermissions()
  const canManageProject = useMemo(
    () => resolvePermissions('project', 'manage'),
    []
  )

  if (years.length === 0) return <Alert>No budgets available</Alert>

  const [activeYear, setActiveYear] = useState<string>(String(years[0].year))
  const [graphType, setGraphType] = useState<GraphTypes>(GraphTypes.line)

  const handleSetGraphType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const switcherGraphType = event.target.checked ? GraphTypes.bar : GraphTypes.line
    setGraphType(switcherGraphType)
  }

  const activeYearData = useMemo(
    () => years.find((year) => String(year.year) === activeYear),
    [activeYear]
  )

  const yearsLabels: SelectItem[] = useMemo(
    () => years.map((year) => ({ label: String(year.year), value: year.year })),
    []
  )

  const setBudgetsUrl = useMemo(
    () => `/admin/projects/${projectId}/budgets/edit`,
    [projectId]
  )

  return (
    <div>
      <Grid
        container
        justify={'space-between'}
        alignItems={'center'}
      >
        <Grid item>
          <Typography variant={'h3'}>Hour Budget</Typography>
        </Grid>
        <Grid item>
          {canManageProject && (
            <Button
              kind="basicSecondary"
              href={setBudgetsUrl}
            >
              <PigIcon />
              <ButtonText>Set budget</ButtonText>
            </Button>
          )}
        </Grid>
      </Grid>

      <CorpSelecWrapper>
        <StyledCorpSelect
          values={yearsLabels}
          value={activeYear}
          onChange={(e) => setActiveYear(String(e.target.value))}
        />
      </CorpSelecWrapper>
      <GraphInfoSwitcherContainer>
        <TotalHoursWrapper>
          <div>Total used hours: <TextBold>{activeYearData?.totalUsedHours}</TextBold></div>
        </TotalHoursWrapper>

        <SwitchWrapper>
          <SwitchLabel>Line chart</SwitchLabel>
          <StyledSwitch
            value={false}
            name={'graphType'}
            onChange={handleSetGraphType}
            color='primary'
          />
          <SwitchLabel>Bar chart</SwitchLabel>
        </SwitchWrapper>

      </GraphInfoSwitcherContainer>
      {activeYearData &&
        <ProjectBudgetGraph
          data={activeYearData}
          type={graphType}
        />}
    </div>
  )
}

const GraphInfoSwitcherContainer = styled.div`
  margin-top: 37px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${theme.breakpoints.down('lg')} {
    display: block;
    > * {
      margin-bottom: 1.5rem;
    }
  }
`

const ButtonText = styled.span`
  margin-left: 10.5px;
`

const CorpSelecWrapper = styled.div`
  width: 70px;
`

const StyledSwitch = styled(Switch)`
  margin: 0 12px;
`

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`

const SwitchLabel = styled.span`
  color: ${colors.grey0};
  font-size: 16px;
  line-height: 16px;
  font-feature-settings: 'calt' off;
`

const TotalHoursWrapper = styled.div`
  color: ${colors.grey3};
  font-size: 16px;
`

const TextBold = styled.span`
  color: #131416;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
`

const StyledCorpSelect = styled(CorpSelect)`
  border: none;
`
