import React, { FC, useMemo } from 'react'
import { TextField, FormLabel } from '@material-ui/core'
import styled from 'styled-components'
import { AccountSelectValues } from '../types'
import { isFormReadyToSubmit } from '../utils'
import { TransactionsInputGroup } from './TransactionsInputGroup'
import { TransactionFormButtons } from './TransactionFormButtons'
import { IconButtonGroup } from './IconButtonGroup'
import { FormikProps, FieldArrayRenderProps, FormikValues } from 'formik'
import { NewTransactionSkeleton } from './NewTransactionSkeleton'
import { hasError } from '../../../utils/helpers'

type Props = {
  accountsSelectValues: AccountSelectValues[] | undefined;
  formikProps: FormikProps<FormikValues>;
  arrayHelpers: FieldArrayRenderProps;
  setIsDuplicateAccounts: (data: boolean) => void;
  amountLeft: string;
  closeModal: () => void;
  isLoading: boolean;
};

export const PairingForm: FC<Props> = ({
  accountsSelectValues,
  formikProps,
  arrayHelpers,
  setIsDuplicateAccounts,
  amountLeft,
  closeModal,
  isLoading,
}) => {
  const { values, handleChange, errors, submitForm } = formikProps
  const isDisabled = useMemo(() => {
    return isFormReadyToSubmit(values, amountLeft)
  }, [values, amountLeft])
  return (
    <>
      <div>
        <TextField
          fullWidth
          required
          value={values.note}
          name={'note'}
          label={'Note'}
          type="text"
          onChange={handleChange}
          error={hasError(formikProps, 'note')}
          helperText={errors.note?.[0]}
        />
        {isLoading ? (
          <NewTransactionSkeleton />
        ) : (
          <>
            <StyledFormLabel>
              Select accounts to pair the payment with:
            </StyledFormLabel>
            <TransactionsInputGroup
              accountsSelectValues={accountsSelectValues}
              formikProps={formikProps}
              setIsDuplicateAccounts={setIsDuplicateAccounts}
            />
            <IconButtonGroup arrayHelpers={arrayHelpers} />
            <TransactionFormButtons
              disabled={!isDisabled}
              submitForm={submitForm}
              closeModal={closeModal}
            />
          </>
        )}
      </div>
    </>
  )
}

const StyledFormLabel = styled(FormLabel)`
  margin: 2rem 0 1rem 0;
`
