import React, { FC } from 'react'
import styled from 'styled-components'

import { Logo } from '../../components/Icon/icons/Logo.icon'
import { colors } from '../../styles/colors'
import { CardInfoData } from './types'
import { CopyButton } from '../../components/buttons/CopyButton'
import { atMinWidth } from '../../styles/helpers'

type Props = {
  data: CardInfoData,
};

export const PaymentCardInfo: FC<Props> = ({ data }) => {
  const { cardNumber, cardHolder, expirationDate, cvv } = data

  return (
    <>
      <CardWrapper>
        <StyledLogoIcon><Logo
          width={40}
          height={83} />
        </StyledLogoIcon>
        <CardInfoWrapper>
          <CardRow>
            <CardTitle>Card number</CardTitle>
            <CardDataBig>{cardNumber} <CopyButton
              text={cardNumber}
              padding="0" /></CardDataBig>
          </CardRow>
          <CardOneRow>
            <div>
              <CardTitle>Cardholder name</CardTitle>
              <CardData>{cardHolder} <CopyButton
                text={cardHolder}
                padding="0" /></CardData>
            </div>
            <div>
              <CardTitle>Expiration date</CardTitle>
              <CardData>{expirationDate}<CopyButton
                text={expirationDate}
                padding="0" /></CardData>
            </div>
            <div>
              <CardTitle>CVV</CardTitle>
              <CardData>{cvv} <CopyButton
                text={cvv}
                padding="0" /></CardData>
            </div>
          </CardOneRow>
        </CardInfoWrapper>

      </CardWrapper>
    </>
  )
}

const CardRow = styled.div`
  font-size: 22px;
  margin-bottom: 24px;
`

const CardOneRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;

  ${atMinWidth.tablet} {
    flex-wrap: none;
    gap: auto;
  }
`

const CardWrapper = styled.div`
  background-color: ${colors.black};
  border-radius: 30px;
  margin-bottom: 56px;
  padding: 27px;
  position: relative;
`

const CardData = styled.div`
  align-items: center;
  color: ${colors.white};
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
`

const CardDataBig = styled(CardData)`
  font-size: 18px;
  line-height: 18px;

  ${atMinWidth.tablet} {
    font-size: 22px;
    line-height: 22px;
  }
`

const CardTitle = styled.div`
  color: ${colors.white};
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  margin-bottom: 6px;
`

const StyledLogoIcon = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
`

const CardInfoWrapper = styled.div`
  margin-top: 70px;

  ${atMinWidth.tablet} {
    margin-top: 114px;
  }
`
