import React, { FC } from 'react'
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Button,
} from '@material-ui/core'
import { TablePaymentRow } from './TablePaymentRow'
import { Payment } from '../UserInfo/types'
import { TableBodySkeleton } from '../../components/skeletons/TableBodySkeleton'
import styled from 'styled-components'
import { useCurrentUserInfo } from '../../components/CurrentUserProvider'

type Props = {
  userPayments: Payment[];
  isLoading: boolean;
  userId: number;
};

export const UserPaymentsTable: FC<Props> = ({
  userPayments,
  isLoading,
  userId,
}) => {
  // TO BE replaced by Payment table from PaymentPage after being merged
  const limitedUserPayments = userPayments && userPayments.slice(0, 10)
  const areUserPayments = userPayments && userPayments.length > 0
  const { currentUser } = useCurrentUserInfo()
  const isAdmin = currentUser.role === 'admin'
  const paymentsLink = isAdmin ? `/admin/transactions/all?user_id=${userId}&payment_direction=income` : '/admin/transactions/?payment_direction=income'

  const userPaymentsRows = limitedUserPayments?.map((payment) => (
    <TablePaymentRow
      key={payment.id}
      payment={payment}
    />
  ))

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell size="medium">Date</TableCell>
              <TableCell>Sender</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Paired to</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableBodySkeleton
                rows={30}
                colspan={3}
              />
            )}
            {areUserPayments && userPaymentsRows}
          </TableBody>
        </Table>
      </TableContainer>
      {areUserPayments ? (
        <StyledButton
          variant={'outlined'}
          color={'primary'}
          href={paymentsLink}
        >
          All payments
        </StyledButton>
      ) : (
        <p>No payments yet</p>
      )}
    </>
  )
}

const StyledButton = styled(Button)`
  margin-top: 2rem;
`
