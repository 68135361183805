import { sortByKey } from '../../utils/helpers'
import {
  ProductCategory,
  FridgeCategories,
  MerchCategories,
  ProductStatus,
  Product,
  ValidatedBarcodes,
  ProductCreate,
  Barcode,
} from './types'
import { SelectItem } from '../../types'
import { colors } from '../../styles/colors'

export const getProductsSortedByName = (
  products: Product[] | undefined
): Product[] => {
  if (products) sortByKey(products, 'name')
  return products || []
}

export const createInitValues = (
  selectedCategoryForm: ProductCategory
): ProductCreate => {
  return {
    name: null,
    productType: null,
    flavor: null,
    currentCost: null,
    count: null,
    group: selectedCategoryForm,
    barCode: null,
    description: null,
    externalProductId: null,
  }
}

export const editInitValues = (
  selectedCategoryForm: ProductCategory,
  product: Product
): ProductCreate => {
  const {
    name,
    productType,
    flavor,
    currentCost,
    count,
    barCode,
    description,
    externalProductId,
  } = product

  return {
    name,
    productType,
    flavor,
    currentCost,
    count,
    barCode,
    description,
    externalProductId,
    group: selectedCategoryForm,
  }
}

export const statusSelectable: SelectItem[] = [
  {
    value: ProductStatus.active,
    label: 'Active',
  },
  {
    value: ProductStatus.inactive,
    label: 'Inactive',
  },
]

export const switcherData = [
  {
    value: ProductCategory.fridge,
    label: `${ProductCategory.fridge.toUpperCase()}`,
  },
  {
    value: ProductCategory.benefits,
    label: `${ProductCategory.benefits.toUpperCase()}`,
  },
  {
    value: ProductCategory.merch,
    label: `${ProductCategory.merch.toUpperCase()}`,
  },
]

export const productCategorySelectable: SelectItem[] = [
  {
    value: ProductCategory.benefits,
    label: 'Benefits',
  },
]

export const fridgeTypesSelectable: SelectItem[] = [
  {
    value: FridgeCategories.alco,
    label: 'alco',
  },
  {
    value: FridgeCategories.cereal,
    label: 'cereal',
  },
  {
    value: FridgeCategories['ice cream'],
    label: 'ice cream',
  },
  {
    value: FridgeCategories['kids snack'],
    label: 'kids snack',
  },
  {
    value: FridgeCategories.nonalco,
    label: 'nonalco',
  },
  {
    value: FridgeCategories.snack,
    label: 'snack',
  },
]

export const merchTypesSelectable: SelectItem[] = [
  {
    value: MerchCategories.body,
    label: 'body',
  },
  {
    value: MerchCategories.head,
    label: 'head',
  },
  {
    value: MerchCategories.other,
    label: 'other',
  },
  {
    value: MerchCategories.feet,
    label: 'feet',
  },
]

export const getBarcodeType = (
  validatedBarcodes: ValidatedBarcodes | null,
  barcode: string
): Barcode => {
  if (validatedBarcodes && validatedBarcodes[barcode]) {
    if (validatedBarcodes[barcode].in_use) {
      return Barcode.error
    } else if (!validatedBarcodes[barcode].valid) {
      return Barcode.invalid
    } else {
      return Barcode.success
    }
  } else {
    return Barcode.default
  }
}

export const areAllBarcodesValid = (
  validatedBarcodes: ValidatedBarcodes
): boolean => {
  return Object.keys(validatedBarcodes)
    .map((validatedBarcode) => validatedBarcodes[validatedBarcode])
    .every((barcode) => !barcode.in_use)
}

export const isFridgeSelected = (
  selectedCategoryForm: ProductCategory
): boolean => {
  return selectedCategoryForm === ProductCategory.fridge
}

export const isFridgeOrMerchSelected = (
  selectedProductCategory: ProductCategory
): boolean => {
  return (
    selectedProductCategory == ProductCategory.fridge ||
    selectedProductCategory == ProductCategory.merch
  )
}

export const isFlavorOrAttributes = (
  selectedProductCategory: ProductCategory
): string => {
  return selectedProductCategory == ProductCategory.fridge
    ? 'Flavor'
    : 'Attributes'
}

export const getColor = (barcodeType: Barcode): string => {
  if (barcodeType === Barcode.error) return colors.error
  if (barcodeType === Barcode.invalid) return colors.brandYellow
  if (barcodeType === Barcode.success) return colors.success
  return colors.grey1
}
