import React, { FC } from 'react'

import { FormikProps, FormikValues } from 'formik'
import { UpdatableFormSelect } from './FormFields/UpdatableFormSelect'
import { UpdatableFormSwitch } from './FormFields/UpdatableFormSwitch'
import { UpdatableFormSlider } from './FormFields/UpdatableFormSlider'
import { UpdatableFormDatePicker } from './FormFields/UpdatableFormDatePicker'
import { UpdatableFormTextField } from './FormFields/UpdatableFormTextField'
import { FieldOptions, UpdatableField } from '../../../types/UpdatableFormFieldData/UpdatableField'
import { TextFieldData } from '../../../types/UpdatableFormFieldData/TextFieldData'
import { SelectFieldData } from '../../../types/UpdatableFormFieldData/SelectFieldData'
import { SwitchFieldData } from '../../../types/UpdatableFormFieldData/SwitchFieldData'
import { SliderFieldData } from '../../../types/UpdatableFormFieldData/SliderFieldData'
import { DateFieldData } from '../../../types/UpdatableFormFieldData/DateFieldData'

export type UpdatableFormFieldElementProps<T extends UpdatableField> = {
  updatableField: T;
  formikProps: FormikProps<FormikValues>;
  updateField: () => void;
  onOpen?: () => void;
  disabled?: boolean;
  fieldOptions?: FieldOptions;
};

/**
 * By type of class that implements UpdatableField shows appropriate form input
 */
export const UpdatableFormFieldElement: FC<
  UpdatableFormFieldElementProps<UpdatableField>
> = (props) => {
  const { updatableField, formikProps, updateField } = props
  const dataType = updatableField.constructor.name
  return (
    <div>
      {dataType === TextFieldData.name && (
        <UpdatableFormTextField
          updateField={updateField}
          updatableField={updatableField as TextFieldData}
          formikProps={formikProps}
        />
      )}
      {dataType === SelectFieldData.name && (
        <UpdatableFormSelect
          updateField={updateField}
          updatableField={updatableField as SelectFieldData}
          formikProps={formikProps}
        />
      )}
      {dataType === SwitchFieldData.name && (
        <UpdatableFormSwitch
          updateField={updateField}
          updatableField={updatableField as SwitchFieldData}
          formikProps={formikProps}
        />
      )}
      {dataType === SliderFieldData.name && (
        <UpdatableFormSlider
          updateField={updateField}
          updatableField={updatableField as SliderFieldData}
          formikProps={formikProps}
        />
      )}
      {dataType === DateFieldData.name && (
        <UpdatableFormDatePicker
          updateField={updateField}
          updatableField={updatableField as DateFieldData}
          formikProps={formikProps}
        />
      )}
    </div>
  )
}
