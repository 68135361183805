export const userDetailUrl = (userId: number) => `/api/users/${userId}`

export const handleQueryParams = (
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  type: string
): void => {
  const url = new URL(window.location.href)
  url.searchParams.set(type, event.target.value)
  window.history.pushState({}, '', url.href)
}
