import React, { FC, useMemo } from 'react'
import { Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { Button, ButtonPropsCustom } from '../../../components/buttons/Button'
import styled from 'styled-components'
import { AssignUserToProject } from '../../AssignUserToProjectModal/AssignUserToProject'
import { useCurrentUserInfo } from '../../../components/CurrentUserProvider'
import { buildLinkToMonthlyReport } from '../../../utils/helpers'
import { isUserInProject } from '../utils'
import { ExportToCSVModal } from '../../ExportToCSVModal/ExportToCSVModal'
import { ProjectPageHeaderDotsMenu } from './ProjectPageHeaderDotsMenu'
import { useProjectDetailContext } from '../ProjectDetailPage'
import { Alert } from '@material-ui/lab'
import { theme } from '../../../styles/muiThemes'
import { DocumentIcon } from '../../../components/Icon/icons/Document.icon'

export const ProjectPageHeader: FC = () => {
  const { project, refetch, isActive } = useProjectDetailContext()
  const { name, id, fullName } = project
  const { currentUser } = useCurrentUserInfo()

  const assignUserToProject: ButtonPropsCustom = {
    kind: 'primary',
    size: 'medium',
  }
  const projectMonthlyReportLink = useMemo(
    () => buildLinkToMonthlyReport(id),
    [id]
  )
  const userExistOnProject = useMemo(
    () => isUserInProject(project, currentUser.id),
    [project]
  )

  const theme = useTheme()
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <>
      <HeaderContainer>
        <StyledTypography variant={'h1'}>{fullName}</StyledTypography>
        <ButtonsContainer>
          {!userExistOnProject && isActive && (
            <AssignUserToProject
              projectId={id}
              projectName={name}
              userId={currentUser.id}
              buttonProps={assignUserToProject}
              done={refetch}
              buttonType={''}
            />
          )}
          {!isSmallerScreen && (
            <a href={projectMonthlyReportLink}>
              <Button
                kind="basicSecondary"
              >
                <DocumentIcon />
                <ButtonText>Report</ButtonText>
              </Button>
            </a>
          )}
          <ExportToCSVModal
            buttonType={'iconText'}
            projectId={project.id}
          >Export</ExportToCSVModal>
          <ProjectPageHeaderDotsMenu project={project} />
        </ButtonsContainer>
      </HeaderContainer>
      {!isActive && (
        <StyledAlert severity={'warning'}>Project deactivated</StyledAlert>
      )}
    </>
  )
}

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  ${theme.breakpoints.down('lg')} {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
`

const StyledAlert = styled(Alert)`
  margin-top: 40px;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;

  ${theme.breakpoints.down('lg')} {
    justify-content: flex-end;
  }
`

const ButtonText = styled.span`
  margin-left: 10.5px;
`
const StyledTypography = styled(Typography)`
  font-size: 40px;
`
