import React, { FC } from 'react'
import { PigIcon } from '../../../components/Icon/icons/Pig.icon'
import { RatesTable } from './RatesTable'
import { CenteredButton, NormalText, StyledEmpty } from './styles'
import { Rate } from './types'

type Props = {
  rates: Rate[] | undefined;
  billingCurrency: string;
  showModal: (rate?: Rate) => void;
  openEndValidityModal: (rate?: Rate) => void;
  openDeleteRateModal: (rate?: Rate) => void;
};

export const MyRatesTab: FC<Props> = ({ rates, showModal, openEndValidityModal, openDeleteRateModal, billingCurrency }) => {
  return (
    <>
      <CenteredButton
        kind="basicSecondary"
        id="set-my-rate"
        type="button"
        onClick={() => showModal()}>
        <PigIcon />
        <NormalText className="pl-2">Set my rate</NormalText>
      </CenteredButton>
    
      {rates ? <RatesTable
        showSum={true}
        rates={rates}
        openModal={showModal}
        openEndValidityModal={openEndValidityModal}
        openDeleteRateModal={openDeleteRateModal}
        billingCurrency={billingCurrency}
      /> : <StyledEmpty> No rates</StyledEmpty>}
    </>
  )
}
