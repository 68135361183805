import React, { FC } from 'react'
import { DataLine } from '../../../../types'
import { UpdatableItemsGroup } from '../../../../components/ui/UpdatableFormField/UpdatableItemsGroup'
import { TabContainer } from '../../../../components/ui/TabContainer'
import { useQuery } from 'react-query'
import {
  UpdatableFieldsFactory,
  UpdatePathGeneratorType,
} from '../../../../types/UpdatableFieldsFactory'
import { AddNewTribeButton } from '../AddNewTribeButton'
import { TabButtonsContainer } from '../../../../components/ui/TabButtonsContainer'
import { UserTribe } from '../../types'
import { canUpdateUser } from './UserInfoTab'
import { UserInfo } from '../..'

type Props = {
  enums: any;
  user: UserInfo;
  sensitiveDataAccess: boolean;
};

export const TribesTab: FC<Props> = ({ enums, user, sensitiveDataAccess }) => {
  const userCanUpdate = canUpdateUser(true)
  const userHimselfCanUpdate = canUpdateUser(false)
  const queryKey = `/api/users/${user.id}/tribes`
  const { data: tribesData, isLoading } = useQuery<UserTribe[]>(queryKey)

  if (isLoading) return <span>Loading</span>
  const availableTribes = enums.tribes.filter(
    (tribe) =>
      !tribesData?.find((tribeData) => tribeData.tribeId === tribe.value)
  )

  tribesData?.sort((a, b) => a.tribeId - b.tribeId)

  const pathsGenerator: UpdatePathGeneratorType = (resource) =>
    `/api/users/${user.id}/tribes/${resource.id}`
  const updatableFieldsFactory = new UpdatableFieldsFactory(
    tribesData!,
    pathsGenerator
  )
  const tribeMember: DataLine[] = [
    {
      label: 'Personal number',
      updatableFields:
        updatableFieldsFactory.generateTextFieldData('personalNumber'),
    },
    {
      label: 'Joined date',
      updatableFields:
        updatableFieldsFactory.generateDateFieldData('joinedDate'),
    },
    {
      label: 'Trial period end date',
      updatableFields:
        updatableFieldsFactory.generateDateFieldData('trialPeriodEndDate'),
    },
    {
      label: 'Left date',
      updatableFields: updatableFieldsFactory.generateDateFieldData('leftDate'),
    },
    {
      label: 'First billed date',
      updatableFields:
        updatableFieldsFactory.generateDateFieldData('firstBilledDate'),
    },
    {
      label: 'Position requested at',
      updatableFields: updatableFieldsFactory.generateDateFieldData(
        'positionRequestedAt'
      ),
    },
    {
      label: 'Job application date',
      updatableFields:
        updatableFieldsFactory.generateDateFieldData('jobApplicationDate'),
    },
  ]

  const preferences: DataLine[] = [
    {
      label: 'Hours available per month',
      description:
        `Self-declared number of hours available per month.
         1 month = 168 hours.
         Your 3 month average is ${user.togglHours3monthAverage?.hours} hours per month.`,
      updatableFields: updatableFieldsFactory.generateTextFieldData(
        'declaredAllocationHours',
        'number'
      ),
    },
    {
      label: 'Primary competency',
      updatableFields: updatableFieldsFactory.generateSelectFieldData(
        'primaryCompetency',
        enums.competencies
      ),
    },
    {
      label: 'Primary competency level',
      updatableFields: updatableFieldsFactory.generateSelectFieldData(
        'primaryCompetencyLevel',
        enums.competencyLevels
      ),
    },
    {
      label: 'Secondary competency',
      updatableFields: updatableFieldsFactory.generateSelectFieldData(
        'secondaryCompetency',
        enums.competencies
      ),
    },
    {
      label: 'Secondary competency level',
      updatableFields: updatableFieldsFactory.generateSelectFieldData(
        'secondaryCompetencyLevel',
        enums.competencyLevels
      ),
    },
  ]

  const checks: DataLine[] = [
    {
      label: 'Health check valid until',
      updatableFields: updatableFieldsFactory.generateDateFieldData(
        'healthAssessmentValidUntil'
      ),
    },
  ]

  const gdprConsent: DataLine[] = [
    {
      label: 'EU and 3rd parties',
      updatableFields: updatableFieldsFactory.generateSwitchFieldData(
        'gdprEuAndThirdParties'
      ),
    },
    {
      label: 'Quality of services and feedback',
      updatableFields: updatableFieldsFactory.generateSwitchFieldData(
        'gdprQualityOfServices'
      ),
    },
    {
      label: 'Social networks - photo',
      updatableFields:
        updatableFieldsFactory.generateSwitchFieldData('gdprSocialPhotos'),
    },
    {
      label: 'Social networks - first name',
      updatableFields: updatableFieldsFactory.generateSwitchFieldData(
        'gdprSocialFirstName'
      ),
    },
    {
      label: 'Social networks - last name',
      updatableFields:
        updatableFieldsFactory.generateSwitchFieldData('gdprSocialLastName'),
    },
  ]
  const labels = tribesData!.map((c) => c.tribeName)
  const updatePaths = ['']

  return (
    <>
      {availableTribes.length > 0 && userCanUpdate && (
        <TabButtonsContainer>
          <AddNewTribeButton
            userId={user.id}
            tribes={availableTribes}
          />
        </TabButtonsContainer>
      )}
      <TabContainer>
        <UpdatableItemsGroup
          name={'Memberships'}
          items={tribeMember}
          updatePaths={updatePaths}
          columnLabels={labels}
          updatable={userCanUpdate}
        />
        <UpdatableItemsGroup
          name={'Work preferences'}
          items={preferences}
          updatePaths={updatePaths}
          columnLabels={labels}
          updatable={userHimselfCanUpdate}
        />
        {sensitiveDataAccess && (
          <UpdatableItemsGroup
            name={'HR checks'}
            items={checks}
            updatePaths={updatePaths}
            columnLabels={labels}
            updatable={userCanUpdate}
          />
        )}
        {sensitiveDataAccess && (
          <UpdatableItemsGroup
            name={'GDPR consent'}
            items={gdprConsent}
            updatePaths={updatePaths}
            columnLabels={labels}
            updatable={userCanUpdate}
          />
        )}
      </TabContainer>
    </>
  )
}
