import React, { FC } from 'react'

import { BasicInfoFormPart } from './BasicInfoFormPart'
import { ReportingDataFormPart } from './ReportingDataFormPart'
import { Props } from './types'

export const AddNewProjectForm: FC<Props> = ({
  formikProps,
  tribes,
  categories,
  billingType,
}) => (
  <>
    <BasicInfoFormPart
      formikProps={formikProps}
      tribes={tribes}
    />
    <ReportingDataFormPart
      formikProps={formikProps}
      tribes={tribes}
      categories={categories}
      billingType={billingType}
    />
  </>
)
