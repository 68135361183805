import { FormikProps } from 'formik'
import { SelectItem } from '../../../../types'

export type NewProjectProps = {
  name: string
  owned_by_tribe_id: string | null
  is_billable: boolean | null
  is_production: boolean | null
  invoiced_by_tribe_id: string | null
  category: string
  billing_type: string
  first_billable_date: string
}

export type Props = {
  formikProps: FormikProps<NewProjectProps>
  tribes: SelectItem[]
  categories: SelectItem[]
  billingType: SelectItem[]
}

export const initVals: NewProjectProps = {
  name: '',
  owned_by_tribe_id: '',
  is_billable: null,
  is_production: null,
  invoiced_by_tribe_id: '',
  category: '',
  billing_type: '',
  first_billable_date: '',
}
