import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const DashboardIcon: FC<IconSVGProps> = ({
  width = 20,
  height = 42,
}) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.667 11.462a.87.87 0 01-.867.871H1.867a.866.866 0 01-.867-.87V1.87A.87.87 0 011.868 1l6.933.012a.87.87 0 01.866.87v9.58zM20.997 6.145A.861.861 0 0120.13 7h-6.933a.86.86 0 01-.867-.855V1.867a.86.86 0 01.865-.855L20.128 1a.862.862 0 01.869.855v4.29zM9.667 20.142a.863.863 0 01-.869.858l-6.933-.012A.861.861 0 011 20.132v-4.275A.862.862 0 011.867 15H8.8a.862.862 0 01.867.857v4.285zM13.198 21a.87.87 0 01-.868-.872v-9.59a.87.87 0 01.867-.872h6.933a.87.87 0 01.867.873v9.577a.87.87 0 01-.866.872L13.198 21z"
      stroke="#C8C6C5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
