import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const Default: FC<IconSVGProps> = ({ width = 20, height = 42 }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.94888 4.35485L10.0377 1.18333C10.6549 0.93889 11.3422 0.93889 11.9594 1.18333L20.0482 4.35485C20.3364 4.47773 20.5811 4.68416 20.7508 4.9475C20.9205 5.21083 21.0074 5.51898 21.0002 5.83217V16.1681C21.0077 16.4808 20.9213 16.7887 20.7523 17.052C20.5833 17.3153 20.3393 17.5219 20.0518 17.6454L11.963 20.8178C11.3454 21.0604 10.6588 21.0604 10.0412 20.8178L1.95243 17.6454C1.66427 17.5225 1.41954 17.3161 1.24985 17.0527C1.08016 16.7894 0.993285 16.4812 1.00044 16.1681V5.83217C0.992994 5.51938 1.07934 5.21152 1.24837 4.94823C1.4174 4.68494 1.66138 4.47828 1.94888 4.35485V4.35485Z"
      stroke="#C8C6C5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0007 8.6469L1.31543 4.84961"
      stroke="#C8C6C5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 8.6469L20.6852 4.84961"
      stroke="#C8C6C5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 20.9997V8.64697"
      stroke="#C8C6C5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
