import React, { FC } from 'react'
import { Payment } from '../../PaymentsPage/types'
import { TableContainer, Table, TableRow, TableCell } from '@material-ui/core'
import styled from 'styled-components'
import { atMinWidth } from '../../../styles/helpers'
import { MoneyFormatter, dateFormatter } from '../../../utils/formatters'

type Props = {
  payment: Payment;
};

export const PaymentInfoTable: FC<Props> = ({ payment }) => {
  const {
    amount,
    source,
    createdAt,
    createdBy,
    transactionDate,
    counterpartyAccountName,
    counterpartyAccountNumber,
    note,
    variableSymbol,
  } = payment

  const amountFormatted = new MoneyFormatter('CZK').format(amount)
  const createdAtFormatted = dateFormatter(createdAt)
  const transactionDateFormatted = dateFormatter(transactionDate)
  return (
    <StyledTableContainer>
      <Table aria-label="simple table">
        <TableRow>
          <TableHead>Amount</TableHead>
          <TableCell>{amountFormatted}</TableCell>
        </TableRow>
        <TableRow>
          <TableHead>Source</TableHead>
          <TableCell>{source}</TableCell>
        </TableRow>
        <TableRow>
          <TableHead>Created by</TableHead>
          <TableCell>{createdBy?.fullName}</TableCell>
        </TableRow>
        <TableRow>
          <TableHead>Created at</TableHead>
          <TableCell>{createdAtFormatted}</TableCell>
        </TableRow>
        <TableRow>
          <TableHead>Transaction date</TableHead>
          <TableCell>{transactionDateFormatted}</TableCell>
        </TableRow>
        <TableRow>
          <TableHead>Counterparty account name</TableHead>
          <TableCell>{counterpartyAccountName}</TableCell>
        </TableRow>
        <TableRow>
          <TableHead>Counterparty account number</TableHead>
          <TableCell>{counterpartyAccountNumber}</TableCell>
        </TableRow>
        <TableRow>
          <TableHead>Note</TableHead> <TableCell>{note}</TableCell>
        </TableRow>
        <TableRow>
          <TableHead>Variable symbol</TableHead>
          <TableCell>{variableSymbol}</TableCell>
        </TableRow>
      </Table>
    </StyledTableContainer>
  )
}

const TableHead = styled.th`
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  border-right: 1px solid rgba(224, 224, 224, 1);
`

export const StyledTableContainer = styled(TableContainer)`
  width: 100%;

  ${atMinWidth.tablet} {
    width: 50%;
    min-width: 500px;
  }
`
