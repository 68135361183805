import styled from 'styled-components'
import { FormControl, FormLabel } from '@material-ui/core'

import { colors } from '../../styles/colors'
import { FormDatePicker } from '../../components/inputs/FormDatePicker'

export const StyledFormControl = styled(FormControl)`
  display: flex;
  gap: 16px;
`

export const StyledFormDatePicker = styled(FormDatePicker)`
  & > div {
    padding: 0;
  }
`

export const FormWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`

export const StyledFormLabel = styled(FormLabel)`
  color: ${colors.grey0};
  font-size: 12px;
  padding-bottom: 4px;
  text-transform: uppercase;
  margin-top: 0;
`
