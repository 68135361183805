import React, { FC, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { Alert } from '@material-ui/lab'

import { TestingDevicesPageView } from './TestingDevicesPageView'
import { DevicesStatus, TestingDevices } from './types'
import { TestingDevicesPageViewSkeleton } from './TestingDevicesPageViewSkeleton'
import { useQueryNotification } from '../UserInfo/utils'

export const TestingDevicesPage: FC = () => {
  const [selectedDevicesStatus, setSelectedDevicesStatus] =
    useState<DevicesStatus>(DevicesStatus.ALL)
  const queryKey = '/api/testing_devices'
  const {
    data: devices,
    isLoading,
    isError,

  } = useQuery<TestingDevices[] | undefined>(queryKey)
  const { errorNotification } = useQueryNotification()

  useEffect(() => {
    if (isError) {
      errorNotification('Fetching testing devices failed')
    }
  }, [isError])

  if (isLoading) {
    return <TestingDevicesPageViewSkeleton rows={8} />
  }

  if (devices?.length) {
    return <TestingDevicesPageView
      setSelectedDevicesStatus={setSelectedDevicesStatus}
      selectedDevicesStatus={selectedDevicesStatus}
      devices={devices as TestingDevices[]}
      isLoading={isLoading}
    />
  }

  return (
    <Alert
      variant={'outlined'}
      severity={'info'}
    >
      No devices
    </Alert>
  )
}
