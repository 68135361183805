/* eslint-disable max-lines */
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React, { FC, useEffect, useState, MouseEvent } from 'react'
import { XCircle } from 'react-feather'

import { BorderedTableCell, CheckboxWrapper, EditableTextField, IconTableCell, IconWrapper, NumbericTableCell, PointerTableRow, StyledCheckbox, StyledTableCell } from './styles'
import { BudgetData } from '../types'
import { TableBodySkeleton } from '../../../components/skeletons/TableBodySkeleton'
import { LabelTextForCheckbox } from '../components/AddNewProject/styles'
import { CopyIcon } from '../../../components/Icon/icons/CopyIcon.icon'
import { months } from './utils'

type Props = {
  projectId: number,
  data: BudgetData | undefined,
  budgets: number[] | undefined,
  defaultBudgets: number[] | undefined,
  activeYear: number,
  loading: boolean,
  setBudgetsData: (index: number, value: string, replace?: boolean, newBudgets?: number[]) => void,
  reset?: boolean,
}

export const BudgetsTable: FC<Props> = ({ data, budgets, loading, setBudgetsData, defaultBudgets, reset }) => {
  const [copyText, setCopyText] = useState<string>()
  const [showCopyIcon, setShowCopyIcon] = useState(true)
  const [showCheckboxes, setShowCheckboxes] = useState(false)
  const [allCheck, setAllCheck] = useState(false)
  const [clickedIndex, setClickedIndex] = useState<number>()
  const [budgetSum, setBudgetSum] = useState(0)
  const [checked, setChecked] = useState<string[]>([])

  useEffect(() => {
    if (budgets?.length) {
      setBudgetSum(getSum(budgets))
    }
  }, [budgets, checked])

  useEffect(() => {
    if (reset) {
      setCopyText('')
      setShowCopyIcon(true)
      setShowCheckboxes(false)
      setAllCheck(false)
      setClickedIndex(undefined)
      setChecked([])
      setBudgetsData(0, '', true, defaultBudgets)
    }
  }, [reset])

  const getSum = (data: number[]) => {
    return data.reduce((partialSum, a) => partialSum + a, 0)
  }

  const handleCopyClick = (index: number) => {
    if (copyText) {
      setCopyText('')
      setShowCopyIcon(true)
      setShowCheckboxes(false)
      setBudgetsData(0, '', true, defaultBudgets)
      setChecked([])
    } else {
      setClickedIndex(index)
      const copiedValue = (document.getElementById(`budget-${index}`) as HTMLInputElement)?.value
      setCopyText(copiedValue)
      setShowCopyIcon(false)
      setShowCheckboxes(true)
    }
  }

  const copyValue = (index: number, remove = false) => {
    const inputForCopy = document.getElementById(`budget-${index}`) as HTMLInputElement
    if (inputForCopy && copyText) {
      if (remove) {
        const originalValue = (defaultBudgets?.length ? defaultBudgets[index] : 0).toString()
        setBudgetsData(index, originalValue)
      } else {
        setBudgetsData(index, copyText)
      }
    }
  }

  const markAll = () => {
    if (allCheck) {
      setChecked([])
      if (defaultBudgets && (clickedIndex || clickedIndex === 0)){
        defaultBudgets[clickedIndex] = Number(copyText)
      }
      setBudgetsData(0, '', true, defaultBudgets)
    } else {
      const checkAllCheckboxes: string[] = []
      const newBudgets = budgets?.map((_value, index) => { copyValue(index); checkAllCheckboxes.push(`copy-budget-value-${index}`); return Number(copyText) })
      setChecked(checkAllCheckboxes)
      setBudgetsData(0, '', true, newBudgets)
    }
    setAllCheck(!allCheck)
  }

  const handleCheckboxClick = (event: MouseEvent, index: number) => {
    const elementId = (event.target as HTMLInputElement).id
    if (checked.includes(elementId)) {
      const activeCheckboxes = checked.filter((check) => check !== elementId)
      setChecked(activeCheckboxes)
      copyValue(index, true)
    } else if (elementId) {
      setChecked([...checked, elementId])
      copyValue(index)
    }
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Month</StyledTableCell>
            <IconTableCell>
              {showCheckboxes && <CheckboxWrapper>
                <LabelTextForCheckbox
                  control={<StyledCheckbox
                    name="multiple-copy"
                    color="default"
                    value=""
                    disabled={!copyText}
                    onChange={markAll}
                  />}
                  label="" />
              </CheckboxWrapper>}
            </IconTableCell>
            <StyledTableCell>Budget</StyledTableCell>
            <StyledTableCell>Spent</StyledTableCell>
            <StyledTableCell>Remaning</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {loading && (
            <TableBodySkeleton
              rows={12}
              colspan={5}
            />
          )}

          {budgets?.map((budget, index) => (
            <PointerTableRow
              key={index}
              id={index.toString()}
            >
              <BorderedTableCell>{months[index]}</BorderedTableCell>
              <IconTableCell>
                {!showCheckboxes && showCopyIcon && (
                  <IconWrapper onClick={() => handleCopyClick(index)}>
                    <CopyIcon color='#000' />
                  </IconWrapper>
                )}

                {clickedIndex === index && !showCopyIcon ?
                  <XCircle onClick={() => handleCopyClick(index)} /> :
                  showCheckboxes && <CheckboxWrapper>
                    <LabelTextForCheckbox
                      control={<StyledCheckbox
                        name={`copy-budget-value-${index}`}
                        id={`copy-budget-value-${index}`}
                        color="default"
                        checked={checked.includes(`copy-budget-value-${index}`)}
                        onClick={(event) => handleCheckboxClick(event, index)}
                      />}
                      label="" />
                  </CheckboxWrapper>
                }
              </IconTableCell>
              <BorderedTableCell>
                <EditableTextField
                  id={`budget-${index}`}
                  className='budget-input'
                  type="number"
                  name="budget"
                  value={budget ?? 0}
                  inputProps={{ min: 0 }}
                  onChange={(event) => setBudgetsData(index, event.target.value)}
                  disabled={!!copyText}
                />
              </BorderedTableCell>
              <NumbericTableCell>{data?.hoursUsed[index] ?? 0}</NumbericTableCell>
              <NumbericTableCell>{data?.hoursBudgetActual[index] ?? 0}</NumbericTableCell>
            </PointerTableRow>
          ))}
          {data && Object.keys(data) &&
            <PointerTableRow>
              <BorderedTableCell>SUM</BorderedTableCell>
              <TableCell></TableCell>
              <NumbericTableCell>{budgetSum}</NumbericTableCell>
              <NumbericTableCell>{getSum(data.hoursUsed)}</NumbericTableCell>
              <NumbericTableCell>{getSum(data.hoursBudgetActual)}</NumbericTableCell>
            </PointerTableRow>
          }

          {!data && !loading && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={4}
              >
                no budgets
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
