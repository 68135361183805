import React, { FC } from 'react'
import { TextField } from '@material-ui/core'
import styled from 'styled-components'
import { ProductCategory, ProductCreate } from '../types'
import { fridgeTypesSelectable, merchTypesSelectable } from '../utils'
import { FormSelect } from '../../../components/inputs/FormSelect'
import { FormikProps } from 'formik'
import { isFridgeOrMerchSelected, isFlavorOrAttributes } from '../utils'
import { hasError, helperText } from '../../../utils/helpers'

type Props = {
  selectedProductCategory: ProductCategory;
  formikProps: FormikProps<ProductCreate>;
};

export const ProductFormFields: FC<Props> = ({ selectedProductCategory, formikProps }) => {
  const productTypes =
    selectedProductCategory === ProductCategory.fridge
      ? fridgeTypesSelectable
      : merchTypesSelectable

  const { values, handleChange } = formikProps
  return (
    <>
      <StyledTextField
        required
        fullWidth
        value={values.name || ''}
        name="name"
        label="Name"
        onChange={handleChange}
        error={hasError(formikProps, 'name')}
        helperText={helperText(formikProps, 'name')}
        placeholder={'Enter product name'}
      />
      <StyledTextField
        fullWidth
        value={values.description || ''}
        name="description"
        label="Description"
        onChange={handleChange}
        error={hasError(formikProps, 'description')}
        helperText={helperText(formikProps, 'description')}
        placeholder={'Enter product description'}
      />
      <FieldRow>
        <StyledTextField
          fullWidth
          value={values.externalProductId || ''}
          name="externalProductId"
          label="External product id"
          onChange={handleChange}
          error={hasError(formikProps, 'externalProductId')}
          helperText={helperText(formikProps, 'externalProductId')}
          placeholder={'Enter external product id'}
        />
        {isFridgeOrMerchSelected(selectedProductCategory) && (
          <FormSelect
            required
            values={productTypes || []}
            name={'productType'}
            label={'Product Type'}
            value={values.productType || ''}
            error={hasError(formikProps, 'productType')}
            helperText={helperText(formikProps, 'productType')}
            placeholder={'Choose product type'}
          />
        )}
      </FieldRow>

      <FieldRow>
        <StyledTextField
          required
          fullWidth
          value={values.currentCost || ''}
          name="currentCost"
          label="Cost"
          onChange={handleChange}
          error={hasError(formikProps, 'currentCost')}
          helperText={helperText(formikProps, 'currentCost')}
          placeholder={'Enter product const'}
        />
        {isFridgeOrMerchSelected(selectedProductCategory) && (
          <StyledTextField
            required
            fullWidth
            value={values.count || ''}
            name="count"
            label="Count"
            onChange={handleChange}
            error={hasError(formikProps, 'count')}
            helperText={helperText(formikProps, 'count')}
            placeholder={'Enter product count'}
          />
        )}
      </FieldRow>

      {isFridgeOrMerchSelected(selectedProductCategory) && (
        <StyledTextField
          fullWidth
          value={values.flavor || ''}
          name="flavor"
          label={isFlavorOrAttributes(selectedProductCategory)}
          onChange={handleChange}
          placeholder={'Enter flavor'}
        />
      )}
    </>
  )
}

const StyledTextField = styled(TextField)`
  margin-bottom: 20px;
`

const FieldRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
`
