import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const SuccessCheckmark: FC<IconSVGProps> = ({ width = 16, height = 16 }) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.44434 18.4336L12.5295 22.4997C12.6465 22.662 12.8036 22.796 12.9877 22.8906C13.1718 22.9852 13.3775 23.0376 13.5878 23.0434C13.798 23.0493 14.0068 23.0084 14.1966 22.9242C14.3864 22.84 14.5518 22.7149 14.6791 22.5593L24.5554 10.9551"
      stroke="#73C37C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M33.25 17C33.25 25.9746 25.9746 33.25 17 33.25C8.02537 33.25 0.75 25.9746 0.75 17C0.75 8.02537 8.02537 0.75 17 0.75C25.9746 0.75 33.25 8.02537 33.25 17Z"
      stroke="#73C37C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round" />
  </svg>
)

