import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { Chip } from '@material-ui/core'
import { atMinWidth } from '../../styles/helpers'
import { UserDetail } from './types'
import { colors } from '../../styles/colors'
import { UsersState } from '../../types/index'

type Props = {
  user: UserDetail;
};

export const Chips: FC<Props> = ({ user }) => {
  const { tribes, nickname, role, state } = user
  return (
    <ChipContainer>
      {state === UsersState.inactive && (
        <FilledChip
          label={UsersState.inactive.toUpperCase()}
          $color={colors.error}
        />
      )}
      {tribes.length > 0 &&
        tribes.map(({ name, id: tribeId }) => (
          <FilledChip
            key={tribeId}
            label={`${name}`}
            $color={colors.brandBlue}
          />
        ))}
      {nickname && (
        <StyledChip
          label={`${nickname}`}
          variant="outlined"
        />
      )}
      {role && (
        <StyledChip
          label={`${role}`}
          variant="outlined"
        />
      )}
    </ChipContainer>
  )
}

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 5px;

  ${atMinWidth.tablet} {
    margin-top: 0;
  }
`

const StyledChip = styled(Chip)`
  height: 26px;

  ${atMinWidth.tablet} {
    height: 30px;
  }
`

const FilledChip = styled(StyledChip)<{ $color: string }>`
  ${(props) => css`
    background-color: ${props.$color};
  `}
`
