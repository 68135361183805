import React, { FC } from 'react'
import { Project } from '../types'
import { InputLabel, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ProjectChangeHandler } from './TogglReport'

export type ProjectSelectProps = {
  projects: Project[];
  selectedProjectId: string;
  changeHandler: ProjectChangeHandler;
};

export const ProjectSelect: FC<ProjectSelectProps> = (props) => {
  const { projects, selectedProjectId, changeHandler } = props

  const defaultValue =
    selectedProjectId === 'all' || selectedProjectId === undefined
      ? {
        id: 'all',
        name: 'all projects',
      }
      : projects.filter(
        (project) => project.id === Number(selectedProjectId)
      )[0]

  const handleChange = (e, value: Project) =>
    changeHandler(value ? value.id : '')

  const options = [defaultValue, ...projects]

  return (
    <div>
      <InputLabel>Select a project</InputLabel>

      <Autocomplete
        id="projectSelect"
        defaultValue={defaultValue}
        options={options}
        onChange={handleChange}
        getOptionSelected={(project) => project.id == selectedProjectId}
        getOptionLabel={(project) => (project ? project.name : '')}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
          />
        )}
      />
    </div>
  )
}
