import React, { FC } from 'react'
import { FormControl, TextField } from '@material-ui/core'

import { FormDatePicker } from '../../../../components/inputs/FormDatePicker'
import {
  CheckboxWrapper,
  LabelTextForCheckbox,
  StyledCheckbox,
  StyledFormSelect,
} from './styles'
import { Props } from './types'
import { getValuesWithNoValuePossibilities } from './utils'
import { FormPartWrapper } from './FormPartWrapper'
import { hasError, helperText } from '../../../../utils/helpers'

export const ReportingDataFormPart: FC<Props> = ({
  formikProps,
  tribes,
  categories,
  billingType,
}) => {
  const { values, handleChange } = formikProps

  return (
    <FormPartWrapper sectionTitle="Reporting data">
      <StyledFormSelect
        label={'Invoiced by tribe'}
        value={values.invoiced_by_tribe_id}
        name={'invoiced_by_tribe_id'}
        values={getValuesWithNoValuePossibilities(tribes)}
        error={hasError(formikProps, 'invoiced_by_tribe_id')}
        helperText={helperText(formikProps, 'invoiced_by_tribe_id')}
      />

      <StyledFormSelect
        label={'Category'}
        value={values.category}
        name={'category'}
        values={getValuesWithNoValuePossibilities(categories)}
        error={hasError(formikProps, 'category')}
        helperText={helperText(formikProps, 'category')}
      />

      <CheckboxWrapper>
        <LabelTextForCheckbox
          control={<StyledCheckbox
            name="is_production"
            color="default"
            onChange={handleChange}
            value={values.is_production}
          />}
          label="Is production" />
      </CheckboxWrapper>

      <StyledFormSelect
        label={'Billing type'}
        value={values.billing_type}
        name={'billing_type'}
        values={billingType}
        error={hasError(formikProps, 'billing_type')}
        helperText={helperText(formikProps, 'billing_type')}
      />

      <FormDatePicker
        fullWidth
        label="First billable date"
        inputVariant="outlined"
        name={'first_billable_date'}
        onChange={(date) =>
          formikProps.setFieldValue('first_billable_date', date)
        }
        value={
          values.first_billable_date
            ? new Date(values.first_billable_date)
            : null
        }
        helperText={helperText(formikProps, 'first_billable_date')}
      />
    </FormPartWrapper>
  )
}
