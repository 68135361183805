import React, { FC, useMemo } from 'react'
import styled from 'styled-components'
import { IconButton } from '@material-ui/core'
import { CartIcon } from '../../../../components/Icon/icons/Cart.icon'
import { colors } from '../../../../styles/colors'
import { theme } from '../../../../styles/muiThemes'
import { useShopContext } from '../../ShopContext'

type Props = {
  onClick: () => void;
};
export const CartButton: FC<Props> = ({ onClick }) => {
  const { selectedProductsAmount } = useShopContext()
  return useMemo(
    () => (
      <Container>
        {selectedProductsAmount > 0 && (
          <ProductsAmount>{selectedProductsAmount}</ProductsAmount>
        )}
        <IconButton onClick={onClick}>
          <CartIcon />
        </IconButton>
      </Container>
    ),
    []
  )
}

const Container = styled.div`
  position: absolute;
  top: -20px;
  right: -20px;
  background: ${colors.brandYellow};
  border-radius: 50%;
  padding: 10px;
  svg {
    width: 40px;
    height: 40px;
  }

  ${theme.breakpoints.down('sm')} {
    position: fixed;
    top: unset;
    bottom: 1em;
    right: 1em;
  }
  ${theme.breakpoints.down('sm')} {
    svg {
      width: 32px;
      height: 32px;
    }
  }
`

const ProductsAmount = styled.span`
  border-radius: 50%;
  background: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
`
