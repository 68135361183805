import React, { FC } from 'react'
import styled from 'styled-components'
import { Alert } from '@material-ui/lab'

import { useQuery } from 'react-query'
import { RowProps, TestingDevices } from '../TestingDevicesPage/types'
import { atMinWidth } from '../../styles/helpers'
import { ReturnDeviceButton } from '../TestingDevicesPage/ReturnDeviceButton'
import { BorrowDeviceButton } from '../TestingDevicesPage/BorrowDeviceButton'
import { DeviceDialog } from '../TestingDevicesPage/DeviceDialog'

export const CheckoutDevice: FC = () => {
  const devicesDetailPathname = window.location.pathname
  const deviceId = devicesDetailPathname.replace(/\D/g, '')
  const queryKey = `/api/testing_devices/${deviceId}`
  const {
    data: device,
    isLoading,
    isError,
  } = useQuery<TestingDevices>(queryKey, {
    enabled: deviceId != null,
  })

  // TODO add skeleton
  if (isLoading) {
    return null
  }

  if (isError || !device) {
    return (
      <Alert
        variant={'outlined'}
        severity={'info'}
      >
        No device information
      </Alert>
    )
  }

  const buttonAction = device.taken ? (
    <ReturnDeviceButton
      buttonType="basicPrimary"
      device={device}
    />
  ) : (
    <BorrowDeviceButton
      buttonType="basicPrimary"
      device={device}
    />
  )

  const { name, inventoryNumber, location } = device
  const rowDataInDialog: RowProps[] = [
    { id: 1, label: 'Device', value: name },
    { id: 2, label: 'Inventory number', value: inventoryNumber },
    { id: 3, label: 'Current location', value: location },
  ]

  return (
    <Wrapper>
      <DeviceDialog
        title="Device checkout"
        rowData={rowDataInDialog}
        actions={buttonAction}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  max-width: 560px;

  ${atMinWidth.custom(1200)} {
    width: 40%;
  }
`
