import React, { FC, useState } from 'react'
import { Typography } from '@material-ui/core'
import { ProjectsTable } from '../../../components/ProjectsTable/ProjectsTable'
import {
  ProjectTableConfig,
  ProjectTableItem,
} from '../../../components/ProjectsTable/types'
import {
  getMemoizedSortedProjects,
  useProjectFiltering,
} from '../../../components/ProjectsTable/utils'
import { useQuery } from 'react-query'
import { Project } from '../../ProjectsPage/types'
import { StatusFilter } from '../../../enum'
import { ProjectFilters } from '../../../components/ProjectsTable/ProjectFilters'

type Props = { userId: number };

export const MyProjects: FC<Props> = (props) => {
  const [selectedProjectsStatus, setSelectedProjectsStatus] =
    useState<StatusFilter>(StatusFilter.Active)

  const { userId } = props

  const myCurrentProjectsTableConfig: ProjectTableConfig = [
    { type: ProjectTableItem.Info },
    { type: ProjectTableItem.TLActions, align: 'right' },
  ]

  const { data, isLoading } = useQuery<Project[]>(
    `/api/users/${userId}/projects/assigned?state=${selectedProjectsStatus}`
  )

  const sortedProjects = getMemoizedSortedProjects(data)

  const { filteredProjects, owningTribe, handleTribeChange } =
    useProjectFiltering(sortedProjects)

  return (
    <>
      <Typography variant="h3">My current projects</Typography>

      <ProjectFilters
        selectedProjectsStatus={selectedProjectsStatus}
        setSelectedProjectsStatus={setSelectedProjectsStatus}
        handleTribeChange={handleTribeChange}
        owningTribe={owningTribe}
      />

      <ProjectsTable
        tableConfig={myCurrentProjectsTableConfig}
        projects={filteredProjects}
        isLoading={isLoading}
      />
    </>
  )
}
