import React, { FC, useMemo } from 'react'
import { InputLabel, Typography } from '@material-ui/core'
import { ExportToCSVForm } from './components/ExportToCSVForm/ExportToCSVForm'
import { useQuery } from 'react-query'
import { ProjectExportToCSV, UserForExportToCSV } from './types'
import { TopLinearProgressBar } from '../../components/ui/TopLinearProgressBar'
import ReactQueryKeys from '../../utils/ReactQueryKeys'

type Props = {
  projectId?: number;
  userId?: number;
  month?: string;
};

export const ExportToCSVModalContent: FC<Props> = (props) => {
  const { projectId, userId, month } = props

  const { data: projectData, isFetching: fetchingProject } =
    useQuery<ProjectExportToCSV>(
      ReactQueryKeys.projectDetailQueryKey(Number(projectId)),
      {
        enabled: !!projectId,
      }
    )
  const { data: userData, isFetching: fetchingUser } =
    useQuery<UserForExportToCSV>(ReactQueryKeys.userDetail(Number(userId)), {
      // enabled: !!userId,
      enabled: false,
    })

  const isFetching = fetchingUser || fetchingProject
  const name = projectData?.name || userData?.fullName
  const label = projectId ? 'Project' : 'User'
  const exportTemplates = useMemo(
    () => projectData?.exportTemplates ?? [],
    [projectData]
  )

  if (userId)
    return (
      <>
        <iframe
          src="https://giphy.com/embed/J5usb4AuaMScUm6r48"
          width="380"
          height="270"
          frameBorder="0"
          className="giphy-embed"
          allowFullScreen
        ></iframe>
        <p>
          <a href="https://giphy.com/gifs/wetv-reality-tv-soul-food-hustle-and-J5usb4AuaMScUm6r48">
            via GIPHY
          </a>
        </p>
      </>
    )

  return (
    <>
      <Typography variant="h3">Export CSV</Typography>

      <InputLabel>{label}</InputLabel>
      <Typography>{name}</Typography>

      {isFetching && <TopLinearProgressBar />}
      {(projectData || userData) && (
        <ExportToCSVForm
          month={month}
          projectData={projectData}
          userData={userData}
          exportTemplates={exportTemplates}
        />
      )}
    </>
  )
}
