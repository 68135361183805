import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const Download: FC<IconSVGProps> = ({
  width = 16,
  height = 16,
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
      stroke="#C8C6C5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M8 12.137V3.86426"
      stroke="#C8C6C5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M11.5 8.63574L8 12.1357L4.5 8.63574"
      stroke="#C8C6C5"
      strokeLinecap="round"
      strokeLinejoin="round" />
  </svg>

)
