import {
  BasketProduct,
  ProductCategory,
  ProductCategoryFilter,
  ProductGroup,
  ProductPurchaseData,
  ProductsInCategories,
  ProductsInGroups,
  ShopProductsData,
} from './types'
import { SearchFieldComparator } from '../../components/inputs/SearchField'

export const productsQueryString = (category) =>
  `/api/products?product_group=${category}`
export const getBasketProducts = (
  data: ShopProductsData | undefined
): BasketProduct[] => {
  return (
    data?.products.map((product) => ({
      product,
      amount: 0,
    })) || []
  )
}

export const getProductCategoriesFromInitData = (
  data: ShopProductsData | undefined
): ProductCategory[] => {
  const categories = data?.metadata.categories || {}
  return Object.entries(categories).reduce<ProductCategory[]>(
    (acc, [categoryName, productsAmount]) => {
      acc.push({
        name: categoryName,
        amount: Number(productsAmount),
      })
      return acc
    },
    []
  )
}

/**
 * Sort products by categories and
 * returns them in object as keys named category name
 * and value as array of items
 * @param products
 */
export const sortProductsByCategories = (
  products: BasketProduct[]
): ProductsInCategories => {
  const categories = {}
  products.forEach((basketProduct) => {
    const product = basketProduct.product
    if (product.productType in categories) {
      categories[product.productType].push(basketProduct)
    } else {
      categories[product.productType] = [basketProduct]
    }
  })
  return categories
}

export const getProductCategories = (
  productsInCategories: ProductsInCategories
): ProductCategory[] => {
  return Object.entries(productsInCategories).reduce<ProductCategory[]>(
    (acc, [categoryName, basketProducts]) => {
      acc.push({
        name: categoryName,
        amount: basketProducts.length,
      })
      return acc
    },
    []
  )
}

export const getProductGroupsFilters = (
  allProducts: BasketProduct[],
  productsInGroups: ProductsInGroups
): ProductGroup[] => {
  return [
    {
      name: 'All',
      label: '',
      amount: allProducts.length,
    },
    {
      name: 'Favourites',
      label: 'favouriteProducts',
      amount: productsInGroups.favouriteProducts.length,
    },
    {
      name: 'You often buy',
      label: 'oftenBuyProducts',
      amount: productsInGroups.oftenBuyProducts.length,
    },
  ]
}

/**
 * Transform products into data structure,
 * that is required in purchase API endpoint
 * @param products
 */
export const getPurchaseMutationData = (
  products: BasketProduct[]
): ProductPurchaseData[] => {
  return products.map((product) => {
    return {
      id: product.product.id,
      count: product.amount,
    }
  })
}

/**
 * Sort products
 * oftenBuyProducts - isFrequentlyPurchased flag is true
 * favouriteProducts - isFavourite flag is true
 * otherProducts - not oftenBuyProducts or favouriteProducts
 */
export const sortProductsToGroups = (
  visibleProducts: BasketProduct[]
): ProductsInGroups => {
  // TODO: If slow, do filters in one loop
  const oftenBuyProducts = visibleProducts.filter(
    (product) => product.product.isFrequentlyPurchased
  )
  const favouriteProducts = visibleProducts.filter(
    (product) => product.product.isFavourite
  )
  const otherProducts = visibleProducts.filter(
    // (product) => !product.product.isFavourite && !product.product.isFrequentlyPurchased
    (product) => !product.product.isFrequentlyPurchased
  )

  return { oftenBuyProducts, favouriteProducts, otherProducts }
}

export const productsSearchFilter = (
  products: BasketProduct[],
  compare: SearchFieldComparator
) => {
  return products.filter((product) =>
    compare([product.product.name, product.product.flavor])
  )
}

export const showFilteredProducts = (selectedCategories: {}): boolean => {
  return Object.values(selectedCategories).reduce<boolean>((acc, val) => {
    if (val) acc = true
    return acc
  }, false)
}

export const filterSelectedCategoriesFromProducts = (
  productsInCategories: ProductsInCategories,
  selectedCategories: {}
): BasketProduct[] => {
  const ret: BasketProduct[] = []
  for (const category in productsInCategories) {
    if (selectedCategories[category]) {
      ret.push(...productsInCategories[category])
    }
  }
  return ret
}

export const createCategoriesFilterInitState = (
  categories: ProductCategory[]
): ProductCategoryFilter => {
  return categories.reduce((acc, category) => {
    acc[category.name] = false
    return acc
  }, {})
}
