export interface DbRate {
  id: number,
  projectId: number | null | string,
  rateComponentName: string,
  competencyLevel: string,
  rate: number | null,
  currency: string,
  validFrom: string,
  validTo: string | null,
  note: string | null,
  isStoppable: boolean,
  isDeletable: boolean
}

export interface ResponseRate extends DbRate {
  userId: number,
  rateComponentId: number,
  createdAt: string,
  updatedAt: string
}

export type Rate = DbRate | ResponseRate

export interface ProjectRate {
  projectId: number,
  projectName: string;
  rates: Rate[];
}

export interface Competency {
  id: number,
  name: string,
  code: string,
  level: string,
  amountCzk: number,
  amountEur: number,
  amountGbp: number
}

export type CreateRateFormValues = {
  confirmed_conflicts: boolean,
  request_type: string,
  rates_user_rate_update_request: {
    valid_from: string | Date,
    project_id: number | string,
    primary_competency_rate_level: string | number,
    primary_competency_rate_rate: string | number,
    primary_competency_rate_note: string,
    team_leadership_bonus_level: string | number,
    team_leadership_bonus_rate: string | number,
    team_leadership_bonus_note: string,
    engineering_bonus_level: string | number,
    engineering_bonus_note: string,
    second_competency_bonus_rate: string | number,
    second_competency_bonus_level: string | number,
    second_competency_bonus_note: string,
    tech_leadership_bonus_level: string | number,
    tech_leadership_bonus_note: string
  }
}

export type RateFormValues = {
  confirmed_conflicts: boolean,
  request_type: string,
  valid_from: string | Date,
  project_id: string | number | null,
  primary_competency_rate_level: string | number,
  primary_competency_rate_rate: string | number,
  primary_competency_rate_note: string,
  second_competency_bonus_rate: string | number,
  second_competency_bonus_level: string | number,
  second_competency_bonus_note: string,
  team_leadership_bonus_level: string | number,
  team_leadership_bonus_rate: string | number,
  team_leadership_bonus_note: string,
  engineering_bonus_level: string | number,
  engineering_bonus_note: string,
  tech_leadership_bonus_level: string | number,
  tech_leadership_bonus_note: string
}

export interface RateError {
  overlapping_components?: ResponseRate[],
  status?: number,
  message?: string,
  code?: string
}

export enum COMPETENCIES {
  PRIMARY_COMPETENCY = 'Primary competency rate',
  TEAM_LEADERSHIP = 'Team leadership bonus',
  ENGINEERING_BONUS = 'Distinguished engineering squad bonus',
  SECOND_COMPETENCY = 'Second competency bonus',
  TECH_LEADERSHIP = 'Tech leadership bonus'
}

export const COMPETENCY_TYPE = {
  [COMPETENCIES.PRIMARY_COMPETENCY]: 'primary_competency_rate',
  [COMPETENCIES.SECOND_COMPETENCY]: 'second_competency_bonus',
  [COMPETENCIES.TEAM_LEADERSHIP]: 'team_leadership_bonus',
  [COMPETENCIES.ENGINEERING_BONUS]: 'engineering_bonus',
  [COMPETENCIES.TECH_LEADERSHIP]: 'tech_leadership_bonus'
}

export enum CURRENCY {
  CZK = 'CZK',
  EUR = 'EUR',
  GBP = 'GBP'
}