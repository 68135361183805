import React, { FC } from 'react'
import { FormikProps } from 'formik'
import { JoinProjectFormValues } from './AssignUserForm'
import { FormSelect } from '../../components/inputs/FormSelect'
import { User } from './types'
import { Button } from '../../components/buttons/Button'
import { FormGrid } from '../../components/ui/FormGrid'
import { NoTribeMessage } from './NoTribeMessage'
import { SelectItem } from '../../types'

type Props = {
  user: User | undefined;
  toggleProjectIdSelectValues: SelectItem[];
  tribeIdSelectValues: SelectItem[];
  userSelectValues: SelectItem[];
  isAssigningUserForm: boolean;
  // FIXME
  handleUserIdChange: any;
  success: boolean;
  formikProps: FormikProps<JoinProjectFormValues>;
};

export const AssignUserFormView: FC<Props> = (props) => {
  const {
    user,
    tribeIdSelectValues,
    toggleProjectIdSelectValues,
    isAssigningUserForm,
    userSelectValues,
    handleUserIdChange,
    success,
  } = props

  const { handleSubmit, isSubmitting, handleChange, values } =
    props.formikProps
  const buttonLabel = isAssigningUserForm ? 'Assign' : 'Join Project'
  const buttonDisabled =
    !values.userId || !values.tribeId || !values.togglProjectId || success

  return (
    <FormGrid onSubmit={handleSubmit}>
      {isAssigningUserForm && (
        <FormSelect
          name="userId"
          label="Person"
          values={userSelectValues}
          disabled={isSubmitting}
          customOnChange={(e) => {
            handleUserIdChange(e, handleChange)
          }}
        />
      )}

      {!!user?.tribes.length && (
        <>
          <FormSelect
            name="togglProjectId"
            label="Toggl Project"
            values={toggleProjectIdSelectValues}
            disabled={isSubmitting}
          />

          <FormSelect
            name="tribeId"
            label="Bill project to"
            values={tribeIdSelectValues}
            disabled={isSubmitting}
          />

          <Button
            kind="primary"
            id="assign-user-submit-button"
            type="submit"
            disabled={buttonDisabled}
          >
            {buttonLabel}
          </Button>
        </>
      )}

      {user && !user?.tribes.length && (
        <NoTribeMessage isAssigningUserForm={isAssigningUserForm} />
      )}
    </FormGrid>
  )
}
