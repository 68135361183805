import React, { FC } from 'react'
import { SpiritSliderStyles } from './SpiritSliderStyles'

type Props = {
  end: number;
  value: number;
};

export const SpiritSlider: FC<Props> = (props) => {
  const { end, value } = props
  const start = 0

  const sliderMarks = [
    {
      value: start,
      label: `${start} hours`,
    },
    {
      value: end,
      label: `${end} hours`,
    },
  ]

  function valueText(value: number) {
    if (end === 0) return '0 %'
    const percentage = (value / end) * 100
    return `${Math.round(percentage)} %`
  }

  return (
    <SpiritSliderStyles
      getAriaValueText={valueText}
      valueLabelFormat={valueText}
      value={value}
      min={start}
      max={end}
      step={1}
      marks={sliderMarks}
      valueLabelDisplay="on"
      disabled
    />
  )
}
