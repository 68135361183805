import { FieldOptions, UpdatableField } from './UpdatableField'

export class SliderFieldData extends UpdatableField {
  defaultValue: number;
  fieldName: string;
  keepVisible = true;
  fieldOptions: FieldOptions;

  constructor(defaultValue: number, fieldName: string, fieldOptions?: FieldOptions) {
    super(defaultValue, fieldName, fieldOptions)
  }
}
