import React, { FC } from 'react'
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Button,
} from '@material-ui/core'
import { TablePurchaseRow } from './TablePurchaseRow'
import { Purchase } from './types'
import { TableBodySkeleton } from '../../components/skeletons/TableBodySkeleton'
import styled from 'styled-components'
import { useCurrentUserInfo } from '../../components/CurrentUserProvider'

type Props = {
  userPurchases: Purchase[];
  isLoading: boolean;
  userId: number;
};

export const UserPurchasesTable: FC<Props> = ({
  userPurchases,
  isLoading,
  userId,
}) => {
  const limitedUserPurchases = userPurchases.slice(0, 14)
  const areUserPurchases = userPurchases && userPurchases.length > 0
  const { currentUser } = useCurrentUserInfo()
  const isAdmin = currentUser.role === 'admin'
  const transactionsLink = `/admin/transactions/${isAdmin ? `all?user_id=${userId}&payment_direction=expense` : '?payment_direction=expense'}`

  const userPurchasesRows = limitedUserPurchases?.map((purchase) => (
    <TablePurchaseRow
      key={purchase.id}
      purchase={purchase}
    />
  ))
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Datetime</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Created by</TableCell>
              <TableCell>Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableBodySkeleton
                rows={30}
                colspan={3}
              />
            )}
            {areUserPurchases && userPurchasesRows}
          </TableBody>
        </Table>
      </TableContainer>
      {areUserPurchases ? (
        <StyledButton
          variant={'outlined'}
          color={'primary'}
          href={transactionsLink}
        >
          All purchases
        </StyledButton>
      ) : (
        <p>No purchases yet</p>
      )}
    </>
  )
}

const StyledButton = styled(Button)`
  margin-top: 2rem;
`
