import { TableCell, TableRow } from '@material-ui/core'
import Alert, { Color } from '@material-ui/lab/Alert'
import React, { FC } from 'react'
import { useTransactionContext } from './MyTransactionContext'

interface Props {
  message: string,
  severity: Color
}

export const MyTransactionAlertRow: FC<Props> = ({ message, severity }) => {
  const { isAllTransactions } = useTransactionContext()
  return (
    <TableRow>
      <TableCell colSpan={isAllTransactions ? 5 : 4}>
        <Alert
          variant={'outlined'}
          severity={severity}
        >
          {message}
        </Alert>
      </TableCell>
    </TableRow>
  )
}
