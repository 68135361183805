export enum ProductCategory {
  'none' = '',
  'fridge' = 'fridge',
  'benefits' = 'benefits',
  'merch' = 'merch',
}

export type CategoriesData = {
  alco: number;
  cereal: number;
  'ice cream': number;
  'kids snack': number;
  nonalco: number;
  snack: number;
};

export type ProductData = {
  metadata: {
    categories: CategoriesData;
    frequentProducts: number;
  };
  products: Product[];
};

export type Product = {
  barCode: string | null;
  barcodes: string[];
  count: number;
  currentCost: number;
  flavor: string;
  id: number;
  inStock: boolean;
  isFavourite: boolean;
  isFrequentlyPurchased: boolean;
  name: string;
  productType: keyof CategoriesData;
  deleted: boolean;
  description: string | null;
  externalProductId: number | null;
};

export type ProductCreate = {
  barCode: string | null;
  count: number | null;
  currentCost: number | null;
  flavor: string | null;
  name: string | null;
  productType: keyof CategoriesData | null;
  group: ProductCategory;
  description: string | null;
  externalProductId: number | null;
};

export enum FridgeCategories {
  alco = 'alco',
  cereal = 'cereal',
  'ice cream' = 'ice cream',
  'kids snack' = 'kids snack',
  nonalco = 'nonalco',
  snack = 'snack',
}

export enum MerchCategories {
  body = 'body',
  head = 'head',
  other = 'other',
  feet = 'feet',
}

export interface ErrorProduct extends Error {
  status?: number;
  errors?: {
    name?: string;
    currentCost?: string;
    count?: string;
    productType?: string;
  };
}

type ValidatedBarcode = {
  valid: boolean;
  in_use: boolean;
  product: string | null;
};

export type ValidatedBarcodes = Record<string, ValidatedBarcode>;


export enum ProductStatus {
  'active' = 'active',
  'inactive' = 'inactive',
}

export enum Barcode {
  'error' = 'error',
  'invalid' = 'invalid',
  'success' = 'success',
  'default' = 'default',
}
