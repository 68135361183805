import React from 'react'
import { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const CSVIcon: FC<IconSVGProps> = ({ width = 24, height = 24 }) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M6 22H3.333A1.333 1.333 0 012 20.665V3.333A1.333 1.333 0 013.333 2h9.448c.353 0 .692.14.942.39l5.22 5.219a1.335 1.335 0 01.39.943v4.114"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M19.332 8.666H14a1.333 1.333 0 01-1.333-1.333V2M11.333 15.332A2.667 2.667 0 008.666 18v1.333A2.667 2.667 0 0011.333 22M16.666 15.332h-1.333a1.334 1.334 0 00-1.334 1.334c0 2 2.667 2 2.667 4a1.334 1.334 0 01-1.333 1.333h-1.334M19.332 15.332v2.264c0 1.567.465 3.1 1.334 4.404a7.943 7.943 0 001.333-4.404v-2.264"
    />
  </svg>
)
