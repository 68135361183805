import React, { FC, memo } from 'react'
import { ProductCategory } from '../../types'
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'

type Props = {
  categories: ProductCategory[];
  selectedCategories: {};
  categoryChange: (category: string, checked: boolean) => void;
};
export const CategoryFilter: FC<Props> = memo(
  ({ selectedCategories, categoryChange, categories }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      categoryChange(event.target.name, event.target.checked)
    }
    return (
      <FormGroup>
        {categories.map((category, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={selectedCategories[category.name]}
                onChange={handleChange}
                name={category.name}
              />
            }
            label={`${category.name} ( ${category.amount} )`}
          />
        ))}
      </FormGroup>
    )
  }
)
