import { Button, ButtonGroup } from '@material-ui/core'
import { FormikProps } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import { atMinWidth } from '../../styles/helpers'
import { PaymentCardInfo } from './PaymentCardInfo'
import { PaymentCardForm } from './PaymentCardForm'
import { CardInfoData, StepsData } from './types'

interface Props {
  formikProps: FormikProps<StepsData>;
  cardInfo: CardInfoData | undefined;
  closeModal: () => void;
  isError: boolean;
}

export const PaymentCardSteps: FC<Props> = ({ formikProps, cardInfo, closeModal, isError }) => {
  const [showCardInfo, setShowCardInfo] = useState(false)
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(true)

  const handleNextClick = () => {
    formikProps.submitForm()
  }

  useEffect(() => {
    if (!isError && cardInfo) {
      setShowCardInfo(true)
    }
  }, [isError, cardInfo])

  return (
    <>
      {showCardInfo && cardInfo ?
        <PaymentCardInfo
          data={cardInfo} />
        :
        <PaymentCardForm
          formikProps={formikProps}
          setIsDisabled={setIsNextBtnDisabled}
        />
      }

      <StyledButtonGroup
        color="primary"
        fullWidth
      >
        <StyledButton
          key="next"
          disabled={isNextBtnDisabled}
          variant={'contained'}
          onClick={() => !showCardInfo ? handleNextClick() : closeModal()}
        >
          {showCardInfo ? 'Close' : 'Show card details'}
        </StyledButton>
      </StyledButtonGroup>
    </>
  )
}

const StyledButtonGroup = styled(ButtonGroup)`
  display: flex;
  margin-top: 32px;

  ${atMinWidth.tablet} {
    flex-direction: row;
  }
`

const StyledButton = styled(Button)`
  font-family: 'Ginto Normal Regular';
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 15.4px;
  text-transform: none;
`
