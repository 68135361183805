import { useMemo } from 'react'
import { DevicesStatus, TestingDevices } from './types'

const DEVICE_BASE_URL = '/admin/testing_devices'

export const getLinkToDeviceDetail = (id: number): string => {
  return `${DEVICE_BASE_URL}/${id}`
}

export const redirectToDeviceDetail = (id: number): string => {
  return (window.location.pathname = getLinkToDeviceDetail(id))
}

export const getDevicesFilteredBySwitcher = ({
  devices,
  selectedDevicesStatus,
}: {
  devices: TestingDevices[];
  selectedDevicesStatus: DevicesStatus;
}) =>
  useMemo(() => {
    return devices.filter((device) => {
      if (selectedDevicesStatus === DevicesStatus.BORROWED) {
        return device.taken
      }
      if (selectedDevicesStatus === DevicesStatus.FREE) {
        return !device.taken
      }
      return device
    })
  }, [selectedDevicesStatus, devices])
