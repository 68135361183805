import React, { FC, useMemo, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import styled from 'styled-components'
import { UsersState } from '../../../types'
import { Alert } from '@material-ui/lab'
import {
  computeUserStates,
  paginateUsers,
  sortUsersByActivityInProject,
} from '../utils'
import { ProjectUserLine } from './ProjectUserLine'
import { useProjectDetailContext } from '../ProjectDetailPage'
import {
  SearchField,
  useSearchFieldState,
} from '../../../components/inputs/SearchField'
import { ProjectUsersHeader } from './ProjectUsersHeader'
import { usersPerPage } from '../config'
import { BubbleSwitcher } from '../../../components/BubbleSwitcher'

type Props = {};
export const ProjectUsers: FC<Props> = () => {
  const [usersState, setUsersState] = useState<UsersState>(UsersState.active)
  const { project } = useProjectDetailContext()

  const sortedUsers = useMemo(
    () => sortUsersByActivityInProject(project.users),
    [project]
  )
  const usersStates = useMemo(
    () =>
      computeUserStates(sortedUsers.active.length, sortedUsers.inactive.length),
    [sortedUsers]
  )
  const selectedUsers = useMemo(
    () => sortedUsers[usersState],
    [usersState, sortedUsers]
  )

  const { searchString, handleFilterChange, compare } = useSearchFieldState()
  const visibleUsers = useMemo(
    () =>
      searchString
        ? selectedUsers.filter((user) => compare([user.fullName]))
        : selectedUsers,
    [selectedUsers, searchString]
  )

  const [currentPage, setCurrentPage] = useState(0)
  const paginatedVisibleUsers = useMemo(() => {
    const paginatedUsers = paginateUsers(
      visibleUsers,
      currentPage,
      usersPerPage
    )
    if (paginatedUsers.length === 0) setCurrentPage(0) // when user is in some higher page, decrease to first page, because there will not be any users visible
    return paginatedUsers
  }, [visibleUsers, currentPage])
  const handleChangeState = (newState: string) => {
    setUsersState(UsersState[newState])
  }
  const handlePaginationChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setCurrentPage(page)
  }

  return (
    <>
      <ProjectUsersHeader />
      <FilterWrapper>
        <BubbleSwitcher
          options={usersStates}
          active={usersState}
          setActive={handleChangeState}
        />
        <StyledUsersSearchField
          onChange={handleFilterChange}
        />
      </FilterWrapper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Person</TableCell>
            <TableCell align={'right'}>Client rate</TableCell>
            <TableCell align={'right'}>Billing to</TableCell>
            <TableCell align={'right'}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedVisibleUsers.map((user) => (
            <ProjectUserLine
              user={user}
              key={user.id}
              projectId={project.id}
              state={usersState}
              projectName={project.name}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={visibleUsers.length}
              page={currentPage}
              rowsPerPage={usersPerPage}
              rowsPerPageOptions={[usersPerPage]}
              onChangePage={handlePaginationChange}
              colSpan={4}
            />
          </TableRow>
        </TableFooter>
      </Table>
      {paginatedVisibleUsers.length === 0 && (
        <NoUsersAlert severity={'warning'}>No users available</NoUsersAlert>
      )}
    </>
  )
}

const StyledUsersSearchField = styled(SearchField)`
  margin-top: 20px;
  margin-bottom: 20px;
`

const NoUsersAlert = styled(Alert)`
  margin-top: 10px;
`

const FilterWrapper = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
