import React, { FC } from 'react'
import { Button } from '../../../components/buttons/Button'

interface Props {
    setConfirmedDiscard: (confirmed: boolean) => void
  }
  
export const DiscardChangesModal: FC<Props> = ({ setConfirmedDiscard }) => {
  return (
    <>
      <h2>Unsaved changes</h2>
      <p>Do you want to discard changes?</p>

      <Button
        kind="primary"
        onClick={() => setConfirmedDiscard(true)}
      >
            OK
      </Button>
    </>
  )
}