import React, { FC } from 'react'
import {
  Button,
  Checkbox,
  Fab,
  FormControlLabel,
  Grid,
  TableBody,
  Typography,
} from '@material-ui/core'
import { ProductsTable } from '../ProductList/ProductsListView'
import { ShopProductRow } from '../ShopProductRow'
import { ShopProductRowVariant } from '../../types'
import { Alert } from '@material-ui/lab'
import { ArrowIcon } from '../../../../components/Icon/icons/Arrow.icon'
import styled from 'styled-components'
import { theme } from '../../../../styles/muiThemes'
import { colors } from '../../../../styles/colors'
import CloseIcon from '@material-ui/icons/Close'
import { useShopContext } from '../../ShopContext'

type Props = {
  onHouse: boolean;
  setOnHouse: () => void;
  purchase: () => void;
  handleClose: () => void;
};
export const CartDrawerContent: FC<Props> = (props) => {
  const { onHouse, setOnHouse, purchase, handleClose } = props
  const { selectedProducts, selectedProductsAmount, totalCost } =
    useShopContext()
  return (
    <DrawerContent>
      <Grid
        container
        justify={'space-between'}
      >
        <Grid item>
          <Typography variant="h3">My Bag</Typography>
        </Grid>
        <Grid item>
          <Fab
            color={'primary'}
            size={'small'}
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </Fab>
        </Grid>
      </Grid>
      <Container>
        <ProductsTable>
          <TableBody>
            {selectedProducts.map((product) => (
              <ShopProductRow
                // handleFavouriteSwitch={props.handleFavouriteSwitch}
                basketProduct={product}
                key={product.product.id}
                variant={ShopProductRowVariant.BAG}
              />
            ))}
            {selectedProductsAmount === 0 && (
              <Alert severity={'warning'}>No products in bag</Alert>
            )}
          </TableBody>
        </ProductsTable>
      </Container>
      <BottomContent>
        <Grid
          container
          justify={'space-between'}
        >
          <Grid item>
            <TotalText>In total</TotalText>
          </Grid>
          <Grid item>
            <TotalCost>{totalCost}</TotalCost>
            <Currency>Kč</Currency>
          </Grid>
        </Grid>
        <PurchaseButton
          color={'primary'}
          variant={'contained'}
          endIcon={<ArrowIcon />}
          disabled={!selectedProducts.length}
          fullWidth
          onClick={purchase}
        >
          Purchase
        </PurchaseButton>
        <OnHouseCheckbox
          control={
            <Checkbox
              checked={onHouse}
              color={'default'}
              onChange={setOnHouse}
            />
          }
          label={'On the house'}
        />
      </BottomContent>
    </DrawerContent>
  )
}

const PurchaseButton = styled(Button)`
  grid-area: button;
  justify-self: end;
  margin: 1em 0;
  ${theme.breakpoints.between('md', 'lg')} {
    svg {
      display: none;
    }
  }
`

const DrawerContent = styled.div`
  min-width: 500px;
  padding: 3em;
  background: ${colors.brandYellow};
  height: 100%;
  ${theme.breakpoints.down('md')} {
    min-width: unset;
    padding: 2em 1em;
  }
`

const Container = styled.div`
  display: grid;
  padding-bottom: 3em;
  overflow: auto;
  max-height: calc(100vh - 300px);
  ${theme.breakpoints.down('sm')} {
    max-height: calc(100vh - 270px);
  }
`

const TotalText = styled.p`
  font-size: 1.2rem;
  margin: 0;
  grid-area: text;
`

const TotalCost = styled.span`
  font-size: 1.9rem;
  font-family: 'Ginto Nord', serif;
`

const Currency = styled.span`
  vertical-align: top;
  padding-left: 0.5em;
`

const OnHouseCheckbox = styled(FormControlLabel)`
  grid-area: onhouse;
  margin-left: -11px;
`

const BottomContent = styled.div`
  padding-top: 1em;
`
