import { Box, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import styled from 'styled-components'

import { colors } from '../../styles/colors'
import { atMinWidth } from '../../styles/helpers'
import { RowProps } from './types'

type Props = { row: RowProps };

export const DeviceInfoRow: FC<Props> = ({ row }) => {
  const { value, label } = row
  const isNotValueAvailable = !value || value.length === 0
  const alternativeValue = isNotValueAvailable ? '-' : value

  return (
    <DeviceInfo>
      <Label>{label}</Label>
      <Typography>{alternativeValue}</Typography>
    </DeviceInfo>
  )
}

const Label = styled.span`
  color: ${colors.grey0};
  font-size: 15px;
  text-transform: capitalize;
`

const DeviceInfo = styled(Box)`
  margin-bottom: 10px;
  ${atMinWidth.tablet} {
    display: grid;
    grid-template-columns: 150px auto;
  }
`
