import { StyledContainer } from '../../bundles/ProjectsPage/components/ProjectFilter'
import { CorpSelect } from '../inputs/CorpSelect'
import { StatusFilter } from '../../enum'
import { HandleFilterInputChange } from '../../components/ProjectsTable/utils'
import React, { FC, useMemo } from 'react'
import { statusSelectable } from '../ProjectsTable/utils'
import { useTribes } from '../../hooks/useTribes'
import { selectableTribes } from '../../components/ProjectsTable/utils'

interface Props {
  setSelectedProjectsStatus: React.Dispatch<React.SetStateAction<StatusFilter>>;
  selectedProjectsStatus: StatusFilter;
  handleTribeChange: HandleFilterInputChange;
  owningTribe: number;
}

export const ProjectFilters: FC<Props> = ({
  setSelectedProjectsStatus,
  selectedProjectsStatus,
  handleTribeChange,
  owningTribe,
}) => {
  const handleStatusChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.target.value === StatusFilter.Inactive
      ? setSelectedProjectsStatus(StatusFilter.Inactive)
      : setSelectedProjectsStatus(StatusFilter.Active)
  }

  const { tribes } = useTribes()

  const tribesSelectable = useMemo(() => selectableTribes(tribes), [tribes])

  return (
    <StyledContainer>
      {tribesSelectable && (
        <CorpSelect
          values={tribesSelectable}
          value={owningTribe}
          onChange={handleTribeChange}
          label={'Tribe'}
        />
      )}
      {statusSelectable && (
        <CorpSelect
          values={statusSelectable}
          value={selectedProjectsStatus}
          onChange={handleStatusChange}
          label={'Status'}
        />
      )}
    </StyledContainer>
  )
}
