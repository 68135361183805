import React, { FC, useMemo } from 'react'
import { Button } from '../../../../components/buttons/Button'
import { FormSelect } from '../../../../components/inputs/FormSelect'
import { FormikProps } from 'formik'
import { FormGrid } from '../../../../components/ui/FormGrid'
import styled from 'styled-components'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import { selectableTribes } from '../../../../components/ProjectsTable/utils'
import { ChangeTribeFormValues } from './ChangeTribeModal'
import { generateMonthsForAYear } from '../../../../utils/helpers'
import { MonthPicker } from '../../../TogglReportPage/components'

type Props = {
  formikProps: FormikProps<ChangeTribeFormValues>;
  userTribes
};

export const ChangeTribeFormView: FC<Props> = (props) => {
  const { formikProps, userTribes } = props
  const { isSubmitting, handleSubmit, values, setFieldValue } = formikProps

  const tribesSelectable = useMemo(() => selectableTribes(userTribes), [userTribes])
  const monthSelectHandler = (selectedMonth: string) => {
    setFieldValue('validFrom', selectedMonth)
  }

  return (
    <>
      <StyledFormGrid onSubmit={handleSubmit}>
        <FormSelect
          name="tribeId"
          label="Tribe"
          value={values.tribeId ?? tribesSelectable[0].value}
          values={tribesSelectable}
          disabled={isSubmitting}
        />
        <MonthPicker
          changeHandler={monthSelectHandler}
          selectedMonth={values.validFrom}
          selectOptions={generateMonthsForAYear()}
          label="Date from"
        />
        <Button
          kind="primary"
          id="assign-user-submit-button"
          type="submit"
          disabled={isSubmitting}
        >
          Save
        </Button>
      </StyledFormGrid>
    </>
  )
}

const StyledFormGrid = styled(FormGrid)`
  margin-top: 24px;
`
