import React, { FC, useCallback, useMemo } from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'

import { DotsMenu, DotsMenuItem } from '../../../components/DotsMenu'
import { Project } from '../../ProjectInfo/types'
import { useUserPermissions } from '../../../components/CurrentUserProvider'
import { useProjectDetailContext } from '../ProjectDetailPage'
import {
  deactivateProject,
  pinProject,
  reactivateProject,
} from '../../../utils/apiHelpers'
import { useQueryNotification } from '../../UserInfo/utils'
import { buildLinkToMonthlyReport } from '../../../utils/helpers'

type Props = {
  project: Project;
};
export const ProjectPageHeaderDotsMenu: FC<Props> = ({ project }) => {
  const { resolvePermissions } = useUserPermissions()
  const { refetch, isActive } = useProjectDetailContext()
  const { successNotification } = useQueryNotification()
  const theme = useTheme()
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'))

  const canManageProject = useMemo(
    () => resolvePermissions('project', 'manage'),
    []
  )
  const canPinProject = useMemo(() => resolvePermissions('project', 'pin'), [])
  const hasPermissions = canPinProject || canManageProject

  const pinProjectHandler = useCallback(async () => {
    await pinProject(project.id)
    successNotification('Budget pinned to dashboard.')
    await refetch()
  }, [])

  const deactivateProjectHandler = useCallback(async () => {
    await deactivateProject(project.id)
    successNotification(
      'Project deactivated and its Toggl entries were frozen.'
    )
    await refetch()
  }, [])

  const reactivateProjectHandler = useCallback(async () => {
    await reactivateProject(project.id)
    successNotification(
      'Project was reactivated and its Toggl entries unfrozen.'
    )
    await refetch()
  }, [])

  const projectMonthlyReportLink = useMemo(
    () => buildLinkToMonthlyReport(project.id),
    [project.id]
  )

  const dostMenuItems: DotsMenuItem[] = useMemo(() => {
    const arr: DotsMenuItem[] = []
    if (canPinProject)
      arr.push({
        label: project.pinnedToDashboard
          ? 'Budget pinned to dashboard'
          : 'Pin budget to dashboard',
        disabled: project.pinnedToDashboard,
        onClick: pinProjectHandler,
      })
    if (canManageProject) {
      arr.push({
        label: isActive ? 'Deactivate' : 'Activate',
        onClick: isActive ? deactivateProjectHandler : reactivateProjectHandler,
      })
      arr.push({
        label: 'Delete',
        // eslint-disable-next-line no-console
        onClick: () => console.log('Delete'),
      })
    }
    if (isSmallerScreen) {
      arr.push({
        label: 'Report',
        onClick: () => (location.href = projectMonthlyReportLink),
      })
    }
    return arr
  }, [project, isSmallerScreen])

  if (!hasPermissions) return null

  return (
    <DotsMenu
      items={dostMenuItems}
      heavy
      transparent
    />
  )
}
