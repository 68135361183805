import React, { FC } from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { GroupsFilter } from './GroupsFilter'
import { CategoryFilter } from './CategoryFilter'
import { ProductCategory, ProductGroup } from '../../types'

type Props = {
  handleGroupFilterChange: (selectedCategory: string) => void;
  handleCategoryChange: (category: string, checked: boolean) => void;
  selectedGroup: string;
  productGroupsFilters: ProductGroup[];
  filteredCategories: ProductCategory[];
  selectedCategories: {};
};
export const FilterDrawerContent: FC<Props> = (props) => {
  const {
    handleGroupFilterChange,
    selectedGroup,
    productGroupsFilters,
    filteredCategories,
    selectedCategories,
    handleCategoryChange,
  } = props
  return (
    <Content>
      <StyledTopheading variant={'h3'}>Filters</StyledTopheading>
      <StyledHeading variant={'overline'}>Groups</StyledHeading>
      <GroupsFilter
        selectedCategory={selectedGroup}
        handleFilterChange={handleGroupFilterChange}
        categories={productGroupsFilters}
      />
      <StyledHeading variant={'overline'}>Categories</StyledHeading>
      <CategoryFilter
        categories={filteredCategories}
        selectedCategories={selectedCategories}
        categoryChange={handleCategoryChange}
      />
    </Content>
  )
}

const StyledTopheading = styled(Typography)`
  margin-bottom: 10px;
`

const StyledHeading = styled(Typography)`
  margin-bottom: 0.1em;
  display: block;
  margin-top: 0.6em;
`

const Content = styled.div`
  padding: 2em;
`
