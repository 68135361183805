import React, { FC, useMemo, useState } from 'react'
import { Paper, Typography } from '@material-ui/core'
import styled from 'styled-components'

import { atMinWidth } from '../../styles/helpers'
import { PageLayoutWrapper } from '../../components/ui/PageLayoutWrapper'
import { DevicesStatus, TestingDevices } from './types'
import {
  SearchField,
  useSearchFieldState,
} from '../../components/inputs/SearchField'
import { DevicesTable } from './DevicesTable'
import { useUserPermissions } from '../../components/CurrentUserProvider'
import { Button } from '../../components/buttons/Button'
import { AddNewDevice } from '../TestingDeviceDetailPage/AddNewDevice'
import { BubbleSwitcher } from '../../components/BubbleSwitcher'
import { getDevicesFilteredBySwitcher } from './utils'
import { PlusIcon } from '../../components/Icon/icons/Plus.icon'
import { colors } from '../../styles/colors'

type Props = {
  setSelectedDevicesStatus: (selectedDevicesStatus: DevicesStatus) => void;
  selectedDevicesStatus: DevicesStatus;
  devices: TestingDevices[];
  isLoading: boolean;
};

export const TestingDevicesPageView: FC<Props> = ({
  setSelectedDevicesStatus,
  selectedDevicesStatus,
  devices,
  isLoading,
}) => {
  const [dialogOpened, setDialogOpened] = useState(false)
  const { resolvePermissions } = useUserPermissions()
  const userCanManage = resolvePermissions('user', 'manage')

  const numberOfAllDevices = devices?.length
  const numberOfBorrowedDevices = devices?.filter(
    (device) => device.taken
  ).length
  const numberOfFreeDevices = numberOfAllDevices - numberOfBorrowedDevices

  const devicesFilteredBySwitcherDate = getDevicesFilteredBySwitcher({
    devices,
    selectedDevicesStatus,
  })

  const { searchString, handleFilterChange, compare } = useSearchFieldState()

  const filteredDevices = useMemo(
    () =>
      searchString
        ? devicesFilteredBySwitcherDate.filter((device) =>
          compare([device.systemVersion, device.brand, device.name, device.serial, device?.location ?? device?.sublocation ?? ""])
        )
        : devicesFilteredBySwitcherDate,
    [devicesFilteredBySwitcherDate, searchString]
  )

  const switcherData = [
    {
      value: DevicesStatus.ALL,
      label: `${DevicesStatus.ALL} (${numberOfAllDevices})`,
    },
    {
      value: DevicesStatus.FREE,
      label: `${DevicesStatus.FREE} (${numberOfFreeDevices})`,
    },
    {
      value: DevicesStatus.BORROWED,
      label: `${DevicesStatus.BORROWED} (${numberOfBorrowedDevices})`,
    },
  ]

  return (
    <PageLayoutWrapper>
      <StyledPaper elevation={0}>
        <Typography variant="h3">Testing devices</Typography>
        <ActionsBlock>
          <BubbleSwitcher
            options={switcherData}
            active={selectedDevicesStatus}
            setActive={setSelectedDevicesStatus}
            isLoading={isLoading}
          />
          <SearchBlock>
            <SearchField
              fullWidth
              onChange={handleFilterChange}
              placeholder="Search name, brand or sys. version"
            />
            {userCanManage && (
              <Button
                kind="basicPrimary"
                onClick={() => setDialogOpened(true)}
              >
                <PlusIcon color={colors.white} />{' '}
                <ButtonText>Add new device</ButtonText>
              </Button>
            )}
            <AddNewDevice
              dialogOpened={dialogOpened}
              setDialogOpened={setDialogOpened}
            />
          </SearchBlock>
        </ActionsBlock>
        <DevicesTable
          filteredDevices={filteredDevices}
        />
      </StyledPaper>
    </PageLayoutWrapper>
  )
}

export const SearchBlock = styled.div`
  display: grid;
  justify-content: end;
  grid-template-columns: 1fr;
  gap: 20px;

  ${atMinWidth.tablet} {
    grid-template-columns: 1fr auto;
  }

  ${atMinWidth.custom(1300)} {
    width: 50%;
  }
`

export const ActionsBlock = styled.div`
  display: grid;
  align-items: center;
  grid-row-gap: 20px;
  margin-bottom: 50px;

  ${atMinWidth.custom(1300)} {
    display: flex;
    justify-content: space-between;
  }
`

export const StyledPaper = styled(Paper)`
  min-height: 90vh;
  position: relative;
  padding: 16px;

  ${atMinWidth.tablet} {
    padding: 48px 40px;
  }

  ${atMinWidth.desktop} {
    padding: 48px 64px;
  }
`

const ButtonText = styled.span`
  margin-left: 10px;
`

