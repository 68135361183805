import React, { FC } from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import styled from 'styled-components'
import { useField } from 'formik'
import { colors } from '../../styles/colors'

type Props = {
  title: string;
  name: string;
  options: { key: string, name: string }[],
}

export const RadioButtonsGroup: FC<Props> = ({ title, name, options }) => {
  const [field] = useField(name)
  const { onChange, value } = field

  return (
    <FormControl component="fieldset">
      <StyledFormLabel>{title}</StyledFormLabel>
      <RadioGroup
        aria-label={name}
        name={name}
        value={value}
        onChange={onChange}>
        {options.map((option) => (
          <StyledFormControlLabel
            key={option.key}
            value={option.key}
            control={<StyledRadio />}
            label={option.name} />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

const StyledFormLabel = styled(FormLabel)`
  font-size: 18px;
  font-weight: 700;
  color: ${colors.black};
  margin-bottom: 16px;
  line-height: 11px
`

const StyledFormControlLabel = styled(FormControlLabel)`
  line-height: 26px;
  margin-bottom: 6px;
`

const StyledRadio = styled(Radio)`
  padding: 0;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: ${colors.black} !important;
`
