import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const AllUsersIcon: FC<IconSVGProps> = ({ width = 20, height = 42 }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.333 7.667a2 2 0 100-4 2 2 0 000 4zM4.333 9A3.333 3.333 0 001 12.333v2h1.333L3 19.667h2.667M17.667 7.667a2 2 0 100-4 2 2 0 000 4zM17.666 9A3.334 3.334 0 0121 12.333v2h-1.334L19 19.667h-2.667M11 6.333A2.667 2.667 0 1011 1a2.667 2.667 0 000 5.333zM15.666 12.333a4.667 4.667 0 10-9.333 0v2h2L9 21h4l.666-6.667h2v-2z"
      stroke="#C8C6C5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
