import React, { FC } from 'react'
import { TabContainer } from '../../../../components/ui/TabContainer'
import { Contacts } from '../Contacts'
import { Addresses } from '../Addresses'
import { UserInfo } from '../..'

type Props = {
  user: UserInfo;
}

export const ContactsTab: FC<Props> = ({ user }) => {
  return (
    <TabContainer>
      <Contacts userId={user.id} />
      <Addresses userId={user.id} />
    </TabContainer>
  )
}
