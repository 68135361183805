import React, { FC } from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { User } from '../types'
import { LinkToProjectReport } from '../../../components/buttons/LinkToUserReport'
import { getLinkToUserDetail, listUserTribes, userRate } from '../utils'
import { NameCell } from '../../../components/ProjectsTable/ProjectRow'
import styled from 'styled-components'

type Props = {
  user: User;
  canReadSensitiveData: boolean;
};
export const UsersTableRow: FC<Props> = ({ user, canReadSensitiveData }) => {


  return (
    <TableRow
      key={user.id}
    >
      <NameCell><a href={getLinkToUserDetail(user.id)}>{user.fullName}</a></NameCell>
      <TableCell>{user.nickname}</TableCell>
      <EmailCell>{user.email}</EmailCell>
      <TableCell>{listUserTribes(user.tribes)}</TableCell>
      <TableCell>{userRate(user.globalRate, user.billingCurrency)}</TableCell>
      <TableCell>{user.balance}</TableCell>
      {canReadSensitiveData && <TableCell>{user.shoppingBalance}</TableCell>}
      <TableCell>{user.role}</TableCell>
      <TableCell>
        <LinkToProjectReport userId={user.id} />
      </TableCell>
    </TableRow>
  )
}

const EmailCell = styled(TableCell)`
  max-width: 280px;
  overflow-wrap: break-word;
`