import React, { FC } from 'react'
import { Enums, Project } from '../../types'
import { UpdatableItemsGroup } from '../../../../components/ui/UpdatableFormField/UpdatableItemsGroup'
import { DataLine } from '../../../../types'
import { TextFieldData } from '../../../../types/UpdatableFormFieldData/TextFieldData'
import { SliderFieldData } from '../../../../types/UpdatableFormFieldData/SliderFieldData'

type Props = {
  project: Project;
  enums: Enums;
};
export const BudgetNotificationTab: FC<Props> = (props) => {
  const { project } = props
  const items: DataLine[] = [
    {
      label: 'Slack channel',
      updatableFields: [
        new TextFieldData(project?.slackChannel || '', 'slack_channel'),
      ],
    },
    {
      label: 'Budget round digits',
      updatableFields: [
        new TextFieldData(
          project?.budgetRoundDigits || 0,
          'budget_round_digits'
        ),
      ],
    },
    {
      label: 'Budget notification threshold',
      updatableFields: [
        new SliderFieldData(
          project?.budgetNotifyThreshold,
          'budget_notify_threshold'
        ),
      ],
    },
  ]
  return (
    <UpdatableItemsGroup
      items={items}
      updatePaths={[`/api/projects/${project.id}`]}
    />
  )
}
