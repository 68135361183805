import React, { FC, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { useCurrentUserInfo } from '../../../../components/CurrentUserProvider'
import { QrCode } from '../../../../components/Icon/icons/QrCode.icon'
import { TransferIcon } from '../../../../components/Icon/icons/Transfer.icon'
import { TabsLayout } from '../../../../components/ui/TabsLayout'
import { PaymentData, PaymentQRCodeData } from '../types'
import { QRCodeDialogInternationalTabContent } from './QRCodeDialogInternationalTabContent'
import { QRCodeDialogQRTabContent } from './QRCodeDialogQRTabContent'

type Props = {
  dialogOpened: boolean;
}

export const QRCodeDialogContent: FC<Props> = ({
  dialogOpened,
}) => {
  const { currentUser } = useCurrentUserInfo()
  const { balance, pairingCode } = currentUser
  const positiveBalance = Math.abs(balance)

  const initialState: PaymentData = {
    amount: positiveBalance,
    note: '',
    pairingCode: pairingCode,
  }

  const [paymentFieldsData, setPaymentFieldsData] = useState(initialState)
  const amount = paymentFieldsData.amount.toString()

  const amountParams = new URLSearchParams({
    amount: amount,
    note: paymentFieldsData.note,
  })
  const isAmountEnabled = paymentFieldsData.amount != null && paymentFieldsData.amount > 0

  const { data, isLoading } = useQuery<PaymentQRCodeData>(
    `/admin/payments/qr_code_data?${amountParams}`,
    {
      enabled: isAmountEnabled,
    }
  )

  useEffect(() => {
    return () => setPaymentFieldsData(initialState)
  }, [dialogOpened])

  const qrTabHeading = {
    icon: (
      <QrCode
        key="qr"
        width={16}
        height={16}
      />
    ),
    label: 'CZK payment',
  }

  const internationalTabHeading = {
    icon: (
      <TransferIcon
        key="international"
        width={16}
        height={16}
      />
    ),
    label: 'International payment',
  }

  return (
    <TabsLayout
      headings={[
        qrTabHeading,
        internationalTabHeading,
      ]}
      tabsContent={[
        <QRCodeDialogQRTabContent
          key="qr"
          paymentFieldsData={paymentFieldsData}
          setPaymentFieldsData={setPaymentFieldsData}
          qrData={data}
          isLoading={isLoading}
          isAmountEnabled={isAmountEnabled}
        />,
        <QRCodeDialogInternationalTabContent
          key="international"
          qrData={data}
          pairingCode={paymentFieldsData.pairingCode?.toString()}
        />,
      ]}
      variant="standard"
      scrollButtons="off"
    />
  )
}
