import React, { FC } from 'react'
import { ProductCategory, Product, ProductStatus } from './types'
import { PageLayoutWrapper } from '../../components/ui/PageLayoutWrapper'
import { Typography } from '@material-ui/core'
import { atMinWidth } from '../../styles/helpers'
import styled from 'styled-components'
import { BubbleSwitcher } from '../../components/BubbleSwitcher'
import { ProductsTable } from './ProductsTable'
import { switcherData } from './utils'
import { ProductFilters } from './ProductFilters'
import { ProductTableHeader } from './ProductTableHeader'
import { Button } from '../../components/buttons/Button'
import { ContentPaper } from '../../components/ContentPaper'
import { useModalState } from '../../components/ModalWrapper'
import { HandleFilterInputChange } from '../../bundles/ShopAndDrinksPage/types'
import { SearchFieldRef } from '../../components/inputs/SearchField'
import { ProductModal } from './ProductModal'

type Props = {
  products: Product[];
  isLoading: boolean;
  selectedProductCategory: ProductCategory;
  setSelectedProductCategory: (electedProductCategory: ProductCategory) => void;
  selectedProductStatus: ProductStatus;
  setSelectedProductStatus: (selectedProductStatus: ProductStatus) => void;
  clearFilters: () => void;
  handleFilterChange: HandleFilterInputChange;
  searchField: SearchFieldRef;
  resetSearchString: () => void;
};

export const ProductsPageView: FC<Props> = ({
  products,
  isLoading,
  selectedProductCategory,
  setSelectedProductCategory,
  selectedProductStatus,
  setSelectedProductStatus,
  clearFilters,
  handleFilterChange,
  searchField,
  resetSearchString,
}) => {
  const {
    isModalOpen: dialogOpened,
    closeModal,
    openModal,
  } = useModalState(false)
  return (
    <PageLayoutWrapper>
      <ContentPaper>
        <Typography variant="h3">Products</Typography>
        <RowGrid>
          <BubbleSwitcher
            options={switcherData}
            active={selectedProductCategory}
            setActive={setSelectedProductCategory}
            isLoading={isLoading}
          />
          <ProductFilters
            searchField={searchField}
            isLoading={isLoading}
            handleFilterChange={handleFilterChange}
            selectedProductStatus={selectedProductStatus}
            setSelectedProductStatus={setSelectedProductStatus}
            resetSearchString={resetSearchString}
          />

          <StyledButton
            kind={'primary'}
            onClick={clearFilters}
          >
            Clear filters
          </StyledButton>
        </RowGrid>

        <ProductModal
          dialogOpened={dialogOpened}
          closeModal={closeModal}
          selectedProductStatus={selectedProductStatus}
        />

        <ProductTableHeader
          isLoading={isLoading}
          openModal={openModal}
        />

        <ProductsTable
          products={products}
          selectedProductCategory={selectedProductCategory}
          isLoading={isLoading}
          selectedProductStatus={selectedProductStatus}
        />
      </ContentPaper>
    </PageLayoutWrapper>
  )
}

const StyledButton = styled(Button)`
  width: 100%;

  ${atMinWidth.custom(1200)} {
    max-width: 250px;
    justify-self: end;
  }
`

export const RowGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 48px;
  ${atMinWidth.custom(1200)} {
    display: grid;
    align-items: flex-end;
    grid-template-rows: 48px;
    grid-row-gap: 24px;
    grid-template-columns: auto minmax(auto, 550px) auto;
    margin-bottom: 40px;

    > .MuiTextField-root {
      grid-column: span 2;
      grid-row: 2;
    }
    > .MuiTextField-root {
      grid-column: 2;
      grid-row: auto;
    }
  }
`
