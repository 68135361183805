import { FormikFormValues, SelectItem } from '../types'
import { ReportType } from '../bundles/TogglReportPage/types'
import React, { Fragment, ReactNode } from 'react'
import { FormikProps } from 'formik'

// TODO: replace usages with lodash
export const sortByKey = (array: any[], key: string) =>
  array.sort(function (a, b) {
    if (a[key] < b[key]) {
      return -1
    }
    if (a[key] > b[key]) {
      return 1
    }
    return 0
  })

export const sortByKeyLocalized = (array: any[], key: string) => {
  array.sort((a, b) =>
    a[key].localeCompare(b[key], 'cs', { ignorePunctuation: true })
  )
}

export const addLeadingZero = (number: number) =>
  number < 10 ? `0${number}` : number

type GetSelectItems = (
  array: any[] | undefined,
  labelKey: string,
  valueKey: string
) => SelectItem[];

export const getSelectItems: GetSelectItems = (array, labelKey, valueKey) =>
  array?.map((item) => ({
    label: item[labelKey],
    value: item[valueKey],
    important: item?.important ?? false
  })) || []

// TODO: understand usage or remove
export const generateSlug = (text: string): string => {
  return text
    .toLowerCase()
    .replace('&', 'and')
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}

export const formatDate = (date: Date): string => { // TODO: replace with date-fns
  return (
    date.getDate() + '. ' + (date.getMonth() + 1) + '. ' + date.getFullYear()
  )
}

export const formatTime = (date: Date): string => {
  return Intl.DateTimeFormat('cs-CZ', {
    hour: 'numeric',
    minute: 'numeric'
  }).format(date)
}

export const numberWithSpaces = (x: number): string => {
  const parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join('.')
}

export const getDateString = (monthsBefore: number) => {
  const today = new Date()
  let targetMonth = today.getMonth() - monthsBefore
  let year = today.getFullYear()
  if (targetMonth < 0) {
    year--
    targetMonth = 11
  }
  const month = addLeadingZero(targetMonth + 1)
  return `${year}/${month}`
}

export const buildLinkToMonthlyReport = (
  targetId: number,
  monthsBefore = 0,
  reportType: ReportType = ReportType.project
) => {
  const dateString = getDateString(monthsBefore)
  const targetString =
    reportType === ReportType.project ? 'project_id' : 'user_id'

  return `/admin/reports?${targetString}=${targetId}&month=${dateString}&report_type=${reportType}`
}

export const parseNewlines = (text: string): ReactNode => {
  return text.split('\n').map((line, index) => (
    <Fragment key={index}>
      {line}
      <br />
    </Fragment>
  ))
}

export const hasError = (formikProps: FormikProps<FormikFormValues>, attribute: string): boolean => {
  return (
    formikProps.touched[attribute] &&
    Boolean(formikProps.errors?.hasOwnProperty(attribute))
  )
}

export const helperText = (formikProps: FormikProps<FormikFormValues>, attribute: string): string => {
  return (
    formikProps.touched[attribute] && formikProps.errors?.hasOwnProperty(attribute) &&
    formikProps.errors[attribute]
  )
}

export const generateMonthsForAYear = () => {
  const now = new Date()
  const month = now.getMonth()
  const currentYear = now.getFullYear()

  const months = Array.from({ length: 12 }, (item, i) => {
    const actualMonth = month + i + 1
    const shouldChange = actualMonth > 12
    const year = shouldChange ? currentYear + 1 : currentYear
    const changedMonth = shouldChange ? actualMonth - 12 : actualMonth
    return `${year}/${changedMonth}`
  })

  return months.reverse()
}
