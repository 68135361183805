import React, { FC } from 'react'
import { Alert } from '@material-ui/lab'
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from '@material-ui/core'
import { Product, ProductCategory, ProductStatus } from './types'
import { TableProductRow } from './TableProductRow'
import { TableBodySkeleton } from '../../components/skeletons/TableBodySkeleton'
import styled from 'styled-components'
import { isFridgeOrMerchSelected, isFlavorOrAttributes } from './utils'

type Props = {
  products: Product[];
  selectedProductCategory: ProductCategory;
  isLoading: boolean;
  selectedProductStatus: ProductStatus;
};

export const ProductsTable: FC<Props> = ({
  products,
  selectedProductCategory,
  isLoading,
  selectedProductStatus,
}) => {
  const noProductsAvailable = !products || products.length === 0

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Cost</TableCell>
            {isFridgeOrMerchSelected(selectedProductCategory) && (
              <>
                <TableCell>
                  {isFlavorOrAttributes(selectedProductCategory)}
                </TableCell>
                <TableCell>Count</TableCell>
                <TableCell>Type</TableCell>
              </>
            )}
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableBodySkeleton
              rows={30}
              colspan={8}
            />
          )}
          {noProductsAvailable && (
            <StyledAlert
              variant={'outlined'}
              severity={'info'}
            >
              No products fulfill applied filters.
            </StyledAlert>
          )}
          {products.map((product) => (
            <TableProductRow
              key={product.id}
              product={product}
              selectedProductCategory={selectedProductCategory}
              selectedProductStatus={selectedProductStatus}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const StyledAlert = styled(Alert)`
  margin-top: 2rem;
`
