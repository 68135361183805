import React from 'react'
import { FC } from 'react'
import { colors } from '../../../styles/colors'
import { IconSVGProps } from '../Icon'

export const QuestionIcon: FC<IconSVGProps> = ({
  width = 24,
  height = 24,
  color = colors.grey1,
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.75 0.750018C10.8788 0.74732 9.04097 1.24543 7.42723 2.19266C5.81349 3.13989 4.48264 4.50172 3.5728 6.13683C2.66295 7.77194 2.20726 9.62074 2.25302 11.4914C2.29877 13.362 2.8443 15.1863 3.833 16.775L0.75 23.25L7.224 20.166C8.60467 21.0248 10.1659 21.5512 11.7847 21.7037C13.4035 21.8562 15.0356 21.6307 16.5524 21.045C18.0692 20.4592 19.4292 19.5292 20.5253 18.3282C21.6213 17.1272 22.4235 15.6881 22.8686 14.1243C23.3137 12.5604 23.3895 10.9146 23.0901 9.31641C22.7907 7.71826 22.1242 6.21148 21.1432 4.91482C20.1622 3.61816 18.8934 2.56704 17.4369 1.84431C15.9804 1.12157 14.376 0.74697 12.75 0.750018Z"
      stroke={`${color}`}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M12.75 15C12.6758 15 12.6033 15.022 12.5417 15.0632C12.48 15.1044 12.4319 15.163 12.4035 15.2315C12.3752 15.3 12.3677 15.3754 12.3822 15.4482C12.3967 15.5209 12.4324 15.5877 12.4848 15.6402C12.5373 15.6926 12.6041 15.7283 12.6768 15.7428C12.7496 15.7573 12.825 15.7498 12.8935 15.7215C12.962 15.6931 13.0206 15.645 13.0618 15.5833C13.103 15.5217 13.125 15.4492 13.125 15.375C13.125 15.2755 13.0855 15.1802 13.0152 15.1098C12.9448 15.0395 12.8495 15 12.75 15Z"
      stroke={`${color}`}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M9.75 9.75C9.75 9.15666 9.92595 8.57664 10.2556 8.08329C10.5852 7.58994 11.0538 7.20543 11.6019 6.97836C12.1501 6.7513 12.7533 6.69189 13.3353 6.80765C13.9172 6.9234 14.4518 7.20912 14.8713 7.62868C15.2909 8.04824 15.5766 8.58279 15.6924 9.16473C15.8081 9.74667 15.7487 10.3499 15.5216 10.8981C15.2946 11.4462 14.9101 11.9148 14.4167 12.2444C13.9234 12.5741 13.3433 12.75 12.75 12.75"
      stroke={`${color}`}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round" />
  </svg>

)
