import React, { FC, useState } from 'react'
import { Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { format, parseISO } from 'date-fns'
import { useQuery } from 'react-query'

import { FormikSubmitHandler } from '../../../../types'
import {
  Mutation,
  useCustomMutation,
} from '../../../../hooks/useCustomMutation'
import { CreateRateFormView } from './CreateRateFormView'
import { COMPETENCIES, Competency, Rate, RateError, RateFormValues } from '../types'
import { Project } from '../../../ProjectsPage/types'
import { getDefaultValueFromActiveRate } from '../utils'
import { User } from '../../../UsersPage/types'
import { StyledTitle } from '../styles'

type Props = {
  user: User;
  done?: () => void;
  activeRate?: Rate;
  handleError: (isError: boolean) => void;
  projects: Project[];
  refetchRates: () => void;
  isProjectTabActive?: boolean;
}

export const NewRateModal: FC<Props> = ({
  done,
  user,
  activeRate,
  handleError,
  projects,
  refetchRates,
  isProjectTabActive = false
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [endpointError, setEndpointError] = useState<RateError>()

  const { data: competencies, isLoading: loading } = useQuery<Competency[]>(
    `/api/users/${user.id}/rates/enums`
  )

  const defaultCompetencyValue = (competency: COMPETENCIES) => activeRate ? getDefaultValueFromActiveRate(activeRate, competency, competencies) : ''
  const initialValues = {
    confirmed_conflicts: false,
    request_type: 'global',
    valid_from: parseISO(format(new Date(), 'yyyy-MM-dd')),
    project_id:
      activeRate && 'projectId' in activeRate ? activeRate.projectId : '',
    primary_competency_rate_level: defaultCompetencyValue(COMPETENCIES.PRIMARY_COMPETENCY),
    primary_competency_rate_rate: '',
    primary_competency_rate_note: '',
    second_competency_bonus_level: defaultCompetencyValue(COMPETENCIES.SECOND_COMPETENCY),
    second_competency_bonus_rate: '',
    second_competency_bonus_note: '',
    team_leadership_bonus_level: defaultCompetencyValue(COMPETENCIES.TEAM_LEADERSHIP),
    team_leadership_bonus_rate: '',
    team_leadership_bonus_note: '',
    engineering_bonus_level: defaultCompetencyValue(COMPETENCIES.ENGINEERING_BONUS),
    engineering_bonus_note: '',
    tech_leadership_bonus_level: defaultCompetencyValue(COMPETENCIES.TECH_LEADERSHIP),
    tech_leadership_bonus_note: '',
  }

  const createRateMutation: Mutation<RateFormValues> = (
    rates: RateFormValues
  ) => ({
    path: `/api/users/${user.id}/rates`,
    method: 'PUT',
    params: rates,
  })

  const rateMutation = useCustomMutation<Response, any, RateFormValues>(
    createRateMutation,
    {
      onSuccess: async () => {
        done && done()
        refetchRates()
        enqueueSnackbar('Rate saved', {
          variant: 'success',
          preventDuplicate: true,
        })
      },
      onError: (error: RateError) => {
        if (error.overlapping_components) {
          setEndpointError(error)
          handleError(true)
        }
        setEndpointError(error)

        if (error.code === 'BAD_REQUEST') {
          enqueueSnackbar(error.message, {
            variant: 'error',
            title: 'Update error',
            preventDuplicate: true,
            persist: true,
          })
        }
      },
    },
    true
  )

  const handleSubmit: FormikSubmitHandler<RateFormValues> = async (
    values,
    { setErrors, setSubmitting }
  ) => {
    const preparedValues = {
      confirmed_conflicts: values.confirmed_conflicts,
      request_type: values.project_id ? 'project' : 'global',
      rates_user_rate_update_request: {
        ...values,
        project_id: values.project_id ? values.project_id : null,
        valid_from: format(new Date(values.valid_from), 'yyyy-MM-dd')
      },
    }

    rateMutation.mutateAsync(preparedValues).catch((err) => {
      setErrors(err.errors)
    })

    setSubmitting(false)
  }

  return (
    <>
      <StyledTitle variant="h3">Set Rate</StyledTitle>
      {competencies && (
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {(formikProps) => {
            return (
              <>
                {!loading && (
                  <CreateRateFormView
                    formikProps={formikProps}
                    activeRate={activeRate}
                    competencies={competencies}
                    projects={projects}
                    error={endpointError}
                    isProjectTabActive={isProjectTabActive}
                    user={user}
                  />
                )}
              </>
            )
          }}
        </Formik>
      )}
    </>
  )
}
