export const unifyEnums = (enums: any) => {
  enums.categories = Array.from(new Set(enums.categories))
  enums.categories = enums.categories.map((category, index) => {
    return {
      value: category,
      label: category,
    }
  })
  enums.categories.push({
    value: null,
    label: 'No category',
  })

  enums.billingTypes = enums.billingTypes.map((billingType) => ({
    value: billingType.id,
    label: billingType.label,
  }))

  enums.billingTypes.push({
    value: null,
    label: 'No billing type',
  })

  enums.tribes = enums.tribes.map((tribe) => ({
    value: tribe.id,
    label: tribe.name,
  }))

  enums.users = enums.users.map((user) => ({
    value: user.id,
    label: user.name,
  }))

  return enums
}

export const getErrorsString = (errors: any[]): string => {
  let ret = ''
  for (const key in errors) {
    ret += key + ' ' + errors[key]
  }
  return ret || 'Error'
}
