import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react'
import { Paper, Typography } from '@material-ui/core'
import { ProjectsTable } from '../../components/ProjectsTable/ProjectsTable'
import { StatusFilter } from '../../enum'
import { Metadata } from './types'
import { Button } from '../../components/buttons/Button'
import { SortedProject } from '../../components/ProjectsTable/utils'
import styled from 'styled-components'
import { TopLinearProgressBar } from '../../components/ui/TopLinearProgressBar'
import { useCurrentUserInfo } from '../../components/CurrentUserProvider'
import { atMaxWidth, atMinWidth } from '../../styles/helpers'
import {
  ProjectTableConfig,
  ProjectTableItem,
} from '../../components/ProjectsTable/types'
import { PageLayoutWrapper } from '../../components/ui/PageLayoutWrapper'
import { SelectItem } from '../../types'
import { StatusSwitcher } from './components/StatusSwitcher'
import { ProjectFilter } from './components/ProjectFilter'
import { AddNewProject } from './components/AddNewProject'
import {
  ModalWidth,
  ModalWrapper,
  useModalState,
} from '../../components/ModalWrapper'
import { colors } from '../../styles/colors'
import { PlusIcon } from '../../components/Icon/icons/Plus.icon'

type Props = {
  setSelectedProjectsStatus: Dispatch<SetStateAction<StatusFilter>>;
  selectedProjectsStatus: StatusFilter;
  handleFilterChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string
  ) => void;
  handleTribeChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string
  ) => void;
  projects?: SortedProject[];
  tribes: SelectItem[];
  metadata?: Metadata;
  searchString: string;
  owningTribe: number;
  error;
  isLoading: boolean;
  categories: SelectItem[];
  billingType: SelectItem[];
};

export const ProjectsPageView: FC<Props> = (props) => {
  const {
    setSelectedProjectsStatus,
    selectedProjectsStatus,
    handleFilterChange,
    handleTribeChange,
    projects,
    tribes,
    metadata,
    searchString,
    owningTribe,
    error,
    isLoading,
    categories,
    billingType,
  } = props

  const { userPermissions } = useCurrentUserInfo()
  const { openModal, isModalOpen, closeModal } = useModalState()
  const userCanManage =
    userPermissions?.project?.manage || userPermissions?.all?.manage
  const tableConfig: ProjectTableConfig = [
    { type: ProjectTableItem.Tribe },
    { type: ProjectTableItem.TLActions, align: 'right' },
  ]

  return (
    <>
      <PageLayoutWrapper>
        <StyledPaper elevation={0}>
          {isLoading && <TopLinearProgressBar />}
          <Typography variant="h3">Projects</Typography>
          <RowFlex>
            <StatusSwitcher
              selectedProjectsStatus={selectedProjectsStatus}
              setSelectedProjectsStatus={setSelectedProjectsStatus}
              isLoading={isLoading}
              metadata={metadata}
              showOpportunity
            />
            <Wrapper>
              <ProjectFilter
                tribes={tribes}
                owningTribe={owningTribe}
                handleTribeChange={handleTribeChange}
                handleFilterChange={handleFilterChange}
              />
              {userCanManage && (
                <LinkToNewProject
                  kind="basicPrimary"
                  onClick={openModal}
                >
                  <PlusIcon color={colors.white} />{' '}
                  <ButtonText>New project</ButtonText>
                </LinkToNewProject>
              )}
            </Wrapper>
          </RowFlex>
          {/*TODO: add better error handling*/}
          {error && <div>{error}</div>}
          <ProjectsTable
            tableConfig={tableConfig}
            projects={projects}
            isLoading={isLoading}
            searchString={searchString}
          />
        </StyledPaper>
      </PageLayoutWrapper>
      <ModalWrapper
        isOpen={isModalOpen}
        closeModal={closeModal}
        width={ModalWidth.SMALL}
        padding={40}
      >
        <AddNewProject
          closeModal={closeModal}
          tribes={tribes}
          categories={categories}
          billingType={billingType}
        />
      </ModalWrapper>
    </>
  )
}

export const RowFlex = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  margin-bottom: 24px;
  gap: 20px;
  flex-wrap: nowrap;

  ${atMaxWidth.desktop} {
    flex-wrap: wrap;
  }

  ${atMinWidth.tablet} {
    align-items: start;
  }

  ${atMinWidth.custom(1540)} {
    display: grid;
    align-items: flex-end;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr auto;
    grid-column-gap: 32px;

    > .MuiTextField-root {
      grid-column: 2;
      grid-row: auto;
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column-reverse;
  gap: 20px;
  width: 100%;

  ${atMinWidth.desktopLarge} {
    flex-direction: row;
    justify-content: space-between;

  }
`

const StyledPaper = styled(Paper)`
  min-height: 90vh;
  position: relative;
  padding: 16px;

  ${atMinWidth.tablet} {
    padding: 48px 40px;
  }

  ${atMinWidth.desktop} {
    padding: 48px 64px;
  }
`

const LinkToNewProject = styled(Button)`
  justify-self: end;
  align-self: flex-end;
  width: fit-content;
  padding: 16px;
`

const ButtonText = styled.span`
  margin-left: 10px;
`
