import React, { FC } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Box, Divider } from '@material-ui/core'
import { Address } from '../types'
import {
  UpdatableGroupContainer,
  UpdatableGroupHeading,
} from '../../../components/ui/UpdatableFormField/UpdatableItemsGroup'
import { Alert } from '@material-ui/lab'
import styled from 'styled-components'
import { AddNewAddress } from './AddNewAddress'
import { AddressTable } from './AddressTable'
import { SelectItem } from '../../../types'
import { Mutation, useCustomMutation } from '../../../hooks/useCustomMutation'
import { useQueryNotification } from '../utils'
import { canUpdateUser } from './Tabs/UserInfoTab'

type Props = {
  userId: number;
};

type queryResult = {
  addresses: Address[];
  enums: {
    addressTypes: SelectItem[];
    countries: SelectItem[];
  };
};

export const Addresses: FC<Props> = ({ userId }) => {
  const queryKey = `/api/users/${userId}/addresses`
  const { data, isLoading } = useQuery<queryResult>(queryKey)
  const queryClient = useQueryClient()
  const { successNotification, errorNotification } = useQueryNotification()

  const createDeleteAddressMutation: Mutation<number> = (addressId) => ({
    path: queryKey + '/' + addressId,
    method: 'DELETE',
  })

  const deleteSubjectMutation = useCustomMutation<Response, Error, number>(
    createDeleteAddressMutation,
    {
      onSuccess: async (data, variables) => {
        await queryClient.refetchQueries(queryKey)
        successNotification('Contact removed')
      },
      onError: (error) => {
        errorNotification(error.message)
      },
    },
    true
  )

  const deleteAddress = (addressId: number) => {
    deleteSubjectMutation.mutate(addressId)
  }
  return (
    <UpdatableGroupContainer>
      <Container>
        <UpdatableGroupHeading>Addresses</UpdatableGroupHeading>
        {canUpdateUser() && (
          <AddNewAddress
            userId={userId}
            enums={data?.enums}
          />
        )}
        {data?.addresses?.map((address, i) => (
          <React.Fragment key={i}>
            <AddressTable
              address={address}
              userId={userId}
              enums={data?.enums}
              deleteAddress={deleteAddress}
            />
            {data?.addresses && i !== data.addresses.length - 1 && <Divider />}
          </React.Fragment>
        ))}
        {data?.addresses?.length === 0 && (
          <Box mt={2}>
            <Alert
              variant={'outlined'}
              severity={'info'}
            >
              No addresses yet.
            </Alert>
          </Box>
        )}
      </Container>
    </UpdatableGroupContainer>
  )
}

const Container = styled.div`
  margin-top: 2em;
  @media (min-width: 1441px) {
    margin-top: 0;
  }
`
function enqueueSnackbar(arg0: string, arg1: { variant: string }) {
  throw new Error('Function not implemented.')
}
