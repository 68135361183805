import React, { FC, useState } from 'react'
import {
  Button,
  Dialog,
  FormLabel,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { useQueryClient } from 'react-query'
import { Formik } from 'formik'
import styled from 'styled-components'
import { addYears, subDays } from 'date-fns'

import { ContentPaper } from '../../../components/ContentPaper'
import { NewContract } from '../types'
import { FormikSubmitHandler, SelectItem } from '../../../types'
import { FormSelect } from '../../../components/inputs/FormSelect'
import { FormDatePicker } from '../../../components/inputs/FormDatePicker'
import { Mutation, useCustomMutation } from '../../../hooks/useCustomMutation'
import { useQueryNotification } from '../utils'
import { AddNewButton } from './Tabs/ContractsTab'
import { hasError, helperText } from '../../../utils/helpers'

type Props = {
  contractTypes: SelectItem[];
  subjects: SelectItem[];
  userTribes: SelectItem[];
  userId: number;
};
export const AddNewContract: FC<Props> = (props) => {
  const { contractTypes, subjects, userTribes, userId } = props
  const [dialogOpened, setDialogOpened] = useState(false)
  const { successNotification } = useQueryNotification()
  const queryClient = useQueryClient()

  const initSubject =
    subjects.length === 1 ? Number(subjects[0].value) : undefined
  const initTribe =
    userTribes.length === 1 ? Number(userTribes[0].value) : undefined

  const yesterday = (date: Date): Date => {
    const d = new Date(date)
    d.setDate(d.getDate() - 1)
    return d
  }

  const initValues: NewContract = {
    contractType: '',
    contractSubjectId: initSubject,
    userInTribeId: initTribe,
    startDate: new Date(),
    endDate: yesterday(new Date()),
    contractPosition: '',
    wagePersonalId: '',
  }

  const createNewContractMutation: Mutation<NewContract> = (newContract) => ({
    path: `/api/users/${userId}/contracts`,
    method: 'POST',
    params: newContract,
  })

  const userInTribeMutation = useCustomMutation<Response, any, NewContract>(
    createNewContractMutation,
    {
      onSuccess: async (data, variables) => {
        await queryClient.refetchQueries(`/api/users/${userId}/contracts`)
        setDialogOpened(false)
        successNotification('Contract created')
      },
    },
    true
  )

  const formSubmit: FormikSubmitHandler<NewContract> = (
    values,
    { setErrors }
  ) => {
    userInTribeMutation.mutateAsync(values).catch((err) => {
      setErrors(err.errors)
    })
  }

  const buttonDisabled = userTribes?.length === 0 || subjects.length === 0

  let tooltipText = ''
  if (userTribes?.length === 0)
    tooltipText = 'First, you need to assign tribe to user'
  else if (subjects?.length === 0)
    tooltipText = 'First, you need to add subject'

  return (
    <>
      <Dialog
        open={dialogOpened}
        onClose={() => setDialogOpened(false)}
      >
        <ContentPaper>
          <Typography variant={'h3'}>New contract</Typography>
          <Formik
            initialValues={initValues}
            onSubmit={formSubmit}
          >
            {(formikProps) => {
              return (
                <DialogForm>
                  <FormSelect
                    required
                    values={contractTypes || []}
                    name={'contractType'}
                    label={'Contract type'}
                    value={formikProps.values.contractType}
                    error={hasError(formikProps, 'contractType')}
                    helperText={helperText(formikProps, 'contractType')}
                  />
                  <FormSelect
                    required
                    values={subjects || []}
                    name={'contractSubjectId'}
                    label={'Subject'}
                    value={formikProps.values.contractSubjectId}
                    error={hasError(formikProps, 'contractSubjectId')}
                    helperText={helperText(formikProps, 'contractSubjectId')}
                  />
                  <FormSelect
                    required
                    values={userTribes}
                    name={'userInTribeId'}
                    label={'Tribe membership'}
                    value={formikProps.values.userInTribeId}
                    error={hasError(formikProps, 'userInTribeId')}
                    helperText={helperText(formikProps, 'userInTribeId')}
                  />
                  <FormLabel>
                    <FormDatePicker
                      fullWidth
                      label={'Start date'}
                      name={'startDate'}
                      onChange={(date) => {
                        formikProps.setFieldValue('startDate', date)
                        return formikProps.setFieldValue('endDate', addYears(subDays(date, 1), 1))
                      }}
                      value={formikProps.values.startDate}
                      helperText={helperText(formikProps, 'startDate')}
                    />
                  </FormLabel>
                  <FormLabel>
                    <FormDatePicker
                      fullWidth
                      label={'End date'}
                      name={'endDate'}
                      onChange={(date) =>
                        formikProps.setFieldValue('endDate', date)
                      }
                      value={formikProps.values.endDate}
                      helperText={helperText(formikProps, 'endDate')}
                    />
                  </FormLabel>
                  <FormLabel>
                    <TextField
                      fullWidth
                      value={formikProps.values.contractPosition}
                      name={'contractPosition'}
                      label={'Contract position'}
                      onChange={formikProps.handleChange}
                      error={hasError(formikProps, 'contractPosition')}
                      helperText={helperText(formikProps, 'contractPosition')}
                    />
                  </FormLabel>
                  <FormLabel>
                    <TextField
                      fullWidth
                      value={formikProps.values.wagePersonalId}
                      name={'wagePersonalId'}
                      label={'Personal ID (wage)'}
                      onChange={formikProps.handleChange}
                      error={hasError(formikProps, 'wagePersonalId')}
                      helperText={helperText(formikProps, 'wagePersonalId')}
                    />
                  </FormLabel>
                  <Button
                    fullWidth
                    variant={'contained'}
                    color={'primary'}
                    onClick={formikProps.submitForm}
                  >
                    Create
                  </Button>
                </DialogForm>
              )
            }}
          </Formik>
        </ContentPaper>
      </Dialog>
      <Tooltip title={tooltipText}>
        <span>
          <AddNewButton
            variant={'outlined'}
            color={'primary'}
            size={'small'}
            disabled={buttonDisabled}
            onClick={() => setDialogOpened(true)}
          >
            New contract
          </AddNewButton>
        </span>
      </Tooltip>
    </>
  )
}

export const DialogForm = styled.form`
  > * {
    margin: 10px 0;
    width: 100%;
  }
`
