import React, { Dispatch, FC, memo, SetStateAction } from 'react'
import { StatusFilter } from '../../../enum'
import { Metadata } from '../types'
import { SelectItem } from '../../../types'
import { BubbleSwitcher } from '../../../components/BubbleSwitcher'

type Props = {
  setSelectedProjectsStatus: Dispatch<SetStateAction<StatusFilter>>;
  selectedProjectsStatus: StatusFilter;
  metadata?: Metadata;
  isLoading: boolean;
  showOpportunity?: boolean;
};

export const StatusSwitcher: FC<Props> = memo((props) => {
  const {
    setSelectedProjectsStatus,
    selectedProjectsStatus,
    metadata,
    isLoading,
    showOpportunity = false
  } = props

  const opportunityPill = [{
    value:  StatusFilter.Opportunity,
    label: `Opportunity (${metadata?.count.opportunity})`,
  }]

  const options: SelectItem[] = [
    {
      value: StatusFilter.Active,
      label: `Active (${metadata?.count.active})`,
    },
    ...(showOpportunity ? opportunityPill : []),
    {
      value: StatusFilter.Inactive,
      label: `Inactive (${metadata?.count.inactive})`,
    },
  ]

  const setActive = (value: string) => {
    const projectsStatuses = Object.entries(StatusFilter)
    const projectStatus = projectsStatuses.find(
      (status) => value === status[1]
    )

    setSelectedProjectsStatus(StatusFilter[projectStatus![0]])
  }
  return (
    <BubbleSwitcher
      options={options}
      active={selectedProjectsStatus}
      setActive={setActive}
      isLoading={isLoading}
    />
  )
})
