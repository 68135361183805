import styled from 'styled-components'
import { FormControlLabel, Table } from '@material-ui/core'

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr 3fr;
  grid-template-rows: 68px;
  grid-gap: 16px;
  margin-bottom: 24px;

  > div:nth-child(3) {
    //targeting div wrapping export button
    justify-self: end;
    align-self: end;
  }
`

const StyledTable = styled(Table)`
  margin: 12px 0;
`

export const ShowDescriptionFormControlLabel = styled(FormControlLabel)`
  padding-top: 20px;
  .MuiFormControlLabel-label {
    margin-left: 8px;
  }
`

export { StyledContainer, StyledTable }
