import React, { FC } from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import { MoneyFormatter, dateFormatter } from '../../utils/formatters'
import { Purchase } from './types'

type Props = {
  purchase: Purchase;
};

export const TablePurchaseRow: FC<Props> = ({ purchase }) => {
  const { datetime, createdBy, cost, productGroup } = purchase
  const parsedDate = dateFormatter(datetime)

  const costFormatted = new MoneyFormatter('CZK').format(cost)

  return (
    <TableRow>
      <TableCell>{parsedDate}</TableCell>
      <TableCell>{productGroup}</TableCell>
      <TableCell>{createdBy ? createdBy.fullName : '-'}</TableCell>
      <TableCell>{costFormatted}</TableCell>
    </TableRow>
  )
}
