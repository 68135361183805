import React from 'react'
import { InputAdornment, InputLabel, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

type SearchFieldProps = {
  value: string;
  onChange: any;
};

export const SearchField = ({ value, onChange }: SearchFieldProps) => {
  return (
    <div>
      <InputLabel>Search description</InputLabel>
      <TextField
        fullWidth
        variant="outlined"
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  )
}
