/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import ReactOnRails from 'react-on-rails'

import '../vendor/tippy'

import { withProviders } from '../utils/context'
import { TogglReportPage } from '../bundles/TogglReportPage/TogglReportPage'
import { AssignUserToProject } from '../bundles/AssignUserToProjectModal/AssignUserToProject'
import { ExportToCSVModal } from '../bundles/ExportToCSVModal/ExportToCSVModal'
import { ProjectsPage } from '../bundles/ProjectsPage'
import { ProjectSectionOnDashBoard } from '../bundles/ProjectSectionOnDashBoard'
import { ProjectsOnUserDetails } from '../bundles/ProjectsOnUserDetails'
import { ProjectInfo } from '../bundles/ProjectInfo'
import { Navigation } from '../bundles/Navigation'
import { ShopAndDrinksPage } from '../bundles/ShopAndDrinksPage'
import { UsersPage } from '../bundles/UsersPage'
import { UserInfo } from '../bundles/UserInfo'
import { Dashboard } from '../bundles/Dashboard'
import { LinkToProjectReport } from '../components/buttons/LinkToUserReport'
import { ProjectDetailPage } from '../bundles/ProjectDetailPage/ProjectDetailPage'
import { PaymentsPage } from '../bundles/PaymentsPage'
import { SignInPage } from '../bundles/SignInPage/SignInPage'
import { TestingDevicesPage } from '../bundles/TestingDevicesPage'
import { TestingDeviceDetailPage } from '../bundles/TestingDeviceDetailPage'
import { CheckoutDevice } from '../bundles/TestingDeviceDetailPage/CheckoutDevice'
import { ProductsPage } from '../bundles/ProductsPage'
import { PaymentDetailPage } from '../bundles/PaymentDetailsPage'
import { UserDetailPage } from '../bundles/UserDetailPage/index'
import { MyTransactionPage } from '../bundles/MyTransactionPage'
import { PaymentCardUsagePage } from '../bundles/PaymentCardUsagePage/PaymentCardUsagePage'
import { Rates } from '../bundles/UserDetailPage/rates/Rates'
import { BudgetsPage } from '../bundles/ProjectsPage/BudgetsPage/BudgetsPage'

// add components to register here
const components = [
  TogglReportPage,
  AssignUserToProject,
  ExportToCSVModal,
  ProjectsPage,
  ProjectSectionOnDashBoard,
  ProjectsOnUserDetails,
  ProjectInfo,
  ProjectsOnUserDetails,
  Navigation,
  ShopAndDrinksPage,
  UsersPage,
  UserInfo,
  Dashboard,
  LinkToProjectReport,
  ProjectDetailPage,
  PaymentsPage,
  PaymentDetailPage,
  SignInPage,
  ProjectDetailPage,
  TestingDevicesPage,
  TestingDeviceDetailPage,
  CheckoutDevice,
  ProductsPage,
  UserDetailPage,
  MyTransactionPage,
  PaymentCardUsagePage,
  Rates,
  BudgetsPage,
]

const getWrappedComponents = () => {
  const componentsReducer = (acc, currentComponent) => ({
    ...acc,
    [currentComponent.name]: (props, railsContext) =>
      withProviders(props, railsContext, currentComponent),
  })

  return components.reduce(componentsReducer, {})
}

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  ...getWrappedComponents(),
})
