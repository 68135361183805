import React, { FC, useMemo, MouseEvent } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { Icon } from '../Icon'
import { ProjectReportIcon } from '../Icon/icons/ProjectReport.icon'
import { buildLinkToMonthlyReport } from '../../utils/helpers'
import { ReportType } from '../../bundles/TogglReportPage/types'

type Props = {
  userId: number;
  type?: 'button' | 'icon';
};

export const LinkToProjectReport: FC<Props> = ({ userId }) => {
  const monthlyReportLink = useMemo(
    () => buildLinkToMonthlyReport(userId, 0, ReportType.user),
    [userId]
  )

  const handleRouteToReport = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    return window.location.assign(monthlyReportLink)
  }

  return (
    <Tooltip title="User report page">
      <IconButton
        color="primary"
        size="small"
        onClick={handleRouteToReport}
      >
        <Icon Component={ProjectReportIcon} />
      </IconButton>
    </Tooltip>
  )
}
