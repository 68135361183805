import React, { FC, useMemo, useState } from 'react'
import { BubbleButton } from '../../../components/buttons/BubbleButton'
import { MainNumber } from './MainNumber'
import { getImageByKey } from '../../../utils/imageGetter'
import { Box, Button } from '@material-ui/core'
import { PaperHeading, PaperTopSection, WorkImage } from '../index'
import { InvoiceStats, UserStats } from '../types'
import styled from 'styled-components'
import { theme } from '../../../styles/muiThemes'
import {
  buildLinkToMonthlyReport,
  getDateString,
} from '../../../utils/helpers'
import { ReportType } from '../../TogglReportPage/types'
import { useCurrentUserInfo } from '../../../components/CurrentUserProvider'
import { InvoiceDetail } from './InvoiceDetail'

type MonthStateType = 'currentMonth' | 'previousMonth';
type Props = {
  userStats: UserStats;
  invoiceInfo: InvoiceStats;
};
export const WorkPaperContent: FC<Props> = (props) => {
  const { userStats, invoiceInfo } = props

  const [selectedMonth, setSelectedMonth] =
    useState<MonthStateType>('currentMonth')
  const currentUser = useCurrentUserInfo()
  const currentUserId = currentUser.currentUser.id

  const getVariant = (type: string): 'contained' | 'outlined' => {
    return selectedMonth === type ? 'contained' : 'outlined'
  }

  const monthsBefore = selectedMonth === 'currentMonth' ? 0 : 1
  const monthlyReportLink = useMemo(
    () =>
      buildLinkToMonthlyReport(currentUserId, monthsBefore, ReportType.user),
    [selectedMonth]
  )
  const currentMonthInvoiceDetails = useMemo(
    () => invoiceInfo[selectedMonth],
    [selectedMonth]
  )
  const currentStats = useMemo(() => userStats[selectedMonth], [selectedMonth])
  const hoursToggldText = `Toggl hours in ${getDateString(monthsBefore)}`
  const moneyInvoiceText = `To invoice in ${getDateString(monthsBefore)}`

  return (
    <>
      <PaperHeading>My work</PaperHeading>
      <PaperTopSection>
        <StyledBubbleButton
          variant={getVariant('currentMonth')}
          onClick={() => setSelectedMonth('currentMonth')}
          color={'primary'}
        >
          This month
        </StyledBubbleButton>
        <StyledBubbleButton
          variant={getVariant('previousMonth')}
          onClick={() => setSelectedMonth('previousMonth')}
          color={'primary'}
        >
          Last month
        </StyledBubbleButton>
      </PaperTopSection>

      <MainNumber
        number={currentStats.hours}
        text={hoursToggldText}
      />
      <MainNumber
        number={currentStats.money}
        text={moneyInvoiceText}
        afterNumberText={currentStats.currency}
      />

      <WorkImage src={getImageByKey('work')} />

      <ButtonBox>
        <InvoiceDetail invoiceInfo={currentMonthInvoiceDetails} />
        <Button href={monthlyReportLink}>Monthly report</Button>
      </ButtonBox>
    </>
  )
}

const StyledBubbleButton = styled(BubbleButton)`
  margin-right: 1em;
  ${theme.breakpoints.only('md')} {
    margin-bottom: 1em;
  }
`

const ButtonBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  text-align: center;
`
