import React, { FC, memo, useMemo } from 'react'
import { SearchField } from '../../../../components/inputs/SearchField'
import { Box } from '@material-ui/core'

type Props = {
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
};
export const ProductsSearchField: FC<Props> = memo(({ onChange }) => {
  return useMemo(
    () => (
      <Box
        mb={4}
        display={'flex'}
        alignItems={'center'}
      >
        <SearchField
          fullWidth
          onChange={onChange}
        />
      </Box>
    ),
    []
  )
})
