import React, { FC, useMemo } from 'react'
import { useQuery } from 'react-query'
import { ShopProductsData } from './types'
import { getProductCategoriesFromInitData, productsQueryString } from './utils'
import { ConfigTypes, ShopConfig } from './ShopConfig'
import { ShopContextProvider } from './ShopContext'
import styled from 'styled-components'
import { theme } from '../../styles/muiThemes'
import { ContentPaper } from '../../components/ContentPaper'
import { Box, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { ProductsList } from './components/ProductList/ProductsList'
import { ProductListSkeleton } from './components/ProductList/ProductListSkeleton'
import { Cart } from './components/cart/Cart'

type Props = {
  productCategory: string;
  shopTitle: string;
};

export const ShopAndDrinksPage: FC<Props> = ({
  productCategory,
  shopTitle,
}) => {
  const config: ShopConfig = useMemo(
    () => ConfigTypes[productCategory],
    [productCategory]
  )

  //FIXME: error handling
  const { data, isLoading, error } = useQuery<ShopProductsData>(
    productsQueryString(productCategory)
  )

  const categories = useMemo(
    () => getProductCategoriesFromInitData(data),
    [data]
  )
  return (
    <StyledContainer>
      <ContentPaper>
        <Typography variant="h3">{shopTitle}</Typography>

        {error && (
          <Box mt={4}>
            <Alert severity={'error'}>Error, while loading products.</Alert>
          </Box>
        )}

        {!isLoading && (
          <ShopContextProvider
            data={data!}
            config={config}
          >
            <ProductsList categories={categories} />
            <Cart productCategory={productCategory} />
          </ShopContextProvider>
        )}
        {isLoading && <ProductListSkeleton />}
      </ContentPaper>
    </StyledContainer>
  )
}

export const StyledContainer = styled.div`
  margin-right: 3rem;
  > * {
    flex: 1;
  }
  ${theme.breakpoints.down('md')} {
    margin-right: 0;
  }
`
