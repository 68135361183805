import { Box, useMediaQuery } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { FC } from 'react'

import { theme } from '../../../../styles/muiThemes'
import { StyledDialog, StyledDialogActions, StyledDialogContent, StyledDialogTitle, StyledIconButton, StyledTitle } from './QRCodeDialog.styles'
import { QRCodeDialogContent } from './QRCodeDialogContent'

type Props = {
  dialogOpened: boolean;
  setDialogOpened: (dialogOpened: boolean) => void;
};

export const QRCodeDialog: FC<Props> = ({
  dialogOpened,
  setDialogOpened,
}) => {
  const isGtSm = useMediaQuery(theme.breakpoints.up('sm'))
  const closeDialog = () => setDialogOpened(false)

  return (
    <StyledDialog
      open={dialogOpened}
      onClose={closeDialog}
      fullWidth
      maxWidth="md"
      fullScreen={!isGtSm}
      scroll="paper"
    >
      <StyledDialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <StyledTitle>Payment instructions</StyledTitle>
          <StyledDialogActions>
            <StyledIconButton onClick={closeDialog}>
              <Close />
            </StyledIconButton>
          </StyledDialogActions>
        </Box>
      </StyledDialogTitle>
      <StyledDialogContent>
        <QRCodeDialogContent dialogOpened={dialogOpened} />
      </StyledDialogContent>
    </StyledDialog>
  )
}
