import React, { FC } from 'react'
import { Grid } from '@material-ui/core'
import { Skeleton, SkeletonProps } from '@material-ui/lab'
import { ButtonSkeleton } from '../../../components/skeletons/ButtonSkeleton'
import styled from 'styled-components'

type Props = {};
export const NewTransactionSkeleton: FC<Props> = () => {
  const usersSkeletonProps: SkeletonProps = {
    width: '94%',
    height: 80,
  }

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        xl={6}>
        <Skeleton {...usersSkeletonProps} />
        <Skeleton {...usersSkeletonProps} />
        <Skeleton {...usersSkeletonProps} />
      </Grid>
      <Grid
        item
        xs={12}
        xl={6}>
        <Skeleton {...usersSkeletonProps} />
        <Skeleton {...usersSkeletonProps} />
        <Skeleton {...usersSkeletonProps} />
      </Grid>
      <ButtonContainer>
        <ButtonSkeleton />
        <ButtonSkeleton />
      </ButtonContainer>
    </Grid>
  )
}

const ButtonContainer = styled.div`
  width: 97%;
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
`
