import React, { useContext, useState, createContext, useMemo } from 'react'
import { useQuery } from 'react-query'
import { MyTransactionContextType, Transaction, TransactionFilters } from './types'
import { useCurrentUserInfo, useUserPermissions } from '../../../components/CurrentUserProvider'
import { usePaginatedQuery } from '../../../hooks/usePaginatedQuery'
import { initialState } from '../utils'
import { BaseUser } from '../../../types'

const MyTransactionContext = createContext<MyTransactionContextType>(
  {} as MyTransactionContextType
)

export const MyTransactionContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { resolvePermissions } = useUserPermissions()
  const { currentUser } = useCurrentUserInfo()
  const isAllTransactions = '/admin/transactions/all' === window.location.pathname
  const queryParameters = new URLSearchParams(window.location.search)
  const userId = queryParameters.get('user_id')
  const paymentDirection = queryParameters.get('payment_direction')
  const initialStateCurrentUser = { ...initialState, user_id: userId ?? currentUser.id, ...(paymentDirection && { direction: paymentDirection }) }
  const [filters, setFilters] = useState<TransactionFilters>(isAllTransactions && !userId ? initialState : initialStateCurrentUser)

  const isAmountFromBigger =
    Number(filters.amount_from) > Number(filters.amount_to) &&
    Number(filters.amount_to) != 0 &&
    Number(filters.amount_from) != 0

  const { data: usersData, isLoading: isUsersLoading } = useQuery<{ users: BaseUser[] }>('/api/users')

  const users = usersData?.users

  const urlParamsPlus = {
    amount_from: filters.amount_from.toString(),
    amount_to: filters.amount_to.toString(),
    date_from: filters.date_from,
    date_to: filters.date_to,
    per_page: filters.per_page,
    scope: filters.scope === 'all' ? '' : filters.scope,
    direction: filters.direction === 'all' ? '' : filters.direction,
    user_id: filters.user_id?.toString() || '',
  }

  Object.keys(urlParamsPlus).forEach((key) => {
    if (urlParamsPlus[key] == null || urlParamsPlus[key].length === 0) {
      delete urlParamsPlus[key]
    }
  })

  const paramsPlus = new URLSearchParams(urlParamsPlus)

  const { data, pagination, loadPage, isLoading, isError, refetch } = usePaginatedQuery<Transaction[]>('/api/transactions', paramsPlus)

  const isNotDataAvailable = data == null || data.length === 0

  const filterTransactions = (filters: TransactionFilters) => {
    // TODO: check if the FIXME is still relevant :)
    // FIXME double calling of paginated query, fix paginated query hook
    loadPage(1)
    setFilters(filters)
  }

  const showActionColumn = useMemo(() => {
    const userCanRevert = resolvePermissions('accountsTransaction', 'manage')

    if (!userCanRevert) {
      return false
    }

    const isSomeRowRevertable = !!data?.some(({ transactableType }) => transactableType === 'purchase')

    return isSomeRowRevertable
  }, [resolvePermissions])

  return (
    <MyTransactionContext.Provider
      value={{
        children,
        isAmountFromBigger,
        data,
        users,
        isNotDataAvailable,
        pagination,
        loadPage,
        isLoading,
        isError,
        isUsersLoading,
        isAllTransactions,
        filterTransactions,
        refetch,
        showActionColumn
      }}
    >
      {children}
    </MyTransactionContext.Provider>
  )
}

export const useTransactionContext = (): MyTransactionContextType => {
  return useContext(MyTransactionContext)
}
