import styled from 'styled-components'
import { Paper } from '@material-ui/core'
import { atMinWidth } from '../../styles/helpers'
import React from 'react'

export const ProjectsTablePaper = styled((props) => (
  <Paper
    {...props}
    elevation={0}
  />
))`
  padding: 16px;
  height: max-content;

  ${atMinWidth.tablet} {
    padding: 48px 3rem;
  }

  ${atMinWidth.desktop} {
    padding: 48px 3rem;
  }
`
