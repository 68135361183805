import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { Alert } from '@material-ui/lab'
import { Divider } from '@material-ui/core'
import styled from 'styled-components'
import { EmployeeSubjectTable } from './Tables/EmployeeSubjectTable'
import { ContractorSubjectTable } from './Tables/ContractorSubjectTable'
import { BaseSubject, CompanySubject, EmployeeSubject } from '../types'
import {
  UpdatableGroupContainer,
  UpdatableGroupHeading,
} from '../../../components/ui/UpdatableFormField/UpdatableItemsGroup'
import { useUserInfo } from './UserInfoProvider'
import { AddNewSubject } from './AddNewSubject'
import { canUpdateUser } from './Tabs/UserInfoTab'

type Props = {
  userId: number;
  subjects: BaseSubject[] | undefined;
  enums: any;
};

export const Subjects: FC<Props> = ({ subjects, enums, userId }) => {
  return (
    <UpdatableGroupContainer>
      <Container>
        <UpdatableGroupHeading>Subjects</UpdatableGroupHeading>
        {canUpdateUser() && (
          <AddNewSubject
            subjectTypes={enums.subjectTypes}
            userId={userId}
          />
        )}
        {subjects?.map((subject, i) => (
          <React.Fragment key={i}>
            {subject.subjectType === 'employee' ? (
              <EmployeeSubjectTable
                userId={userId}
                key={i}
                data={subject as EmployeeSubject}
                enums={enums}
              />
            ) : (
              <ContractorSubjectTable
                key={i}
                data={subject as CompanySubject}
                addresses={enums?.userAddresses || []}
              />
            )}
            {i !== subjects.length - 1 && <StyledDivider />}
          </React.Fragment>
        ))}
        {subjects?.length === 0 && (
          <Alert
            variant={'outlined'}
            severity={'info'}
          >
            No subjects yet
          </Alert>
        )}
      </Container>
    </UpdatableGroupContainer>
  )
}

const Container = styled.div`
  margin-top: 2em;
  @media (min-width: 1441px) {
    margin-top: 0;
  }
`

const StyledDivider = styled(Divider)`
  margin-bottom: 2em;
  @media (min-width: 1441px) {
    margin-bottom: 1em;
  }
`
