import React, { FC } from 'react'
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from '@material-ui/core'
import { TablePaymentRow } from './TablePaymentRow'
import { Payment } from './types'
import { TableBodySkeleton } from '../../components/skeletons/TableBodySkeleton'

type Props = {
  filteredPayments: Payment[] | undefined;
  isLoading: boolean;
};

export const PaymentsTable: FC<Props> = ({ filteredPayments, isLoading }) => {
  const areNoPayments = !filteredPayments || filteredPayments?.length === 0
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Sender</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Source</TableCell>
            <TableCell>Paired to</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableBodySkeleton
              rows={30}
              colspan={8}
            />
          )}
          {areNoPayments && (
            <TableRow>
              <TableCell>No payments fulfill applied filters.</TableCell>
            </TableRow>
          )}
          {filteredPayments?.map((payment) => (
            <TablePaymentRow
              key={payment.id}
              payment={payment}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
