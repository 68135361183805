import {useQuery} from 'react-query'
import {QueryFunction} from 'react-query/types/core/types'
import axios, {AxiosResponse} from 'axios'
import {UseQueryResult} from 'react-query/types/react/types'
import {useCallback, useMemo, useState} from 'react'
import {fi} from 'date-fns/locale'

export type PaginationParams = {
  count: number
  limit: number
  page: number
  records: number
}

const getPaginationFromHeaders = (headers: Record<string, string>|undefined): PaginationParams => {
  if(!headers) return {count: 0, limit: 0, records: 0, page: 0}
  return {
    count: Number(headers['pagination-count']),
    limit: Number(headers['pagination-limit']),
    page: Number(headers['pagination-page']),
    records: Number(headers['pagination-records'])
  }
}

export type PaginatedQueryResponse<T> = Pick<UseQueryResult<T>, 'isLoading' | 'data' | 'isError'> & {
  pagination: PaginationParams,
  loadPage: (pageToLoad: number) => void
  refetch: UseQueryResult<AxiosResponse<T | undefined>>['refetch']
}

export const usePaginatedQuery = <T>(queryKey: string, queryParams?: URLSearchParams, initialPage?: number,): PaginatedQueryResponse<T> => {

  const [page, setPage] = useState(initialPage ?? 1)


  const searchParams = useMemo(() => {
    const ret = queryParams ?? new URLSearchParams()
    ret.set('page', String(page))
    return ret
  }, [page, queryParams])

  const loadPaginatedFn: QueryFunction<AxiosResponse<T>> = async ({queryKey}) => {
    const response = await axios.get<T>(queryKey, {
      headers: {
        Accept: 'application/json'
      },
    })
    return response
  }
  const {isLoading, data, isError, refetch} = useQuery<AxiosResponse<T>>(queryKey + '?' + searchParams, loadPaginatedFn)

  const pagination = useMemo(() => getPaginationFromHeaders(data?.headers), [data])

  const loadPage = useCallback((pageToLoad: number) => {
    if(pageToLoad > pagination.count) throw Error('Page does not exist.')
    setPage(pageToLoad)
  }, [pagination])


  return {
    isError,
    isLoading,
    data: data?.data,
    pagination,
    loadPage,
    refetch
  }
}
