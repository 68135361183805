import React, { FC, Fragment } from 'react'
import styled from 'styled-components'
import { CopyButton } from '../../../../components/buttons/CopyButton'
import { atMinWidth } from '../../../../styles/helpers'

type Props = {
  bankDetails: {
    [key: string]: string;
  };
}

export const QRCodeDialogBankDetails: FC<Props> = ({ bankDetails }) => {
  return (
    <Container>
      {Object.entries(bankDetails).map(([key, value]) => (
        <Fragment key={key}>
          <Key>{key}</Key>
          <ValueWrapper>
            <Value>{value}</Value>
            <CopyButton
              text={value}
              color="black"
              width={20}
              height={20}
              strokeWidth="1"
              margin="-12px"
            />
          </ValueWrapper>
        </Fragment>
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  column-gap: 64px;
  row-gap: 8px;

  ${atMinWidth.tablet} {
    grid-template-columns: auto 1fr;
  }
`

const Key = styled.span`
  font-size: 16px;
  line-height: 1.65;
  color: #848484;
  margin-bottom: -8px;

  ${atMinWidth.tablet} {
    margin-bottom: 0;
    font-size: 18px;
  }
`

const Value = styled.span`
  font-size: 16px;
  line-height: 1.65;

  ${atMinWidth.tablet} {
    font-size: 18px;
  }
`

const ValueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`
