import { Typography, TextField, TableCell, TableRow, Checkbox } from '@material-ui/core'
import { FormLabel } from 'react-bootstrap'
import styled from 'styled-components'
import { CorpSelect } from '../../../components/inputs/CorpSelect'
import { theme } from '../../../styles/muiThemes'
import { colors } from '../../../styles/colors'

export const StyledTypography = styled(Typography)`
  margin-bottom: 0;
`

export const StyledFormLabel = styled(FormLabel)`
  text-transform: uppercase;
  font-size: 12px;
`

export const StyledCorpSelect = styled(CorpSelect)`
  border: none;
  width: 100px;

  margin-bottom: 32px;
`

export const StyledWrapper = styled.div`
  ${theme.breakpoints.up('lg')} {
    width: 434px;
}
`

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  margin-bottom: 40px;

  ${theme.breakpoints.down('lg')} {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  ${theme.breakpoints.down('lg')} {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
`

export const StyledTextField = styled(TextField)`
  border: 1px solid ${colors.grey0};
  padding-left: 12px;
  margin-bottom: 32px;

  div::before {
    content: none;
    border-bottom: 0;
  }
`

export const BorderedTableCell = styled(TableCell)`
  padding: 0;
  color: ${colors.grey0};

  &:not(:first-child) {
    text-align: center;
  }

  &:first-child {
    font-size: 12px;
  }

  form > div {
    min-width: auto;
  }

  .budget-input {
    padding-left: 10px;
    padding-right: 10px;

    input { 
      text-align: right;
    }
  }

  &.MuiTableCell-root:not(:first-of-type) {
      border-left: 1px solid rgba(224, 224, 224, 1);
    }
  }
`

export const NumbericTableCell = styled(BorderedTableCell)`
  text-align: right !important;
  padding-right: 25px;
`

export const StyledTableRow = styled(TableRow)`
  height: 43px;
`

export const IconTableCell = styled(TableCell)`
  width: 70px;
  padding: 0;
  text-align: center;
`

export const EditableTextField = styled(TextField)`
  .MuiInput-underline  {
    &:before, &:after {
      content: none;
    }
  }

  .MuiInputBase-input {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    color: rgb(132, 132, 132);
    text-align: center;
  }

  .Mui-focused {
    background: inherit;

    font-size: 14px;
    color: rgb(132, 132, 132);
    height: 30px;
    border: 1px solid #DDDCDB !important;
  }
`

export const StyledTableCell = styled(TableCell)`
  &:not(:first-child) {
    text-align: center;
    width: 100px;
  }

  padding: 0;
`

export const CheckboxWrapper = styled.div`
  align-items: center;
  justify-content: center;
`

export const IconWrapper = styled.span`
  text-align: center;
  svg {
    display: none;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  color: #848484;
  padding: 0;
  width: 12px;
  height: 12px;
  border-radius: none;
`

export const PointerTableRow = styled(TableRow)`
  &:hover {
    cursor: pointer;
  }

  &:hover span svg {
    display: inline-block;
  }
`