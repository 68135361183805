/* eslint-disable max-lines */
import React, { FC, useEffect, useState } from 'react'
import { Typography, TextField, Dialog, IconButton } from '@material-ui/core'
import { formatISO } from 'date-fns'
import styled from 'styled-components'
import { useQueryClient } from 'react-query'
import { Formik } from 'formik'
import { ContentPaper } from '../../components/ContentPaper'
import { Button } from '../../components/buttons/Button'
import { useCustomMutation, Mutation } from '../../hooks/useCustomMutation'
import { useQueryNotification } from '../UserInfo/utils'
import { FormikSubmitHandler } from '../../types'
import {
  TestingDevices,
  ErrorTestingDevice,
  NewTestingDevice,
} from '../TestingDevicesPage/types'
import { newDeviceFieldData } from './AddNewDeviceFieldData'
import CloseIcon from '@material-ui/icons/Close'
import { toLower } from 'lodash'
import { hasError, helperText } from '../../utils/helpers'

const initValues = {
  serial: '',
  brand: '',
  name: '',
  systemVersion: '',
  inventoryNumber: '',
  location: 'River',
  sublocation: '',
  inventoryUrl: '',
  createdAt: '',
  updatedAt: '',
  taken: false,
}

type Props = {
  dialogOpened: boolean;
  setDialogOpened: (dialogOpened: boolean) => void;
};

export const AddNewDevice: FC<Props> = ({ dialogOpened, setDialogOpened }) => {
  const queryClient = useQueryClient()
  const { successNotification, errorNotification } = useQueryNotification()
  const [newDeviceId, setNewDeviceId] = useState<number | null>(null)

  const createNewSubjectMutation: Mutation<TestingDevices> = (initVals) => ({
    path: '/api/testing_devices',
    method: 'POST',
    params: initVals,
  })

  const newSubjectMutation = useCustomMutation(
    createNewSubjectMutation,
    {
      onSuccess: async (data: Record<string, number | any>) => {
        const newDevice = await data.json()
        setNewDeviceId(newDevice.id)
        await queryClient.refetchQueries('/api/testing_devices')
        successNotification('New testing device created')
        if (newDeviceId) {
          setDialogOpened(false)
        }
      },
      onError: (error: ErrorTestingDevice) => {
        if (error.status != 422) {
          errorNotification(
            'Sorry, something went wrong. Please, try again later'
          )
        }
      },
    },
    true
  )

  useEffect(() => {
    if (newDeviceId) {
      setTimeout(() => {
        window.location.replace(`/admin/testing_devices/${newDeviceId}`)
      }, 700)
    }
  }, [newDeviceId])

  const formikSubmit: FormikSubmitHandler<NewTestingDevice> = async (values, { resetForm, setErrors }) => {
    const todaysDate = formatISO(new Date())

    const postValues = {
      ...values,
      location: values.location ? toLower(values.location) : values.location,
      createdAt: todaysDate,
      updatedAt: todaysDate,
    }

    await newSubjectMutation.mutateAsync(postValues).catch((err) => {
      setErrors(err.errors)
    })
  }

  // TODO add error handling

  return (
    <Dialog
      open={dialogOpened}
      onClose={() => setDialogOpened(false)}
    >
      <StyledCloseButton
        onClick={() => setDialogOpened(false)}
        aria-label="close"
      >
        <CloseIcon />
      </StyledCloseButton>
      <StyledModal>
        <Typography variant={'h3'}>New device</Typography>
        <Formik
          initialValues={initValues}
          onSubmit={formikSubmit}
        >
          {(formikProps) => (
            <>
              {newDeviceFieldData.map((field) => {
                const { require, placeholder, name, labelText, disabled, id } = field
                const value = formikProps.values[name]

                return (
                  <StyledTextField
                    key={id}
                    fullWidth
                    {...(require && { required: require })}
                    value={value}
                    name={name}
                    label={labelText}
                    placeholder={placeholder}
                    onChange={formikProps.handleChange}
                    error={hasError(formikProps, 'name')}
                    helperText={helperText(formikProps, 'name')}
                    disabled={disabled}
                  />
                )
              })}

              <ButtonWrapper>
                <Button
                  kind={'secondary'}
                  onClick={() => setDialogOpened(false)}
                >
                  Cancel
                </Button>
                <Button
                  kind={'primary'}
                  onClick={formikProps.submitForm}
                >
                  Create
                </Button>
              </ButtonWrapper>
            </>
          )}
        </Formik>
      </StyledModal>
    </Dialog>
  )
}

const StyledTextField = styled(TextField)`
  margin-bottom: 20px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const StyledModal = styled(ContentPaper)`
  margin: 10px 30px;
`

const StyledCloseButton = styled(IconButton)`
  position: absolute;
  right: 14px;
  top: 14px;
  z-index: 2;
`
