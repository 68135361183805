import React, { FC, useState } from 'react'
import { Button, Dialog, Typography } from '@material-ui/core'
import { FormikSubmitHandler, SelectItem } from '../../../types'
import { Formik } from 'formik'
import { FormSelect } from '../../../components/inputs/FormSelect'
import { Mutation, useCustomMutation } from '../../../hooks/useCustomMutation'
import { getErrorsString } from '../../ProjectInfo/utils'
import { useSnackbar } from 'notistack'
import { ContentPaper } from '../../../components/ContentPaper'
import { useQueryClient } from 'react-query'
import { DialogForm } from './AddNewContract'
import { Simulate } from 'react-dom/test-utils'

export type UserInTribe = {
  userId: number;
  tribeId: number;
};

type Props = {
  userId: number;
  tribes: SelectItem[];
};
export const AddNewTribeButton: FC<Props> = ({ userId, tribes }) => {
  const [dialogOpened, setDialogOpened] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  type FormikInitValues = {
    tribe: number;
  };
  const initValues: FormikInitValues = {
    tribe: Number(tribes[0].value),
  }

  const createUserInTribeMutation: Mutation<UserInTribe> = (userInTribe) => ({
    path: `/api/users/${userId}/tribes`,
    method: 'POST',
    params: userInTribe,
  })

  const userInTribeMutation = useCustomMutation<Response, any, UserInTribe>(
    createUserInTribeMutation,
    {
      onSuccess: async (data, variables) => {
        await queryClient.refetchQueries(`/api/users/${userId}/tribes`)
        setDialogOpened(false)
        enqueueSnackbar('Updated', {
          variant: 'success',
          preventDuplicate: true,
        })
      },
      onError: (err, variables, context) => {
        //FIXME: error handling
        enqueueSnackbar(getErrorsString(err.errors), {
          variant: 'error',
          title: 'Update error',
          preventDuplicate: true,
          persist: false,
        })
      },
    },
    true
  )

  const handleSubmit: FormikSubmitHandler<FormikInitValues> = async (
    values,
    { setErrors, validateField }
  ) => {
    userInTribeMutation
      .mutateAsync({
        userId: userId,
        tribeId: values.tribe,
      })
      .catch((err) => {
        setErrors(err.errors)
      })
  }
  return (
    <>
      <Dialog
        open={dialogOpened}
        onClose={() => setDialogOpened(false)}
      >
        <ContentPaper>
          <Typography variant={'h3'}>Nový tribe</Typography>
          <p>Vyberte tribe, ke kterému se chcete přidat:</p>
          <Formik
            initialValues={initValues}
            onSubmit={handleSubmit}
          >
            {(formikProps) => (
              <DialogForm>
                <FormSelect
                  name={'tribe'}
                  label={'tribe'}
                  required
                  values={tribes}
                  value={formikProps.values.tribe}
                  onChange={formikProps.handleChange}
                />
                <Button
                  variant={'contained'}
                  color={'primary'}
                  fullWidth
                  onClick={formikProps.submitForm}
                >
                  Potvrdit
                </Button>
              </DialogForm>
            )}
          </Formik>
        </ContentPaper>
      </Dialog>
      <Button
        variant={'outlined'}
        size={'small'}
        onClick={() => setDialogOpened(true)}
      >
        New membership
      </Button>
    </>
  )
}
