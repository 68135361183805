import React, { FC } from 'react'
import { colors } from '../../../styles/colors'
import { IconSVGProps } from '../Icon'

export const CreditCard: FC<IconSVGProps> = ({ width = 26, height = 24,
  color = colors.grey1,
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.261 1H2.73925C1.77876 1 1.00012 1.77864 1.00012 2.73913V13.1739C1.00012 14.1344 1.77876 14.913 2.73925 14.913H19.261C20.2215 14.913 21.0001 14.1344 21.0001 13.1739V2.73913C21.0001 1.77864 20.2215 1 19.261 1Z"
      stroke={`${color}`}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M1.00012 4.47852H21.0001"
      stroke={`${color}`}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M18.3914 7.95703H15.7827"
      stroke={`${color}`}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M10.5653 7.95703H3.60883"
      stroke={`${color}`}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M6.21752 10.5645H3.60883"
      stroke={`${color}`}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round" />
  </svg>

)
