import React, { FC, ReactFragment } from 'react'
import { Box, Tooltip } from '@material-ui/core'

import { colors } from '../../styles/colors'
import { InfoIcon } from '../../components/Icon/icons/Info.icon'

type Props = {
  text: string | ReactFragment;
};

export const LocationTooltip: FC<Props> = ({ text }) => (
  <Tooltip
    title={text}
    arrow
  >
    <Box>
      <InfoIcon
        color={colors.grey1}
        width={18}
        height={18}
      />
    </Box>
  </Tooltip>
)
