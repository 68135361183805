import React, { FC, PropsWithChildren } from 'react'
import { Box, BoxProps, Typography } from '@material-ui/core'
import styled from 'styled-components'

import { atMinWidth } from '../../../../styles/helpers'

type Props = {
  title: string;
};

export const QRCodeDialogTabSection: FC<PropsWithChildren<Props & BoxProps>> = ({ children, title, ...boxProps }) => {
  return (
    <Box {...boxProps}>
      <StyledSubtitle>{title}</StyledSubtitle>
      {children}
    </Box>

  )
}

const StyledSubtitle = styled(Typography)`
  font-family: 'Ginto Normal Regular', serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 16px;

  ${atMinWidth.mobile} {
    margin-bottom: 24px;
  }
`
