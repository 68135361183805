import { TextField } from '@material-ui/core'
import React, { FC, useState } from 'react'
import { FormikProps, FormikValues } from 'formik'

type Props = {
  formikProps: FormikProps<FormikValues>;
  required: boolean;
  name: string;
  label: string;
  value: string;
};

export const AmountField: FC<Props> = ({
  formikProps,
  required,
  name,
  label,
  value,
}) => {
  const [invalidAmount, setInvalidAmount] = useState(false)
  const { handleChange } = formikProps

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    Number(e.target.value) < 0
      ? setInvalidAmount(true)
      : setInvalidAmount(false)
    handleChange(e)
  }

  return (
    <TextField
      required={required}
      name={name}
      label={label}
      value={value}
      type="number"
      fullWidth
      InputProps={{
        inputProps: {
          min: 0,
        },
      }}
      variant="standard"
      error={Boolean(invalidAmount)}
      helperText={Boolean(invalidAmount) && 'Invalid amount number'}
      onChange={onChange}
    />
  )
}
