import React, { useEffect, useState } from 'react'
import { merge } from 'lodash'
import {
  csvRequestParams,
  EntityType,
  ReportHandlers,
  ReportType,
  RequestParams,
  TogglReportData,
} from './types'
import {
  ResponseHandler,
  getReportData,
  getCsvExport,
} from '../../services/api'
import { TogglReport } from './components/TogglReport'
import { encodeUrlParams, parseUrlParams } from './utils'
import { Paper } from '@material-ui/core'
import styled from 'styled-components'

const allEntities: Pick<RequestParams, 'user_id' | 'project_id'> = {
  user_id: 'all',
  project_id: 'all',
}

export const TogglReportPage = () => {
  const initialParams = parseUrlParams(window.location.search)
  // const [isLoading, setIsLoading] = useState<boolean>(false)
  const [requestParams, setRequestParams] =
    useState<RequestParams>(initialParams)
  const [apiResponse, setApiResponse] = useState<TogglReportData>()

  useEffect(() => {
    apiResponse?.params && setPageUrlParams(requestParams)
    getReportData(requestParams, apiHandler)
  }, [requestParams])

  const setPageUrlParams = (params) => {
    const path =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      encodeUrlParams(params)
    window.history.pushState('page2', 'Title', path)
  }

  const apiHandler: ResponseHandler<TogglReportData> = ({
    data,
    error,
    isLoading,
  }) => {
    data && setApiResponse(data)
  }

  const requestParamsChangeHandler = (type: string, value: any) => {
    setRequestParams((prevState) => {
      const newParams =
        type === EntityType.report ? merge(prevState, allEntities) : prevState
      return { ...newParams, [type]: value }
    })
  }

  const monthChangeHandler = (month: string) => {
    requestParamsChangeHandler(EntityType.month, month)
  }
  const entityChangeHandler = (entityId: number, type: ReportType) => {
    requestParamsChangeHandler(EntityType[type], entityId)
  }
  const reportTypeChangeHandler = (newValue: ReportType) => {
    requestParamsChangeHandler(EntityType.report, newValue)
  }

  const csvDownloader = (params?: csvRequestParams) => getCsvExport(params)

  const handlers: ReportHandlers = {
    monthChangeHandler,
    entityChangeHandler,
    reportTypeChangeHandler,
    csvDownloader,
  }

  const props = { ...handlers, data: apiResponse! }

  return (
    <StyledPaper>
      {apiResponse === undefined ? (
        <p>Loading...</p>
      ) : (
        <TogglReport {...props} />
      )}
    </StyledPaper>
  )
}

const StyledPaper = styled(Paper)`
  padding: 64px 48px;
  margin: 64px 48px;
  box-shadow: none;
`
