import { Day, DayRange, utils } from 'react-modern-calendar-datepicker'
import { User } from '../../types'

import { addLeadingZero, sortByKey } from '../../../../utils/helpers'
import { ExportToCsvFormValues } from './types'
import { SelectItem } from '../../../../types'

export const todayDate = utils('en').getToday()

export const getInitialDate = (preselectedMonth?: string): DayRange => {
  if (preselectedMonth) {
    const yearMonthSplit = preselectedMonth?.split('/')

    const firstDay: Day = {
      year: +yearMonthSplit[0],
      month: +yearMonthSplit[1],
      day: 1,
    }
    const lastDay: Day = {
      ...firstDay,
      day: utils('en').getMonthLength(firstDay),
    }

    return {
      from: firstDay,
      to: lastDay,
    }
  }

  const firstDayOfLastMonth = {
    day: 1,
    month: todayDate.month == 1 ? 12 : todayDate.month - 1,
    year: todayDate.month == 1 ? todayDate.year - 1 : todayDate.year,
  }
  const lastDayOfLastMonth = {
    ...firstDayOfLastMonth,
    day: utils('en').getMonthLength({
      ...todayDate,
      month: todayDate.month == 1 ? 12 : todayDate.month - 1,
    }),
  }

  return {
    from: firstDayOfLastMonth,
    to: lastDayOfLastMonth,
  }
}

export const getSelectedDateRangeLabel = (date: DayRange) => {
  const formatLabelDate = (date: Day) =>
    `${date.day}.${date.month}.${date.year}`

  const labelFrom = formatLabelDate(date.from as Day)
  const labelTo = date.to ? formatLabelDate(date.to as Day) : ''

  return labelFrom + ' - ' + labelTo
}

export const getSelectedDateRangeLinkFormat = (date: DayRange) => {
  const formatLabelDate = (date: Day) =>
    `${addLeadingZero(date.day)}-${addLeadingZero(date.month)}-${date.year}`

  const labelFrom = formatLabelDate(date.from as Day)
  const labelTo = date.to ? formatLabelDate(date.to as Day) : ''

  return labelFrom + ' to ' + labelTo
}

export const getPreviewLink = (values: ExportToCsvFormValues) => `
  /api/projects/${
  values.projectId
}/preview?date=${getSelectedDateRangeLinkFormat(values.date)}&pick_user=${
  values.user
}&template=${values.template}
  `

type GetUsersSortedByLastname = (users: User[]) => SelectItem[];

export const getUsersSortedByLastName: GetUsersSortedByLastname = (users) =>
  sortByKey(users, 'lastName').map((user) => ({
    value: user.id,
    label: user.fullName,
  }))
