import React, { FC } from 'react'
import { TableRow, TableCell, Chip } from '@material-ui/core'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { Payment } from '../UserInfo/types'
import { MoneyFormatter, dateFormatter } from '../../utils/formatters'

type Props = {
  payment: Payment;
};

// TO BE replaced by Payment row from PaymentPage after its merged
export const TablePaymentRow: FC<Props> = ({ payment }) => {
  const {
    id,
    createdAt,
    counterpartyAccountName,
    note,
    amount,
    source,
    pairedTo,
  } = payment
  const parsedDate = dateFormatter(createdAt)
  const pairingStatus = pairedTo ?? (
    <Chip
      label="Unpaired"
      color="secondary"
    />
  )

  const amountFormatted = new MoneyFormatter('CZK').format(amount)

  return (
    <TableRow>
      <TableCell>
        <StyledLink href={`/admin/payments/${id}`}>{id}</StyledLink>
      </TableCell>
      <StyledTableCell>{parsedDate}</StyledTableCell>
      <StyledCell>
        <span>{counterpartyAccountName}</span>
        <StyledNote>Note: {note}</StyledNote>
      </StyledCell>
      <TableCell>{amountFormatted}</TableCell>
      <TableCell>{source}</TableCell>
      <TableCell>{pairingStatus}</TableCell>
    </TableRow>
  )
}

const StyledNote = styled.span`
  color: ${colors.grey0};
`

const StyledCell = styled(TableCell)`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`

const StyledLink = styled.a`
  text-decoration: underline;
`

const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`
