import React, { FC } from 'react'
import { AssignUserModalContent } from './AssignUserModalContent'
import { Button, ButtonPropsCustom } from '../../components/buttons/Button'
import { ModalWrapper, useModalState } from '../../components/ModalWrapper'
import { PlusIcon } from '../../components/Icon/icons/Plus.icon'
import { colors } from '../../styles/colors'
import styled from 'styled-components'

type Props = {
  userId?: number;
  projectId: number;
  projectName: string;
  done?: () => void;
  buttonProps?: Partial<ButtonPropsCustom>;
  buttonType?: string;
};

export const AssignUserToProject: FC<Props> = (props) => {
  const { projectId, userId, projectName, buttonProps, done } = props
  const { isModalOpen, closeModal, openModal } = useModalState()

  const isAssigningUserForm = !userId

  const handleDone = () => {
    closeModal()
    done?.()
  }
  const buttonOption = {
    join: (
      <Button
        kind="basicPrimary"
        aria-label="join project"
        onClick={openModal}
        {...buttonProps}
      >
        <PlusIcon color={colors.white} />
        <ButtonText>Join project</ButtonText>
      </Button>
    ),

    assign: (
      <Button
        kind="basicSecondary"
        onClick={openModal}
      >
        <PlusIcon />
        <ButtonText>Assign person</ButtonText>
      </Button>
    ),
  }

  return (
    <>
      {buttonOption[isAssigningUserForm ? 'assign' : 'join']}

      <ModalWrapper
        isOpen={isModalOpen}
        closeModal={closeModal}
      >
        <AssignUserModalContent
          projectId={projectId}
          userId={userId!}
          projectName={projectName}
          isAssigningUserForm={isAssigningUserForm}
          done={handleDone}
        />
      </ModalWrapper>
    </>
  )
}

const ButtonText = styled.span`
  margin-left: 10.5px;
  text-transform: none;
`
