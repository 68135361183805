import React, { FC } from 'react'
import styled from 'styled-components'

import { ReturnDeviceButton } from './ReturnDeviceButton'
import { BorrowDeviceButton } from './BorrowDeviceButton'
import { TestingDevices } from './types'
import { colors } from '../../styles/colors'

type Props = {
  isDeviceTakenByMe: boolean;
  isDeviceTaken: boolean;
  device: TestingDevices;
};

export const DeviceActionButton: FC<Props> = ({
  isDeviceTakenByMe,
  isDeviceTaken,
  device,
}) => {
  if (isDeviceTakenByMe) {
    return (
      <ReturnDeviceButton
        buttonType="basicPrimary"
        device={device}
      />
    )
  }
  if (isDeviceTaken) {
    return <BorrowedInfo>Borrowed</BorrowedInfo>
  }
  return (
    <BorrowDeviceButton
      buttonType="basicSecondary"
      device={device}
    />
  )
}

const BorrowedInfo = styled.div`
  color: ${colors.grey0};
  font-family: 'Ginto Normal Regular', serif;
  font-weight: 700;
`
