import React, { ChangeEvent, FC, useState } from 'react'
import {
  Box,
  Button,
  Drawer,
  Hidden,
  Table,
  TableBody,
  Typography,
} from '@material-ui/core'
import { GroupsFilter } from './GroupsFilter'
import { CategoryFilter } from './CategoryFilter'
import { ShopProductRow } from '../ShopProductRow'
import { Alert } from '@material-ui/lab'
import { BasketProduct, ProductCategory, ProductGroup } from '../../types'
import styled from 'styled-components'
import { theme } from '../../../../styles/muiThemes'
import FilterListIcon from '@material-ui/icons/FilterList'
import { FilterDrawerContent } from './FilterDrawerContent'
import { ProductsSearchField } from './ProductsSearchField'

type Props = {
  handleFilterChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  handleGroupFilterChange: (selectedCategory: string) => void;
  handleCategoryChange: (category: string, checked: boolean) => void;
  selectedGroup: string;
  productGroupsFilters: ProductGroup[];
  filteredCategories: ProductCategory[];
  selectedCategories: {};
  finalProducts: BasketProduct[];
  searchString: string;
};

export const ProductsListView: FC<Props> = (props) => {
  const {
    handleFilterChange,
    handleGroupFilterChange,
    selectedGroup,
    productGroupsFilters,
    filteredCategories,
    selectedCategories,
    handleCategoryChange,
    finalProducts,
    searchString,
  } = props
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)

  return (
    <>
      <ProductsSearchField onChange={handleFilterChange} />
      <Hidden
        mdUp
        implementation={'css'}
      >
        <Button
          color={'primary'}
          variant={'outlined'}
          endIcon={<FilterListIcon />}
          fullWidth
          onClick={() => setFilterDrawerOpen(true)}
        >
          Filter
        </Button>
      </Hidden>

      <Drawer
        open={filterDrawerOpen}
        onClose={() => setFilterDrawerOpen(false)}
        anchor={'bottom'}
      >
        <FilterDrawerContent
          filteredCategories={filteredCategories}
          selectedCategories={selectedCategories}
          handleGroupFilterChange={handleGroupFilterChange}
          selectedGroup={selectedGroup}
          handleCategoryChange={handleCategoryChange}
          productGroupsFilters={productGroupsFilters}
        />
      </Drawer>

      <Hidden smDown>
        <GroupsFilter
          selectedCategory={selectedGroup}
          handleFilterChange={handleGroupFilterChange}
          categories={productGroupsFilters}
        />
      </Hidden>

      <ProductsListContainer>
        <div>
          <Hidden smDown>
            <Typography variant={'overline'}>Filter</Typography>
            <CategoryFilter
              categories={filteredCategories}
              selectedCategories={selectedCategories}
              categoryChange={handleCategoryChange}
            />
          </Hidden>
        </div>
        <div>
          <Typography variant={'overline'}>{'Products'}</Typography>
          <ProductsTable>
            <TableBody>
              {finalProducts.map((product) => (
                <ShopProductRow
                  basketProduct={product}
                  key={product.product.id}
                  searchString={searchString}
                />
              ))}
            </TableBody>
          </ProductsTable>
        </div>
      </ProductsListContainer>
      {searchString && finalProducts.length === 0 && (
        <Box mt={4}>
          <Alert severity={'warning'}>No products found.</Alert>
        </Box>
      )}
    </>
  )
}

export const FilterSkeletonContainer = styled.div`
  display: grid;
  grid-template-columns: 19px 1fr;
  align-items: center;
  grid-column-gap: 14px;
  grid-row-gap: 10px;
  padding-left: 10px;
  ${theme.breakpoints.down('sm')} {
    display: block;
  }
`

export const ProductsListContainer = styled.div`
  padding-top: 1em;
  display: grid;
  grid-template-columns: 300px 1fr;
  ${theme.breakpoints.between('md', 'lg')} {
    grid-template-columns: 160px 1fr;
  }
  ${theme.breakpoints.down('sm')} {
    padding-bottom: 2em;
    display: block;
  }
`

export const ProductsTable = styled(Table)`
  min-width: 0;
`
