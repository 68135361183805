import React, { FC } from 'react'
import FormControl from '@material-ui/core/FormControl'
import { useField } from 'formik'
import Switch from '@material-ui/core/Switch'

type Props = {
  name: string;
};

export const FormSwitch: FC<Props> = ({ name }) => {
  // const [field] = useField(name);
  const [state, setState] = React.useState(false)

  // const { onChange, value } = field;

  return (
    // <FormControl>
    <Switch
      name={name}
      checked={state}
      onChange={() => setState((prevState) => !prevState)}
      color="secondary"
    // value={value}
    />
    // </FormControl>
  )
}
