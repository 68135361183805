import React, { FC } from 'react'
import { Image } from 'react-bootstrap'

import { ModalWrapper } from '../../../components/ModalWrapper'

type FullSizeImageModalProps = {
  isOpen: boolean,
  imageSrc: string,
  closeModal: () => void
}

export const FullSizeImageModal: FC<FullSizeImageModalProps> = ({ isOpen, imageSrc, closeModal }) => {
  return (
    <ModalWrapper
      isOpen={isOpen}
      closeModal={closeModal}
    >
      <Image
        src={imageSrc}
        width="100%" />
    </ModalWrapper>
  )
}
