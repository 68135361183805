import React from 'react'
import { FC } from 'react'
import { Alert } from '@material-ui/lab'

type Props = {
  isAssigningUserForm: boolean;
};
export const NoTribeMessage: FC<Props> = ({ isAssigningUserForm }) => {
  const message = isAssigningUserForm
    ? 'You cannot assign this user to a projects at the moment. Please ask Backoffice to put her/him in a tribe first.'
    : 'You cannot join projects at the moment. Please ask Backoffice to put you in a tribe first.'

  return <Alert severity="error">{message}</Alert>
}
