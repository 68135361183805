import { Box } from '@material-ui/core'
import React, { FC } from 'react'
import styled from 'styled-components'
import { atMinWidth } from '../../../styles/helpers'
import { MyTransactionFilters } from './MyTransactionFilters'
import { MyTransactionPagination } from './MyTransactionPagination'
import { MyTransactionsTable } from './MyTransactionsTable'

export const MyTransactionPageView: FC = () => {

  return (
    <StyledBox>
      <MyTransactionFilters />
      <Box>
        <MyTransactionsTable />
        <MyTransactionPagination />
      </Box>
    </StyledBox>
  )
}

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${atMinWidth.mobile} {
    grid-template-columns: 150px 1fr;
  }
`
