import React, { FC, useState } from 'react'
import { Avatar, Box, Typography } from '@material-ui/core'
import styled, { css } from 'styled-components'
import { colors } from '../../../styles/colors'
import { isActive } from '../utils'
import { useCurrentUserInfo } from '../../../components/CurrentUserProvider'
import { QrCode } from '../../../components/Icon/icons/QrCode.icon'
import { QRCodeDialog } from '../../Dashboard/components/QRCodeDialog/QRCodeDialog'

export const UserInfo: FC = () => {
  const [dialogOpened, setDialogOpened] = useState(false)
  const handleClick = () => setDialogOpened(true)

  const { currentUser } = useCurrentUserInfo()

  const getUserInitials = (name: string): string => {
    const words = name.split(' ')
    return words.reduce((acc, word) => acc + word[0], '')
  }

  const inDebt = currentUser?.balance < 0
  const userFullName = currentUser?.firstName + ' ' + currentUser?.lastName
  const userInitials = getUserInitials(userFullName)

  const userInfoPath = `/admin/users/${currentUser.id}`

  return (
    <OuterUserInfoContainer>
      <UserInfoContainer
        href={userInfoPath}
        active={isActive(userInfoPath)}
      >
        <UserAvatar
          src={currentUser?.slackAvatar?.image32}
          alt={userFullName}
        >
          {userInitials}
        </UserAvatar>

        <UserInfoName variant="subtitle1">{currentUser?.firstName}</UserInfoName>

        <UserInfoBalance
          variant="subtitle1"
          $inDebt={inDebt}
        >
          {currentUser?.balance || 0} Kč
        </UserInfoBalance>
      </UserInfoContainer>
      <UserQRCode onClick={handleClick}>
        <QrCode color='white' />
      </UserQRCode>    
      <QRCodeDialog
        setDialogOpened={setDialogOpened}
        dialogOpened={dialogOpened}
      />
    </OuterUserInfoContainer>
  )
}

const ActiveStyles = css`
  background: rgba(255, 255, 255, 0.05);
  color: white !important;
  .MuiAvatar-circle {
    background: white;
  }
`

const UserInfoContainer = styled.a<{ active: boolean }>`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 0 10px;
  padding: 8px 24px;
  transition: 300ms;
  color: ${colors.grey1}!important;
  &:hover {
    ${ActiveStyles}
  }
  ${(props) => props.active === true && ActiveStyles}
`

const UserAvatar = styled(Avatar)`
  grid-row: span 2;
  font-size: 0.9em;
  width: 40px;
  height: 40px;
  color: ${colors.brand};
  transition: 300ms;
`

const UserInfoName = styled(Typography)`
  color: ${colors.grey1};
  line-height: 1.2;
  padding-bottom: 5px;
`

const UserInfoBalance = styled(Typography)<{ $inDebt: boolean }>`
  color: ${colors.grey1};
  ${(props) =>
    props.$inDebt &&
    css`
      color: ${colors.error};
    `}
`
const UserQRCode = styled(Box)`
  position: relative;
  width: 24px;
  cursor: pointer;
  left: 170px;
  bottom: 40px;
`

const OuterUserInfoContainer = styled(Box)`
  margin-bottom: -24px;
`
