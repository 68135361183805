import { ProvidersWrapper } from '../components/ProvidersWrapper'
import React, { Context, useContext } from 'react'

export const withProviders = (props, railsContext, Component) => {
  const user = {
    currentUser: railsContext.currentUser,
    userPermissions: railsContext.userPermissions,
  }

  return (
    <ProvidersWrapper user={user}>
      <Component {...props} />
    </ProvidersWrapper>
  )
}

export function useAssertedContext<T>(context: Context<T | undefined>): T {
  const value = useContext(context)

  if (typeof value === 'undefined') {
    throw new Error('Context not provided')
  }

  return value
}
