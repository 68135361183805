import React, { ReactNode, FC } from 'react'
import { FormPartTitle } from './styles'

type Props = {
  children: ReactNode;
  sectionTitle: string;
};

export const FormPartWrapper: FC<Props> = ({ sectionTitle, children }) => (
  <>
    <FormPartTitle>{sectionTitle}</FormPartTitle>
    {children}
  </>
)
