import React, { FC } from 'react'
import { colors } from '../../../styles/colors'
import { IconSVGProps } from '../Icon'

export const Logo: FC<IconSVGProps> = ({ width = 20, height = 42, color = colors.white }) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 20 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.792 21.16H.37L9.379.734h1.343l9.032 20.428H15.33l-1.135-2.626H5.927l-1.135 2.626zm2.618-5.987h5.35l-2.71-6.77-2.64 6.77z"
      fill={color}
    />
    <path
      d="M4.654 30.557a5.154 5.154 0 01-.14-1.705c.209-2.717 2.456-4.905 5.142-5.09 3.22-.183 5.883 2.35 5.883 5.505 0 .438-.047.852-.14 1.267l-.069.276-.07.207a.424.424 0 01-.046.161l-1.343 3.87c-.023.114-.208.114-.255 0l-.44-1.244-.092-.277c-.14-.414-.741-.391-.857.023l-.232.876-1.83 6.632a.145.145 0 01-.277 0l-1.83-6.632-.232-.876c-.115-.414-.718-.437-.856-.023l-.093.277-.44 1.243c-.023.116-.209.116-.255 0L4.84 31.225l-.07-.208a.176.176 0 00-.011-.046 1.917 1.917 0 00-.051-.199 1.882 1.882 0 01-.054-.215z"
      fill={color}
    />
  </svg>
)
