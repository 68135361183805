import { Paper } from '@material-ui/core'
import styled from 'styled-components'
import { atMinWidth } from '../../styles/helpers'
import { theme } from '../../styles/muiThemes'
import { Pagination } from '@material-ui/lab'
import { SearchField } from '../../components/inputs/SearchField'

export const StyledPaper = styled(Paper)`
  min-height: 90vh;
  position: relative;
  padding: 16px;

  ${atMinWidth.tablet} {
    padding: 48px 40px;
  }

  ${atMinWidth.desktop} {
    padding: 48px 64px;
  }
`

export const PaymentSearchField = styled(SearchField)`
  margin-bottom: 32px;
`
export const PaymentListContainer = styled.div`
  padding-top: 2em;
  display: grid;
  gap: 5rem;
  grid-template-columns: 250px 1fr;
  ${theme.breakpoints.between('md', 'lg')} {
    grid-template-columns: 160px 1fr;
  }
  ${theme.breakpoints.down('sm')} {
    padding-bottom: 2em;
    display: block;
  }
`

export const StyledPagination = styled(Pagination)`
  margin-top: 1.5rem;
  justify-content: center;
  display: flex;
`
