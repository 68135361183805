export type ShopConfig = {
  title: string;
  allowsOutOfStockBuy?: boolean;
  type: string;
};

interface ConfigInt {
  [key: string]: ShopConfig;
}

export const ConfigTypes: ConfigInt = {
  fridge: {
    title: 'Food & Drinks',
    allowsOutOfStockBuy: true,
    type: 'fridge',
  },
  merch: {
    title: 'Merch Shop',
    allowsOutOfStockBuy: false,
    type: 'merch',
  },
}
