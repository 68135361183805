import React, { FC } from 'react'
import { formatISO } from 'date-fns'
import { useQueryClient } from 'react-query'

import { Button, ButtonType } from '../../components/buttons/Button'
import { TestingDevices } from './types'
import { Mutation, useCustomMutation } from '../../hooks/useCustomMutation'
import { useCurrentUserInfo } from '../../components/CurrentUserProvider'
import { useQueryNotification } from '../UserInfo/utils'

type Props = {
  device: TestingDevices;
  buttonType: ButtonType;
};

export const BorrowDeviceButton: FC<Props> = ({ device, buttonType }) => {
  const queryClient = useQueryClient()
  const { currentUser } = useCurrentUserInfo()
  const { successNotification, errorNotification } = useQueryNotification()

  const createNewDeviceSubjectMutation: Mutation<TestingDevices> = (
    initVals
  ) => ({
    path: `/api/testing_devices/${device.id}/borrow`,
    method: 'POST',
    params: initVals,
  })

  const newDeviceSubjectMutation = useCustomMutation(
    createNewDeviceSubjectMutation,
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(
          `/api/testing_devices/${device.id}/borrow`
        )
        await queryClient.refetchQueries(`/api/testing_devices/${device.id}`)
        await queryClient.refetchQueries('/api/testing_devices')
        successNotification('Device is now in your hands, congratulation')
      },
      onError: () => {
        errorNotification(
          'Sorry something happened :) let us know and we fit it'
        )
      },
    },
    true
  )

  const updateDate = formatISO(new Date())

  const currentUserData = {
    id: currentUser.id,
    name: currentUser.fullName,
    nickname: currentUser.nickname,
    fullName: currentUser.fullName,
    firstName: currentUser.firstName,
    lastName: currentUser.lastName,
    slackAvatar: currentUser.slackAvatar,
  }

  const values = {
    serial: device.serial,
    brand: device.brand,
    name: device.name,
    systemVersion: device.systemVersion,
    inventoryNumber: device.inventoryNumber,
    location: device.location,
    sublocation: device.sublocation,
    inventoryUrl: device.inventoryUrl,
    createdAt: device.createdAt,
    updatedAt: updateDate,
    taken: true,
    id: device.id,
    currentUser: currentUserData,
  }

  return (
    <Button
      fullWidth
      kind={buttonType}
      onClick={() => newDeviceSubjectMutation.mutateAsync(values)}
    >
      Borrow
    </Button>
  )
}
