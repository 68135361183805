import React, {FC} from 'react'
import styled from 'styled-components'
import {SignInIcon} from '../../components/Icon/icons/SignIn.icon'
import {SignInArrow} from '../../components/Icon/icons/SignInArrow.icon'
import {Logo} from '../../components/Icon/icons/Logo.icon'
import {colors} from '../../styles/colors'
import {atMinWidth} from '../../styles/helpers'
import {Button} from '@material-ui/core'

export const SignInPage: FC = () => {
    const handleSignIn = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevent the default button behavior

        const form = document.createElement('form');
        form.method = 'POST';
        form.action = '/login';

        document.body.appendChild(form);
        form.submit();
    };


    return (
        <SignInContainer>
            <LogoContainer>
                <Logo/>
            </LogoContainer>
            <SignInContent>
                <SignInTitle>Corplifting</SignInTitle>
                <SignInText>
                    Please sign in with Applifting, DX Heroes or Knowspread e-mail
                </SignInText>
                <form onSubmit={handleSignIn}>
                    <StyledButtonContainer>
                        <StyledButton size="large" type="submit">
                            Sign in
                            <SignInArrow/>
                        </StyledButton>
                    </StyledButtonContainer>
                </form>
            </SignInContent>
            <SignInIcon/>
        </SignInContainer>
    )
}

const SignInContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: relative;
    padding: 72px 0 72px 0;
    background-color: ${colors.white};

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ${atMinWidth.tablet} {
        padding: 0;
        display: grid;
        grid-template-columns: 1fr 7fr;
        grid-template-areas: 'logo content';
    }
`

const SignInTitle = styled.h1`
    font-weight: 120px;
    font-family: 'Ginto Nord';
    margin-bottom: 24px;
    font-size: 38px;
    letter-spacing: 0.1rem;
    position: relative;
    z-index: 5;
    color: ${colors.black};
    font-size: calc(1rem + 6vw);
    letter-spacing: 0.2rem;

    ${atMinWidth.tablet} {
        margin-bottom: 50px;
    }

    ${atMinWidth.desktopLarge} {
        font-size: 100px;
        letter-spacing: 0.3rem;
    }
`

const SignInText = styled.p`
    font-size: 20px;
    width: 300px;
    margin-bottom: 40px;
    letter-spacing: -0rem;
    position: relative;
    z-index: 5;
    color: ${colors.black};
`

const StyledButtonContainer = styled.a`
    flex-grow: 1;
    display: flex;
    width: 80%;

    ${atMinWidth.tablet} {
        flex-grow: 0;
        display: block;
        width: 300px;
    }
`

const StyledButton = styled(Button)`
    padding: 0 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 5;
    margin-top: auto;
    text-transform: capitalize;
    background-color: ${colors.brandYellow};
`

const LogoContainer = styled.div`
    grid-area: logo;
    width: 80%;
    margin: auto;

    path {
        fill: ${colors.black};
    }

    ${atMinWidth.tablet} {
        margin: 0;
        text-align: center;
        padding: 56px 0 0 0;
    }

    ${atMinWidth.desktopLarge} {
        border-right: 1px solid ${colors.grey4};
    }
`

const SignInContent = styled.div`
    flex-grow: 1;
    grid-area: content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
    margin: 57px auto 0 auto;

    ${atMinWidth.tablet} {
        justify-content: center;
        align-items: flex-start;
        margin-top: 0px;
    }

    ${atMinWidth.desktopLarge} {
        background: linear-gradient(
                90deg,
                #0000 calc(100% - 1px),
                ${colors.grey4} 0
        );
        background-size: calc((100% - 2 * 1px) / (2 + 1) + 1px) 100%;
    }
`
