import { FieldOptions, UpdatableField } from './UpdatableField'
import { SelectItem } from '../index'

export class SelectFieldData extends UpdatableField {
  defaultValue: string | number;
  options: SelectItem[];
  keepVisible = false;

  constructor(
    defaultValue: string | number,
    fieldName: string,
    options: SelectItem[],
    fieldOptions?: FieldOptions
  ) {
    super(defaultValue, fieldName, fieldOptions)
    this.options = options
  }

  getLabel(): string {
    const foundOption = this.options.find((option) => {
      if (!this.defaultValue) {
        if (!option.value) return true
      }
      return option.value == this.defaultValue
    })
    return foundOption?.label || '-'
  }
}
