import styled from 'styled-components'
import { AccountSelectValues } from '../types'
import React, { FC, useEffect } from 'react'
import { FormikProps, FormikValues } from 'formik'
import { atMinWidth } from '../../../styles/helpers'
import { hasDuplicates } from '../utils'
import { FormSelect } from '../../../components/inputs/FormSelect'
import { AmountField } from './AmountField'

type Props = {
  accountsSelectValues: AccountSelectValues[] | undefined;
  formikProps: FormikProps<FormikValues>;
  setIsDuplicateAccounts: (isDuplicateAccounts: boolean) => void;
};

export const TransactionsInputGroup: FC<Props> = ({
  accountsSelectValues,
  formikProps,
  setIsDuplicateAccounts,
}) => {
  const { values, handleChange } = formikProps
  useEffect(() => {
    setIsDuplicateAccounts(hasDuplicates(values.transactions))
  }, [values])

  return (
    <>
      {values.transactions.length > 0 &&
        values.transactions.map((value: FormikValues, index: number) => {
          const inputValue =
            values.transactions[index] && values.transactions[index].account_id
              ? values.transactions[index].account_id
              : ''
          return (
            <SplitContainer key={index}>
              <FormSelect
                value={inputValue}
                values={accountsSelectValues || []}
                name={`transactions.${index}.account_id`}
                label={'ACCOUNT'}
                onChange={handleChange}
              />
              <AmountField
                required={true}
                name={`transactions.${index}.amount`}
                formikProps={formikProps}
                label={'AMOUNT'}
                value={value.amount}
              />
            </SplitContainer>
          )
        })}
    </>
  )
}

const SplitContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;

  ${atMinWidth.mobile} {
    flex-wrap: nowrap;
  }
`
