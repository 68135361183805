import React, { useEffect, useState } from 'react'
import { isEmpty, sortBy } from 'lodash'
import {
  csvRequestParams,
  DataRow,
  EntityType,
  ReportFields as Field,
  ReportType,
  TogglReportData,
} from '../types'
import { calculateSum, removeColumn } from '../utils'
import {
  MonthPicker,
  ProjectSelect,
  RecursiveGroup,
  ReportTableHeader,
  ReportTotal,
  SearchField,
  TypeToggle,
  UserSelect,
} from '.'
import {
  ShowDescriptionFormControlLabel,
  StyledContainer,
  StyledTable,
} from './StyledComponents'
import { NoDataToShow } from './NoDataToShow'
import { TableHead, TableRow, Typography } from '@material-ui/core'
import Switch from '@material-ui/core/Switch'
import { ExportToCSVModal } from '../../ExportToCSVModal/ExportToCSVModal'
import { InvoiceDetailWrapper } from '../../Dashboard/components/InvoiceDetailWrapper'
import { useUserPermissions } from '../../../components/CurrentUserProvider'

// tohle by šlo asi smazat
const possibleFields: Field[] = [
  Field.user,
  Field.project,
  Field.description,
  Field.sum,
]
const userReportFields = [Field.project, Field.description, Field.sum]
const projectReportFields = [Field.user, Field.description, Field.sum]

export type TogglReportProps = {
  data: TogglReportData;
  monthChangeHandler: (month: string) => void;
  entityChangeHandler: (entityId: number, type: ReportType) => void;
  reportTypeChangeHandler: (newValue: ReportType) => void;
  csvDownloader: (params?: csvRequestParams) => void;
};

type FilterByUserOrProject = {
  type: EntityType;
  entityId: string | 'all';
};
export type SortProps = { field: string; descending: boolean };

// FIXME: entityChangeHandler: expects entityId: number and gets string
export type ProjectChangeHandler = (entityId) => void;

export const TogglReport = ({
  data,
  monthChangeHandler,
  entityChangeHandler,
  reportTypeChangeHandler,
  csvDownloader,
}: TogglReportProps) => {
  const {
    items,
    users,
    projects,
    report_type,
    month_picker_options: months,
    params: requestParams,
  } = data

  // translate string to enum
  const reportType: ReportType = ReportType[report_type]

  const initialSearchFilter = { type: EntityType[reportType], entityId: 'all' }

  // const [isLoading, setIsLoading] = useState<boolean>(false)
  //search in descriptions
  const [searchQuery, setQuery] = useState<string>('')

  const [searchFilter, setSearchFilter] =
    useState<FilterByUserOrProject>(initialSearchFilter)

  const [sort, setSort] = useState<SortProps>()

  const [showDescription, setShowDescription] = useState<boolean>(false)
  const [showExportButton, setShowExportButton] = useState<boolean>(false)
  const [tableRows, setTableRows] = useState<DataRow[]>(
    removeColumn(reportType, items)
  )
  const totalSum = calculateSum(tableRows)

  const [headerFields, setHeaderFields] = useState<Field[]>(possibleFields)
  const [groupByFields, setGroupByFields] = useState<Field[]>([
    Field.user,
    Field.project,
  ])

  // TODO: move to table
  useEffect(() => {
    filterByDescription(searchQuery)
  }, [searchQuery])

  // TODO: move to table
  useEffect(() => {
    setTableRows(removeColumn(reportType, items))
  }, [items])

  useEffect(() => {
    updateShowExportButton()
  }, [requestParams])

  // TODO: move to table
  useEffect(() => {
    filterByUserOrProject(searchFilter.type, searchFilter.entityId)
  }, [searchFilter])

  //TODO; not needed can be removed and decisions moved
  useEffect(() => {
    if (reportType === ReportType.user) {
      setHeaderFields(userReportFields)
      setGroupByFields([Field.project])
    } else {
      setHeaderFields(projectReportFields)
      setGroupByFields([Field.user])
    }
  }, [reportType])

  useEffect(() => {
    sort && applySort(sort)
  }, [sort])

  const { resolvePermissions } = useUserPermissions()

  const updateShowExportButton = () => {
    const userReportParams =
      reportType === ReportType.user &&
      !isEmpty(requestParams.user_id) &&
      requestParams.user_id !== 'all'

    const projectReportParams =
      reportType === ReportType.project &&
      !!requestParams.project_id &&
      requestParams.project_id !== 'all'

    const result = userReportParams || projectReportParams
    setShowExportButton(result)
  }

  // TODO: move to table
  const filterByDescription = (searchQuery: string): void => {
    const regex = new RegExp(searchQuery, 'i')
    const rows =
      searchQuery === ''
        ? items
        : items.filter((i) => i.description && regex.test(i.description))
    setTableRows(removeColumn(reportType, rows))
  }
  // TODO: move to table
  const applySort = (sort: SortProps) => {
    const rows = sort.descending
      ? sortBy(tableRows, [(i) => i[sort.field]])
      : sortBy(tableRows, [(i) => i[sort.field]]).reverse()
    setTableRows(rows)
  }

  // TODO: move to table
  const filterByUserOrProject = (
    type: EntityType,
    entityId: string | 'all'
  ) => {
    const rows =
      entityId === 'all'
        ? items
        : items.filter((i) => i[type] === parseInt(entityId, 10))
    setTableRows(removeColumn(reportType, rows))
  }

  const sortButtonHandler = (field: string) => {
    if (sort) {
      setSort({ field: field, descending: !sort.descending })
    } else {
      setSort({ field: field, descending: field === 'sum' ? false : true })
    }
  }

  const userChangeHandler = (entityId) => {
    setQuery('')
    switch (reportType) {
    case ReportType.user:
      entityChangeHandler(entityId, ReportType.user)
      break
    case ReportType.project:
      setSearchFilter({ type: EntityType.user, entityId })
      break
    }
  }

  const projectChangeHandler: ProjectChangeHandler = (entityId) => {
    setQuery('')

    switch (reportType) {
    case ReportType.user:
      setSearchFilter({ type: EntityType.project, entityId })
      break
    case ReportType.project:
      entityChangeHandler(entityId, ReportType.project)
      break
    }
  }

  const monthSelectHandler = (selectedMonth) => {
    setQuery('')
    monthChangeHandler(selectedMonth)
  }

  const handleSwitchChange = () => setShowDescription(!showDescription)

  const userSelector = (
    <UserSelect
      users={users}
      changeHandler={userChangeHandler}
      selectedUserId={requestParams.user_id}
    />
  )

  const projectSelector = (
    <ProjectSelect
      projects={projects}
      changeHandler={projectChangeHandler}
      selectedProjectId={requestParams.project_id}
    />
  )

  const options = [
    { key: ReportType.user, label: 'Person' },
    { key: ReportType.project, label: 'Project' },
  ]

  return (
    <>
      <Typography variant={'h3'}>Monthly report</Typography>
      <StyledContainer>
        <TypeToggle
          changeHandler={reportTypeChangeHandler}
          activeReportType={reportType}
          options={options}
        />

        {reportType == ReportType.user ? userSelector : projectSelector}

        <div>
          {resolvePermissions('user', 'manage') && (
            <InvoiceDetailWrapper
              userId={requestParams.user_id}
              month={requestParams.month}
            />
          )}
          {showExportButton && (
            <ExportToCSVModal
              buttonType="normal"
              month={requestParams.month}
              userId={
                requestParams.user_id === 'all'
                  ? undefined
                  : requestParams.user_id
              }
              projectId={
                requestParams.project_id === 'all'
                  ? undefined
                  : requestParams.project_id
              }
            />
          )}
        </div>

        <MonthPicker
          changeHandler={monthSelectHandler}
          selectedMonth={requestParams.month}
          selectOptions={months}
        />

        {reportType == ReportType.user ? projectSelector : userSelector}
      </StyledContainer>

      <StyledContainer>
        <ShowDescriptionFormControlLabel
          control={
            <Switch
              checked={showDescription}
              onChange={handleSwitchChange}
              color="primary"
            />
          }
          label="Show description"
        />
        {showDescription && (
          <SearchField
            onChange={setQuery}
            value={searchQuery}
          />
        )}
      </StyledContainer>

      <StyledTable>
        <TableHead>
          <TableRow>
            {headerFields.map((field, id) => {
              const align = field === Field.sum ? 'right' : 'left'
              return (
                <ReportTableHeader
                  key={id}
                  sort={sort}
                  align={align}
                  field={field}
                  clickHandler={sortButtonHandler}
                />
              )
            })}
          </TableRow>
        </TableHead>

        {items.length > 0 ? (
          <ReportTotal
            title="TOTAL"
            sum={totalSum}
          />
        ) : (
          <NoDataToShow
            params={requestParams}
            reportType={reportType}
          />
        )}

        <RecursiveGroup
          headerFields={headerFields}
          fieldSet={groupByFields}
          rows={tableRows}
          level={0}
          showDescription={showDescription}
        />
      </StyledTable>
    </>
  )
}
