import React, { FC } from 'react'
import { Typography } from '@material-ui/core'
import { useQuery } from 'react-query'
import { Payment } from '../../PaymentsPage/types'
import { Account } from '../types'
import { createAccountSelect } from '../utils'
import { NewTransaction } from './NewTransaction'
import { ModalWrapper } from '../../../components/ModalWrapper'
import { Alert } from '@material-ui/lab'

type Props = {
  payment: Payment;
  isModalOpen: boolean;
  closeModal: () => void;
};

export const AddNewTransaction: FC<Props> = ({
  payment,
  isModalOpen,
  closeModal,
}) => {
  const {
    data: accounts,
    isLoading,
    isError,
  } = useQuery<Account[]>('/api/users/accounts')

  const accountsSelectValues = accounts && createAccountSelect(accounts)

  if (isError || accountsSelectValues == null) {
    return (
      <Alert
        variant={'outlined'}
        severity={'info'}
      >
        No accounts information available
      </Alert>
    )
  }

  return (
    <ModalWrapper
      isOpen={isModalOpen}
      closeModal={closeModal}
    >
      <Typography variant={'h3'}>New transaction</Typography>
      <NewTransaction
        payment={payment}
        accountsSelectValues={accountsSelectValues}
        isLoading={isLoading}
        closeModal={closeModal}
      />
    </ModalWrapper>
  )
}
