import React, { FC, useMemo } from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { theme } from '../../../../styles/muiThemes'
import { ArrowIcon } from '../../../../components/Icon/icons/Arrow.icon'

type Props = {
  onClick: () => void;
};
export const PurchaseButton: FC<Props> = ({ onClick }) => {
  return useMemo(
    () => (
      <StyledButton
        color={'primary'}
        variant={'contained'}
        endIcon={<ArrowIcon />}
        fullWidth
        onClick={onClick}
      >
        Purchase
      </StyledButton>
    ),
    []
  )
}

const StyledButton = styled(Button)`
  ${theme.breakpoints.down('lg')} {
    svg {
      display: none;
    }
  }
`
