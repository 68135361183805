import React, { FC } from 'react'
import { DataLine, SelectItem } from '../../../../types'
import { TextFieldData } from '../../../../types/UpdatableFormFieldData/TextFieldData'
import { SwitchFieldData } from '../../../../types/UpdatableFormFieldData/SwitchFieldData'
import { UpdatableItemsGroup } from '../../../../components/ui/UpdatableFormField/UpdatableItemsGroup'
import { Address, CompanySubject } from '../../types'
import { Typography } from '@material-ui/core'
import { SelectFieldData } from '../../../../types/UpdatableFormFieldData/SelectFieldData'
import { useUserInfo } from '../UserInfoProvider'
import { canUpdateUser } from '../Tabs/UserInfoTab'

type Props = {
  data: CompanySubject;
  addresses: Address[];
};
export const ContractorSubjectTable: FC<Props> = ({ data, addresses }) => {
  const { user } = useUserInfo()
  const addressItems: SelectItem[] = addresses.map((address) => {
    return { value: address.id, label: address.addressName }
  })
  const items: DataLine[] = [
    {
      label: 'Business name',
      updatableFields: [new TextFieldData(data.businessName, 'businessName')],
    },
    {
      label: 'Business adress',
      updatableFields: [
        new SelectFieldData(
          data.businessAddressId,
          'businessAddressId',
          addressItems
        ),
      ],
    },
    {
      label: 'Identification number (IČ)',
      updatableFields: [
        new TextFieldData(
          data.businessIdentificationNumber,
          'businessIdentificationNumber'
        ),
      ],
    },
    {
      label: 'Tax ID (DIČ)',
      updatableFields: [
        new TextFieldData(
          data.taxIdentificationNumber,
          'taxIdentificationNumber'
        ),
      ],
    },
    {
      label: 'VAT payer',
      updatableFields: [new SwitchFieldData(data.vatPayer, 'vatPayer')],
    },
  ]

  if (!user) {
    return null
  }

  return (
    <>
      <Typography variant={'h5'}>
        {data.businessName || `${user.fullName} (${data.subjectType})`}
      </Typography>
      <UpdatableItemsGroup
        updatePaths={[`/api/users/${user.id}/contract_subjects/${data.id}`]}
        items={items}
        updatable={canUpdateUser()}
      />
    </>
  )
}
