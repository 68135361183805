import React, { FC, useMemo, useState } from 'react'
import { StatusFilter } from '../../enum'
import { ProjectsPageView } from './ProjectsPageView'
import {
  getMemoizedSortedProjects,
  selectableTribes,
  useProjectFiltering,
} from '../../components/ProjectsTable/utils'
import { useQuery } from 'react-query'
import { Projects } from './types'
import { useTribes } from '../../hooks/useTribes'
import { SelectItem } from '../../types'

type Props = {
  categories: SelectItem[]
  billingType: SelectItem[]
}

export const ProjectsPage: FC<Props> = ({ categories, billingType }) => {
  const [selectedProjectsStatus, setSelectedProjectsStatus] =
    useState<StatusFilter>(StatusFilter.Active)

  const { data, isLoading, error } = useQuery<Projects>(
    `/api/projects?state=${selectedProjectsStatus}`
  )

  const { tribes } = useTribes()

  const sortedProjects = getMemoizedSortedProjects(data?.projects)

  const {
    searchString,
    filteredProjects,
    handleFilterChange,
    handleTribeChange,
    owningTribe,
  } = useProjectFiltering(sortedProjects)

  const tribesSelectable = useMemo(() => selectableTribes(tribes), [tribes])

  return (
    <ProjectsPageView
      selectedProjectsStatus={selectedProjectsStatus}
      setSelectedProjectsStatus={setSelectedProjectsStatus}
      handleFilterChange={handleFilterChange}
      handleTribeChange={handleTribeChange}
      projects={filteredProjects}
      searchString={searchString}
      owningTribe={owningTribe}
      metadata={data?.metadata}
      isLoading={isLoading}
      error={error}
      tribes={tribesSelectable}
      categories={categories}
      billingType={billingType}
    />
  )
}
