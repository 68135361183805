import React, { Dispatch, FC, SetStateAction, useLayoutEffect } from 'react'
import { Formik } from 'formik'
import { AssignUserFormView } from './AssignUserFormView'
import { User, Users } from './types'
import { UseMutationResult } from 'react-query/types/react/types'
import { getSelectItems } from '../../utils/helpers'
import { FormikSubmitHandler, SelectItem } from '../../types'

type Props = {
  mutation: UseMutationResult;
  projectId: number;
  user: User | undefined;
  users: Users | undefined;
  isAssigningUserForm: boolean;
  setUserToFetchId: Dispatch<SetStateAction<number | undefined>>;
};

export type JoinProjectFormValues = {
  userId: number | '';
  tribeId: number | '';
  togglProjectId: number | '';
};

export const AssignUserForm: FC<Props> = (props) => {
  const { user, users, isAssigningUserForm, setUserToFetchId, mutation } =
    props

  const availableProjects = user?.availableTogglProjects ?? []
  const projects = getSelectItems(availableProjects, 'label', 'value')

  const togglProjectIdSelectValues: SelectItem[] =
    !user || user.availableTogglProjects.length < 1
      ? [
        {
          label: user?.togglApiKeyInvalid
            ? 'User does not have a valid Toggl API key'
            : 'User has no unassigned toggl projects available',
          value: '',
        },
      ]
      : projects

  const tribeIdSelectValues: SelectItem[] = getSelectItems(
    user?.tribes,
    'name',
    'id'
  )
  const userSelectValues: SelectItem[] = getSelectItems(
    users,
    'fullName',
    'id'
  )

  const getInitialTribeIdValue = () => {
    return user?.tribes?.length == 1 ? user?.tribes[0].value : undefined
  }

  const initialValues: JoinProjectFormValues = {
    userId: user?.id || '',
    togglProjectId: '',
    tribeId: getInitialTribeIdValue() as number,
  }

  const handleUserIdChange = (e, handleChange) => {
    handleChange(e)
    setUserToFetchId(e.target.value)
  }

  const handleSubmit: FormikSubmitHandler<JoinProjectFormValues> = async (
    values,
    formikHelpers
  ) => {
    await mutation.mutate({
      tribe_id: values.tribeId,
      toggl_project_id: values.togglProjectId,
      toggl_project_name:
        user?.availableTogglProjects.filter(
          (project) => project.value === values.togglProjectId
        )[0].label || '',
    })

    formikHelpers.setSubmitting(false)
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(formikProps) => {
        useLayoutEffect(() => {
          formikProps.setFieldValue('tribeId', getInitialTribeIdValue())
        }, [user])

        return (
          <AssignUserFormView
            user={user}
            success={mutation.isSuccess}
            isAssigningUserForm={isAssigningUserForm}
            userSelectValues={userSelectValues}
            tribeIdSelectValues={tribeIdSelectValues}
            toggleProjectIdSelectValues={togglProjectIdSelectValues}
            handleUserIdChange={handleUserIdChange}
            formikProps={formikProps}
          />
        )
      }}
    </Formik>
  )
}
