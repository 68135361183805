import React, { FC, useState, useMemo } from 'react'
import { ProductCategory, ProductData, ProductStatus } from './types'
import { useQuery } from 'react-query'
import { ProductsPageView } from './ProductsPageView'
import { Alert } from '@material-ui/lab'
import { getProductsSortedByName } from './utils'
import { useSearchFieldState } from '../../components/inputs/SearchField'

export const ProductsPage: FC = () => {
  const [selectedProductCategory, setSelectedProductCategory] =
    useState<ProductCategory>(ProductCategory.fridge)
  const [selectedProductStatus, setSelectedProductStatus] =
    useState<ProductStatus>(ProductStatus.active)

  const queryKey = `/api/products?product_group=${selectedProductCategory}&state=${selectedProductStatus}`
  const {
    data: productsData,
    isLoading,
    isError,
  } = useQuery<ProductData | undefined>(queryKey)

  const sortedProducts = getProductsSortedByName(productsData?.products)

  const {
    searchString,
    handleFilterChange,
    compare,
    resetSearchString,
    searchField,
  } = useSearchFieldState()

  const filteredProducts = useMemo(
    () =>
      searchString
        ? sortedProducts.filter((product) =>
          compare([product.name, product.flavor])
        )
        : sortedProducts,
    [sortedProducts, searchString]
  )

  const clearFilters = () => {
    setSelectedProductStatus(ProductStatus.active)
    resetSearchString()
  }

  if (isError) {
    return (
      <Alert
        variant={'outlined'}
        severity={'info'}
      >
        No payments
      </Alert>
    )
  }

  return (
    <ProductsPageView
      products={filteredProducts}
      isLoading={isLoading}
      selectedProductCategory={selectedProductCategory}
      setSelectedProductCategory={setSelectedProductCategory}
      selectedProductStatus={selectedProductStatus}
      setSelectedProductStatus={setSelectedProductStatus}
      clearFilters={clearFilters}
      handleFilterChange={handleFilterChange}
      searchField={searchField}
      resetSearchString={resetSearchString}
    />
  )
}
