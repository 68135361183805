import React, { FC, memo, useCallback, useMemo } from 'react'
import { ProductGroup } from '../../types'
import { BubbleSwitcher } from '../../../../components/BubbleSwitcher'
import { SelectItem } from '../../../../types'

type Props = {
  categories: ProductGroup[];
  handleFilterChange: (selectedCategory: string) => void;
  selectedCategory: string;
};

export const GroupsFilter: FC<Props> = memo(
  ({ handleFilterChange, selectedCategory, categories }) => {
    const options: SelectItem[] = useMemo(
      () =>
        categories.map((category) => ({
          value: category.label,
          label: category.name,
        })),
      [categories]
    )
    const renderItem = useCallback(
      (item: SelectItem) => {
        const category = categories.find(
          (category) => category.label === item.value
        )!
        return `${category.name} (${category.amount})`
      },
      [categories]
    )
    return (
      <BubbleSwitcher
        options={options}
        active={selectedCategory}
        setActive={handleFilterChange}
        renderItem={renderItem}
      />
    )
  }
)
