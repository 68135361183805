import React, { FC, useContext } from 'react'

export type UserContext = {
  // TODO: refactor remove currentUser wrapper
  currentUser: {
    balance: number;
    balanceOverLimit: boolean;
    pairingCode: number;
    billingCurrency: string;
    firstName: string;
    fullName: string;
    id: number;
    lastName: string;
    nickname: string;
    role: string;
    slackAvatar?: {
      image24: string;
      image32: string;
      image48: string;
      image72: string;
      image192: string;
      image512: string;
      image1024: string;
      imageOriginal: string;
    };
  };

  userPermissions?: {
    all?: {
      manage: boolean;
    };
    tribe?: {};
    payment?: {
      create?: boolean;
      manage?: boolean;
      read?: boolean;
      approve?: boolean;
    };
    product?: {
      read?: boolean;
      manage?: boolean;
      bulkEdit?: boolean;
    };
    project?: {
      manage?: boolean;
      pin?: boolean;
      destroy?: boolean;
      assignUser?: boolean;
      unassignUser?: boolean;
      read?: boolean;
      budgetOverview?: boolean;
      preview?: boolean;
      export?: boolean;
    };
    template?: {};
    user?: {
      read?: boolean;
      search?: boolean;
      assignable?: boolean;
      update?: boolean;
      manage?: boolean;
    };
    userInTribe?: {};
    userInProject?: {
      assignUser?: boolean;
      unassignUser?: boolean;
    };
  };
};

type Props = {
  user: UserContext;
};

type UseUserData = () => UserContext;

// @ts-ignore
const CurrentUserContext = React.createContext<UserContext>({})

export const useCurrentUserInfo: UseUserData = () =>
  useContext(CurrentUserContext)
export const useUserPermissions = () => {
  const { userPermissions } = useCurrentUserInfo()
  const resolvePermissions = (resource: string, action: string): boolean => {
    return (
      userPermissions?.all?.manage ||
      userPermissions?.[resource]?.[action] ||
      false
    )
  }
  return { resolvePermissions, permissions: userPermissions }
}

export const UserProvider: FC<Props> = (props) => {
  const { children, user } = props

  return (
    <CurrentUserContext.Provider value={user}>
      {children}
    </CurrentUserContext.Provider>
  )
}
