import React, { FC } from 'react'
import styled from 'styled-components'
import { IconSVGProps } from '../Icon'
import { atMinWidth } from '../../../styles/helpers'

export const SignInIcon: FC<IconSVGProps> = () => (
  <LogoSvg
    width="679"
    height="1050"
    viewBox="0 0 679 1050"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M230.538 589.263H230.207L230.077 589.568L183.139 699.984H0.762663L374.951 -160.5H430.009L803.238 699.984H620.861L573.923 589.568L573.793 589.263H573.462H230.538ZM511.986 448.463H512.713L512.453 447.784L402.947 162.242L402.486 161.04L402.015 162.238L289.628 447.78L289.359 448.463H290.093H511.986ZM625.81 1096.62L625.804 1096.64L625.8 1096.67C625.265 1099.91 624.428 1102.86 623.621 1105.7L623.617 1105.71C622.993 1107.91 622.385 1110.05 621.961 1112.19L621.952 1112.24V1112.29C621.952 1112.46 621.906 1112.71 621.819 1113.04C621.733 1113.35 621.618 1113.71 621.497 1114.07L621.493 1114.08C621.375 1114.44 621.252 1114.81 621.158 1115.16C621.074 1115.47 621.005 1115.8 620.993 1116.09L618.137 1124.75L618.134 1124.76L562.431 1285.95C562.43 1285.96 562.428 1285.96 562.427 1285.96C561.555 1288.16 559.371 1289.28 557.253 1289.28C555.131 1289.28 553.24 1288.17 552.82 1286.04L552.813 1286.01L552.802 1285.97L534.554 1233.53L534.551 1233.53L530.711 1221.88C524.788 1203.91 499.143 1204.93 494.213 1222.88L494.211 1222.88L484.607 1259.78L484.606 1259.79L408.729 1539.47C408.728 1539.48 408.727 1539.48 408.725 1539.49C406.95 1544.84 399.932 1544.84 398.156 1539.49C398.155 1539.48 398.154 1539.48 398.152 1539.47L322.276 1259.79L322.275 1259.78L312.67 1222.88L312.669 1222.88C307.739 1204.93 282.094 1203.91 276.17 1221.88L272.331 1233.53L272.328 1233.53L254.087 1285.95C254.086 1285.96 254.085 1285.96 254.083 1285.96C253.211 1288.16 251.027 1289.28 248.909 1289.28C246.787 1289.28 244.896 1288.17 244.476 1286.04L244.47 1286.01L244.459 1285.98L188.746 1122.81L188.735 1122.78L188.721 1122.75C187.774 1120.83 186.851 1118 186.851 1116.17V1116.09L186.826 1116.02L183.955 1107.31L183.944 1107.27L181.076 1095.67C181.076 1095.67 181.075 1095.66 181.075 1095.66C177.241 1078.22 175.324 1060.77 175.324 1042.36C175.324 909.581 285.553 802.981 418.778 810.732C529.953 818.486 622.932 910.553 631.559 1024.91L632.057 1024.88L631.559 1024.91C633.476 1050.12 631.558 1074.34 625.81 1096.62Z"
      stroke="#00000043"
    />
  </LogoSvg>
)

const LogoSvg = styled.svg`
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  overflow: hidden;
  display: none;

  ${atMinWidth.tablet} {
    display: block;
    right: -300px;
  }

  ${atMinWidth.desktop} {
    right: -350px;
  }

  ${atMinWidth.custom(1500)} {
    right: -250px;
  }

  ${atMinWidth.custom(1680)} {
    right: 0px;
  }

  path {
    stroke: '#00000043';
  }
`
