import React from 'react'
import styled from 'styled-components'
import { Form, FormGroup } from 'react-bootstrap'
import { FormSwitch } from '../../../components/FormSwitch'

type DescriptionCheckboxProps = {
  showDescription: boolean;
  changeHandler: any;
};

const StyledCheck = styled(Form.Check)`
  margin: 0px;
  height: 36px;

  label {
    margin: 0px;
  }
`

export const DescriptionCheckbox = ({
  showDescription,
  changeHandler,
}: DescriptionCheckboxProps) => {
  return (
    <div className={'mr-4 mb-2'}>
      <FormSwitch name="test" />

      <StyledCheck
        id="showDescription"
        type="checkbox"
        label="Show description"
        onChange={() => changeHandler(!showDescription)}
        checked={showDescription}
      />
    </div>
  )
}
