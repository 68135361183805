import React, { FC } from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import { Transaction } from '../../PaymentsPage/types'
import { TransactionTableRow } from './TransactionTableRow'

type Props = {
  accountTransactions: Transaction[];
};

export const TransactionsTable: FC<Props> = ({ accountTransactions }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Paired on</TableCell>
          <TableCell>Account name</TableCell>
          <TableCell>Paired by / note</TableCell>
          <TableCell>Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {accountTransactions.map((transaction, index) => {
          return (
            <TransactionTableRow
              transaction={transaction}
              key={index}
            />
          )
        })}
      </TableBody>
    </Table>
  )
}
