import React, { FC } from 'react'
import { Button } from '../../../components/buttons/Button'
import { PigIcon } from '../../../components/Icon/icons/Pig.icon'
import { Table, TableBody, TableContainer } from '@material-ui/core'
import { ProjectsTableHead } from '../../../components/ProjectsTable/ProjectsTableHead'
import { ProjectTableConfig, ProjectTableItem } from '../../../components/ProjectsTable/types'
import { ProjectsGroup } from '../../../components/ProjectsTable/ProjectsGroup'
import { ProjectRate, Rate } from './types'
import { StyledEmpty } from './styles'

type Props = {
  projects: ProjectRate[];
  showModal: (rate?: Rate) => void;
  openEndValidityModal: (rate?: Rate) => void;
  openDeleteRateModal: (rate?: Rate) => void;
  billingCurrency: string;
};

const tableConfig: ProjectTableConfig = [
  {
    type: ProjectTableItem.Info,
    align: 'right'
  }
]


export const ProjectRateTab: FC<Props> = ({ projects, showModal, openEndValidityModal, openDeleteRateModal, billingCurrency}) => {
  const getProject = (projectName: string, projectRates: Rate[]) => {
    return {
      name: projectName,
      group: '',
      projects: projectRates,
      projectsCount: projectRates.length
    }
  }

  return (
    <div className='my-4'>
      <Button
        kind="basicSecondary"
        id="set-my-rate"
        type="button"
        onClick={() => showModal({ projectId: ''} as Rate)}>
        <PigIcon />
        <span className="pl-2">Set project rate</span>
      </Button>

      <TableContainer>
        <Table>
          <ProjectsTableHead
            tableConfig={tableConfig}
            showLabel={false} />
          <TableBody>
            {projects?.length ? projects.map((project) => (
              <ProjectsGroup
                key={project.projectId}
                group={getProject(project.projectName, project.rates)}
                expandGroup={false}
                tableConfig={tableConfig}
                withRates
                showProjectCount={false}
                openModal={showModal}
                openEndValidityModal={openEndValidityModal}
                openDeleteRateModal={openDeleteRateModal}
                billingCurrency={billingCurrency}
              />
            )) : <StyledEmpty>No projects</StyledEmpty>}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
