import React, { FC, memo } from 'react'
import { TableRow, TableCell, IconButton } from '@material-ui/core'
import { Product, ProductCategory, ProductStatus } from './types'
import { TextFieldData } from '../../types/UpdatableFormFieldData/TextFieldData'
import { ItemField } from '../../components/ui/UpdatableFormField/ItemField'
import ArchiveIcon from '@material-ui/icons/Archive'
import EditIcon from '@material-ui/icons/Edit'
import { useQueryClient } from 'react-query'
import { useQueryNotification } from '../UserInfo/utils'
import styled from 'styled-components'
import { useCustomMutation, Mutation } from '../../hooks/useCustomMutation'
import { FridgeMerchTableCells } from './components/FridgeMerchTableCells'
import { useModalState } from '../../components/ModalWrapper'
import { isFridgeOrMerchSelected } from './utils'
import { ProductModal } from './ProductModal'

type Props = {
  product: Product;
  selectedProductCategory: ProductCategory;
  selectedProductStatus: ProductStatus;
};

export const TableProductRow: FC<Props> = memo(
  ({ product, selectedProductCategory, selectedProductStatus }) => {
    const queryClient = useQueryClient()
    const { isModalOpen, closeModal, openModal } = useModalState(false)
    const { successNotification, errorNotification } = useQueryNotification()
    const { id, name, currentCost } = product

    const createNewSubjectMutation: Mutation<void> = () => ({
      path: `/api/products/${product.id}/archive`,
      method: 'POST',
    })

    const newSubjectMutation = useCustomMutation(
      createNewSubjectMutation,
      {
        onSuccess: async () => {
          await queryClient.refetchQueries(
            `/api/products?product_group=${selectedProductCategory}&state=${selectedProductStatus}`
          )
          successNotification(
            `Product ${product.name} was successfully archived`
          )
        },
        onError: (err: Error) => {
          errorNotification(err.message || 'Sorry something happened')
        },
      },
      true
    )

    const archiveProduct = async () => {
      newSubjectMutation.mutate('')
    }

    return (
      <TableRow>
        <ClickableTableCell onClick={openModal}>{id}</ClickableTableCell>
        <TableCell>
          <ItemField
            updatableField={new TextFieldData(name, 'name')}
            updatePath={`/api/products/${id}`}
            error={''}
            noPadding={true}
            updatable={true}
          />
        </TableCell>
        <StyledTableCell>
          <ItemField
            updatableField={new TextFieldData(currentCost, 'currentCost')}
            updatePath={`/api/products/${id}`}
            error={''}
            noPadding={true}
            updatable={true}
          />
        </StyledTableCell>
        {isFridgeOrMerchSelected(selectedProductCategory) && (
          <FridgeMerchTableCells
            product={product}
            selectedProductCategory={selectedProductCategory}
          />
        )}
        <StyledTableCell>
          <ActionButtons>
            <IconButton onClick={openModal}>
              <EditIcon color="primary" />
            </IconButton>
            <ProductModal
              dialogOpened={isModalOpen}
              closeModal={closeModal}
              product={product}
              selectedProductCategory={selectedProductCategory}
              selectedProductStatus={selectedProductStatus}
            />
            <IconButton onClick={archiveProduct}>
              <ArchiveIcon color="primary" />
            </IconButton>
          </ActionButtons>
        </StyledTableCell>
      </TableRow>
    )
  }
)

const StyledTableCell = styled(TableCell)`
  padding: 8px;
  .jdFAxY {
    min-width: 50px;
  }
`

const ClickableTableCell = styled(StyledTableCell)`
  text-decoration: underline;
`

const ActionButtons = styled.div`
  display: flex;
`
