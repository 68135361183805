import React, { FC } from 'react'
import { RatesTable } from './RatesTable'
import { Rate } from './types'
import { StyledEmpty } from './styles'

type Props = {
  rates: Rate[] | undefined;
  showModal: (rate?: Rate) => void;
  openEndValidityModal: (rate?: Rate) => void;
  openDeleteRateModal: (rate?: Rate) => void;
};

export const HistoryTab: FC<Props> = ({ rates, showModal, openEndValidityModal, openDeleteRateModal }) => {

  return (
    <div className='mt-4'>
      {rates?.length ? <RatesTable
        rates={rates} 
        openModal={showModal}
        openEndValidityModal={openEndValidityModal}
        openDeleteRateModal={openDeleteRateModal}
      /> : <StyledEmpty> No history changes</StyledEmpty>}
    </div>
  )
}
