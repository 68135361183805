import React from 'react'
import { FC } from 'react'
import { colors } from '../../../styles/colors'
import { IconSVGProps } from '../Icon'

export const InfoIcon: FC<IconSVGProps> = ({
  width = 24,
  height = 24,
  color = colors.grey1,
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6879_23094)">
      <path
        d="M14.25 16.4995H13.5C13.1022 16.4995 12.7206 16.3415 12.4393 16.0602C12.158 15.7789 12 15.3973 12 14.9995V11.2495C12 11.0506 11.921 10.8598 11.7803 10.7192C11.6397 10.5785 11.4489 10.4995 11.25 10.4995H10.5"
        stroke={`${color}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M11.625 6.75049C11.5508 6.75049 11.4783 6.77248 11.4167 6.81369C11.355 6.85489 11.3069 6.91346 11.2785 6.98198C11.2502 7.0505 11.2427 7.1259 11.2572 7.19865C11.2717 7.27139 11.3074 7.33821 11.3598 7.39065C11.4123 7.4431 11.4791 7.47881 11.5518 7.49328C11.6246 7.50775 11.7 7.50033 11.7685 7.47194C11.837 7.44356 11.8956 7.3955 11.9368 7.33383C11.978 7.27216 12 7.19966 12 7.12549C12 7.02603 11.9605 6.93065 11.8902 6.86032C11.8198 6.79 11.7245 6.75049 11.625 6.75049Z"
        stroke={`${color}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z"
        stroke={`${color}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_6879_23094">
        <rect
          width="24"
          height="24"
          fill="white" />
      </clipPath>
    </defs>
  </svg>
)
