import React, { FC, useMemo } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from '@material-ui/core'
import { User } from '../types'
import { UsersTableRow } from './UsersTableRow'
import { TableBodySkeleton } from '../../../components/skeletons/TableBodySkeleton'
import { useUserPermissions } from '../../../components/CurrentUserProvider'

type Props = {
  users?: User[];
  isLoading: boolean;
};

export const UsersTable: FC<Props> = ({ users, isLoading }) => {
  const { resolvePermissions } = useUserPermissions()
  const canReadSensitiveData = useMemo(() => resolvePermissions('user', 'readSensitiveData'), [resolvePermissions])

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Nickname</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Tribe memberships</TableCell>
            <TableCell>Rate</TableCell>
            <TableCell>Balance</TableCell>
            { canReadSensitiveData && <TableCell>Shopping table</TableCell> }
            <TableCell>Role</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableBodySkeleton
              rows={30}
              colspan={8}
            />
          )}
          {users?.map((user) => (
            <UsersTableRow
              canReadSensitiveData={canReadSensitiveData}
              user={user}
              key={user.id}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
