import { ChartOptions } from 'chart.js'

export const usersPerPage = 10

export const ChartJSOptions: ChartOptions<'line' | 'bar'> = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
      align: 'end',
      labels: {
        boxWidth: 12,
        boxHeight: 12,
        padding: 16,
        usePointStyle: true,
        pointStyle: 'circle'
      }
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      color: '#000',
      anchor: 'end',
      clamp: false,
      align: 'top',
      display: (context) => {
        const value = context.dataset.data[context.dataIndex]
        return !!value
      },
      formatter: (value) => {
        return Math.round(value * 100) / 100
      },
    },
  },
}
