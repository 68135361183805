import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const Hash: FC<IconSVGProps> = ({
  width = 16,
  height = 16,
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.21729 5.87012H14.9999"
      stroke="#C8C6C5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M1 10.1304H13.7826"
      stroke="#C8C6C5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M4.66504 14.9999L7.10591 0.999939"
      stroke="#C8C6C5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M8.92578 14.9999L11.3667 0.999939"
      stroke="#C8C6C5"
      strokeLinecap="round"
      strokeLinejoin="round" />
  </svg>


)
