import { Box, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import styled from 'styled-components'

import { ContentPaper } from '../../components/ContentPaper'
import { atMinWidth } from '../../styles/helpers'
import { DeviceInfoRow } from './DeviceInfoRow'
import { RowProps } from './types'

type Props = {
  title: string;
  actions: React.ReactNode;
  question?: string;
  rowData: RowProps[];
};

export const DeviceDialog: FC<Props> = ({
  title,
  actions,
  question,
  rowData,
}) => (
  <DialogContentPaper>
    <Title variant="h3">{title}</Title>
    {rowData.map((row) => (
      <DeviceInfoRow
        key={row.id}
        row={row}
      />
    ))}
    {question && (
      <Box
        fontSize="16px"
        mt="40px"
      >
        {question}
      </Box>
    )}
    <ButtonsWrapper>{actions}</ButtonsWrapper>
  </DialogContentPaper>
)

const DialogContentPaper = styled(ContentPaper)`
  padding: 40px;
`

const ButtonsWrapper = styled(Box)`
  display: grid;
  gap: 20px;
  margin-top: 40px;
  ${atMinWidth.tablet} {
    display: flex;
    grid-template-columns: repeat(2, 1fr);
  }
`

const Title = styled(Typography)`
  margin-bottom: 40px;
`
