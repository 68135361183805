import React from 'react'
import { formatSum } from '../utils'
import { TableCell, TableHead, TableRow, Typography } from '@material-ui/core'

type ReportTotalProps = {
  title: string;
  sum: number;
};

export const ReportTotal = ({ title, sum }: ReportTotalProps) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell colSpan={2}>
          <Typography variant="subtitle1">{title}</Typography>
        </TableCell>

        <TableCell align="right">
          <Typography variant="subtitle1">{formatSum(sum)}</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  )
}
