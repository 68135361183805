import React, { FC } from 'react'
import { DataLine } from '../../../../types'
import { TextFieldData } from '../../../../types/UpdatableFormFieldData/TextFieldData'
import { UpdatableItemsGroup } from '../../../../components/ui/UpdatableFormField/UpdatableItemsGroup'
import { TabContainer } from '../../../../components/ui/TabContainer'
import { SelectFieldData } from '../../../../types/UpdatableFormFieldData/SelectFieldData'
import { DateFieldData } from '../../../../types/UpdatableFormFieldData/DateFieldData'
import { useCurrentUserInfo } from '../../../../components/CurrentUserProvider'
import { useUserInfo } from '../UserInfoProvider'
import { SwitchFieldData } from '../../../../types/UpdatableFormFieldData/SwitchFieldData'

type Props = {
  enums: any;
};

export const canUpdateUser: (onlyHr?: boolean) => boolean = (onlyHr = true) => {
  const { currentUser, userPermissions } = useCurrentUserInfo()
  const { user } = useUserInfo()

  if (!user) {
    return false
  }

  return (
    (currentUser.id === user.id && !onlyHr) ||
    userPermissions?.all?.manage === true ||
    userPermissions?.user?.manage === true
  )
}

export const UserInfoTab: FC<Props> = ({ enums }) => {
  const { currentUser } = useCurrentUserInfo()
  const { sensitiveDataAccess, user } = useUserInfo()

  if (!user) {
    return null
  }

  const general: DataLine[] = [
    {
      label: 'First name',
      updatableFields: [new TextFieldData(user.firstName, 'firstName')],
    },
    {
      label: 'Last name',
      updatableFields: [new TextFieldData(user.lastName, 'lastName')],
    },
    {
      label: 'E-mail',
      updatableFields: [new TextFieldData(user.email, 'email', 'email')],
    },
    {
      label: 'Nickname',
      updatableFields: [new TextFieldData(user.nickname, 'nickname')],
    },
    {
      label: 'Primary tribe',
      updatableFields: [
        new SelectFieldData(user.primaryTribeId, 'primary_tribe_id', enums.tribes),
      ],
    },
    {
      label: 'Corplifting role',
      updatableFields: [
        new SelectFieldData(user.role, 'role', enums.userRoles),
      ],
    },
    {
      label: 'Billing currency',
      updatableFields: [
        new SelectFieldData(
          user.billingCurrency,
          'billingCurrency',
          enums.currencies
        ),
      ],
    },
    {
      label: 'Account identifier (VS)',
      updatableFields: [new TextFieldData(user.pairingCode, 'pairingCode')],
    },
  ]

  const personal: DataLine[] = [
    {
      label: 'Date of birth',
      updatableFields: [new DateFieldData(user.birthdate, 'birthdate')],
    },
    {
      label: 'Sex',
      updatableFields: [new SelectFieldData(user.sex, 'sex', enums.sexes)],
    },
    {
      label: 'Nationality',
      updatableFields: [
        new SelectFieldData(user.nationality, 'nationality', enums.countries),
      ],
    },
    {
      label: 'Citizenship',
      updatableFields: [
        new SelectFieldData(user.citizenship, 'citizenship', enums.countries),
      ],
    },
    {
      label: 'Highest education',
      updatableFields: [
        new SelectFieldData(
          user.highestEducation,
          'highestEducation',
          enums.education
        ),
      ],
    },
    {
      label: 'On maternity leave',
      updatableFields: [
        new SwitchFieldData(user.maternityLeave, 'maternity_leave'),
      ],
    },
  ]

  const integrations: DataLine[] = [
    {
      label: 'Toggl Api Key',
      updatableFields: [new TextFieldData(user.togglApiKey, 'togglApiKey')],
    },
    {
      label: 'Toggl Workspace',
      updatableFields: [
        new SelectFieldData(
          user.togglWorkspaceId,
          'togglWorkspaceId',
          user.togglWorkspaces
        ),
      ],
    },
    {
      label: 'Slack User ID',
      updatableFields: [new TextFieldData(user.slackUserId, 'slackUserId')],
    },
  ]

  const notifications: DataLine[] = [
    {
      label: 'Purchase (snack, benefits, merch)',
      updatableFields: [
        new SwitchFieldData(
          user.slackNotifications?.purchase,
          'slack_notifications/purchase'
        ),
      ],
    },
    {
      label: 'Incoming payment',
      updatableFields: [
        new SwitchFieldData(
          user.slackNotifications?.payment,
          'slack_notifications/payment'
        ),
      ],
    },
  ]

  return (
    <TabContainer>
      <UpdatableItemsGroup
        name={'General'}
        items={general}
        updatePaths={[`/api/users/${user.id}`]}
        updatable={canUpdateUser()}
      />
      {sensitiveDataAccess && (
        <UpdatableItemsGroup
          name={'Personal'}
          items={personal}
          updatePaths={[`/api/users/${user.id}`]}
          updatable={canUpdateUser()}
        />
      )}
      {currentUser.id === user.id && (
        <UpdatableItemsGroup
          name={'Integrations'}
          items={integrations}
          updatePaths={[`/api/users/${user.id}`]}
        />
      )}
      {sensitiveDataAccess && (
        <UpdatableItemsGroup
          name={'Slack notifications'}
          items={notifications}
          updatePaths={[`/api/users/${user.id}`]}
          updatable={sensitiveDataAccess}
        />
      )}
    </TabContainer>
  )
}
