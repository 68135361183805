import React, { FC, useRef } from 'react'
import { FormDatePicker } from '../../../inputs/FormDatePicker'
import styled from 'styled-components'
import { IconButton } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import EventIcon from '@material-ui/icons/Event'
import { DateFieldData } from '../../../../types/UpdatableFormFieldData/DateFieldData'
import { UpdatableFormFieldElementProps } from '../UpdatableFormFieldElement'
import { format } from 'date-fns'

export const UpdatableFormDatePicker: FC<
  UpdatableFormFieldElementProps<DateFieldData>
> = (props) => {
  const { updatableField, formikProps, updateField } = props
  const calendarButton = useRef()
  const fieldName = updatableField.fieldName
  const value = formikProps.values[fieldName]
  const dateValue = value ? new Date(value) : value
  const currError = formikProps.errors[fieldName]
  const setValue = (value: MaterialUiPickersDate | null) => {
    if (value && !isNaN(value)) {
      const newValue = format(value, 'yyyy-MM-dd')
      formikProps.setFieldValue(fieldName, newValue)
    } else {
      formikProps.setFieldValue(fieldName, value)
    }
  }

  const removeValue = () => {
    setValue(null)
    updateField()
  }
  const handleError = (error: React.ReactNode) => {
    if (currError !== error) {
      // @ts-ignore -- because of error is ReactNode, but it is working perfectly
      formikProps.setFieldError(fieldName, error)
    }
  }
  const onBlur = (ev: any) => {
    if (!ev.relatedTarget) {
      updateField()
    }
  }

  return (
    <StyledContainer>
      <StyledDatePicker
        value={dateValue}
        autoFocus
        error={!!currError}
        onChange={(date) => setValue(date)}
        onAccept={updateField}
        onBlur={onBlur}
        onError={handleError}
        keyboardIcon={<EventIcon fontSize={'small'} />}
        helperText={''}
      />
      <StyledIconButton
        edge={'start'}
        onClick={removeValue}
      >
        <HighlightOffIcon fontSize={'small'} />
      </StyledIconButton>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledDatePicker = styled(FormDatePicker)`
  flex-grow: 1;
  .MuiInputBase-root {
    height: 32px;
    .MuiOutlinedInput-notchedOutline {
      border-width: 1px !important;
    }
  }
  .MuiInputBase-input {
    font-size: 14px;
    padding-left: 9px;
  }
`

const StyledIconButton = styled(IconButton)`
  margin-bottom: -5px;
  margin-top: -5px;
  margin-left: 1px;
`
