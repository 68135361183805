import { ChangeEvent } from 'react'

export type ShopProduct = {
  id: number;
  name: string;
  productType: string;
  flavor: string;
  currentCost: number;
  count: number;
  inStock: boolean;
  isFavourite: boolean;
  isFrequentlyPurchased: boolean;
  barCode: string;
  barcodes: string[];
};

export type ShopProductsData = {
  metadata: {
    categories: { string: number };
  };
  products: ShopProduct[];
};

export type BasketProduct = {
  product: ShopProduct;
  amount: number;
};

export type FavouriteMutation = {
  productId: number;
  nextState: boolean;
};

export enum AmountOperation {
  PLUS,
  MINUS,
}

export enum ShopProductRowVariant {
  CLASSIC,
  BAG,
}

export type ProductPurchaseData = {
  id: number;
  count: number;
};

export type PurchaseData = {
  on_house: 0 | 1;
  products: ProductPurchaseData[];
};

export type ProductGroup = {
  name: string;
  label: string;
  amount: number;
};
export type ProductCategory = {
  name: string;
  amount: number;
};

export type ProductsInGroups = {
  oftenBuyProducts: BasketProduct[];
  favouriteProducts: BasketProduct[];
  otherProducts: BasketProduct[];
};

export interface ProductsInCategories {
  [key: string]: BasketProduct[];
}

export interface ProductCategoryFilter {
  [key: string]: boolean;
}

export type HandleFilterInputChange = (
  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | any
) => void;
