import React, { FC } from 'react'
import { Switch } from '@material-ui/core'
import styled from 'styled-components'
import { SwitchFieldData } from '../../../../types/UpdatableFormFieldData/SwitchFieldData'
import { UpdatableFormFieldElementProps } from '../UpdatableFormFieldElement'

export const UpdatableFormSwitch: FC<
  UpdatableFormFieldElementProps<SwitchFieldData>
> = (props) => {
  const { updatableField, formikProps, updateField } = props
  const value = formikProps.values[updatableField.fieldName] ?? false
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formikProps.setFieldValue(updatableField.fieldName, event.target.checked)
    updateField()
  }

  return (
    <StyledSwitch
      checked={value}
      value={value}
      onChange={handleOnChange}
    />
  )
}

const StyledSwitch = styled(Switch)`
  margin-left: 9px;
`
