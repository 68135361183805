import React, { FC } from 'react'
import { Avatar, Box } from '@material-ui/core'
import { formatDistance } from 'date-fns'
import styled from 'styled-components'

import { TestingDevices } from './types'
import { LocationTooltip } from './LocationTooltip'

type Props = {
  device: TestingDevices;
};

export const LocationRow: FC<Props> = ({ device }) => {
  const { location, taken, currentUser, sublocation } = device

  if (taken && currentUser) {
    const { slackAvatar, fullName, nickname } = currentUser.user
    const image = slackAvatar.image72
    const countedBorrowedDays = (
      <Box>
        {nickname},{' '}
        {formatDistance(Date.now(), new Date(currentUser.startDate as string))}
      </Box>
    )

    return (
      <Box
        display="flex"
        alignItems="center"
        gridGap="6px"
      >
        <StyledAvatar
          alt="Remy Sharp"
          src={image}
        />
        <Box fontSize="15px">{fullName}</Box>
        <LocationTooltip text={countedBorrowedDays} />
      </Box>
    )
  }

  if (location) {
    const deviceLocation = location.charAt(0).toUpperCase() + location.slice(1)

    return (
      <Box
        display="flex"
        alignItems="center"
      >
        <Box mr="6px">{deviceLocation}</Box>
        <LocationTooltip text={sublocation} />
      </Box>
    )
  }

  return <>-</>
}

const StyledAvatar = styled(Avatar)`
  height: 24px;
  width: 24px;
`
