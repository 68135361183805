import React from 'react'
import { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const ChevronIcon: FC<IconSVGProps> = ({ width = 24, height = 24 }) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      d="M0 0H24V24H0z"
      transform="rotate(90 12 12)"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M17 14.5l-5-5-5 5"
    ></path>
  </svg>
)
