import React, { FC } from 'react'
import { Formik } from 'formik'
import { ExportToCSVFormView } from './ExportToCSVFormView'
import { ProjectExportToCSV, UserForExportToCSV } from '../../types'
import { getExportFromCsvModal } from '../../../../services/api'
import { IdNamePair, SelectItem } from '../../../../types'
import {
  getInitialDate,
  getSelectedDateRangeLinkFormat,
  getUsersSortedByLastName,
} from './helpers'
import { getSelectItems } from '../../../../utils/helpers'
import {
  ExportCSVFormikSubmitHandler,
  ExportToCsvFormValues,
  SelectSetup,
} from './types'
import { DayRange } from 'react-modern-calendar-datepicker'

type Props = {
  projectData?: ProjectExportToCSV;
  userData?: UserForExportToCSV;
  exportTemplates: IdNamePair[];
  month?: string;
};

export const ExportToCSVForm: FC<Props> = (props) => {
  const { projectData, exportTemplates, userData, month } = props

  const requestUrl = projectData
    ? `/projects/${projectData.id}/export.csv`
    : `/users/${userData?.id}/export.csv`

  const getSelectSetup = () => {
    if (userData) {
      const projects: SelectItem[] = [
        {
          value: 'all',
          label: 'all projects',
        },
        ...getSelectItems(userData.projects, 'name', 'id'),
      ]

      return {
        name: 'projectId',
        label: 'Select project',
        values: projects,
      }
    }

    const users: SelectItem[] = projectData
      ? [
        {
          value: 'all',
          label: 'all the people',
        },
        ...getUsersSortedByLastName(projectData.users),
      ]
      : []

    return {
      name: 'user',
      label: 'Select a person',
      values: users,
    }
  }

  const selectSetup: SelectSetup = getSelectSetup()
  const templates: SelectItem[] = getSelectItems(exportTemplates, 'name', 'id')
  const initialDate: DayRange = getInitialDate(month)

  const initialValues: ExportToCsvFormValues = {
    projectId: projectData?.id || 'all',
    user: userData?.id || 'all',
    date: initialDate,
    template: templates[2].value as string, //FIXME: predefining default with 2 decimal places??
  }

  const handleSubmit: ExportCSVFormikSubmitHandler = async (
    values,
    { setSubmitting }
  ) => {
    await getExportFromCsvModal(
      {
        project_id: values.projectId,
        pick_user: values.user,
        date: getSelectedDateRangeLinkFormat(values.date),
        template: values.template,
      },
      requestUrl
    )

    setSubmitting(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <ExportToCSVFormView
          formikProps={formikProps}
          selectSetup={selectSetup}
          templates={templates}
        />
      )}
    </Formik>
  )
}
