import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const PigIcon: FC<IconSVGProps> = ({
  width = 18,
  height = 17,
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 4.75963C10.2975 4.67117 11.5835 5.05234 12.6232 5.83354"
      stroke="#14141F"
      strokeWidth="1.04348"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M17.3331 6.93457C17.3331 7.31894 17.1804 7.68757 16.9086 7.95936C16.6368 8.23115 16.2682 8.38385 15.8838 8.38385"
      stroke="#151B36"
      strokeWidth="1.04348"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M7.22496 2.78897C6.95017 2.30243 6.52239 1.92026 6.00807 1.70182C5.49375 1.48338 4.9217 1.44091 4.38076 1.581C4.31823 1.59736 4.26121 1.63015 4.21562 1.67598C4.17003 1.7218 4.13752 1.77898 4.12148 1.8416C4.10544 1.90422 4.10644 1.96999 4.12438 2.03209C4.14232 2.09419 4.17655 2.15036 4.22351 2.19477C4.57438 2.52542 4.82373 2.94922 4.94235 3.41651C4.96078 3.48971 4.95588 3.56684 4.92833 3.63713C4.90079 3.70741 4.85199 3.76734 4.78873 3.80854C4.10469 4.22815 3.5177 4.78843 3.06669 5.45219C2.61569 6.11596 2.31098 6.86804 2.17279 7.65854H1.39163C1.19944 7.65854 1.01513 7.73488 0.879234 7.87078C0.743338 8.00668 0.666992 8.19099 0.666992 8.38318V9.83245C0.666992 10.0246 0.743338 10.209 0.879234 10.3448C1.01513 10.4807 1.19944 10.5571 1.39163 10.5571H2.62351C3.24549 11.6933 4.17139 12.634 5.2976 13.2739C6.42382 13.9138 7.70589 14.2276 9.00033 14.1803C12.8025 14.1803 15.8844 11.5853 15.8844 8.38318C15.8844 4.63897 11.6815 1.78897 7.22496 2.78897Z"
      stroke="#151B36"
      strokeWidth="1.04348"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M5.19632 7.11523C5.33964 7.11523 5.47974 7.15773 5.5989 7.23736C5.71807 7.31698 5.81095 7.43016 5.8658 7.56257C5.92064 7.69498 5.93499 7.84068 5.90703 7.98124C5.87907 8.12181 5.81006 8.25093 5.70871 8.35227C5.60737 8.45361 5.47825 8.52263 5.33769 8.55059C5.19712 8.57855 5.05142 8.56419 4.91901 8.50935C4.7866 8.4545 4.67343 8.36162 4.5938 8.24246C4.51418 8.12329 4.47168 7.98319 4.47168 7.83987C4.47244 7.64792 4.54903 7.46405 4.68476 7.32832C4.82049 7.19259 5.00437 7.116 5.19632 7.11523Z"
      fill="#151B36" />
    <path
      d="M5.74222 13.4893L5.01758 15.9922"
      stroke="#151B36"
      strokeWidth="1.04348"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M12.2637 13.4893L12.9883 15.9922"
      stroke="#151B36"
      strokeWidth="1.04348"
      strokeLinecap="round"
      strokeLinejoin="round" />
  </svg>

)

