import {
  FormControl,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import { FormikProps } from 'formik'
import React, { FC } from 'react'

import { FormSelect } from '../../../components/inputs/FormSelect'
import { SelectItem } from '../../../types'
import { getSelectItems, hasError, helperText } from '../../../utils/helpers'
import { TypeToggle } from '../../TogglReportPage/components'
import { useTransactionContext } from './MyTransactionContext'
import { CreatePayment, DirectionType } from './types'
import { FormWrapper, StyledFormDatePicker, StyledFormLabel } from '../styles'

interface AddNewProjectProps {
  formikProps: FormikProps<CreatePayment>;
  setDirectionType: (direction: DirectionType) => void;
  directionType: DirectionType
}

export const CreatePaymentForm: FC<AddNewProjectProps> = ({
  formikProps,
  setDirectionType,
  directionType
}) => {
  const { users } = useTransactionContext()
  const { handleChange, setFieldValue, values, touched, errors } = formikProps

  const userSelectValues: SelectItem[] = getSelectItems(
    users,
    'fullName',
    'id'
  )

  const options = [
    { key: DirectionType.deposit, label: 'Deposit' },
    { key: DirectionType.withdrawal, label: 'Withdrawal' },
  ]

  const transactionDate = values.transaction_date ? new Date(values.transaction_date) : null

  return (
    <FormWrapper>
      <FormControl>
        <StyledFormLabel>Direction</StyledFormLabel>
        <TypeToggle
          changeHandler={(direction) => {
            setFieldValue('direction', direction)
            setDirectionType(direction as DirectionType)
          }}
          activeReportType={directionType}
          options={options}
        />
      </FormControl>

      <StyledFormDatePicker
        fullWidth
        label="Date"
        placeholder='Transaction date'
        inputVariant="outlined"
        name='transaction_date'
        onChange={(date) =>
          setFieldValue('transaction_date', date)
        }
        value={transactionDate}
        error={hasError(formikProps, 'transaction_date')}
        helperText={helperText(formikProps, 'transaction_date')}
      />
      <FormSelect
        required
        label={'Person'}
        name='user_id'
        variant='outlined'
        onChange={handleChange}
        values={userSelectValues}
        value={values.user_id}
        error={hasError(formikProps, 'user_id')}
        helperText={helperText(formikProps, 'user_id')}
      />
      <FormControl required>
        <StyledFormLabel>Amount</StyledFormLabel>
        <TextField
          fullWidth
          type="number"
          name="amount"
          variant='outlined'
          value={values.amount}
          inputProps={{ min: 0 }}
          onChange={handleChange}
          InputProps={{
            endAdornment: <InputAdornment
              position="end">CZK</InputAdornment>,
          }}
          error={hasError(formikProps, 'amount')}
          helperText={helperText(formikProps, 'amount')}
        />
      </FormControl>
      <FormControl required>
        <StyledFormLabel>Comment</StyledFormLabel>
        <TextField
          fullWidth
          type="text"
          name="note"
          variant='outlined'
          value={values.note}
          onChange={handleChange}
          error={hasError(formikProps, 'note')}
          helperText={helperText(formikProps, 'note')}
        />
      </FormControl>
    </FormWrapper >
  )
}
