import { useQuery } from 'react-query'
import { Tribe } from '../bundles/ProjectsPage/types'

export const useTribes = () => {
  const { data: tribes } = useQuery<Tribe[]>('/api/tribes')

  const getTribeById = (tribeId: number): Tribe | undefined => {
    return tribes?.find((tribe) => tribe.id === tribeId)
  }
  return {
    tribes,
    getTribeById,
  }
}
