import React, { FC } from 'react'
import { PageLayoutWrapper } from '../../../components/ui/PageLayoutWrapper'
import { Grid } from '@material-ui/core'
import { Skeleton, SkeletonProps } from '@material-ui/lab'
import { ContentPaper } from '../../../components/ContentPaper'
import { ButtonSkeleton } from '../../../components/skeletons/ButtonSkeleton'
import { TypographySkeleton } from '../../../components/skeletons/TypographySkeleton'
import styled from 'styled-components'
import { ProjectBudgetGraph } from './ProjectBudgetGraph'
import { GraphData } from '../types'
import { GraphTypes } from '../../ProjectInfo/types'

type Props = {};
export const ProjectDetailPageSkeleton: FC<Props> = () => {
  const headerButtonProps: React.CSSProperties = {
    marginRight: '20px',
  }
  const usersSkeletonProps: SkeletonProps = {
    width: '100%',
    style: { marginTop: '15px' },
    height: 25,
  }
  const skeletonGraph: GraphData = {
    labels: ['22/01', '22/02', '22/03', '22/04', '22/05', '22/06'],
    year: 2022,
    totalUsedHours: 0,
    datasets: [
      {
        label: 'Dataset',
        data: [0, 10, 5, 2, 20, 30, 45],
      },
    ],
  }
  return (
    <PageLayoutWrapper>
      <Grid
        container
        justify={'space-between'}
        alignItems={'center'}
      >
        <Grid item>
          <Skeleton
            width={400}
            height={58}
          />
        </Grid>
        <Grid item>
          <ButtonSkeleton {...headerButtonProps} />
          <ButtonSkeleton {...headerButtonProps} />
          <ButtonSkeleton {...headerButtonProps} />
          <Skeleton
            style={{ display: 'inline-block' }}
            width={31}
            height={48}
            variant={'rect'}
          />
        </Grid>
      </Grid>
      <StyledContentGraphPaper>
        <Grid
          container
          justify={'space-between'}
          alignItems={'center'}
        >
          <Grid item>
            <TypographySkeleton variant={'h3'} />
          </Grid>
          <Grid item>
            <Skeleton
              width={200}
              height={20}
            />
          </Grid>
        </Grid>
        <ProjectBudgetGraph
          data={skeletonGraph}
          type={GraphTypes.line}
        />
      </StyledContentGraphPaper>
      <Grid
        container
        spacing={6}
      >
        <Grid
          item
          xs={12}
          xl={6}
        >
          <ContentPaper>
            <TypographySkeleton variant={'h3'} />
            <Skeleton {...usersSkeletonProps} />
            <Skeleton {...usersSkeletonProps} />
            <Skeleton {...usersSkeletonProps} />
          </ContentPaper>
        </Grid>
        <Grid
          item
          xs={12}
          xl={6}
        >
          <ContentPaper>
            <Grid
              container
              justify={'space-between'}
              alignItems={'center'}
            >
              <Grid item>
                <TypographySkeleton variant={'h3'} />
              </Grid>
              <Grid item>
                <ButtonSkeleton />
              </Grid>
            </Grid>
            <Skeleton {...usersSkeletonProps} />
            <Skeleton {...usersSkeletonProps} />
            <Skeleton {...usersSkeletonProps} />
          </ContentPaper>
        </Grid>
      </Grid>
    </PageLayoutWrapper>
  )
}

const StyledContentGraphPaper = styled(ContentPaper)`
  margin-bottom: 40px;
  margin-top: 40px;
  height: 586px;
  canvas {
    margin-top: 30px;
    opacity: 0.2;
  }
`
