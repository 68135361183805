import React, { FC, memo, useCallback, useMemo } from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import styled, { css } from 'styled-components'
import { colors } from '../../../styles/colors'
import { BasketProduct, ShopProductRowVariant } from '../types'
import { ShopProductAmountSelection } from './ShopProductAmountSelection'
import { atMinWidth } from '../../../styles/helpers'
import { theme } from '../../../styles/muiThemes'
import { LikeButton } from './LikeButton'

type Props = {
  basketProduct: BasketProduct;
  variant?: ShopProductRowVariant;
  searchString?: string;
};

export const ShopProductRow: FC<Props> = ({
  basketProduct,
  searchString,
  variant,
}) => {
  const resolvedVariant = useMemo(
    () => variant || ShopProductRowVariant.CLASSIC,
    [variant]
  )
  const isBag = useMemo(
    () => resolvedVariant === ShopProductRowVariant.BAG,
    [resolvedVariant]
  )
  const productData = useMemo(() => basketProduct.product, [basketProduct])

  const reg = useMemo(
    () => new RegExp(searchString ?? '', 'gi'),
    [searchString]
  )
  const setHighLight = useCallback(
    (text: string): string => {
      if (!searchString) return text
      return text.replace(
        reg,
        (str) => `<span class='highlight'>${str}</span>`
      )
    },
    [searchString]
  )

  const name = useMemo(
    () => setHighLight(productData.name),
    [productData.name]
  )
  const flavour = useMemo(
    () => setHighLight(productData.flavor ?? ''),
    [productData.flavor]
  )
  return useMemo(
    () => (
      <ProductRow
        $selected={isBag && basketProduct.amount > 0}
        $variant={resolvedVariant}
      >
        <NameCell>
          <ProductName dangerouslySetInnerHTML={{ __html: name }} />
          <ProductType
            variant={resolvedVariant}
            dangerouslySetInnerHTML={{ __html: flavour }}
          />
        </NameCell>

        <PriceCell align={'right'}>
          <ProductPrice>{productData.currentCost} Kč</ProductPrice>
        </PriceCell>

        {!isBag && (
          <HeartCell align={'right'}>
            <LikeButton
              liked={productData.isFavourite}
              id={productData.id}
            />
          </HeartCell>
        )}

        <ShopProductAmountSelection basketProduct={basketProduct} />
      </ProductRow>
    ),
    [productData.isFavourite]
  )
}

export const ProductRow = memo(styled(TableRow)<{
  $selected: boolean;
  $variant: ShopProductRowVariant;
}>`
  margin-left: 40px;
  position: relative;
  font-size: 1rem;
  ${theme.breakpoints.between('md', 'lg')} {
    font-size: 0.9rem;
  }
  &:hover {
    background: none !important;
  }
  &::after {
    content: '';
    width: 4px;
    height: 100%;
    background: transparent;
    position: absolute;
    transition: background-color 300ms;
    left: calc(-1rem - 6px);
    ${atMinWidth.desktopLarge} {
      left: -46px;
    }
  }
  .highlight {
    background: rgba(250, 199, 31, 0.2);
  }
  & td {
    font-size: 1em;
    ${(props) =>
    props.$variant === ShopProductRowVariant.BAG &&
      css`
        border-color: rgba(0, 0, 0, 0.1); ;
      `}
  }
  ${(props) =>
    props.$selected &&
    props.$variant !== ShopProductRowVariant.BAG &&
    css`
      &::after {
        background-color: black;
      }
    `}
`)

const PriceCell = memo(styled(TableCell)`
  white-space: nowrap;
`)

const HeartCell = memo(styled(TableCell)`
  padding-left: 0;
  padding-right: 0;
`)

export const NameCell = memo(styled(TableCell)`
  width: 100%;
  padding-right: 0;
  padding-left: 0;
`)

const ProductName = memo(styled.h4`
  font-size: 1.1em;
  font-weight: 700;
`)

const ProductType = memo(styled.p<{ variant: ShopProductRowVariant }>`
  font-size: 1em;
  color: ${(props) =>
    props.variant === ShopProductRowVariant.BAG
      ? 'rgba(0, 0, 0, 0.6)'
      : colors.grey0};
  margin-bottom: 0;
`)

export const ProductPrice = memo(styled.p`
  font-size: 1.2em;
  font-weight: 400;
  color: black;
  margin: 0;
  line-height: 1;
`)
