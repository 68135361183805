import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const MyPurchases: FC<IconSVGProps> = ({ width = 20, height = 42 }) => (
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.647 13.227l-.783-6.272H3.318L1.5 21.5h14.546"
      stroke="#C8C6C5"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M16.046 15.136v1.818h1.818"
      stroke="#C8C6C5"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M16.046 21.5a4.545 4.545 0 100-9.09 4.545 4.545 0 000 9.09zM6.954 9.682V5.136c0-2 1.636-3.636 3.637-3.636 2 0 3.636 1.636 3.636 3.636v4.546"
      stroke="#C8C6C5"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
)
