import React, { FC } from 'react'
import styled from 'styled-components'
import { Barcode } from '../types'
import { colors } from '../../../styles/colors'
import CheckIcon from '@material-ui/icons/Check'
import WarningIcon from '@material-ui/icons/Warning'
import HelpIcon from '@material-ui/icons/Help'

type Props = {
  barcodeType?: Barcode;
};

export const BarcodeIcon: FC<Props> = ({ barcodeType }) => {
  return (
    <>
      {!barcodeType && <StyledQuestionIcon />}
      {barcodeType === Barcode.error && <StyledWarningIcon />}
      {barcodeType === Barcode.invalid && <StyledHelpIcon />}
      {barcodeType === Barcode.success && <StyledCheckIcon />}
    </>
  )
}

export const StyledCheckIcon = styled(CheckIcon)`
  color: #186859;
`

export const StyledWarningIcon = styled(WarningIcon)`
  color: #7e011e;
`

export const StyledHelpIcon = styled(HelpIcon)`
  color: #7d6003;
`

export const StyledQuestionIcon = styled(HelpIcon)`
  color: ${colors.grey5};
`
