import React, { FC } from 'react'
import { UpdatableItemsGroup } from '../../components/ui/UpdatableFormField/UpdatableItemsGroup'
import { DataLine } from '../../types'
import { TextFieldData } from '../../types/UpdatableFormFieldData/TextFieldData'
import { TestingDevices } from '../TestingDevicesPage/types'

type Props = {
  device: TestingDevices;
};

export const getItems = (device: TestingDevices): DataLine[] => {
  return [
    {
      label: 'Name',
      updatableFields: [new TextFieldData(device.name || '', 'name')],
    },
    {
      label: 'Brand',
      updatableFields: [new TextFieldData(device.brand, 'brand')],
    },
    {
      label: 'Serial',
      updatableFields: [new TextFieldData(device.serial, 'serial')],
    },
    {
      label: 'System version',
      updatableFields: [
        new TextFieldData(device.systemVersion, 'systemVersion'),
      ],
    },
    {
      label: 'Location',
      updatableFields: [new TextFieldData(device.location || '', 'location', 'text', { disabled: true })],
    },
    {
      label: 'Room',
      updatableFields: [new TextFieldData(device.sublocation, 'sublocation')],
    },
    {
      label: 'Inventory number',
      updatableFields: [
        new TextFieldData(device.inventoryNumber, 'inventoryNumber'),
      ],
    },
    {
      label: 'Notion url',
      updatableFields: [new TextFieldData(device.inventoryUrl, 'inventoryUrl')],
    },
  ]
}

export const DeviceInfoTabs: FC<Props> = ({ device }) => {
  return (
    <UpdatableItemsGroup
      items={getItems(device)}
      updatePaths={[`/api/testing_devices/${device.id}`]}
    />
  )
}
