import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const FoodAndDrinksIcon: FC<IconSVGProps> = ({
  width = 16,
  height = 24,
  color = '#C8C6C5'
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 16 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.811 9.311L7.25 6.75V1.5A.75.75 0 006.5.75H5a.75.75 0 00-.75.75v5.25L1.689 9.311a1.5 1.5 0 00-.439 1.06V21.75a1.5 1.5 0 001.5 1.5H6.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M4.25 4.5h3M14.75 15.682a3 3 0 01-6 0V12h6v3.682zM11.75 18.75v4.5M14 23.25H9.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
