import {
  FormControl,
  FormLabel,
  InputAdornment,
  TextField,
  debounce,
} from '@material-ui/core'
import React, { FC, useMemo } from 'react'
import { directions } from '../utils'
import { FormikProps, FormikValues } from 'formik'
import { FormSelect } from '../../../components/inputs/FormSelect'
import { theme } from '../../../styles/muiThemes'
import styled from 'styled-components'

interface Props {
  formikProps: FormikProps<FormikValues>
}

export const AmountFilter: FC<Props> = ({ formikProps }) => {
  const debouncehandleSubmit = useMemo(() => debounce(() => { formikProps.handleSubmit() }, 1000), [])
  return (
    <>
      <DirectionFormControl fullWidth>
        <FormLabel>Payment direction</FormLabel>
        <FormSelect
          name='direction'
          onChange={(value) => {
            formikProps.handleChange(value)
            formikProps.handleSubmit()
          }}
          values={directions} />
      </DirectionFormControl>
      <RangeWrapper>
        <FormControl fullWidth>
          <FormLabel>Amount from</FormLabel>
          <TextField
            fullWidth
            type="number"
            name="amount_from"
            value={formikProps.values.amount_from}
            variant="outlined"
            inputProps={{ min: 0 }}
            onChange={(value) => {
              formikProps.handleChange(value)
              debouncehandleSubmit()
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">CZK</InputAdornment>,
            }}

          ></TextField>
        </FormControl>

        <FormControl fullWidth>
          <FormLabel>Amount to</FormLabel>
          <TextField
            fullWidth
            type="number"
            name="amount_to"
            value={formikProps.values.amount_to}
            variant="outlined"
            inputProps={{ min: Number(formikProps.values.amount_from) + 1 }}
            onChange={(value) => {
              formikProps.handleChange(value)
              debouncehandleSubmit()
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">CZK</InputAdornment>,
            }}
          ></TextField>
        </FormControl>
      </RangeWrapper>
    </>
  )
}

const DirectionFormControl = styled(FormControl)`
  ${theme.breakpoints.up('2xl')} {
    flex: 1 0 160px;
  }
`

const RangeWrapper = styled.div`
  display: contents;

  ${theme.breakpoints.up('sm')} {
    display: flex;
    column-gap: var(--column-gap-desktop);
  }

  ${theme.breakpoints.up('md')} {
    display: contents;
  }

  ${theme.breakpoints.up('2xl')} {
    & > * {
      flex: 1 0 160px;
    }
  }
`
