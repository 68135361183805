import React, { FC } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { ContentPaper } from '../../components/ContentPaper'
import { UserPaymentsTable } from './UserPaymentsTable'
import { Alert } from '@material-ui/lab'
import { Payment } from '../UserInfo/types'
import { atMinWidth } from '../../styles/helpers'

type Props = {
  userId: number;
  lastName: string;
};

export const UserPayments: FC<Props> = ({ userId }) => {
  const queryKey = `/api/users/${userId}/payments`
  const {
    data: userPayments,
    isLoading,
    isError,
  } = useQuery<Payment[]>(queryKey)

  if (isError || userPayments == null) {
    return (
      <Alert
        variant={'outlined'}
        severity={'info'}
      >
        No payments
      </Alert>
    )
  }

  return (
    <StyledContentPaper>
      <Typography variant="h3">Payments</Typography>
      <UserPaymentsTable
        userPayments={userPayments}
        isLoading={isLoading}
        userId={userId}
      />
    </StyledContentPaper>
  )
}

const StyledContentPaper = styled(ContentPaper)`
  width: 100%;

  ${atMinWidth.desktop} {
    width: 48%;
  }
`
