import React from 'react'
import styled from 'styled-components'
import { ReportType } from '../types'

export const TdRight = styled.td`
  text-align: right;
`

const StyledTr = styled.tr`
  padding-top: 8px;
  padding-bottom: 8px;
`

export const NoDataToShow = ({ reportType, params }) => {
  const entitySelected =
    (reportType === ReportType.user && params.user_id !== 'all') ||
    (reportType === ReportType.project && params.project_id !== 'all')

  return (
    <thead>
      <StyledTr>
        <td colSpan={3}>
          {entitySelected ? (
            <p>No data for this {reportType} in selected period.</p>
          ) : (
            <p>Select {reportType} first.</p>
          )}
        </td>
      </StyledTr>
    </thead>
  )
}
