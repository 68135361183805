export const directions = [
  { value: 'all', label: 'All' },
  { value: 'income', label: 'Income' },
  { value: 'expense', label: 'Expense' },
]

export const transactionCategories = [
  { value: 'all', label: 'All' },
  { value: 'benefit', label: 'Benefit' },
  { value: 'fridge', label: 'Fridge' },
  { value: 'merch', label: 'Merch' },
  { value: 'payment', label: 'Payment' },
]

export const initialState = {
  scope: 'all',
  date_from: '',
  date_to: '',
  amount_from: '',
  amount_to: '',
  direction: 'all',
  per_page: '20',
}
