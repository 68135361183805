import React, { FC } from 'react'
import { Button } from '@material-ui/core'
import styled from 'styled-components'

import { FoodAndDrinksIcon } from '../../../components/Icon/icons/FoodAndDrinks.icon'
import { ModalWrapper, useModalState } from '../../../components/ModalWrapper'
import { Download } from '../../../components/Icon/icons/Download.icon'
import { Hash } from '../../../components/Icon/icons/Hash.icon'
import { PlaneIcon } from '../../../components/Icon/icons/Plane.icon'
import { ShoppingCartIcon } from '../../../components/Icon/icons/ShoppingCart.icon'
import { atMinWidth } from '../../../styles/helpers'

export const GetSnackStepsInfo: FC = () => {
  const { isModalOpen, closeModal, openModal } = useModalState(false)
  return (
    <>
      <StyledButton
        startIcon={<FoodAndDrinksIcon color='#000' />}
        onClick={openModal}>
        <span>How to set up my getsnack?</span>
      </StyledButton>
      <ModalWrapper
        isOpen={isModalOpen}
        closeModal={closeModal}
      >
        <StyledTitle>How to set up my GetSnack?</StyledTitle>
        <StyledStep><Download /><span>Download the app from <a href="https://apps.apple.com/app/getsnack/id1609229533">Apple store</a> or <a href="https://play.google.com/store/apps/details?id=cz.applifting.getsnack">Google Play</a></span></StyledStep>
        <StyledStep><PlaneIcon /><span>Sign in with your company e-mail adress</span></StyledStep>
        <StyledStep><Hash /><span>Add snackbar ID: <Underline>snackbar.applifting.cz</Underline></span></StyledStep>
        <StyledStep><ShoppingCartIcon /><span>Enjoy your shopping!</span></StyledStep>
        <ContactWrapper>Having troubles? Do not hesitate to contact us on Slack!</ContactWrapper>
      </ModalWrapper>
    </>
  )
}

const StyledButton = styled(Button)`
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: left;
  padding: 5px;
  padding-left: 10px;
  width: fit-content;

  &:hover {
    background: #fff;
  }

  .MuiButton-startIcon {
    margin-left: 0;
  }

  ${atMinWidth.mobile} {
    padding: 10px;
  }

  ${atMinWidth.desktop} {
    background: none;
    margin-left: 0;
    padding: 0;
    width: fit-content;

    &:hover {
      background: none;
    }
  }

  ${atMinWidth.desktopLarge} {
    height: 48px;
  }

  span {
    font-size: 11px;
    line-height: 12px;

    ${atMinWidth.desktop} {
      font-size: 12px;
    }

    ${atMinWidth.desktopLarge} {
      font-size: 16px;
      line-height: 18px;
    }
  }

  svg {
    margin-right: 10px;

    ${atMinWidth.desktop} {
      margin-right: 8px;
    }

    ${atMinWidth.desktopLarge} {
      margin-right: 22px;
    }
  }
`
const StyledTitle = styled.p`
  font-family: 'Ginto Normal Regular';
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 34px;
  margin-bottom: 32px;

`
const StyledStep = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;

  ${atMinWidth.mobile} {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 16px;
  }

  svg {
    margin-right: 9px;
  }
`
const Underline = styled.span`
  text-decoration: underline;
`
const ContactWrapper = styled.div`
  font-weight: 400;
  line-height: 23px;
  padding-top: 16px;
`
