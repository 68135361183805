import React, { FC } from 'react'
import { TableCell, TableHead, TableRow } from '@material-ui/core'
import { ProjectTableConfig, ProjectTableItem } from './types'

type Props = { tableConfig: ProjectTableConfig, showLabel?: boolean };

export const ProjectsTableHead: FC<Props> = (props) => {
  const { tableConfig, showLabel = true } = props

  const labelOptions = {
    [ProjectTableItem.Tribe]: 'tribe',
    [ProjectTableItem.TLActions]: 'actions',
    [ProjectTableItem.Join]: 'actions',
    [ProjectTableItem.Info]: 'billing to',
  }

  const getHeadLabel = (item) => labelOptions[item.type]

  return (
    <TableHead>
      <TableRow>
        <TableCell>project</TableCell>

        {tableConfig.map((item, key) => (
          <TableCell
            key={key}
            align={item?.align || 'left'}
          >
            {showLabel && getHeadLabel(item)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
