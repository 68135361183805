import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { FormDatePicker } from '../../../components/inputs/FormDatePicker'
import { format } from 'date-fns'
import { Filter } from '../types'

type Props = {
  filters: Filter;
  setFilters: (filters: Filter) => void;
};

export const DateFilter: FC<Props> = ({ filters, setFilters }) => {
  const [invalidStartDate, setInvalidStartDate] = useState(false)
  const [invalidEndDate, setInvalidEndDate] = useState(false)
  const { startDate, endDate } = filters

  const handleDateChange = (
    filterName: string,
    date: Date | 'Invalid Date' | null
  ) => {
    if (date != 'Invalid Date' && date != null) {
      setFilters({ ...filters, [filterName]: format(date, 'yyyy-MM-dd') })
      filterName == 'startDate'
        ? setInvalidStartDate(false)
        : setInvalidEndDate(false)
    } else {
      filterName == 'startDate'
        ? setInvalidStartDate(true)
        : setInvalidEndDate(true)
    }
  }

  const startDateObject = startDate ? new Date(startDate) : null
  const endDateObject = endDate ? new Date(endDate) : null

  return (
    <div>
      <StyledFormDatePicker
        fullWidth
        label={'Date from'}
        onChange={(date) => {
          handleDateChange('startDate', date)
        }}
        value={startDateObject}
        error={invalidStartDate}
        helperText={invalidStartDate && 'Invalid date format'}
      />

      <StyledFormDatePicker
        fullWidth
        label={'Date until'}
        onChange={(date) => {
          handleDateChange('endDate', date)
        }}
        value={endDateObject}
        error={invalidEndDate}
        helperText={invalidEndDate && 'Invalid date format'}
      />
    </div>
  )
}

const StyledFormDatePicker = styled(FormDatePicker)`
  margin-bottom: 2rem;
`
