import React, { ComponentProps, FC, useMemo } from 'react'
import { FormControl, FormLabel, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { FormikProps, FormikValues } from 'formik'
import { theme } from '../../../styles/muiThemes'
import styled from 'styled-components'
import { BaseUser } from '../../../types'

interface Props {
  users: BaseUser[]
  formikProps: FormikProps<FormikValues>
}

interface Option {
  id: number
  label: string
}

export const UsersFilter: FC<Props> = ({ formikProps, users }) => {
  const handleAutocompleteChange: ComponentProps<typeof Autocomplete>['onChange'] = (_, value: Option) => {
    formikProps.setFieldValue('user_id', value?.id, true)
    formikProps.handleSubmit()
  }

  const options = useMemo(() => {
    return users.map((user) => ({
      id: user.id,
      label: [user.nickname, user.fullName].filter(Boolean).join(' - '),
    }))
  }, [users])

  return (
    <StyledFormControl fullWidth>
      <FormLabel>Person</FormLabel>
      <Autocomplete
        key={formikProps.values.user_id}
        id='tags-outlined'
        options={options}
        getOptionLabel={(option: Option) => option.label}
        onChange={handleAutocompleteChange}
        value={options.find((option) => option.id === formikProps.values.user_id || '')}
        clearOnBlur={true}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            name='user_id'
            variant="outlined"
            placeholder="All"
          />
        )}
      />
    </StyledFormControl>
  )
}

const StyledFormControl = styled(FormControl)`
  ${theme.breakpoints.up('xl')} {
    flex: 1 0 240px !important;
  }
`

const StyledTextField = styled(TextField)`
  input {
    padding: 0 !important;

    ::placeholder {
      opacity: 1;
    }
  }
`
