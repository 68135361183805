import React, { FC } from 'react'
import { ModalWrapper, useModalState } from '../../components/ModalWrapper'
import { ExportToCSVModalContent } from './ExportToCSVModalContent'
import { ModalTriggerButton, ModalTriggerButtonType } from '../../components/ModalTriggerButton'

type Props = {
  projectId?: number;
  userId?: number;
  month?: string;
  buttonType?: ModalTriggerButtonType;
};

export const ExportToCSVModal: FC<Props> = (props) => {
  const { projectId, userId, month, buttonType } = props
  const { openModal, isModalOpen, closeModal } = useModalState()

  return (
    <>
      <ModalTriggerButton
        onClick={openModal}
        buttonType={buttonType}
      />

      <ModalWrapper
        isOpen={isModalOpen}
        closeModal={closeModal}
      >
        <ExportToCSVModalContent
          projectId={projectId}
          userId={userId}
          month={month}
        />
      </ModalWrapper>
    </>
  )
}
