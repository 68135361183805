import React, { FC, useMemo } from 'react'

import { Chart as ChartJS, ChartOptions, registerables } from 'chart.js'
import { Bar, Line } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { colorChartJSDataset } from '../utils'
import { GraphData } from '../types'
import { GraphTypes } from '../../ProjectInfo/types'
import styled from 'styled-components'
import { atMinWidth } from '../../../styles/helpers'
import { ChartJSOptions } from '../config'

ChartJS.register(...registerables)
ChartJS.register(ChartDataLabels)

type Props = {
  data: GraphData;
  type: GraphTypes;
};
export const ProjectBudgetGraph: FC<Props> = ({ data, type }) => {
  const coloredData = useMemo(() => colorChartJSDataset(data), [data])

  const types = {
    line: (
      <Line
        data={coloredData}
        height={60}
        options={ChartJSOptions}
      />
    ),
    bar: (
      <Bar
        data={coloredData}
        height={60}
        options={ChartJSOptions}
      />
    ),
  }
  return <GraphContainer>{types[type]}</GraphContainer>
}

const GraphContainer = styled.div`
  position: relative;
  height: 250px;
  width: 100%;

  /* prevent overflow on resize */
  canvas {
    max-width: 100%;
  }

  ${atMinWidth.desktopLarge} {
    height: 450px;
  }
`
