import React, { FC, useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { useQuery } from 'react-query'
import { PageLayoutWrapper } from '../../components/ui/PageLayoutWrapper'
import { ContentPaper } from '../../components/ContentPaper'
import { DeviceHistory } from './DeviceHistory'
import { Button } from '../../components/buttons/Button'
import { UpdatableItemsGroup } from '../../components/ui/UpdatableFormField/UpdatableItemsGroup'
import { TestingDevices } from '../TestingDevicesPage/types'
import { TestingDeviceQRCode } from '../TestingDevicesPage/TestingDeviceQRCode'
import { RemoveTestingDevice } from '../TestingDevicesPage/RemoveTestingDevice'
import { Alert, Skeleton } from '@material-ui/lab'
import { BreadcrumbsNav } from '../../components/BreadcrumbsNav'
import { BreadcrumbsData } from '../../types/index'
import { TestingDeviceDetailSkeleton } from './TestingDeviceDetailSkeleton'
import { getItems } from './DeviceInfoTabs'
import { HeaderContainer, ButtonWrapper, ProjectDetailGrid } from './styles'

type Props = {
  device: TestingDevices;
};

export const DeviceInfoTabs: FC<Props> = ({ device }) => {
  return (
    <UpdatableItemsGroup
      items={getItems(device)}
      updatePaths={[`/api/testing_devices/${device.id}`]}
    />
  )
}

export const TestingDeviceDetailPage: FC = () => {
  const [dialogQRCodeOpened, setDialogQRCodeOpened] = useState(false)
  const [dialogRemoveDeviceOpened, setDialogRemoveDeviceOpened] =
    useState(false)
  const devicesDetailPathname = window.location.pathname
  const deviceId = devicesDetailPathname.split('/').pop()

  const queryKey = `/api/testing_devices/${deviceId}`
  const {
    data: device,
    isLoading,
    isError,
  } = useQuery<any>(queryKey, {
    enabled: deviceId != null,
  })

  if (isError && device == null) {
    return (
      <Alert
        variant={'outlined'}
        severity={'info'}
      >
        No devices detail information
      </Alert>
    )
  }

  const breadcrumbsLinks: BreadcrumbsData = {
    links: [
      {
        id: 1,
        title: 'Testing devices',
        href: '/admin/testing_devices',
      },
    ],
    currentPageTitle: device?.name,
  }

  return (
    <>
      <BreadcrumbsNav
        data={breadcrumbsLinks}
        isLoading={isLoading} />
      <PageLayoutWrapper>
        <HeaderContainer>
          {isLoading ? <Skeleton
            variant="rect"
            width={481}
            height={56}
            animation="wave" /> :
            <>
              <Typography variant={'h1'}>{device.name}</Typography>
              <Box display="flex">
                <ButtonWrapper>
                  <Button
                    kind={'primary'}
                    onClick={() => setDialogQRCodeOpened(true)}>
                    QR code
                  </Button>
                  <Button
                    kind={'primary'}
                    onClick={() => setDialogRemoveDeviceOpened(true)}>
                    Remove
                  </Button>
                </ButtonWrapper>
                <RemoveTestingDevice
                  device={device}
                  dialogOpened={dialogRemoveDeviceOpened}
                  setDialogOpened={setDialogRemoveDeviceOpened}
                />
                <TestingDeviceQRCode
                  device={device}
                  dialogOpened={dialogQRCodeOpened}
                  setDialogOpened={setDialogQRCodeOpened}
                />
              </Box>
            </>
          }
        </HeaderContainer>
        <ProjectDetailGrid>
          <ContentPaper>
            <Typography variant={'h3'}>Info</Typography>
            {isLoading ? <TestingDeviceDetailSkeleton rows={8} /> :
              <DeviceInfoTabs device={device} />
            }
          </ContentPaper>
          <DeviceHistory
            device={device}
            isLoading={isLoading} />
        </ProjectDetailGrid>
      </PageLayoutWrapper>
    </>
  )
}
