import { PaymentsStatus } from './types'

export const switcherData = [
  {
    value: PaymentsStatus.ALL,
    label: `${PaymentsStatus.ALL}`,
  },
  {
    value: PaymentsStatus.UNPAIRED,
    label: `${PaymentsStatus.UNPAIRED}`,
  },
]

export const initialState = {
  searchString: '',
  page: 1,
  pageCount: 0,
  startDate: '',
  endDate: '',
  amountFrom: '',
  amountTo: '',
}

export const normalizeQuery = (query: string): string => {
  return query.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}
