export type FieldOptions = {
  disabled?: boolean;
  alternatePath?: string;
}
export abstract class UpdatableField {
  fieldName: string;
  defaultValue: string | number | boolean | Date;
  keepVisible: boolean;
  fieldOptions?: FieldOptions;

  protected constructor(
    defaultValue: string | number | boolean | Date,
    fieldName: string,
    fieldOptions?: FieldOptions
  ) {
    this.defaultValue = defaultValue
    this.fieldName = fieldName
    this.fieldOptions = fieldOptions
  }

  getLabel(): string {
    return this.defaultValue ? String(this.defaultValue) : '-'
  }
}
