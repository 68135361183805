import React, { FC } from 'react'
import { Chip, Tooltip } from '@material-ui/core'
import styled, { css } from 'styled-components'
import { ValidatedBarcodes, Barcode } from '../types'
import { colors } from '../../../styles/colors'
import CancelIcon from '@material-ui/icons/Cancel'
import { getBarcodeType, getColor } from '../utils'
import { BarcodeIcon } from './BarCodeIcon'

type Props = {
  barcode: string;
  productBarcodes: string[];
  setProductBarcodes: (productBarcodes: string[]) => void;
  validatedBarcodes: ValidatedBarcodes | null;
};

export const BarCode: FC<Props> = ({
  barcode,
  productBarcodes,
  setProductBarcodes,
  validatedBarcodes,
}) => {
  const deleteBarcode = () => {
    const barcodes = productBarcodes.filter((productBarcode) => {
      return productBarcode != barcode
    })
    setProductBarcodes(barcodes)
  }

  const barcodeType = getBarcodeType(validatedBarcodes, barcode)
  const alreadyUsedInProduct =
    validatedBarcodes &&
    validatedBarcodes?.[barcode] &&
    validatedBarcodes?.[barcode].product

  const tooltipText =
    barcodeType === Barcode.error
      ? `Already used in ${alreadyUsedInProduct}`
      : barcodeType === Barcode.invalid
        ? 'May be invalid'
        : ''

  const color = getColor(barcodeType)

  return (
    <Tooltip
      title={tooltipText}
      placement="top-start"
      arrow
    >
      <StyledChip
        label={barcode}
        icon={<BarcodeIcon barcodeType={barcodeType} />}
        onDelete={deleteBarcode}
        deleteIcon={<StyledDeleteIcon />}
        $chipColor={color}
      />
    </Tooltip>
  )
}

const StyledChip = styled(Chip)<{ $chipColor: string }>`
  ${(props) => css`
    min-width: 160px;
    background-color: ${props.$chipColor};
    justify-content: space-between;
    padding: 0 6px;

    &:focus {
      background-color: ${props.$chipColor};
    }

    .MuiChip-label {
      padding-left: 4px;
      padding-right: 10px;
    }
  `}
`

const StyledDeleteIcon = styled(CancelIcon)`
  color: ${colors.grey5};
`
