import React, { FC } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { SyncIcon } from '../Icon/icons/Sync.icon'
import styled, { css } from 'styled-components'
import { Icon } from '../Icon'
import { useSnackbar } from 'notistack'
import { useCustomMutation } from '../../hooks/useCustomMutation'

type Props = {
  projectId: number;
  projectName: string;
};

export const SyncProjectButton: FC<Props> = (props) => {
  const { projectId, projectName } = props

  const { enqueueSnackbar } = useSnackbar()

  const syncProjectFunc = () => ({
    path: `/api/projects/${projectId}/synchronize_toggl`,
  })

  const syncProjectMutation = useCustomMutation(syncProjectFunc, {
    onSuccess: () => {
      enqueueSnackbar(`${projectName} Toggl synced`, {
        variant: 'success',
      })
    },
    onError: (error) => {
      enqueueSnackbar(`Sync failed ${error}`, {
        variant: 'error',
      })
    },
  })

  // FIXME: find out hot to use mutate without variables
  const handleClick = () => syncProjectMutation.mutate({})

  return (
    <Tooltip title="Sync project Toggl">
      <IconButton
        color="primary"
        size="small"
        onClick={handleClick}
      >
        <StyledIcon
          Component={SyncIcon}
          $shouldRotate={syncProjectMutation.isLoading}
        />
      </IconButton>
    </Tooltip>
  )
}

const StyledIcon = styled(Icon)<{ $shouldRotate: boolean }>`
  ${(props) =>
    props.$shouldRotate &&
    css`
      animation: spin 0.8s linear infinite;
    `}

  @keyframes spin {
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }
`
