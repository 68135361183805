import React, { FC } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from '@material-ui/core'
import { theme } from '../styles/muiThemes'
import { StylesProvider } from '@material-ui/core/styles'
import { fetchHelper } from '../utils/apiHelpers'
import { SnackProvider } from './SnackProvider'
import { UserContext, UserProvider } from './CurrentUserProvider'

type Props = { user: UserContext };

export const ProvidersWrapper: FC<Props> = (props) => {
  const { children, user } = props

  const defaultQueryFn = async ({ queryKey }) => {
    return await fetchHelper(queryKey)
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        queryFn: defaultQueryFn,
        refetchOnWindowFocus: false,
      },
    },
  })

  return (
    <StylesProvider injectFirst>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <UserProvider user={user}>
            <SnackProvider>{children}</SnackProvider>
          </UserProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </StylesProvider>
  )
}
