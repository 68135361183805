import React, { FC, memo, useCallback } from 'react'
import styled from 'styled-components'
import { ButtonProps } from '@material-ui/core'

import { SelectItem } from '../types'
import { PillSkeleton } from './skeletons/PillSkeleton'
import { colors } from '../styles/colors'
import { atMaxWidth, atMinWidth } from '../styles/helpers'
import { BubbleButton } from './buttons/BubbleButton'

type Props = {
  options: SelectItem[];
  active: string;
  setActive: (value: string) => void;
  renderItem?: (item: SelectItem) => string;
  isLoading?: boolean;
};

const styles = {
  marginRight: '10px',
  borderColor: colors.brand,
  color: colors.grey5,
  height: 'fit-content',
}

export const BubbleSwitcher: FC<Props> = memo(
  ({ options, active, setActive, renderItem, isLoading }) => {
    const bubbleButtonState = useCallback(
      (value: string) => (value === active ? 'contained' : 'outlined'),
      [active]
    )
    const bubbleButtonProps = useCallback(
      (value: string): ButtonProps => ({
        color: 'primary',
        style: styles,
        variant: bubbleButtonState(value),
        onClick: () => setActive(value),
      }),
      [active]
    )
    return (
      <OneLineWrapper>
        {options.map((option) => {
          if (isLoading)
            return (
              <PillSkeleton
                key={option.value}
                marginRight={'10px'}
              />
            )
          else
            return (
              <StyledBubbleButton
                key={option.value}
                {...bubbleButtonProps(String(option.value))}
              >
                {renderItem ? renderItem(option) : option.label}
              </StyledBubbleButton>
            )
        })}
      </OneLineWrapper>
    )
  }
)

const OneLineWrapper = styled.div`
  display: flex;
`

const StyledBubbleButton = styled(BubbleButton)`
  height: 40px !important;
  padding: 20px;

  ${atMinWidth.desktop} {
    height: fit-content;
  }
`
