import React, { FC } from 'react'
import { Edit2, StopCircle, XCircle } from 'react-feather'
import {
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core'
import { ActionContainer, StrongTableCell, StyledButton, StyledIcon, StyledTable, StyledTableHeader, StyledTableRow } from './styles'
import { Competency, Rate } from './types'

import { LockIcon } from '../../../components/Icon/icons/Lock.icon'
import {formatDate, getAmountByLevel, getLevelNicename, getRatesSum} from './utils'

type Props = {
  rates: Rate[];
  showSum?: boolean;
  hideEdit?: boolean;
  hideEndValidity?: boolean;
  showDisabled?: boolean;
  openModal?: (rate?: Rate) => void;
  openEndValidityModal?: (rate?: Rate) => void;
  openDeleteRateModal?: (rate?: Rate) => void;
  hideActions?: boolean;
  competencies?: Competency[];
  billingCurrency?: string;
}

export const RatesTable: FC<Props> = ({ rates, hideEdit = false, hideEndValidity = false, showSum = false, showDisabled = false, hideActions = false, openModal, competencies, openEndValidityModal, openDeleteRateModal, billingCurrency }) => {
  const withProjectId = rates && !!rates.find(rate => rate.projectId)
  // const allowActions = userPermissions?.user?.manage === true // TODO: prepisat ked to bude done
  const allowActions = true

  return <TableContainer>
    <StyledTable
      className="table table-sm-12"
      id="active-users"
    >
      <StyledTableHeader>
        <StyledTableRow>
          {withProjectId && <TableCell width="90">Project ID</TableCell>}
          <TableCell>Name</TableCell>
          <TableCell width="200">Level</TableCell>
          <TableCell width="100">Valid from</TableCell>
          <TableCell
            width="100"
            align="right">Valid to</TableCell>
          <TableCell align="right">Comment</TableCell>
          <TableCell align="right">Rate</TableCell>
          {allowActions && !hideActions && <TableCell align="right">Action</TableCell>}
        </StyledTableRow>
      </StyledTableHeader>
      <TableBody>
        {showSum && <TableRow>
          <StrongTableCell
            colSpan={withProjectId ? 6 : 5}>{withProjectId ? 'Rate total' : 'My rate total'}</StrongTableCell>
          <StrongTableCell align="right">{getRatesSum(rates)} {billingCurrency}</StrongTableCell>
          <TableCell></TableCell>
        </TableRow>}
        {rates?.map((rate) => {
          const isDisabled = showDisabled && withProjectId && !rate.projectId
          const validFrom = rate.validFrom ?? rate.validFrom
          const validTo = rate.validTo ?? rate.validTo
          const competencyLevel = competencies && 'rateComponentId' in rate && getLevelNicename(competencies, rate.rateComponentId)
          const nonStandardRate = rate.rate
          return (
            <StyledTableRow
              title={rate.id ? `ID: ${rate.id}` : ''}
              key={`${rate.id}-${rate.validFrom}`}
              $isInactive={isDisabled}>
              {withProjectId && <TableCell>{rate.projectId ?? '-'}</TableCell>}
              <TableCell>{rate.rateComponentName}{isDisabled ? <StyledIcon><LockIcon /></StyledIcon> : null}</TableCell>
              <TableCell >{competencyLevel ?? rate.competencyLevel}</TableCell>
              <TableCell>{formatDate(validFrom)}</TableCell>
              <TableCell align="right">{validTo ? formatDate(validTo) : '-'}</TableCell>
              <TableCell align="right">{rate.note ?? '-'}</TableCell>
              <TableCell align="right">{nonStandardRate ? `${nonStandardRate} ${rate.currency}` : getAmountByLevel(competencies, rate.competencyLevel, rate.currency)}</TableCell>
              {allowActions && !hideActions && <TableCell
                align="right"
                width={150}>
                <ActionContainer>
                  {!hideEdit &&
                    <StyledButton
                      title="Edit validity"
                      disabled={isDisabled || !!validTo}
                      kind='secondary'
                      onClick={() => openModal && openModal(rate)}
                    >
                      <Edit2 />
                    </StyledButton>
                  }
                  {!hideEndValidity &&
                  <StyledButton
                    title="Stop validity"
                    disabled={isDisabled || !!rate.validTo || !rate.isStoppable}
                    kind='secondary'
                    onClick={() => openEndValidityModal && openEndValidityModal(rate)}
                  >
                    <StopCircle />
                  </StyledButton>}
                  <StyledButton
                    title="Delete"
                    disabled={isDisabled || !rate.isDeletable}
                    kind="secondary"
                    onClick={() => openDeleteRateModal && openDeleteRateModal(rate)}
                  >
                    <XCircle />
                  </StyledButton>
                </ActionContainer>
              </TableCell>}
            </StyledTableRow>
          )
        })}
      </TableBody>
    </StyledTable>
  </TableContainer>
}
