import React, { FC } from 'react'
import { Skeleton } from '@material-ui/lab'
import { range } from 'lodash'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'

type Props = {
  rows: number;
};

export const TestingDeviceDetailSkeleton: FC<Props> = ({ rows }) => {
  const skeletonRows = range(rows)

  return (
    <>
      {skeletonRows.map((item) => (
        <StyledLine key={item}>
          <Skeleton
            variant="rect"
            width={105}
            height={25}
            animation="wave" />
          <Skeleton
            variant="rect"
            width={300}
            height={25}
            animation="wave" />
        </StyledLine>
      ))}
    </>
  )
}

const StyledLine = styled(Grid)`
  min-height: 33px;
  margin: 20px 0;
  display: flex;
  gap: 40px;
`
