import React, { FC } from 'react'
import { ModalWrapper, useModalState } from '../../../components/ModalWrapper'
import { Button } from '@material-ui/core'
import { InvoiceStat } from '../types'
import { InvoiceDetailTribe } from './InvoiceDetailTribe'

type Props = {
  invoiceInfo: InvoiceStat[];
};
export const InvoiceDetail: FC<Props> = ({ invoiceInfo }) => {
  const { isModalOpen, closeModal, openModal } = useModalState(false)
  return (
    <>
      <Button onClick={openModal}>Invoice detail</Button>
      <ModalWrapper
        isOpen={isModalOpen}
        closeModal={closeModal}
      >
        {invoiceInfo.map((tribe, index) => (
          <InvoiceDetailTribe
            tribe={tribe}
            key={index}
          />
        ))}
      </ModalWrapper>
    </>
  )
}
