import React, { FC, useMemo } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { Icon } from '../Icon'
import { ProjectReportIcon } from '../Icon/icons/ProjectReport.icon'
import { buildLinkToMonthlyReport } from '../../utils/helpers'

type Props = {
  projectId: number;
};

export const LinkToProjectReport: FC<Props> = (props) => {
  const { projectId } = props

  const projectReportLink = useMemo(
    () => buildLinkToMonthlyReport(projectId, 0),
    [projectId]
  )

  return (
    <Tooltip title="Project report page">
      <a href={projectReportLink}>
        <IconButton
          color="primary"
          size="small"
        >
          <Icon Component={ProjectReportIcon} />
        </IconButton>
      </a>
    </Tooltip>
  )
}
