import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Typography, Dialog } from '@material-ui/core'
import { useQueryClient } from 'react-query'
import { Formik } from 'formik'

import { ContentPaper } from '../../../components/ContentPaper'
import { FormikSubmitHandler, NewUserType, SelectItem } from '../../../types'
import { useQueryNotification } from '../../UserInfo/utils'
import { Button } from '../../../components/buttons/Button'
import { AddNewUserForm } from './AddnewUserForm'
import {
  useCustomMutationWithCreatedId,
  Mutation,
} from '../../../hooks/useCustomMutationWithCreatedId'

type Props = {
  dialogOpened: boolean;
  setDialogOpened: (dialogOpened: boolean) => void;
  userRoles: SelectItem[];
};

const initVals: NewUserType = {
  firstName: '',
  lastName: '',
  slack_user_id: '',
  role: 'user',
  email: '',
  nickname: '',
}

export const AddNewUser: React.FC<Props> = ({
  dialogOpened,
  setDialogOpened,
  userRoles,
}) => {
  const queryClient = useQueryClient()
  const [newUserId, setNewUserId] = useState<number | null>(null)
  const { successNotification } = useQueryNotification()

  const createNewSubjectMutation: Mutation<NewUserType> = (vals) => ({
    path: '/api/users',
    method: 'POST',
    params: vals,
  })

  const newSubjectMutation = useCustomMutationWithCreatedId<
    Response,
    any,
    NewUserType
  >(
    createNewSubjectMutation,
    {
      onSuccess: async (data: Record<string, number | any>) => {
        await setNewUserId(data.createdId)
        await queryClient.refetchQueries('/api/users')
        successNotification('New user created')
        if (newUserId) {
          setDialogOpened(false)
        }
      },
    },
    true
  )

  useEffect(() => {
    if (newUserId) {
      window.location.replace(`/admin/users/${newUserId}`)
    }
  }, [newUserId])

  const formikSubmit: FormikSubmitHandler<NewUserType> = (
    values,
    { setErrors }
  ) => {
    const cleanedValues = JSON.parse(JSON.stringify(values), (_, value) => {
      return value === '' ? null : value
    })
    newSubjectMutation.mutateAsync(cleanedValues).catch((err) => {
      setErrors(err.errors)
    })
  }

  return (
    <Dialog
      open={dialogOpened}
      onClose={() => setDialogOpened(false)}
    >
      <ContentPaper>
        <Typography variant={'h3'}>New person</Typography>
        <Formik
          initialValues={initVals}
          onSubmit={formikSubmit}
        >
          {(formikProps) => (
            <>
              <AddNewUserForm
                formikProps={formikProps}
                userRoles={userRoles}
              />
              <ButtonWrapper>
                <Button
                  kind={'primary'}
                  onClick={formikProps.submitForm}
                  disabled={!formikProps.isValid}
                >
                  Create
                </Button>
                <Button
                  kind={'secondary'}
                  onClick={() => setDialogOpened(false)}
                >
                  Cancel
                </Button>
              </ButtonWrapper>
            </>
          )}
        </Formik>
      </ContentPaper>
    </Dialog>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
