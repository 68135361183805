import React from 'react'
import { Button, ButtonGroup, InputLabel } from '@material-ui/core'

type ToggleProps = {
  activeReportType: string;
  changeHandler: (string: string) => void;
  options: { key: string, label: string }[];
};

export const TypeToggle = ({
  activeReportType,
  changeHandler,
  options,
}: ToggleProps) => {


  return (
    <div>
      <InputLabel>Report Type</InputLabel>
      <ButtonGroup
        color="primary"
        fullWidth
      >
        {options.map((option) => (
          <Button
            key={option.key}
            variant={activeReportType === option.key ? 'contained' : 'outlined'}
            onClick={() => changeHandler(option.key)}
          >
            {option.label}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  )
}
