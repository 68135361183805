import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const TransferIcon: FC<IconSVGProps> = ({
  width = 24,
  height = 24,
  color = '#151B36',
}) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 9.47827H14.1739"
          stroke={color}
          strokeWidth="1.30435"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.82617 13.8262H22.0001"
          stroke={color}
          strokeWidth="1.30435"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.47826 12.9565L2 9.47826L5.47826 6"
          stroke={color}
          strokeWidth="1.30435"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.5215 17.3044L21.9997 13.8262L18.5215 10.3479"
          stroke={color}
          strokeWidth="1.30435"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}
