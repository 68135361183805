import React, { FC, useState } from 'react'
import {
  AppBar,
  Hidden,
  IconButton,
  SwipeableDrawer,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import styled from 'styled-components'
import { Logo } from '../../components/Icon/icons/Logo.icon'
import { colors } from '../../styles/colors'
import { UserInfo } from './components/UserInfo'
import { NavGroup, NavGroupProps } from './components/NavGroup'
import MenuIcon from '@material-ui/icons/Menu'
import { theme } from '../../styles/muiThemes'
import { Icon } from '../../components/Icon'
import { Button } from '../../components/buttons/Button'

type Props = {
  items: NavGroupProps[];
};

export const Navigation: FC<Props> = ({ items }) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const mobileVersion = useMediaQuery(theme.breakpoints.down('md'))

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Hidden
        lgUp
        implementation={'css'}
      >
        <AppBar position="fixed">
          <MyToolbar>
            <ToolbarLogo>
              <a href="/admin/">
                <Logo />
              </a>
            </ToolbarLogo>

            <Hamburger>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
            </Hamburger>
          </MyToolbar>
        </AppBar>
      </Hidden>

      <StyledDrawer
        anchor={mobileVersion ? 'right' : 'left'}
        open={mobileVersion ? open : true}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        variant={mobileVersion ? 'temporary' : 'persistent'}
      >
        <DrawerContent>
          <ContentGrid>
            <IconButton
              color="inherit"
              aria-label="logo"
              href={'/admin/'}
            >
              <Icon Component={Logo} />
            </IconButton>

            <UserInfo />

            {items.map((group) => (
              <NavGroup
                key={group.groupName}
                groupName={group.groupName}
                items={group.items}
              />
            ))}
          </ContentGrid>

          <LogoutButton
            fullWidth
            kind="primary"
            href={'/logout'}
            size={'small'}
          >
            Sign out
          </LogoutButton>
        </DrawerContent>
      </StyledDrawer>
    </>
  )
}

const MyToolbar = styled(Toolbar)`
  display: grid;
  grid-template-columns: 1fr 20px 1fr;
`

const ToolbarLogo = styled.div`
  grid-column: 2;
  padding: 11px 0;
`

const Hamburger = styled.div`
  grid-column: 3;
  justify-self: flex-end;
`

const StyledDrawer = styled(SwipeableDrawer)`
  width: 200px;
  flex-shrink: 0;
  ${theme.breakpoints.down('md')} {
    width: unset;
  }
`
const DrawerContent = styled.div`
  flex-basis: 100%;
  position: relative;
  padding: 32px 0;
  color: white;
  background-color: ${colors.brand};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 220px;
`

const ContentGrid = styled.div`
  display: grid;
  align-content: start;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
`

const LogoutButton = styled(Button)`
  color: white;

  &:hover {
    color: white;
    background-color: rgba(255, 255, 255, 0.05) !important;
  }
`
