import React from 'react'
import { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const ProjectReportIcon: FC<IconSVGProps> = ({
  width = 24,
  height = 24,
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M16.666 22a5.333 5.333 0 100-10.667 5.333 5.333 0 000 10.667z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M19.024 16.667h-2.357V14.31M8.667 19.333H3.333A1.333 1.333 0 012 18V3.333A1.333 1.333 0 013.333 2h9.448c.354 0 .693.14.943.39l2.552 2.553c.25.25.39.589.39.942v2.782"
    />
  </svg>
)
