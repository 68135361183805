import React, { FC } from 'react'
import { Button, Typography } from '@material-ui/core'
import styled from 'styled-components'

import { PageLayoutWrapper } from '../../components/ui/PageLayoutWrapper'
import { GetCardInstructions } from './GetCardInstructions'
import { ContentPaper } from '../../components/ContentPaper'
import { getImageByKey } from '../../utils/imageGetter'
import { atMinWidth } from '../../styles/helpers'
import { PlusIcon } from '../../components/Icon/icons/Plus.icon'
import { ModalWrapper, useModalState } from '../../components/ModalWrapper'
import { CreatePaymentModal } from './CreatePaymentModal'
import { colors } from '../../styles/colors'

export const PaymentCardUsagePage: FC = () => {
  const { isModalOpen, closeModal, openModal } = useModalState(false)

  return (
    <PageLayoutWrapper>
      <Header>
        <img
          src={getImageByKey('payment')}
          alt="coins"
        />
        <Typography variant="h3">Revolut payment</Typography>
        <StyledButton onClick={openModal}><PlusIcon color={colors.white} />Use credit card</StyledButton>
        <ModalWrapper
          isOpen={isModalOpen}
          closeModal={closeModal}
        >
          <CreatePaymentModal closeModal={closeModal} />
        </ModalWrapper>
      </Header>
      <ContentPaper>
        <Typography variant="h3">Info</Typography>
        <GetCardInstructions />
      </ContentPaper>
    </PageLayoutWrapper>
  )
}

const Header = styled.div`
  background: #82E7AD;
  height: 250px;
  margin-bottom: 40px;
  padding: 40px;
  position: relative;

  img {
    bottom: 0;
    height: 250px;
    position: absolute;
    right: 0;
  }

  h3 {
    margin-bottom: 40px;
    position: relative;
    z-index: 1;

    ${atMinWidth.tablet} {
      margin-bottom: 83px;
    }
  }
`

const StyledButton = styled(Button)`
  background: ${colors.black};
  color: ${colors.white};
  padding: 20px;

  svg {
    margin-right: 10px;
  }

  &:hover, &:focus {
    cursor: pointer;
    background: ${colors.grey3};
  }
`
