import { BaseUser } from '../../types'
import { UserInfo } from '../UserInfo'
import { Project, Tribe } from '../ProjectsPage/types'

export enum UserStateAction {
  activate = 'activate',
  deactivate = 'deactivate',
}

export type Purchase = {
  id: number;
  count: number;
  cost: number;
  createdAt: string;
  updatedAt: string;
  createdBy: BaseUser;
  datetime: string;
  productGroup: 'fridge' | 'benefits' | 'merch';
  source: 'get_snack' | 'mobile' | 'web';
  userId: number;
  userName: string;
};

export interface UserDetail extends UserInfo {
  globalRate: number | null;
  tribes: Tribe[];
  projects: Project[];
}

type EnumObject = {
  value: string;
  label: string;
};

export type Enums = {
  competencies: EnumObject[];
  competenciesLevel: EnumObject[];
  countries: EnumObject[];
  currencies: EnumObject[];
  education: EnumObject[];
  sexes: EnumObject[];
  tribes: EnumObject[];
  userRoles: EnumObject[];
};

export type ToggleRounderLink = {
  link: string;
}