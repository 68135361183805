import React, { FC } from 'react'
import { Button } from '../../../components/buttons/Button'
import styled from 'styled-components'
import { FormikValues } from 'formik'

type Props = {
  submitForm: (() => Promise<void>) & (() => Promise<FormikValues>);
  disabled: boolean;
  closeModal: () => void;
};

export const TransactionFormButtons: FC<Props> = ({ submitForm, disabled, closeModal }) => {
  return (
    <ButtonWrapper>
      <Button
        kind={'secondary'}
        onClick={closeModal}>
        Cancel
      </Button>
      <Button
        kind={'primary'}
        onClick={submitForm}
        disabled={disabled}>
        Submit
      </Button>
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
`
