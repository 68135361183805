import { SliderFieldData } from './UpdatableFormFieldData/SliderFieldData'
import { SelectItem } from './index'
import { SelectFieldData } from './UpdatableFormFieldData/SelectFieldData'
import { SwitchFieldData } from './UpdatableFormFieldData/SwitchFieldData'
import { DateFieldData } from './UpdatableFormFieldData/DateFieldData'
import {
  SupportedTextFieldType,
  TextFieldData,
} from './UpdatableFormFieldData/TextFieldData'

export interface ResourceData {
  id: number;
  [name: string]: any;
}

export type UpdatePathGeneratorType = (resource: ResourceData) => string;

export class UpdatableFieldsFactory {
  private dataArr: ResourceData[];
  private generateAlternatePath: UpdatePathGeneratorType;

  constructor(
    dataArr: ResourceData[],
    generateAlternatePath: UpdatePathGeneratorType
  ) {
    this.dataArr = dataArr
    this.generateAlternatePath = generateAlternatePath
  }

  generateTextFieldData(
    fieldName: string,
    fieldType: SupportedTextFieldType = 'text',
  ): TextFieldData[] {
    return this.dataArr.map(
      (dataItem) =>
        new TextFieldData(
          dataItem[fieldName],
          fieldName,
          fieldType,
          {alternatePath: this.generateAlternatePath(dataItem)}
        )
    )
  }

  generateSliderFieldData(
    fieldName: string,
  ): SliderFieldData[] {
    return this.dataArr.map(
      (dataItem) =>
        new SliderFieldData(
          Number(dataItem[fieldName]),
          fieldName,
          {alternatePath: this.generateAlternatePath(dataItem)}
        )
    )
  }

  generateSelectFieldData(
    fieldName: string,
    options: SelectItem[]
  ): SelectFieldData[] {
    return this.dataArr.map(
      (dataItem) =>
        new SelectFieldData(
          dataItem[fieldName],
          fieldName,
          options,
          {alternatePath: this.generateAlternatePath(dataItem)}
        )
    )
  }

  generateSwitchFieldData(
    fieldName: string,
  ): SwitchFieldData[] {
    return this.dataArr.map(
      (dataItem) =>
        new SwitchFieldData(
          Boolean(dataItem[fieldName]),
          fieldName,
          {alternatePath: this.generateAlternatePath(dataItem)}
        )
    )
  }

  generateDateFieldData(
    fieldName: string,
  ): DateFieldData[] {
    return this.dataArr.map(
      (dataItem) =>
        new DateFieldData(
          dataItem[fieldName] && String(dataItem[fieldName]),
          fieldName,
          {alternatePath: this.generateAlternatePath(dataItem)}
        )
    )
  }
}
