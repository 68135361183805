import { FieldOptions, UpdatableField } from './UpdatableField'

export type SupportedTextFieldType = 'text' | 'email' | 'tel' | 'number';
export class TextFieldData extends UpdatableField {
  fieldType: SupportedTextFieldType;
  keepVisible = false;

  constructor(
    defaultValue: string | number,
    fieldName: string,
    fieldType: SupportedTextFieldType = 'text',
    fieldOptions?: FieldOptions
  ) {
    super(defaultValue, fieldName, fieldOptions)
    this.fieldType = fieldType
  }
}
