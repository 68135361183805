import React, { ChangeEvent, FC } from 'react'
import { Pagination } from '@material-ui/lab'
import styled from 'styled-components'

import { useTransactionContext } from './MyTransactionContext'

export const MyTransactionPagination: FC = () => {
  const { pagination, loadPage, isNotDataAvailable } = useTransactionContext()

  if (!isNotDataAvailable && pagination.count === 1) return null

  return (
    <StyledPagination
      count={pagination.count}
      page={pagination.page}
      onChange={(e: ChangeEvent<number>, value: number) => {
        loadPage(value)
      }}
    />
  )
}

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`
