import React, { FC, useState } from 'react'
import { useCurrentUserInfo } from '../../components/CurrentUserProvider'
import { Chip, Paper } from '@material-ui/core'
import styled, { css } from 'styled-components'

import { colors } from '../../styles/colors'
import { SpiritSlider } from './components/SpiritSlider'
import { getImageByKey } from '../../utils/imageGetter'
import { MainNumber } from './components/MainNumber'
import { theme } from '../../styles/muiThemes'
import { PageLayoutWrapper } from '../../components/ui/PageLayoutWrapper'
import { WorkPaperContent } from './components/WorkPaperContent'
import { InvoiceStats, PinnedProjectStats, UserStats } from './types'
import { HighBalanceWarning } from './components/HighBalanceWarning'
import { GetSnackSteps } from './components/GetSnackSteps'
import { atMinWidth } from '../../styles/helpers'
import { round } from 'lodash'

type Props = {
  userTags: string[];
  userStats: UserStats;
  pinnedProjectStats: PinnedProjectStats;
  invoiceInfo: InvoiceStats;
};

export const Dashboard: FC<Props> = (props) => {
  const { userStats, pinnedProjectStats, userTags, invoiceInfo } = props

  const { currentUser } = useCurrentUserInfo()
  const isBalanceOverLimit = currentUser.balanceOverLimit

  const [hideGetSnackBanner, setHideGetSnackBanner] = useState<boolean>(
    !!localStorage.getItem('hideGetSnackPaper')
  )

  const closeGetSnackPaper = () => {
    localStorage.setItem('hideGetSnackPaper', 'true')
    setHideGetSnackBanner(true)
  }

  const pinnedProjectHoursLeft =
  pinnedProjectStats.totalHours === 0 || pinnedProjectStats.usedHours === 0 ? 0 :
    round(pinnedProjectStats.totalHours - pinnedProjectStats.usedHours)

  return (
    <>
      {isBalanceOverLimit && <HighBalanceWarning />}
      <PageLayoutWrapper>
        <DashboardContainer
          $isBalanceOverLimit={isBalanceOverLimit}
          $showGetSnackPaper={!hideGetSnackBanner}>
          <Heading>
            <HelloUserHeading>Hello, {currentUser?.firstName}</HelloUserHeading>
            <div>
              {userTags.map((attribute) => (
                <StyledChip
                  key={attribute}
                  label={attribute}
                />
              ))}
            </div>
          </Heading>

          <MyWorkPaper>
            <WorkPaperContent
              userStats={userStats}
              invoiceInfo={invoiceInfo}
            />
          </MyWorkPaper>

          <SpiritPaper>
            <PaperHeading>{pinnedProjectStats.name}</PaperHeading>
            <PaperTopSection>
              <SpiritSlider
                end={round(pinnedProjectStats.totalHours)}
                value={round(pinnedProjectStats.usedHours)}
              />
            </PaperTopSection>

            <MainNumber
              number={round(pinnedProjectHoursLeft)}
              text={'Hours left this month'}
            />
            <MainNumber
              number={round(pinnedProjectStats.usedHours)}
              text={'Hours used this month'}
            />

            <PaperImage src={getImageByKey('spirit')} />
          </SpiritPaper>

          {!hideGetSnackBanner &&
            <GetSnackPaper>
              <GetSnackSteps closeGetSnackPaper={closeGetSnackPaper} />
            </GetSnackPaper>
          }
        </DashboardContainer>
      </PageLayoutWrapper>
    </>
  )
}

const DashboardContainer = styled.div<{ $isBalanceOverLimit: boolean, $showGetSnackPaper: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 4em;
  grid-column-gap: 4em;
  grid-template-areas:
    'heading heading'
    'mywork spirit'
    ${(props) => props.$showGetSnackPaper === true && '\'getsnack getsnack\''}
    'mobileapp mobileapp';

  ${theme.breakpoints.down('lg')} {
    grid-row-gap: 2em;
    grid-column-gap: 2em;
  }
  ${theme.breakpoints.down('md')} {
    grid-row-gap: 1em;
    grid-template-columns: 1fr;
    grid-template-areas:
      'heading heading'
      'mywork mywork'
      'spirit spirit'
      ${(props) => props.$showGetSnackPaper === true && '\'getsnack getsnack\''}
      'mobileapp mobileapp';
  }
  ${theme.breakpoints.down('sm')} {
    padding: 1.5em 1em 0;
  }

  ${(props) =>
    props.$isBalanceOverLimit === true &&
    css`
      margin-top: 120px;

      ${theme.breakpoints.down('lg')} {
        margin-top: 80px;
      }
    `}
`

const Heading = styled.div`
  grid-area: heading;
  ${theme.breakpoints.down('sm')} {
    margin-bottom: 0.8em;
  }
`

const StyledPaper = styled(Paper)`
  padding: 2.2em 3.5em;
  height: 100%;
  position: relative;
  ${theme.breakpoints.down('lg')} {
    padding: 1.5em 1.5em;
  }
  ${theme.breakpoints.down('sm')} {
    padding: 1.1em;
  }
`

const PaperImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  mix-blend-mode: multiply;
  ${theme.breakpoints.down('lg')} {
    //display: none;
    opacity: 0.4;
  }
  ${theme.breakpoints.down('sm')} {
    max-width: 55%;
  }
`

export const WorkImage = styled(PaperImage)`
  bottom: 70px;
`

export const PaperHeading = styled.h2`
  padding-bottom: 1em;
  line-height: 1.2 !important;
  ${theme.breakpoints.down('sm')} {
    padding-bottom: 0.5em;
  }
`

export const PaperTopSection = styled.div`
  margin-bottom: 3em;
`

const MyWorkPaper = styled(StyledPaper)`
  background-color: ${colors.brandBlue};
  grid-area: mywork;
`

const SpiritPaper = styled(StyledPaper)`
  background-color: ${colors.grey1};
  grid-area: spirit;
`

const GetSnackPaper = styled(StyledPaper)`
  grid-area: getsnack;
  padding: 40px;
  background-image: url("${getImageByKey('getSnack')}");
  background-size: cover;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
  }

  ${atMinWidth.tablet} {
    padding: 20px;;
  }

  ${atMinWidth.desktop} {
    padding: 25px;
    height: 390px;

    ::before {
      display: none;
    }
  }

  ${atMinWidth.desktopLarge} {
    padding: 40px;
    height: 544px;
  }
`

const HelloUserHeading = styled.h1`
  font-family: 'Ginto Nord', serif;
  font-size: 91px;
  font-weight: 800;
  letter-spacing: 0.05em;
  -webkit-text-stroke: 1px black;
  color: transparent;
  padding-bottom: 1rem;
  ${theme.breakpoints.down('lg')} {
    font-size: 4.5em;
  }
  ${theme.breakpoints.down('md')} {
    font-size: 4em;
  }
  ${theme.breakpoints.down('sm')} {
    font-size: 3em;
  }
`

const StyledChip = styled(Chip)`
  font-weight: bold;
  margin-right: 1em;

  ${theme.breakpoints.down('sm')} {
    font-size: 1em;
  }
`
const StyledImage = styled(PaperImage)`
  opacity: 0.5;
  width: 100%;
  height: 100%;
  top: 0;

  ${theme.breakpoints.down('sm')} {
    max-width: 100%;
  }

  ${atMinWidth.tablet} {
    opacity: 1;
  }

  ${atMinWidth.desktopLarge} {
    height: auto;
  }
`
