import { SelectItem, UserInProject } from '../../types'

export type Project = {
  id: number;
  name: string;
  fullName: string;
  group: string;
  ownedByTribeId: number;
  invoicedByTribeId: number;
  isBillable: boolean;
  isProduction: boolean;
  isOpportunity: boolean;
  premierContractId: number;
  slackChannel: string;
  budgetRoundDigits: number;
  budgetNotifyThreshold: number;
  category: number;
  billingType: string;
  firstBillableDate: string;
  users?: UserInProject[];
  pinnedToDashboard: boolean;
  state: string;
  contractedFte: number;
  guruId: number;
  supportId: number;
  teamLeadId: number;
};

export type PropsData = {
  project: Project;
  enums: any;
  budgets: {
    years: any[];
    totalHours: number;
  };
};

export type Enums = {
  billingTypes: SelectItem[];
  categories: SelectItem[];
  tribes: SelectItem[];
  users: SelectItem[];
};

export enum GraphTypes {
  line = 'line',
  bar = 'bar',
}
