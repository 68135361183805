import { Typography } from '@material-ui/core'
import { Formik } from 'formik'
import React, { FC, useState } from 'react'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'

import { Mutation, useCustomMutation } from '../../hooks/useCustomMutation'
import { FormikSubmitHandler } from '../../types'
import { useQueryNotification } from '../UserInfo/utils'
import { PaymentCardSteps } from './PaymentCardSteps'
import { CardInfoData, StepsData } from './types'
import { GENERIC_BE_ERROR_MSG } from '../../constants'

interface Props {
  closeModal: () => void
}

export const CreatePaymentModal: FC<Props> = ({ closeModal }) => {
  const queryClient = useQueryClient()
  const { errorNotification } = useQueryNotification()
  const [isError, setIsError] = useState(false)

  const [cardInfo, setCardInfo] = useState<CardInfoData>()

  const sendDataForAccessTokenMutation: Mutation<StepsData> = (data) => ({
    path: '/api/payment_card_usages',
    method: 'POST',
    params: data
  })

  const getAccessToken = useCustomMutation(
    sendDataForAccessTokenMutation,
    {
      onSuccess: async (data: Record<string, number | any>) => {
        const dataJson = await data.json()
        const cardInfo: CardInfoData = await queryClient.fetchQuery(
          `/api/payment_card_usages/${dataJson.token}`
        )
        if (cardInfo) {
          setCardInfo(cardInfo)
        }
      },
      onError: () => {
        errorNotification(GENERIC_BE_ERROR_MSG)
        setIsError(true)
      },
    },
    true
  )

  const formikSubmit: FormikSubmitHandler<StepsData> = (values, { setErrors }) => {
    const cleanedValues = JSON.parse(JSON.stringify(values), (_, value) => {
      return value === '' ? null : value
    })

    getAccessToken.mutateAsync(cleanedValues).catch((err) => {
      setErrors(err)
    })
  }

  const initVals: StepsData = {
    purpose: 'software',
    comment: '',
    supplier: '',
    is_subscription: false,
    paid_by_client: false
  }

  return <>
    <StyledTitle variant="h3">Use credit card</StyledTitle>

    <Formik
      initialValues={initVals}
      onSubmit={formikSubmit}
    >
      {(formikProps) => (
        <PaymentCardSteps
          formikProps={formikProps}
          cardInfo={cardInfo}
          closeModal={closeModal}
          isError={isError}
        />
      )}
    </Formik>
  </>
}

const StyledTitle = styled(Typography)`
  font-family: 'Ginto Normal Regular', serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.04em;
  margin-bottom: 32px;
`
