import React, { FC, useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import styled from 'styled-components'

import { MyTransactionPageView } from './components/MyTransactionPageView'
import { Button } from '../../components/buttons/Button'
import { ContentPaper } from '../../components/ContentPaper'
import { PageLayoutWrapper } from '../../components/ui/PageLayoutWrapper'
import { QRCodeDialog } from '../Dashboard/components/QRCodeDialog/QRCodeDialog'
import { QrCode } from '../../components/Icon/icons/QrCode.icon'
import { DotsButtons } from './components/DotsButtons'
import { MyTransactionContextProvider } from './components/MyTransactionContext'
import { PlusIcon } from '../../components/Icon/icons/Plus.icon'
import { CreatePaymentModal } from './components/CreatePaymentModal'
import { useUserPermissions } from '../../components/CurrentUserProvider'
import { atMaxWidth, atMinWidth } from '../../styles/helpers'

export const MyTransactionPage: FC = () => {
  const isAllTransactions = '/admin/transactions/all' === window.location.pathname
  const [dialogOpened, setDialogOpened] = useState(false)
  const [createPurchaseOpened, setCreatePurchaseOpened] = useState(false)
  const handleClick = () => setDialogOpened(true)
  const { resolvePermissions } = useUserPermissions()
  const userCanCreatePayment = resolvePermissions('paymentsPayment', 'manage')

  return (
    <MyTransactionContextProvider>
      <PageLayoutWrapper>
        <ContentPaper>
          {isAllTransactions ? (
            <>
              <HeaderWrapper
                gridRowGap="0 !important"
                marginBottom="24px"
              >
                <StyledTypography variant="h3">All Transactions</StyledTypography>
                <ButtonsWrapper>
                  {userCanCreatePayment &&
                    <StyledButton
                      kind="basicPrimary"
                      onClick={() => setCreatePurchaseOpened(true)}
                      startIcon={<PlusIcon color="#FFFFFF" />}
                    >
                      <ButtonText>Cash transaction</ButtonText>
                    </StyledButton>
                  }
                  <DotsButtonsWrapper>
                    <DotsButtons />
                  </DotsButtonsWrapper>
                </ButtonsWrapper>
              </HeaderWrapper>
            </>
          ) : (
            <HeaderWrapper
              gridRowGap="0 !important"
              marginBottom="24px"
            >
              <StyledTypography variant="h3">My Transactions</StyledTypography>
              <StyledButton
                kind="primary"
                onClick={handleClick}
                startIcon={<QrCode color="#FFFFFF" />}
              >
                <ButtonText>QR code for payment</ButtonText>
              </StyledButton>
            </HeaderWrapper>
          )}
          <MyTransactionPageView />
        </ContentPaper>
      </PageLayoutWrapper>
      <QRCodeDialog
        setDialogOpened={setDialogOpened}
        dialogOpened={dialogOpened}
      />
      <CreatePaymentModal
        setDialogOpened={setCreatePurchaseOpened}
        dialogOpened={createPurchaseOpened}
      />
    </MyTransactionContextProvider>
  )
}

const StyledButton = styled(Button)`
  padding: 16px 20px;
`

const ButtonText = styled(Typography)`
  font-size: 14px;
  text-transform: none;
  line-height: 110%;
`

const StyledTypography = styled(Typography)`
  margin-bottom: 0;

  ${atMaxWidth.desktop} {
    margin-bottom: 20px;
  }
`

const HeaderWrapper = styled(Box)`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`

const DotsButtonsWrapper = styled.div`
  display: flex !important;
  margin-left: auto;
`

const ButtonsWrapper = styled.div`
  display: flex;

  ${atMaxWidth.desktop} {
    justify-content: space-between;
  }

  ${atMinWidth.desktop} {
    gap: 34px;

  }
`
