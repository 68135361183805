import React, { FC, useState } from 'react'
import {
  ProjectTableConfig,
  ProjectTableItem,
} from '../components/ProjectsTable/types'
import { ProjectsTable } from '../components/ProjectsTable/ProjectsTable'
import { useQuery } from 'react-query'
import {
  getMemoizedSortedProjects,
  useProjectFiltering,
} from '../components/ProjectsTable/utils'
import { Typography } from '@material-ui/core'
import { Project } from './ProjectsPage/types'
import { PageLayoutWrapper } from '../components/ui/PageLayoutWrapper'
import { ProjectsTablePaper } from '../components/ProjectsTable/styledComponnets'
import styled from 'styled-components'
import { StatusFilter } from '../enum'
import { ProjectFilters } from '../components/ProjectsTable/ProjectFilters'

type Props = { userId: number };

export const ProjectsOnUserDetails: FC<Props> = (props) => {
  const [selectedProjectsStatus, setSelectedProjectsStatus] =
    useState<StatusFilter>(StatusFilter.Active)

  const { userId } = props

  const myCurrentProjectsTableConfig: ProjectTableConfig = [
    { type: ProjectTableItem.Info },
  ]

  const { data, isLoading } = useQuery<Project[]>(
    `/api/users/${userId}/projects/assigned?state=${selectedProjectsStatus}`
  )

  const sortedProjects = getMemoizedSortedProjects(data)

  const { filteredProjects, owningTribe, handleTribeChange } =
    useProjectFiltering(sortedProjects)

  return (
    <PageLayoutWrapper>
      <StyledProjectsTablePaper>
        <Typography variant="h3">Projects</Typography>

        <ProjectFilters
          setSelectedProjectsStatus={setSelectedProjectsStatus}
          selectedProjectsStatus={selectedProjectsStatus}
          handleTribeChange={handleTribeChange}
          owningTribe={owningTribe}
        />

        <ProjectsTable
          tableConfig={myCurrentProjectsTableConfig}
          projects={filteredProjects}
          isLoading={isLoading}
        />
      </StyledProjectsTablePaper>
    </PageLayoutWrapper>
  )
}

const StyledProjectsTablePaper = styled(ProjectsTablePaper)`
  margin-top: 3rem;
  margin-bottom: 3rem;
`
