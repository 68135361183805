import React, { FC } from 'react'
import { PageLayoutWrapper } from '../../components/ui/PageLayoutWrapper'
import { Paper, Typography } from '@material-ui/core'
import { atMinWidth } from '../../styles/helpers'
import styled from 'styled-components'
import { PaymentInfoTable } from './components/PaymentInfoTable'
import { useQuery } from 'react-query'
import { Alert } from '@material-ui/lab'
import { PairedPaymentTable } from './components/PairedPaymentTable'
import { Payment } from '../PaymentsPage/types'
import { PaymentDetailPageSkeleton } from './PaymentDetailPageSkeleton'
import { BreadcrumbsNav } from '../../components/BreadcrumbsNav'
import { BreadcrumbsData } from '../../types/index'

export const PaymentDetailPage: FC = () => {
  const paymentDetailPathname = new URL(window.location.href)
  const paymentId = paymentDetailPathname.pathname.split('/').pop()

  const queryKey = `/api/payments/${paymentId}`
  const { data: payment, isLoading, isError } = useQuery<Payment | undefined>(queryKey, {
    enabled: paymentId != null,
  })

  if (isLoading) {
    return <PaymentDetailPageSkeleton />
  }

  if (isError || payment == null) {
    return (
      <Alert
        variant={'outlined'}
        severity={'info'}>
        No payments detail information
      </Alert>
    )
  }

  const breadcrumbsLinks: BreadcrumbsData = {
    links: [
      {
        id: 1,
        title: 'Transactions',
        href: '/admin/transactions/all',
      },
    ],
    currentPageTitle: `Payment #${payment.id.toString()}`,
  }

  return (
    <>
      <BreadcrumbsNav data={breadcrumbsLinks} />
      <PageLayoutWrapper>
        <Typography variant="h3">Payment</Typography>
        <StyledPaper elevation={0}>
          {payment && (
            <>
              <PaymentInfoTable payment={payment} />
              <PairedPaymentTable payment={payment} />
            </>
          )}
        </StyledPaper>
      </PageLayoutWrapper>
    </>
  )
}

const StyledPaper = styled(Paper)`
  min-height: 90vh;
  position: relative;
  padding: 16px;

  ${atMinWidth.tablet} {
    padding: 48px 40px;
  }

  ${atMinWidth.desktop} {
    padding: 48px 64px;
  }
`
