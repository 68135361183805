import React, {FC, useMemo} from 'react'
import {useQuery} from 'react-query'
import {Alert} from '@material-ui/lab'
import styled from 'styled-components'
import {ContentPaper} from '../../components/ContentPaper'
import {ProjectsOnUserDetails} from '../ProjectsOnUserDetails'
import {TogglCheck} from './TogglCheck'
import {UserPayments} from './UserPayments'
import {UserPurchases} from './UserPurchases'
import {BreadcrumbsNav} from '../../components/BreadcrumbsNav'
import {BreadcrumbsData} from '../../types/index'
import {atMaxWidth, atMinWidth} from '../../styles/helpers'
import {UserDetail, Enums, ToggleRounderLink} from './types'
import {UserInfo} from '../UserInfo'
import {Header} from './Header'
import {useCurrentUserInfo} from '../../components/CurrentUserProvider'
import {useUserPermissions} from '../../components/CurrentUserProvider'
import {PageLayoutWrapper} from '../../components/ui/PageLayoutWrapper'
import {Rates} from './rates/Rates'

type Props = {
    enums: Enums;
    togglRounderLink: ToggleRounderLink;
};

export const UserDetailPage: FC<Props> = ({enums, togglRounderLink}) => {
    const {resolvePermissions} = useUserPermissions()
    const {currentUser} = useCurrentUserInfo()
    // TODO routing library
    const userDetailPathname = new URL(window.location.href)
    const userId = Number(userDetailPathname.pathname.split('/').pop())
    const queryKey = `/api/users/${userId}?offset=-2`
    const {
        data: user,
        isLoading,
        isError,
        refetch,
    } = useQuery<UserDetail | undefined>(queryKey, {
        enabled: userId != null,
    })

    const canManageUsers = useMemo(
        () => resolvePermissions('user', 'manage'),
        []
    )

    const isUserCurrentUser = user?.id === currentUser.id
    const isAllowed = isUserCurrentUser || canManageUsers

    if (isLoading) {
        return null
    }

    if (isError || !user)
        return (
            <Alert
                variant={'outlined'}
                severity={'info'}
            >
                No user detail information available
            </Alert>
        )

    const {lastName, id} = user

    const breadcrumbsLinks: BreadcrumbsData = {
        links: [
            {
                id: 1,
                title: 'People',
                href: '/admin/users?scope=active',
            },
        ],
        currentPageTitle: user.fullName,
    }

    return (
        <StyledContainer>
            <BreadcrumbsNav data={breadcrumbsLinks}/>
            <Header
                user={user}
                refetch={refetch}
            />
            <UserInfo
                user={user}
                enums={enums}
            />
            <StyledContentPaper>
                <Rates user={user}/>
            </StyledContentPaper>
            <ProjectsOnUserDetails userId={id}/>
            {isAllowed && (
                <PageLayoutWrapper>
                    <TablesContainer>
                        <UserPurchases userId={id}/>
                        <UserPayments
                            userId={id}
                            lastName={lastName}
                        />
                    </TablesContainer>
                    <StyledContentPaper>
                        <TogglCheck
                            userId={id}
                            togglRounderUrl={togglRounderLink.link}/>
                    </StyledContentPaper>
                </PageLayoutWrapper>
            )}
        </StyledContainer>
    )
}

const TablesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 42px;
  flex-direction: column;

  ${atMinWidth.custom(1380)} {
    flex-wrap: nowrap;
    flex-direction: row;
  }
`

const StyledContentPaper = styled(ContentPaper)`
  margin: 3rem 42px 3rem 0;

  ${atMaxWidth.desktop} {
    margin-right: 0;
  }

  ${atMinWidth.custom(1380)} {
    flex-wrap: nowrap;
  }
`

const StyledContainer = styled.div`
  padding: 10px;

  ${atMinWidth.desktop} {
    padding: 0;
  }
`
