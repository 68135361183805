import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { InvoiceStat } from '../types'
import ReactQueryKeys from '../../../utils/ReactQueryKeys'
import { InvoiceDetail } from './InvoiceDetail'

type Props = {
  userId: string;
  month: string;
};

export const InvoiceDetailWrapper: FC<Props> = (props) => {
  const { userId, month } = props

  const { data: invoiceData } = useQuery<InvoiceStat[]>(
    ReactQueryKeys.invoiceInfo(Number(userId), month),
    {
      enabled: !!userId,
    }
  )

  return (
    <>
      {userId !== 'all' && invoiceData && (
        <InvoiceDetail invoiceInfo={invoiceData} />
      )}
    </>
  )
}
