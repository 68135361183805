import React from 'react'
import { groupBy, mapValues, omit } from 'lodash'
import { DataRow, ReportFields, RowGroup } from '../types'
import { calculateSum, mapUniqueColumnValues } from '../utils'
import { GroupTitle } from './GroupTitle'
import { ReportTableRow } from './ReportTableRow'
import { TableBody } from '@material-ui/core'

type RecursiveGroupProps = {
  fieldSet: string[];
  rows: DataRow[];
  level: number;
  headerFields: ReportFields[];
  showDescription: boolean;
};
export const RecursiveGroup = ({
  fieldSet,
  rows,
  level,
  headerFields,
  showDescription,
}: RecursiveGroupProps): JSX.Element => {
  const grouppingField = fieldSet[0]
  const fieldSetCopy = [...fieldSet]
  fieldSetCopy.shift()

  const grouppingFieldValues = grouppingField
    ? mapUniqueColumnValues(rows, grouppingField)
    : []
  const groups: RowGroup = groupBy(rows, grouppingField)
  // TODO: The type was lost in some of the utils, and now we have lodash types,
  // so it's complaining when we don't cast it to any. We should fix this.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const groupsWithoutGrouppingField: any = mapValues(groups, (list) =>
    list.map((item) => omit(item, grouppingField))
  )
  const drillDown: boolean = grouppingFieldValues.length > 0

  const groupHeader: JSX.Element =
    drillDown &&
    grouppingFieldValues.map((value, id) => {
      const groupName = value
      const groupRows = groupsWithoutGrouppingField[groupName]
      const groupSum: number = groupRows && calculateSum(groupRows)

      return (
        <React.Fragment key={`${id}-${value}`}>
          <TableBody key={id}>
            <GroupTitle
              groupName={groupName}
              sum={groupSum}
              level={level}
            />
          </TableBody>

          <RecursiveGroup
            headerFields={headerFields}
            fieldSet={fieldSetCopy}
            rows={groupRows}
            level={level + 1}
            showDescription={showDescription}
          />
        </React.Fragment>
      )
    })

  const groupItems =
    !drillDown &&
    rows.map((row: DataRow, id) => (
      <ReportTableRow
        key={id}
        {...row}
      />
    ))

  return (
    <>
      {drillDown && groupHeader}
      {!drillDown && showDescription && <TableBody>{groupItems}</TableBody>}
    </>
  )
}
