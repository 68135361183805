import React, { FC } from 'react'
import { TableRow, TableCell } from '@material-ui/core'
import { Transaction } from '../../PaymentsPage/types'
import { MoneyFormatter, dateFormatter } from '../../../utils/formatters'

type Props = {
  transaction: Transaction;
};

export const TransactionTableRow: FC<Props> = ({ transaction }) => {
  const { createdAt, account, createdBy, amount, note } = transaction
  const amountFormatted = new MoneyFormatter('CZK').format(amount)
  const parsedDate = dateFormatter(createdAt)
  return (
    <TableRow>
      <TableCell>{parsedDate}</TableCell>
      <TableCell>{account.owner.fullName}</TableCell>
      <TableCell>
        {createdBy.fullName} / {note}
      </TableCell>
      <TableCell>{amountFormatted}</TableCell>
    </TableRow>
  )
}
