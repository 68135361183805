import React, { FC } from 'react'
import { Skeleton, SkeletonProps } from '@material-ui/lab'

export const ButtonSkeleton: FC<React.CSSProperties> = (styles) => {
  const headerButtonProps: SkeletonProps = {
    variant: 'rect',
    width: 150,
    style: { display: 'inline-block', ...styles },
    height: 48,
  }
  return <Skeleton {...headerButtonProps} />
}
