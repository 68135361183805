import React, { FC, useCallback, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Box, Checkbox, Tooltip, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import { sortBy } from 'lodash'

import { formatDate, formatTime, parseNewlines } from '../../utils/helpers'
import { Note as NoteType } from './types'
import { useCurrentUserInfo } from '../CurrentUserProvider'
import { Mutation, useCustomMutation } from '../../hooks/useCustomMutation'
import { useQueryNotification } from '../../bundles/UserInfo/utils'
import {
  Actions, AuthorName, Content, NoteTimestamp, NewNoteButton, EditNoteButton, NewNoteLabel, NewNoteSection, Note, NoteDetails,
  NoteHeader, PostedNotesSection, StyledAvatar, NewNoteForm, EditNoteForm, NewNoteTextareaAutosize, PinCheckbox, PinButton,
  EditTextareaAutosize, EditButtonsWrapper, StyledTypography
} from './Notes.styles'
import { PlusIcon } from '../Icon/icons/Plus.icon'
import { PinIcon } from '../Icon/icons/Pin.icon'
import { colors } from '../../styles/colors'
import { DotsMenu } from '../DotsMenu'

type Props = {
  notesQueryKey: string;
}

type FormValues = {
  text: string;
}

// TODO: refactor this component after deciding which parts will be reusable and how
export const Notes: FC<Props> = ({ notesQueryKey }) => {
  const { data: notes, refetch: refetchNotes } = useQuery<NoteType[]>(notesQueryKey)
  const sortedNotes = useMemo(() => {
    return sortBy(notes,
      ({ pinnedAt }) => {
        return pinnedAt ? new Date(pinnedAt).getTime() : 0
      },
      ({ createdAt }) => {
        return new Date(createdAt).getTime()
      }
    ).reverse()
  }, [notes])

  const { currentUser } = useCurrentUserInfo()
  const { successNotification, errorNotification } = useQueryNotification()
  const [editingNoteId, setEditingNoteId] = useState<number | null | undefined>()

  const createNoteMutation: Mutation<FormValues> = (values) => ({
    path: notesQueryKey,
    params: values,
  })

  const { mutateAsync: createNote, isLoading: isSubmitting, isSuccess, reset } = useCustomMutation(createNoteMutation, {
    onSuccess: () => {
      successNotification('Note has been successfully created')
      refetchNotes()
      // NOTE: This lets react-query set isSuccess to true, and in the next tick resets it to false.
      // The textarea has isSuccess as a key, so it will get cleared only on success.
      // (without having to use a controlled textarea)
      setTimeout(reset)
    },
    onError: () => {
      errorNotification('Something went wrong')
    }
  })

  const updateNoteMutation: Mutation<FormValues & { id: number }> = ({ id, ...params }) => ({
    path: `${notesQueryKey}/${id}`,
    params,
    method: 'PUT',
  })

  const { mutateAsync: updateNote, isLoading: isSubmittingEdit } = useCustomMutation(updateNoteMutation, {
    onSuccess: () => {
      successNotification('Note has been successfully updated')
      refetchNotes()
    },
    onError: () => {
      errorNotification('Something went wrong')
    }
  })

  const deleteNoteMutation: Mutation<{ id: number }> = ({ id }) => ({
    path: `${notesQueryKey}/${id}`,
    method: 'DELETE',
  })

  const { mutateAsync: deleteNote } = useCustomMutation(deleteNoteMutation, {
    onSuccess: () => {
      successNotification('Note has been successfully deleted')
      refetchNotes()
    },
    onError: () => {
      errorNotification('Something went wrong')
    }
  })

  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      text: '',
      pin: false,
    },
    onSubmit: ({ text, pin }, { resetForm }) => {
      createNote({
        text,
        pin,
      }).then(() => {
        resetForm()
      })
    },
  })

  const {
    handleChange: handleEditChange,
    handleSubmit: handleEditSubmit,
    values: editValues,
    resetForm: resetEditForm
  } = useFormik({
    initialValues: {
      edit: '',
    },
    onSubmit: ({ edit }, { resetForm }) => {
      updateNote({
        id: editingNoteId,
        text: edit,
        // TODO: Change notes controller to not touch pinnedAt if it's not passed into the update params.
        // Then we can remove this line.
        pin: !!notes?.find(({ id }) => id === editingNoteId)?.pinnedAt
      }).then(() => {
        setEditingNoteId(null)
        resetForm()
      })
    },
  })

  const cancelEdit = useCallback(() => {
    setEditingNoteId(null)
    resetEditForm()
  }, [resetEditForm])

  return (
    <Content>
      <NewNoteSection>
        <StyledTypography
          variant="h6"
        >
          Post a new note
        </StyledTypography>
        <NewNoteForm onSubmit={handleSubmit}>
          <Note>
            <NoteHeader>
              {!!currentUser.slackAvatar && <StyledAvatar src={currentUser.slackAvatar.image48} />}
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                gridRowGap="6px"
              >
                <NewNoteLabel>Note</NewNoteLabel>
                <NewNoteTextareaAutosize
                  key={`${isSuccess}`}
                  rowsMin={2}
                  placeholder="Share what's on your mind"
                  name="text"
                  onChange={handleChange}
                />
                <PinCheckbox
                  control={
                    <Checkbox
                      key={`${isSuccess}`}
                      name="pin"
                      onChange={handleChange}
                      size="small"
                    />
                  }
                  label="Pin note"
                />
                <NewNoteButton
                  kind="basicPrimary"
                  size="small"
                  startIcon={<PlusIcon color="currentColor" />}
                  type="submit"
                  disabled={!values.text || isSubmitting}
                >
                  Add note
                </NewNoteButton>
              </Box>
            </NoteHeader>
          </Note>
        </NewNoteForm>
      </NewNoteSection>
      {!!sortedNotes?.length && (
        <PostedNotesSection>
          <Typography
            variant="h6"
            component="h4"
          >
            Posted Notes
          </Typography>
          {sortedNotes.map(({ id: noteId, createdAt, text, author, pinnedAt, updatedAt }) => {
            const createdAtDate = new Date(createdAt)
            const updatedAtDate = new Date(updatedAt)

            return (
              <Note
                key={noteId}
                isPinned={!!pinnedAt}
              >
                <NoteHeader>
                  <StyledAvatar src={author.slackAvatar.image48} />
                  <NoteDetails>
                    <AuthorName noWrap>{`${author.firstName} ${author.lastName}`}</AuthorName>
                    <Box
                      display="flex"
                      gridColumnGap="8px">
                      <Tooltip title={formatTime(createdAtDate)}>
                        <NoteTimestamp>{formatDate(createdAtDate)}</NoteTimestamp>
                      </Tooltip>
                      {updatedAt !== createdAt && (
                        <Tooltip title={formatTime(updatedAtDate)}>
                          <NoteTimestamp>(updated {formatDate(updatedAtDate)})</NoteTimestamp>
                        </Tooltip>
                      )}
                    </Box>
                  </NoteDetails>
                  <Actions>
                    <Tooltip title={pinnedAt ? 'Unpin note' : 'Pin note'}>
                      <PinButton
                        size="small"
                        onClick={() => updateNote({
                          id: noteId,
                          pin: !pinnedAt,
                        })}
                      >
                        <PinIcon color={pinnedAt ? colors.brandYellow : undefined} />
                      </PinButton>
                    </Tooltip>
                    <DotsMenu
                      small
                      transparent
                      items={[
                        {
                          label: 'Delete',
                          onClick: () => deleteNote({ id: noteId, })
                        },
                        ...author.id === currentUser.id ? [
                          {
                            label: 'Edit',
                            onClick: () => setEditingNoteId(noteId),
                            disabled: !!editingNoteId,
                          }
                        ] : []
                      ]}
                    />
                  </Actions>
                </NoteHeader>
                {editingNoteId === noteId ? (
                  <EditNoteForm onSubmit={handleEditSubmit}>
                    <EditTextareaAutosize
                      defaultValue={text}
                      placeholder="Share what's on your mind"
                      name="edit"
                      onChange={handleEditChange}
                    />
                    <EditButtonsWrapper>
                      <EditNoteButton
                        kind="basicSecondary"
                        size="small"
                        type="button"
                        onClick={cancelEdit}
                      >
                        Cancel
                      </EditNoteButton>
                      <EditNoteButton
                        kind="basicPrimary"
                        size="small"
                        type="submit"
                        disabled={!editValues.edit || editValues.edit === text || isSubmittingEdit}
                      >
                        Save
                      </EditNoteButton>
                    </EditButtonsWrapper>
                  </EditNoteForm>
                ) : (
                  <div>{parseNewlines(text)}</div>
                )}
              </Note>
            )
          })}
        </PostedNotesSection>
      )}
    </Content>
  )
}
