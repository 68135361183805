import React, { FC } from 'react'
import { EmployeeSubject } from '../../types'
import { DataLine, SelectItem } from '../../../../types'
import { TextFieldData } from '../../../../types/UpdatableFormFieldData/TextFieldData'
import { UpdatableItemsGroup } from '../../../../components/ui/UpdatableFormField/UpdatableItemsGroup'
import { Typography } from '@material-ui/core'
import { SwitchFieldData } from '../../../../types/UpdatableFormFieldData/SwitchFieldData'
import { SelectFieldData } from '../../../../types/UpdatableFormFieldData/SelectFieldData'
import { canUpdateUser } from '../Tabs/UserInfoTab'

type Props = {
  userId: number;
  data: EmployeeSubject;
  enums: { [key: string]: SelectItem[] };
};
export const EmployeeSubjectTable: FC<Props> = ({ data, userId, enums }) => {
  const fields: DataLine[] = [
    {
      label: 'Birth certificate number',
      updatableFields: [
        new TextFieldData(
          data.birthCertificateNumber,
          'birthCertificateNumber'
        ),
      ],
    },
    {
      label: 'Place of birth',
      updatableFields: [new TextFieldData(data.birthplace, 'birthplace')],
    },
    {
      label: 'Maiden mame',
      updatableFields: [new TextFieldData(data.maidenName, 'maidenName')],
    },
    {
      label: 'Marital status',
      updatableFields: [
        new SelectFieldData(
          data.maritalStatus,
          'maritalStatus',
          enums.maritalStatuses
        ),
      ],
    },
    {
      label: 'Identity document type',
      updatableFields: [
        new SelectFieldData(
          data.identityDocumentType,
          'identityDocumentType',
          enums.identityDocumentTypes
        ),
      ],
    },
    {
      label: 'Identity document no.',
      updatableFields: [
        new TextFieldData(
          data.identityDocumentNumber,
          'identityDocumentNumber'
        ),
      ],
    },
    {
      label: 'Health insurance company',
      updatableFields: [
        new SelectFieldData(
          data.healthInsuranceCompanyCode,
          'healthInsuranceCompanyCode',
          enums.insuranceCompanies
        ),
      ],
    },
    // Not in use. Hiding for now.
    // {
    //   label: "Income beneficiary",
    //   description: "Poživatelé důchody.",
    //   updatableFields: [new SwitchFieldData(data.incomeBeneficiary, "incomeBeneficiary")],
    // },
    // Not in use. Hiding for now.
    // {
    //   label: "Distress warant",
    //   description: "Exekuce, insolvence.",
    //   updatableFields: [new SwitchFieldData(data.distressWarrant, "distressWarrant")],
    // },
    {
      label: 'Pink statement signed by',
      updatableFields: [
        new SelectFieldData(
          data.pinkStatementSignedByTribeId,
          'pinkStatementSignedByTribeId',
          enums.tribes
        ),
      ],
    },
  ]
  return (
    <>
      <Typography variant={'h5'}>Employee</Typography>
      <UpdatableItemsGroup
        updatePaths={[`/api/users/${userId}/contract_subjects/${data.id}`]}
        items={fields}
        updatable={canUpdateUser()}
      />
    </>
  )
}
