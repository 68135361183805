import { Dialog } from '@material-ui/core'
import React, { FC } from 'react'
import { useQueryClient } from 'react-query'

import { Button } from '../../components/buttons/Button'
import { Mutation, useCustomMutation } from '../../hooks/useCustomMutation'
import { useQueryNotification } from '../UserInfo/utils'
import { DeviceDialog } from './DeviceDialog'
import { RowProps, TestingDevices } from './types'

type Props = {
  dialogOpened: boolean;
  setDialogOpened: (dialogOpened: boolean) => void;
  device: TestingDevices;
};
export const RemoveTestingDevice: FC<Props> = ({
  dialogOpened,
  setDialogOpened,
  device,
}) => {
  const { name, inventoryNumber, id } = device
  const queryClient = useQueryClient()
  const { successNotification, errorNotification } = useQueryNotification()

  const deleteNewSubjectMutation: Mutation<TestingDevices> = (initVals) => ({
    path: `/api/testing_devices/${id}`,
    method: 'DELETE',
    params: initVals,
  })

  const deleteubjectMutation = useCustomMutation(
    deleteNewSubjectMutation,
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(`/api/testing_devices/${id}`)
        await setDialogOpened(false)
        await window.location.replace(
          `${window.location.origin}/api/testing_devices`
        )
        await successNotification('Testing devices was deleted')
      },
      onError: () => {
        errorNotification('Sorry something happened')
      },
    },
    true
  )

  const removeDevice = () => deleteubjectMutation.mutateAsync({})
  const handleCloseDialog = () => setDialogOpened(false)

  const rowDataInDialog: RowProps[] = [
    { id: 1, label: 'Device', value: name },
    { id: 2, label: 'Inventory number', value: inventoryNumber },
  ]

  return (
    <Dialog
      fullWidth
      open={dialogOpened}
      onClose={handleCloseDialog}
    >
      <DeviceDialog
        title="Delete device"
        question="Do you really want to delete this device?"
        rowData={rowDataInDialog}
        actions={
          <>
            <Button
              kind="basicSecondary"
              fullWidth
              onClick={handleCloseDialog}
            >
              cancel
            </Button>
            <Button
              kind="basicPrimary"
              fullWidth
              onClick={removeDevice}
            >
              delete
            </Button>
          </>
        }
      />
    </Dialog>
  )
}
