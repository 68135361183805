import { FC } from 'react'
import Slide from '@material-ui/core/Slide'
import { SnackbarProvider } from 'notistack'
import React from 'react'

export const SnackProvider: FC = (props) => {
  const { children } = props

  return (
    <SnackbarProvider
      maxSnack={10}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      TransitionComponent={Slide}
    >
      {children}
    </SnackbarProvider>
  )
}
