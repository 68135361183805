import React, { FC } from 'react'
import { useQueryClient } from 'react-query'
import { Formik } from 'formik'

import { FormikSubmitHandler } from '../../../../types'
import { useQueryNotification } from '../../../UserInfo/utils'
import { Button } from '../../../../components/buttons/Button'
import {
  Mutation,
  useCustomMutationWithCreatedId,
} from '../../../../hooks/useCustomMutationWithCreatedId'
import { AddNewProjectForm } from './AddNewProjectForm'
import { StyledButton, StyledButtonGroup, StyledTitle } from './styles'
import { initVals, NewProjectProps, Props } from './types'

interface NewProps extends Props {
  closeModal: () => void;
}

export const AddNewProject: FC<Omit<NewProps, 'formikProps'>> = ({
  closeModal,
  tribes,
  categories,
  billingType,
}) => {
  const queryClient = useQueryClient()
  const { successNotification } = useQueryNotification()

  const createNewSubjectMutation: Mutation<NewProjectProps> = (vals) => ({
    path: '/api/projects',
    method: 'POST',
    params: vals,
  })

  const newSubjectMutation = useCustomMutationWithCreatedId<
    Response,
    any,
    NewProjectProps
  >(
    createNewSubjectMutation,
    {
      onSuccess: async (data: Record<string, number | any>) => {
        const newId = data.createdId
        await queryClient.refetchQueries('/api/projects')
        successNotification('New project created')
        if (newId) {
          window.location.replace(`/admin/projects/${newId}`)
        }
      },
    },
    true
  )

  const formikSubmit: FormikSubmitHandler<any> = (values, { setErrors }) => {
    const cleanedValues = JSON.parse(JSON.stringify(values), (_, value) => {
      return value === '' ? null : value
    })
    newSubjectMutation.mutateAsync(cleanedValues).catch((err) => {
      setErrors(err.errors)
    })
  }

  return (
    <>
      <StyledTitle variant={'h3'}>New project</StyledTitle>
      <Formik
        initialValues={initVals}
        onSubmit={formikSubmit}
      >
        {(formikProps) => (
          <>
            <AddNewProjectForm
              categories={categories}
              formikProps={formikProps}
              tribes={tribes}
              billingType={billingType}
            />
            <StyledButtonGroup
              fullWidth
              variant="outlined"
            >
              <StyledButton
                kind="basicSecondary"
                variant="contained"
                onClick={closeModal}
              >
                Cancel
              </StyledButton>
              <Button
                kind="basicPrimary"
                onClick={formikProps.submitForm}
                disabled={!formikProps.isValid}
              >
                Create
              </Button>
            </StyledButtonGroup>
          </>
        )}
      </Formik>
    </>
  )
}
