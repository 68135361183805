import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const Dots: FC<IconSVGProps> = ({ width = 7, height = 22 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 7 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33333 20.9999C4.622 20.9999 5.66667 19.9552 5.66667 18.6666C5.66667 17.3779 4.622 16.3333 3.33333 16.3333C2.04467 16.3333 1 17.3779 1 18.6666C1 19.9552 2.04467 20.9999 3.33333 20.9999Z"
      stroke="#14141F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33333 5.66667C4.622 5.66667 5.66667 4.622 5.66667 3.33333C5.66667 2.04467 4.622 1 3.33333 1C2.04467 1 1 2.04467 1 3.33333C1 4.622 2.04467 5.66667 3.33333 5.66667Z"
      stroke="#14141F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33333 13.3334C4.622 13.3334 5.66667 12.2887 5.66667 11.0001C5.66667 9.71142 4.622 8.66675 3.33333 8.66675C2.04467 8.66675 1 9.71142 1 11.0001C1 12.2887 2.04467 13.3334 3.33333 13.3334Z"
      stroke="#14141F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
