import React, { FC } from 'react'
import { range } from 'lodash'
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  Typography,
  TableBody,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import styled from 'styled-components'

import { PageLayoutWrapper } from '../../components/ui/PageLayoutWrapper'
import { ButtonSkeleton } from '../../components/skeletons/ButtonSkeleton'
import { PillSkeleton } from '../../components/skeletons/PillSkeleton'
import { DevicenameCell, ActionTableCell, ActionsWrapper } from './TableDeviceRow'
import { ActionsBlock, StyledPaper } from './TestingDevicesPageView'

type Props = {
  rows: number;
}

export const TestingDevicesPageViewSkeleton: FC<Props> = ({ rows }) => {
  const skeletonRows = range(rows)

  return (
    <PageLayoutWrapper>
      <SkeletonStyledPaper>
        <Typography variant="h3">Testing devices</Typography>
        <ActionsBlock>
          <PillSkeleton
            key="pill"
            marginRight={'10px'}
          />

          <Skeleton
            variant="rect"
            width={451}
            height={40}
            animation="wave" />
        </ActionsBlock>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Device</TableCell>
                <TableCell>Brand</TableCell>
                <TableCell>System version</TableCell>
                <TableCell>Location</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {skeletonRows.map((item) => (
                <tr key={item}>
                  <DevicenameCell>
                    <Skeleton
                      variant="rect"
                      width={348}
                      height={40}
                      animation="wave" />
                  </DevicenameCell>
                  <TableCell>
                    <Skeleton
                      variant="rect"
                      width={100}
                      height={40}
                      animation="wave" />
                  </TableCell>
                  <TableCell>
                    <Skeleton
                      variant="rect"
                      width={145}
                      height={40}
                      animation="wave" />
                  </TableCell>
                  <InlineTableCell>
                    <Skeleton
                      variant="circle"
                      width={24}
                      height={24}
                      animation="wave" />
                    <Skeleton
                      variant="rect"
                      width={95}
                      height={48}
                      animation="wave" />
                    <Skeleton
                      variant="circle"
                      width={12}
                      height={12}
                      animation="wave" />
                  </InlineTableCell>
                  <ActionTableCell align="right">
                    <ActionsWrapper>
                      <ButtonSkeleton />
                    </ActionsWrapper>
                  </ActionTableCell>
                </tr>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </SkeletonStyledPaper>
    </PageLayoutWrapper>
  )
}

const InlineTableCell = styled(TableCell)`
  display: flex;
  align-items: center;
  gap: 6px;
`

const SkeletonStyledPaper = styled(StyledPaper)`
  padding: 48px 40px 48px 64px;
`
