import React, { FC, memo, useCallback, useMemo } from 'react'
import { SelectItem } from '../../types'
import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import styled, { css } from 'styled-components'
import { SelectProps } from '@material-ui/core/Select/Select'

type Props = Pick<
  SelectProps,
  | 'value'
  | 'error'
  | 'onChange'
  | 'onBlur'
  | 'name'
  | 'required'
  | 'onClose'
  | 'defaultValue'
  | 'open'
  | 'className'
> & {
  values: SelectItem[];
  label?: string;
  placeholder?: string;
  helperText?: string;
};
export const CorpSelect: FC<Props> = memo((props) => {
  const resolveDisabled = useCallback((value: string | number) => {
    return value === 'disabled'
  }, [])
  const {
    values,
    value,
    helperText,
    name,
    placeholder,
    label,
    required,
    error,
    className,
    onChange,
    ...rest
  } = props
  const labelId = useMemo(() => `${name}Label`, [name])
  return (
    <StyledSelectContainer className={className}>
      {label && (
        <StyledInputLabel
          id={labelId}
          htmlFor={name}
          required={required}
        >
          {label}
        </StyledInputLabel>
      )}
      <Select
        variant="outlined"
        fullWidth
        value={value}
        onChange={onChange}
        displayEmpty
        name={name}
        labelId={labelId}
        id={name}
        error={error}
        {...rest}
      >
        {placeholder && (
          <StyledMenuItem
            value=""
            disabled
          >
            <em>{placeholder}</em>
          </StyledMenuItem>
        )}
        {values.map(({ value, label, important }) => (
          <StyledMenuItem
            key={value}
            value={value}
            disabled={resolveDisabled(value)}
            $important={important}
          >
            {label}
          </StyledMenuItem>
        ))}
      </Select>
      {helperText && (
        <StyledFormHelperText error={error}>{helperText}</StyledFormHelperText>
      )}
    </StyledSelectContainer>
  )
})

const StyledSelectContainer = styled.div`
  width: 100%;
  flex-grow: 1;
`

const StyledMenuItem = styled(MenuItem) <{ $important?: boolean }>`
  display: block;
  padding: 5px 10px;
  ${({ $important }) => $important && css`font-weight: bold;`}
`

const StyledInputLabel = styled(InputLabel)`
  margin-bottom: 4px;
`

const StyledFormHelperText = styled(FormHelperText)`
  margin-left: 14px;
  margin-right: 14px;
`
