import React, { FC } from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import { formatDate } from '../../utils/helpers'

export const HistoryTableRow: FC<{ holder: any }> = ({ holder }) => {
  const { user, startDate, endDate } = holder

  const startDateInfo = startDate ? (
    <TableCell align={'right'}>{formatDate(new Date(startDate))}</TableCell>
  ) : (
    <TableCell align="center">-</TableCell>
  )
  const endDateInfo = endDate ? (
    <TableCell align={'right'}>{formatDate(new Date(endDate))}</TableCell>
  ) : (
    <TableCell align="center">-</TableCell>
  )

  return (
    <TableRow>
      <TableCell>{user.name}</TableCell>
      {startDateInfo}
      {endDateInfo}
    </TableRow>
  )
}
