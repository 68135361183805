import React, { FC } from 'react'
import { RatesTable } from './RatesTable'
import { Rate } from './types'
import { StyledEmpty } from './styles'

type Props = {
  rates: Rate[] | undefined;
  openEndValidityModal: (rate?: Rate) => void;
  openDeleteRateModal: (rate?: Rate) => void;
};

export const ScheduledChangesTab: FC<Props> = ({ rates, openEndValidityModal, openDeleteRateModal }) => {

  return (
    <div className="mt-4">
      {rates?.length ? <RatesTable
        hideEdit={true}
        hideEndValidity={true}
        rates={rates}
        openEndValidityModal={openEndValidityModal}
        openDeleteRateModal={openDeleteRateModal}
      /> : <StyledEmpty> No scheduled changes</StyledEmpty>}
    </div>
  )
}
