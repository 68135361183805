import React from 'react'
import styled from 'styled-components'
import { formatSum } from '../utils'
import { TableCell, TableRow, Typography } from '@material-ui/core'

type GroupTitleProps = {
  groupName: string;
  sum: number;
  level: number;
};

const StyledGroupTitle = styled.p<{ size: number }>`
  font-size: ${(props) => props.size}em;
  margin-bottom: 0px;
`

export const GroupTitle = ({ groupName, sum, level }: GroupTitleProps) => {
  const headingSize = 1.4 - (level / 10) * 2

  const title = <Typography>{groupName}</Typography>

  return (
    <TableRow>
      <TableCell>{level == 0 && title}</TableCell>
      <TableCell>{level == 1 && title}</TableCell>
      <TableCell align="right">{formatSum(sum)}</TableCell>
    </TableRow>
  )
}
