import React, { FC, useState } from 'react'
import { Button, Dialog, TextField, Typography } from '@material-ui/core'
import { ContentPaper } from '../../../components/ContentPaper'
import { Formik } from 'formik'
import { DialogForm } from './AddNewContract'
import { useQueryClient } from 'react-query'
import { useQueryNotification } from '../utils'
import { Mutation, useCustomMutation } from '../../../hooks/useCustomMutation'
import { Address, Contact, NewAddress } from '../types'
import { FormikSubmitHandler, SelectItem } from '../../../types'
import { FormSelect } from '../../../components/inputs/FormSelect'
import { hasError, helperText } from '../../../utils/helpers'

type Props = {
  userId: number;
  enums:
  | {
    addressTypes: SelectItem[];
    countries: SelectItem[];
  }
  | undefined;
};
export const AddNewAddress: FC<Props> = ({ userId, enums }) => {
  const [dialogOpened, setDialogOpened] = useState(false)
  const queryClient = useQueryClient()
  const { successNotification } = useQueryNotification()

  const queryKey = `/api/users/${userId}/addresses`
  const createNewAddressMutation: Mutation<Contact> = (vals) => ({
    path: queryKey,
    method: 'POST',
    params: vals,
  })

  const newSubjectMutation = useCustomMutation<Response, any, Contact>(
    createNewAddressMutation,
    {
      onSuccess: async (data, variables) => {
        await queryClient.refetchQueries(queryKey)
        successNotification('Contact created')
        setDialogOpened(false)
      },
    },
    true
  )

  const formikSubmit: FormikSubmitHandler<Address> = (
    values,
    { setErrors }
  ) => {
    newSubjectMutation.mutateAsync(values).catch((err) => {
      setErrors(err.errors)
    })
  }

  const initVals: NewAddress = {
    addressType: 'business',
    street1: '',
    street2: '',
    town: '',
    countryCode: 'CZ',
    postCode: '',
  }

  return (
    <>
      <Dialog
        open={dialogOpened}
        onClose={() => setDialogOpened(false)}
      >
        <ContentPaper>
          <Typography variant={'h3'}>New address</Typography>
          <Formik
            initialValues={initVals}
            onSubmit={formikSubmit}
          >
            {(formikProps) => (
              <DialogForm>
                <FormSelect
                  required
                  values={enums?.addressTypes || []}
                  name={'addressType'}
                  label={'Address type'}
                  value={formikProps.values.addressType}
                  error={hasError(formikProps, 'addressType')}
                  helperText={helperText(formikProps, 'addressType')}
                />
                <TextField
                  fullWidth
                  value={formikProps.values.street1}
                  name={'street1'}
                  label={'Street line 1'}
                  placeholder={'Rohanské nábřeží 670/19'}
                  onChange={formikProps.handleChange}
                  error={hasError(formikProps, 'street1')}
                  helperText={helperText(formikProps, 'street1')}

                />
                <TextField
                  fullWidth
                  value={formikProps.values.street2}
                  name={'street2'}
                  placeholder={'River - Entrance B'}
                  label={'Street line 2'}
                  onChange={formikProps.handleChange}
                  error={hasError(formikProps, 'street2')}
                  helperText={helperText(formikProps, 'street2')}

                />
                <TextField
                  fullWidth
                  required
                  value={formikProps.values.town}
                  name={'town'}
                  label={'Town'}
                  onChange={formikProps.handleChange}
                  error={hasError(formikProps, 'town')}
                  helperText={helperText(formikProps, 'town')}

                />
                <FormSelect
                  required
                  values={enums?.countries || []}
                  name={'countryCode'}
                  label={'Country'}
                  value={formikProps.values.countryCode}
                  error={hasError(formikProps, 'countryCode')}
                  helperText={helperText(formikProps, 'countryCode')}

                />
                <TextField
                  fullWidth
                  value={formikProps.values.postCode}
                  name={'postCode'}
                  label={'Post code'}
                  onChange={formikProps.handleChange}
                  error={hasError(formikProps, 'postCode')}
                  helperText={helperText(formikProps, 'postCode')}

                />
                <Button
                  fullWidth
                  variant={'contained'}
                  color={'primary'}
                  onClick={formikProps.submitForm}
                >
                  Create
                </Button>
              </DialogForm>
            )}
          </Formik>
        </ContentPaper>
      </Dialog>
      <Button
        variant={'outlined'}
        color={'primary'}
        size={'small'}
        onClick={() => setDialogOpened(true)}
      >
        New address
      </Button>
    </>
  )
}
