import React, { FC } from 'react'
import { Payment } from '../../PaymentsPage/types'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { Button } from '../../../components/buttons/Button'
import { Alert } from '@material-ui/lab'
import { AddNewTransaction } from './AddNewTransaction'
import { StyledTableContainer } from './PaymentInfoTable'
import { TransactionsTable } from './TransactionsTable'
import { atMinWidth } from '../../../styles/helpers'
import { useModalState } from '../../../components/ModalWrapper'
import { MoneyFormatter } from '../../../utils/formatters'

type Props = {
  payment: Payment;
};

export const PairedPaymentTable: FC<Props> = ({ payment }) => {
  const { amountLeftToPair, accountTransactions } = payment

  const isShownRemaningPairingBalance =
    payment.amountLeftToPair !== 0 && payment.accountTransactions.length !== 0

  const amount = new MoneyFormatter('CZK').format(amountLeftToPair)

  const { isModalOpen, closeModal, openModal } = useModalState()

  return (
    <>
      <StyledTypography variant="h5">Payment paired to accounts</StyledTypography>
      {isShownRemaningPairingBalance && (
        <PairingWarning>
          <Alert severity="warning">{amount} CZK left to pair</Alert>
          <Button
            onClick={openModal}
            kind={'primary'}>
            Pair payment
          </Button>
          <AddNewTransaction
            payment={payment}
            isModalOpen={isModalOpen}
            closeModal={closeModal} />
        </PairingWarning>
      )}

      <StyledTableContainer>
        {accountTransactions.length !== 0 ? (
          <TransactionsTable accountTransactions={accountTransactions} />
        ) : (
          <StyledInfo>
            This payment has not yet been paired to any account.{' '}
            <a onClick={openModal}>
              <StyledText>Pair it now?</StyledText>
            </a>
            <AddNewTransaction
              payment={payment}
              isModalOpen={isModalOpen}
              closeModal={closeModal}
            />
          </StyledInfo>
        )}
      </StyledTableContainer>
    </>
  )
}

const StyledText = styled.span`
  text-decoration: underline !important;
`

const StyledTypography = styled(Typography)`
  margin: 3rem 0 1rem 0;
`

const PairingWarning = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 1rem 0 2rem 0;

  ${atMinWidth.tablet} {
    width: 50%;
    min-width: 500px;
  }
`

const StyledInfo = styled.p`
  margin-top: 2rem;
`
