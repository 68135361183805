export const colors = {
  brand: '#14141F',
  brandLight: 'rgba(20,20,31,0.88)',
  brandBlue: '#5ECAE7',
  brandYellow: '#FAC61F',

  black: '#000',
  white: '#FFFFFF',

  grey0: '#848484',
  grey1: '#C8C6C5',
  grey3: '#2C333A',
  grey4: '#F0F0F0',
  grey5: '#727279',
  lightGrey: '#DDDCDB',

  success: '#42D4BA',
  error: '#FD406D',
  warning: '#FD4040',
  danger: '#FFB545',
}
