import React, { FC } from 'react'
import { IconButton, Tooltip, useMediaQuery } from '@material-ui/core'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import { AmountFilter } from './AmountFilter'
import { DateFilter } from './DateFilter'
import { UsersFilter } from './UsersFilter'
import { ScopeFilter } from './ScopeFilter'
import { useTransactionContext } from './MyTransactionContext'
import { theme } from '../../../styles/muiThemes'
import { Formik } from 'formik'
import { initialState } from '../utils'
import { useCurrentUserInfo } from '../../../components/CurrentUserProvider'
import { Skeleton } from '@material-ui/lab'
import { FormWrapper, StyledForm, RowWrapper, UsersAndScopeWrapper, DateWrapper, AmountWrapper, MobileClearButton } from './styles'

export const MyTransactionFilters: FC = () => {
  const { isUsersLoading, isAllTransactions, filterTransactions, users } = useTransactionContext()
  const isGt2xl = useMediaQuery(theme.breakpoints.up('2xl'))

  const queryParameters = new URLSearchParams(window.location.search)
  const userId = queryParameters.get('user_id')
  const paymentDirection = queryParameters.get('payment_direction')

  const { currentUser } = useCurrentUserInfo()
  const initialStateWithUserId = { ...initialState, user_id: Number(userId) ?? currentUser.id, ...(paymentDirection && { direction: paymentDirection }) }

  if (isAllTransactions && isUsersLoading) {
    return (
      <Skeleton
        animation="wave"
        style={{ height: '40px', marginTop: 24, marginBottom: 12 }}
      />
    )
  }

  return (
    <Formik
      initialValues={isAllTransactions && !userId ? initialState : initialStateWithUserId}
      onSubmit={values => {
        filterTransactions(values)
      }}>
      {formikProps => (
        <FormWrapper>
          <StyledForm onSubmit={formikProps.handleSubmit}>
            <RowWrapper>
              <UsersAndScopeWrapper>
                {isAllTransactions && users?.length && (
                  <UsersFilter
                    formikProps={formikProps}
                    users={users}
                  />
                )}
                <ScopeFilter
                  formikProps={formikProps}
                  isAllTransactions
                />
              </UsersAndScopeWrapper>
              <DateWrapper>
                <DateFilter formikProps={formikProps} />
              </DateWrapper>
            </RowWrapper>
            <AmountWrapper>
              <AmountFilter formikProps={formikProps} />
            </AmountWrapper>
          </StyledForm>
          {isGt2xl ? (
            <Tooltip title="Clear filters">
              <IconButton
                onClick={() => {
                  formikProps.handleReset()
                  formikProps.handleSubmit()
                }}
              >
                <CancelOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <MobileClearButton
              kind="basicSecondary"
              size="small"
              onClick={() => {
                formikProps.handleReset()
                formikProps.handleSubmit()
              }}
            >
              Clear filters
            </MobileClearButton>
          )}
        </FormWrapper>
      )}
    </Formik>
  )
}

