import React, { FC, useState, useMemo } from 'react'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { Alert } from '@material-ui/lab'
import { AccountSelectValues } from '../types'
import { calculatePairedSum, isAmountFilled } from '../utils'
import { PairingForm } from './PairingForm'
import { FormikProps, FieldArrayRenderProps, FormikValues } from 'formik'
import { Payment } from '../../PaymentsPage/types'

type Props = {
  formikProps: FormikProps<FormikValues>;
  arrayHelpers: FieldArrayRenderProps;
  payment: Payment;
  accountsSelectValues: AccountSelectValues[] | undefined;
  closeModal: () => void;
  isLoading: boolean;
};

export const FormContent: FC<Props> = ({
  formikProps,
  arrayHelpers,
  payment,
  accountsSelectValues,
  closeModal,
  isLoading,
}) => {
  const [isDuplicateAccounts, setIsDuplicateAccounts] = useState(false)
  const { amountLeftToPair, amount } = payment
  const { values } = formikProps
  const remainingAmountToPair = useMemo(() => {
    return amountLeftToPair - calculatePairedSum(values.transactions)
  }, [values.transactions])

  const amountParsed = amount.toFixed(1)
  const amountLeft = isAmountFilled(values.transactions)
    ? remainingAmountToPair.toFixed(1)
    : amountLeftToPair.toFixed(1)

  const textAlert =
    (isDuplicateAccounts &&
      'Duplicate accounts entered. Please create transaction under unique account') ||
    (remainingAmountToPair < 0 && 'Invalid amount paired')

  return (
    <>
      {textAlert && <Alert severity="error">{textAlert}</Alert>}
      <PaymentInfoContainer>
        <Typography variant="h6">Payment: {amountParsed}</Typography>
        <Typography variant="h6">Amount left to pair: {amountLeft}</Typography>
      </PaymentInfoContainer>

      <PairingForm
        accountsSelectValues={accountsSelectValues}
        formikProps={formikProps}
        arrayHelpers={arrayHelpers}
        setIsDuplicateAccounts={setIsDuplicateAccounts}
        amountLeft={amountLeft}
        closeModal={closeModal}
        isLoading={isLoading}
      />
    </>
  )
}

const PaymentInfoContainer = styled.div`
  margin: 3rem 0 1rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
`
