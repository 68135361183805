import React from 'react'
import styled from 'styled-components'
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  Box,
  TableBody,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { TestingDevices } from './types'
import { TableDeviceRow } from './TableDeviceRow'

export const DevicesTable: React.FC<{
  filteredDevices: TestingDevices[];
}> = ({ filteredDevices }) => {

  if (!filteredDevices.length) {
    return (
      <Alert
        variant={'outlined'}
        severity={'info'}
      >
        No devices with this filter setup
      </Alert>
    )
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Device</TableCell>
            <TableCell>Brand</TableCell>
            <TableCell>System version</TableCell>
            <TableCell>Location</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredDevices.map((device) => (
            <TableDeviceRow
              key={device.id}
              device={device}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
