import React, { FC, useMemo } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { AssignUserToProject } from '../../AssignUserToProjectModal/AssignUserToProject'
import { useUserPermissions } from '../../../components/CurrentUserProvider'
import { useProjectDetailContext } from '../ProjectDetailPage'

type Props = {};
export const ProjectUsersHeader: FC<Props> = () => {
  const { resolvePermissions } = useUserPermissions()
  const { project, refetch, isActive } = useProjectDetailContext()
  const canAssignUserToProject = useMemo(
    () => resolvePermissions('project', 'assignUser'),
    []
  )
  return (
    <Grid
      container
      justify={'space-between'}
    >
      <Grid item>
        <Typography variant={'h3'}>People</Typography>
      </Grid>
      <Grid item>
        {canAssignUserToProject && isActive && (
          <AssignUserToProject
            projectId={project.id}
            projectName={project.name}
            buttonProps={{ size: 'medium' }}
            done={refetch}
          />
        )}
      </Grid>
    </Grid>
  )
}
