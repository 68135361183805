import React, { FC, useMemo, useState } from 'react'
import { Drawer, Link, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { PurchaseData } from '../../types'
import { useSnackbar } from 'notistack'
import { useQueryClient } from 'react-query'
import { getPurchaseMutationData, productsQueryString } from '../../utils'
import {
  Mutation,
  useCustomMutation,
} from '../../../../hooks/useCustomMutation'
import { CartButton } from './CartButton'
import { CartBottomLine } from './CartBottomLine'
import { CartDrawerContent } from './CartDrawerContent'
import { useShopContext } from '../../ShopContext'

type Props = {
  productCategory: string;
};
export const Cart: FC<Props> = (props) => {
  const { productCategory } = props
  const { emptyBasket, products } = useShopContext()

  const [cartVisible, setCartVisible] = useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const [onHouse, setOnHouse] = useState(false)

  const showCart = () => setCartVisible(true)
  const hideCart = () => setCartVisible(false)

  const item = useMemo(
    () => (productCategory == 'fridge' ? 'drink / snack' : 'merch'),
    [productCategory]
  )

  const createPurchaseMutation: Mutation<PurchaseData> = (
    data: PurchaseData
  ) => {
    return {
      path: `/api/purchases/${productCategory}`,
      params: {
        ...data,
      },
    }
  }

  const purchaseMutation = useCustomMutation<{}, string, PurchaseData>(
    createPurchaseMutation,
    {
      onSuccess: async () => {
        hideCart()
        emptyBasket()
        enqueueSnackbar(
          <Typography variant="body2">
            Your purchase has been recorded. <br></br>
            <Bold>Enjoy your {item}!</Bold>
          </Typography>,
          {
            variant: 'success',
            autoHideDuration: 5000,
            onClose: () => location.reload(),
          }
        )

        await queryClient.refetchQueries(productsQueryString(productCategory))
      },
      onError: (err) => {
        //FIXME: error handling
        enqueueSnackbar('Error, while creating purchase', {
          variant: 'error',
        })
      },
    }
  )

  const handlePurchase = () => {
    const mySelected = products.filter((product) => product.amount > 0)
    const purchaseData = getPurchaseMutationData(mySelected)
    purchaseMutation.mutate({
      on_house: onHouse ? 1 : 0,
      products: purchaseData,
    })
  }

  return (
    <>
      <CartButton onClick={showCart} />
      <CartBottomLine
        drawerOpened={cartVisible}
        viewCart={showCart}
        purchase={handlePurchase}
      />
      <StyledDrawer
        anchor={'right'}
        open={cartVisible}
        onClose={hideCart}
      >
        <CartDrawerContent
          onHouse={onHouse}
          setOnHouse={() => setOnHouse((prevState) => !prevState)}
          purchase={handlePurchase}
          handleClose={hideCart}
        />
      </StyledDrawer>
    </>
  )
}

const StyledDrawer = styled(Drawer)`
  flex-shrink: 0;
`

const Bold = styled(Link)`
  font-weight: bold;
`
