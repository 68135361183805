import { MouseEvent } from 'react'
import { Tribe } from '../../bundles/ProjectsPage/types'
import { ChangeEvent, useCallback, useState } from 'react'
import { debounce } from '@material-ui/core'
import { sortByKeyLocalized } from '../../utils/helpers'
import { SelectItem } from '../../types'
import { User } from './types'
import { recalculateBalance } from '../../services/api'
import { useQueryNotification } from '../../bundles/UserInfo/utils'
import { MoneyFormatter } from '../../utils/formatters'

const USER_BASE_URL = '/admin/users'

export const listUserTribes = (tribes: Tribe[]): string => {
  return tribes
    .map((tribe) => tribe.alias)
    .join(', ')
    .toString()
}

export const userRate = (
  rate: number | null,
  billingCurrency: string
): string | null => {
  return rate ? new MoneyFormatter(billingCurrency).format(rate) : null
}

export const selectableTribes = (tribes?: Tribe[]): SelectItem[] => {
  const base = [{ label: 'All', value: 0 }]
  tribes?.forEach((tribe) => {
    base.push({ label: tribe.name, value: tribe.id })
  })
  return base
}

type GetFilteredUsers = (
  users: User[],
  searchString: string,
  searchTribe: number
) => User[];

export const userBelongsToTribe = (user: User, searchTribeId: number) => {
  if (searchTribeId === 0) return true
  return user.tribes.some((tribe) => tribe.id === searchTribeId)
}

export const getSortedUsers = (users?: User[]) => {
  if (users) sortByKeyLocalized(users, 'fullName')

  return users || []
}

export const getFilteredUsers: GetFilteredUsers = (
  users,
  searchString,
  searchTribe
) => {
  const formatedSearchString = searchString.toLowerCase()

  const keysContainSearchString = (user: User) => {
    return (
      user.fullName?.toLowerCase().includes(formatedSearchString) ||
      user.email?.toLowerCase().includes(formatedSearchString) ||
      user.nickname?.toLowerCase().includes(formatedSearchString)
    )
  }

  return users.filter((user) => {
    return (
      userBelongsToTribe(user, searchTribe) && keysContainSearchString(user)
    )
  })
}

type HandleFilterInputChange = (
  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
) => void;
type RecalculateBalance = (event: MouseEvent<HTMLButtonElement>) => void;

type UseUserFiltering = (users: User[]) => {
  filteredUsers: User[];
  handleFilterChange: HandleFilterInputChange;
  handleTribeChange: HandleFilterInputChange;
  recalculateUsersBalance: RecalculateBalance;
  searchString: string;
  owningTribe: number;
};

export const useUserFiltering: UseUserFiltering = (users) => {
  const [searchString, setSearchString] = useState<string>('')
  const [owningTribe, setOwningTribe] = useState<number>(0)
  const { successNotification, errorNotification } = useQueryNotification()

  const debouncer = useCallback(
    debounce((searchValue: string) => {
      setSearchString(searchValue)
    }, 300),
    []
  )

  const handleFilterChange: HandleFilterInputChange = useCallback((event) => {
    debouncer(event.target.value)
  }, [])

  const handleTribeChange: HandleFilterInputChange = useCallback((event) => {
    if (event.target) {
      setOwningTribe(parseInt(event.target.value))
    } else {
      setOwningTribe(Number(event))
    }
  }, [])

  const recalculateUsersBalance: RecalculateBalance = async () => {
    try {
      await recalculateBalance()
      successNotification('Recalculated')
    } catch (error) {
      errorNotification('Error while recalculating')
    }
  }

  const filteredUsers =
    searchString || owningTribe !== 0
      ? getFilteredUsers(users, searchString, owningTribe)
      : users

  return {
    searchString,
    owningTribe,
    filteredUsers,
    handleFilterChange,
    handleTribeChange,
    recalculateUsersBalance,
  }
}

export const getLinkToUserDetail = (id: number): string => {
  return `${USER_BASE_URL}/${id}`
}

export const redirectToUserDetail = (id: number): void => {
  return window.location.assign(getLinkToUserDetail(id))
}
