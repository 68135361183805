import React, { FC, useState } from 'react'
import { Button, Dialog, TextField, Typography } from '@material-ui/core'
import { ContentPaper } from '../../../components/ContentPaper'
import { FormikSubmitHandler, SelectItem } from '../../../types'
import { FormSelect } from '../../../components/inputs/FormSelect'
import { Formik } from 'formik'
import { Mutation, useCustomMutation } from '../../../hooks/useCustomMutation'
import { useQueryClient } from 'react-query'
import { DialogForm } from './AddNewContract'
import { getTextFieldType, useQueryNotification } from '../utils'
import { Contact, NewContact } from '../types'
import { hasError, helperText } from '../../../utils/helpers'

type Props = {
  userId: number;
  contactTypes: SelectItem[];
};
export const AddNewContact: FC<Props> = ({ userId, contactTypes }) => {
  const [dialogOpened, setDialogOpened] = useState(false)
  const queryClient = useQueryClient()
  const { successNotification, errorNotification } = useQueryNotification()

  const initVals: NewContact = {
    contactType: '',
    value: '',
  }

  const createNewSubjectMutation: Mutation<Contact> = (vals) => ({
    path: `/api/users/${userId}/contacts`,
    method: 'POST',
    params: vals,
  })

  const newSubjectMutation = useCustomMutation<Response, any, NewContact>(
    createNewSubjectMutation,
    {
      onSuccess: async (data, variables) => {
        await queryClient.refetchQueries(`/api/users/${userId}/contacts`)
        successNotification('Contact created')
        setDialogOpened(false)
      },
    },
    true
  )

  const formikSubmit: FormikSubmitHandler<Contact> = (
    values,
    { setErrors }
  ) => {
    newSubjectMutation.mutateAsync(values).catch((err) => {
      setErrors(err.errors)
    })
  }

  return (
    <>
      <Dialog
        open={dialogOpened}
        onClose={() => setDialogOpened(false)}
      >
        <ContentPaper>
          <Typography variant={'h3'}>New contact</Typography>
          <Formik
            initialValues={initVals}
            onSubmit={formikSubmit}
          >
            {(formikProps) => (
              <DialogForm>
                <FormSelect
                  required
                  label={'Contact type'}
                  value={formikProps.values.contactType}
                  name={'contactType'}
                  values={contactTypes}
                  error={hasError(formikProps, 'contactType')}
                  helperText={helperText(formikProps, 'contactType')}
                />
                <TextField
                  fullWidth
                  required
                  value={formikProps.values.value}
                  name={'value'}
                  label={'Contact'}
                  type={getTextFieldType(formikProps.values.contactType)}
                  onChange={formikProps.handleChange}
                  error={hasError(formikProps, 'value')}
                  helperText={helperText(formikProps, 'value')}
                />
                <Button
                  fullWidth
                  variant={'contained'}
                  color={'primary'}
                  onClick={formikProps.submitForm}
                >
                  Create
                </Button>
              </DialogForm>
            )}
          </Formik>
        </ContentPaper>
      </Dialog>
      <Button
        variant={'outlined'}
        color={'primary'}
        size={'small'}
        onClick={() => setDialogOpened(true)}
      >
        New contact
      </Button>
    </>
  )
}
