import React, { FC } from 'react'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { TypographySkeleton } from '../../components/skeletons/TypographySkeleton'
import { Typography } from '@material-ui/core'
import { ButtonSkeleton } from '../../components/skeletons/ButtonSkeleton'
import { Button } from '../../components/buttons/Button'
import { PlusIcon } from '../../components/Icon/icons/Plus.icon'

type Props = {
  isLoading: boolean;
  openModal: () => void;
};

export const ProductTableHeader: FC<Props> = ({ isLoading, openModal }) => {
  const headerButtonProps: React.CSSProperties = {
    width: '225px',
    height: '48px',
  }
  return (
    <TableHeaderContainer>
      {isLoading ? (
        <>
          <TypographySkeleton variant={'h3'} />
          <ButtonSkeleton {...headerButtonProps} />
        </>
      ) : (
        <>
          <StyledTypography variant="h3">Available products</StyledTypography>
          <Button
            kind="basicPrimary"
            onClick={openModal}>
            <PlusIcon color={colors.white} /> <ButtonText>Add new product</ButtonText>
          </Button>
        </>
      )}
    </TableHeaderContainer>
  )
}

const ButtonText = styled.span`
  margin-left: 10px;
`

const StyledTypography = styled(Typography)`
  margin-top: 2rem;
`

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
`
