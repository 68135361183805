import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core'
import { Field, FormikProps } from 'formik'
import React, { FC } from 'react'
import styled from 'styled-components'

import { colors } from '../../styles/colors'
import { LabelText } from '../ProjectsPage/components/AddNewProject/styles'
import { StepsData } from './types'
import { RadioButtonsGroup } from '../../components/inputs/RadioButtonsGroup'

type Props = {
  formikProps: FormikProps<StepsData>;
  setIsDisabled: (filledInput: boolean) => void;
};

export const PaymentCardForm: FC<Props> = ({ formikProps, setIsDisabled }) => {
  const { values, handleChange } = formikProps

  const handleInputChange = (e) => {
    if (e.target.value.length > 3) {
      setIsDisabled(false)
      handleChange(e)
    } else {
      setIsDisabled(true)
    }
  }



  const purposeOptions = [
    { key: 'software', name: 'Software Licences and Services' },
    { key: 'education', name: 'Education' },
    { key: 'procurement', name: 'Procurement payments' }
  ]

  return (
    <>
      <RadioButtonsGroup
        key="purpose"
        title="Purpose of purchase"
        name="purpose"
        options={purposeOptions}
      />
      <StepWrapper>
        <StyledTitle>Purchase details</StyledTitle>

        <StyledLabelText>Supplier *</StyledLabelText>
        <StyledInputField
          as="input"
          name="supplier"
          placeholder="supplier of the purchase"
          onChange={handleInputChange}
          defaultValue={values.supplier}
          required />

        <StyledLabelText>Description *</StyledLabelText>
        <StyledTextarea
          as="textarea"
          name="comment"
          placeholder="Mention name of the supplier and the subject of the payment."
          defaultValue={values.comment}
          onChange={handleInputChange}
          required
        />

        <CheckboxWrapper>
          <LabelTextForCheckbox
            control={<StyledCheckbox
              name="is_subscription"
              color="default"
              onChange={handleChange}
              checked={values.is_subscription}
            />}
            label="recurring payment" />
          <Tooltip title="If you are not sure, ask a BO buddy or your TL.">
            <LabelTextForCheckbox
              control={
                <StyledCheckbox
                  name="paid_by_client"
                  color="default"
                  onChange={handleChange}
                  checked={values.paid_by_client}
                />}
              label="paid by client" />
          </Tooltip>
        </CheckboxWrapper>
      </StepWrapper>
    </>
  )
}

export const StepWrapper = styled.div`
  margin-top: 27px;
`

export const StyledTitle = styled.div`
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 23.4px;
  margin-bottom: 16px;
`

const StyledLabelText = styled(LabelText)`
  color: ${colors.grey0};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 15px;
  margin-bottom: 6px;
`

const StyledTextarea = styled(Field)`
  border: 1px solid ${colors.grey4};
  font-size: 1rem;
  margin-bottom: 17px;
  padding: 8px 12px;
  width: 100%;
`

const StyledInputField = styled(Field)`
  border: 1px solid ${colors.grey4};
  font-size: 1rem;
  margin-bottom: 17px;
  padding: 8px 12px;
  width: 100%;
`

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledCheckbox = styled(Checkbox)`
  color: ${colors.black}
  margin-right: 12px;
  padding: 0;
`

const LabelTextForCheckbox = styled(FormControlLabel)`
  color: #151B36;
  font-size: 1rem;
  text-transform: none;
`
