import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const ShoppingCartIcon: FC<IconSVGProps> = ({
  width = 15,
  height = 16,
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8532 15H3.22178C3.01969 14.9963 2.82476 14.9245 2.66858 14.7962C2.51239 14.6679 2.4041 14.4907 2.36124 14.2931L1.03093 8.69315C0.995316 8.55903 0.990386 8.41861 1.01651 8.28232C1.04263 8.14604 1.09914 8.01739 1.18182 7.90595C1.26451 7.79451 1.37124 7.70314 1.4941 7.63862C1.61696 7.57411 1.75278 7.53812 1.89147 7.53333H13.1836C13.3222 7.53812 13.4581 7.57411 13.5809 7.63862C13.7038 7.70314 13.8105 7.79451 13.8932 7.90595C13.9759 8.01739 14.0324 8.14604 14.0585 8.28232C14.0846 8.41861 14.0797 8.55903 14.0441 8.69315L12.7138 14.2931C12.6709 14.4907 12.5626 14.6679 12.4064 14.7962C12.2503 14.9245 12.0553 14.9963 11.8532 15V15Z"
      stroke="#C8C6C5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M4.7373 9.40002V13.1334"
      stroke="#C8C6C5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M7.5376 9.40002V13.1334"
      stroke="#C8C6C5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M10.3374 9.40002V13.1334"
      stroke="#C8C6C5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M5.67107 1.78204C4.8149 2.05526 4.05453 2.56724 3.47937 3.25781C2.90421 3.94837 2.53818 4.7888 2.42432 5.68027"
      stroke="#C8C6C5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M12.6529 5.70391C12.5436 4.80783 12.1794 3.96196 11.6035 3.26676C11.0277 2.57156 10.2644 2.05626 9.4043 1.78204"
      stroke="#C8C6C5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M8.70423 1H6.3709C5.9843 1 5.6709 1.3134 5.6709 1.7V2.16667C5.6709 2.55327 5.9843 2.86667 6.3709 2.86667H8.70423C9.09083 2.86667 9.40423 2.55327 9.40423 2.16667V1.7C9.40423 1.3134 9.09083 1 8.70423 1Z"
      stroke="#C8C6C5"
      strokeLinecap="round"
      strokeLinejoin="round" />
  </svg>
)
