import { FormikHelpers, FormikValues } from 'formik/dist/types'
import { UpdatableField } from './UpdatableFormFieldData/UpdatableField'
import { CreatePayment } from '../bundles/MyTransactionPage/components/types'
import { ProductCreate } from '../bundles/ProductsPage/types'
import { NewProjectProps } from '../bundles/ProjectsPage/components/AddNewProject/types'
import { NewTestingDevice } from '../bundles/TestingDevicesPage/types'
import { NewContract, NewSubject, NewAddress, NewContact } from '../bundles/UserInfo/types'

export type FormikSubmitHandler<Values> = (
  values: Values,
  formikHelpers: FormikHelpers<Values>
) => void | Promise<any>;

export type IdNamePair = {
  id: number;
  name: string;
};

export type SelectItem = {
  value: string | number;
  label: string;
  important?: boolean
};

export type DataLine = {
  label: string;
  description?: string;
  updatableFields: UpdatableField[];
  deleteIcon?: boolean;
};
export enum UsersState {
  active = 'active',
  inactive = 'inactive',
}

export type SlackAvatar = {
  image24: string;
  image32: string;
  image48: string;
  image72: string;
  image192: string;
  image512: string;
  image1024: string;
  imageOriginal: string;
};
export interface BaseUser {
  id: number;
  email: string;
  lastName: string;
  balance: number;
  firstName: string;
  fullName: string;
  nickname: string;
  role: string;
  primaryTribeId: number;
  billingCurrency: string;
  togglApiKey: string;
  togglWorkspaceId: number;
  togglWorkspaces: any;
  driveFolderId: string;
  highestEducation: string;
  maternityLeave: boolean;
  balanceOverLimit: boolean;
  shoppingBalance: number;
  birthdate: string;
  nationality: string;
  citizenship: string;
  slackUserId: string;
  sex: string;
  togglApiKeyInvalid?: boolean;
  slackAvatar: SlackAvatar;
  state: UsersState;
  name?: string;
  slack_user_id?: string;
  slackNotifications: {
    purchase: boolean;
    payment: boolean;
  };
}

export type NewUserType = Pick<
  BaseUser,
  'lastName' | 'firstName' | 'nickname' | 'role' | 'email' | 'slack_user_id'
>;

export interface UserInProject extends BaseUser {
  billsToTribe: string;
  projectStatus: string;
  clientRate: number;
  togglProjectId: number;
  togglProjectName: string;
  tribeId: number;
}

type BreadcrumbLink = {
  id: number;
  title: string;
  href: string;
};

export type BreadcrumbsData = {
  links: BreadcrumbLink[];
  currentPageTitle: string;
};

export type FormikFormValues = FormikValues
  | CreatePayment
  | NewContract
  | NewSubject
  | ProductCreate
  | NewProjectProps
  | NewTestingDevice
  | NewAddress
  | NewContact
  | NewUserType
