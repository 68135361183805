import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { TableRow, TableCell } from '@material-ui/core'

import { getLinkToDeviceDetail } from './utils'
import { TestingDevices } from './types'
import { useCurrentUserInfo } from '../../components/CurrentUserProvider'
import { colors } from '../../styles/colors'
import { DeviceActionButton } from './DeviceActionsButtons'
import { LocationRow } from './LocationRow'

export const TableDeviceRow: FC<{ device: TestingDevices }> = ({ device }) => {
  const { currentUser } = useCurrentUserInfo()
  const isDeviceTaken = device.taken
  const isDeviceTakenByCurrentUser =
    currentUser.id === device.currentUser?.user.id
  const isDeviceTakenByMe = isDeviceTakenByCurrentUser && device.taken

  const systemVersion = !device.systemVersion?.length
    ? '-'
    : device.systemVersion

  return (
    <StyledTableRow $isDeviceTaken={isDeviceTaken}>
      <DevicenameCell>
        <a href={getLinkToDeviceDetail(device.id)}>{device.name}</a>
      </DevicenameCell>
      <TableCell>{device.brand}</TableCell>
      <TableCell>{systemVersion}</TableCell>
      <TableCell>
        <LocationRow device={device} />
      </TableCell>
      <ActionTableCell align="right">
        <ActionsWrapper>
          <DeviceActionButton
            isDeviceTakenByMe={isDeviceTakenByMe}
            isDeviceTaken={isDeviceTaken}
            device={device}
          />
        </ActionsWrapper>
      </ActionTableCell>
    </StyledTableRow>
  )
}

export const ActionTableCell = styled(TableCell)`
  * button {
    width: fit-content;
  }
`

export const ActionsWrapper = styled.div`
  display: grid;
  align-items: center;
  display: flex;
  justify-content: right;
`

export const StyledTableRow = styled(TableRow) <{ $isDeviceTaken: boolean }>`
  ${(props) =>
    props.$isDeviceTaken === true &&
    css`
      > td {
        color: ${colors.grey0};
        &:first-child {
          text-decoration: line-through;
        }
      }
    `}
`

export const DevicenameCell = styled(TableCell)`
  &: hover {
    font-weight: 600;
  }
`
