import spirit from '../../assets/images/spirit.png'
import work from '../../assets/images/my_work.png'
import phone from '../../assets/images/phone_app.png'
import dominik from '../../assets/images/dominik.png'
import getSnack from '../../assets/images/getSnack.png'
import payment from '../../assets/images/payment.png'

const images = {
  spirit,
  work,
  phone,
  dominik,
  getSnack,
  payment,
}

export function getImageByKey(key: string): string {
  return images[key]
}
