import React, { FC } from 'react'
import styled from 'styled-components'
import { Avatar, Typography } from '@material-ui/core'
import { RefetchOptions, QueryObserverResult } from 'react-query'

import { theme } from '../../styles/muiThemes'
import { Chips } from './Chips'
import { DotsButtons } from './components/DotsButtons'
import { ToggleSync } from './components/ToggleSync'
import { UserDetail } from './types'
import { LinkToProjectReport } from '../../components/buttons/LinkToUserReport'
import { useModalState } from '../../components/ModalWrapper'
import { FullSizeImageModal } from './components/FullSizeImageModal'
import { atMaxWidth, atMinWidth } from '../../styles/helpers'

type Props = {
  user: UserDetail;
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<UserDetail | undefined, unknown>>;
};

export const Header: FC<Props> = ({ user, refetch }) => {
  const { fullName, id: userId } = user
  const { isModalOpen, closeModal, openModal } = useModalState()

  return (
    <HeaderContainer>
      <div>
        <UserNameWrapper>
          <UserAvatar
            src={user?.slackAvatar?.image72}
            alt={fullName}
            onClick={openModal}
          />
          <HeaderTitle>
            {fullName}
          </HeaderTitle>
        </UserNameWrapper>
        <Chips user={user} />
      </div>
      <ButtonContainer>
        <ToggleSync userId={userId} />
        <LinkToProjectReport
          userId={user.id}
          type="button"
        />
        <DotsButtons
          user={user}
          refetch={refetch}
        />
      </ButtonContainer>
      <FullSizeImageModal
        isOpen={isModalOpen}
        imageSrc={user?.slackAvatar?.imageOriginal}
        closeModal={closeModal} />
    </HeaderContainer>
  )
}

const HeaderTitle = styled(Typography)`
  font-family: 'Ginto Nord', serif;
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0.05em;
  -webkit-text-stroke: 1px black;

  ${atMinWidth.desktop} {
    font-size: 58px;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 3rem;
  gap: 2rem;
  margin-bottom: 2rem;

  ${atMinWidth.desktop} {
    flex-direction: row;
    gap: 3rem;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
  ${atMaxWidth.desktop} {
    justify-content: space-between;
  }
`

const UserAvatar = styled(Avatar)`
  width: 58px;
  height: 58px;
  cursor: pointer;
`

const UserNameWrapper = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
  margin-bottom: 5px;
`
