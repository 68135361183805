import { css, FlattenSimpleInterpolation } from 'styled-components'

export const size = {
  breakpoints: {
    mobile: 480,
    tablet: 768,
    desktop: 992,
    xl: 1200,
    '2xl': 1780,
  },
}

export const squareSize = (size: number): FlattenSimpleInterpolation => css`
  width: ${size}px;
  height: ${size}px;
`

export const customMinMediaQuery = (minWidth: number): string =>
  `@media (min-width: ${minWidth}px)`

export const customMaxMediaQuery = (maxWidth: number): string =>
  `@media (max-width: ${maxWidth}px)`

export const atMinWidth = {
  desktopMega: customMinMediaQuery(size.breakpoints['2xl']),
  desktopLarge: customMinMediaQuery(size.breakpoints.xl),
  desktop: customMinMediaQuery(size.breakpoints.desktop),
  tablet: customMinMediaQuery(size.breakpoints.tablet),
  mobile: customMinMediaQuery(size.breakpoints.mobile),
  custom: (width: number): string => customMinMediaQuery(width),
}

export const atMaxWidth = {
  desktopMega: customMaxMediaQuery(size.breakpoints['2xl']),
  desktopLarge: customMaxMediaQuery(size.breakpoints.xl),
  desktop: customMaxMediaQuery(size.breakpoints.desktop),
  tablet: customMaxMediaQuery(size.breakpoints.tablet),
  mobile: customMaxMediaQuery(size.breakpoints.mobile),
  custom: (width: number): string => customMaxMediaQuery(width),
}