import React, { FC, memo, useMemo } from 'react'
import { HeartIcon } from '../../../components/Icon/icons/Heart.icon'
import styled from 'styled-components'
import { IconButton as MuiIconButton } from '@material-ui/core'
import { useCustomMutation } from '../../../hooks/useCustomMutation'
import { useSnackbar } from 'notistack'
import { ShopProductsData } from '../types'
import { useQueryClient } from 'react-query'
import { useShopContext } from '../ShopContext'
import { productsQueryString } from '../utils'

type Props = {
  id: number;
  liked: boolean;
};

export const LikeButton: FC<Props> = memo((props) => {
  const { liked, id } = props
  const { enqueueSnackbar } = useSnackbar()
  const { config } = useShopContext()
  const queryKey = useMemo(
    () => productsQueryString(config.type),
    [config.type]
  )

  const likeMutationPath = `/api/products/${id}/favourite`
  const dislikeMutationPath = `/api/products/${id}/unfavourite`
  const queryClient = useQueryClient()

  const favouriteSwitchOptions = {
    onSuccess: () => {
      queryClient.setQueryData<ShopProductsData | undefined>(
        [queryKey],
        (products) => {
          const productToUpdate = products?.products.find(
            (product) => product.id === id
          )
          if (productToUpdate)
            productToUpdate.isFavourite = !productToUpdate.isFavourite
          return products
        }
      )
    },
    onError: (error) => {
      //FIXME: error handling
      enqueueSnackbar('API error setting favourite product!', {
        variant: 'error',
      })
    },
  }

  const createFavouriteMutation = () => {
    const path = liked ? dislikeMutationPath : likeMutationPath
    return {
      path: path,
    }
  }
  const mutation = useCustomMutation(
    createFavouriteMutation,
    favouriteSwitchOptions
  )

  const handleClick = () => {
    mutation.mutate({})
  }

  return useMemo(
    () => (
      <IconButton onClick={handleClick}>
        <HeartIcon filled={liked} />
      </IconButton>
    ),
    [liked]
  )
})

const IconButton = styled(MuiIconButton)`
  display: block;
`
