import React, { FC, useMemo } from 'react'
import { useQueryNotification } from '../../UserInfo/utils'
import { UsersState } from '../../../types/index'
import { handleUserActivation } from '../../../utils/apiHelpers'
import { DotsMenu, DotsMenuItem } from '../../../components/DotsMenu'
import { UserStateAction, UserDetail } from '../types'
import { RefetchOptions, QueryObserverResult } from 'react-query'
import { useCurrentUserInfo } from '../../../components/CurrentUserProvider'
import { useUserPermissions } from '../../../components/CurrentUserProvider'

type Props = {
  user: UserDetail;
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<UserDetail | undefined, unknown>>;
};

export const DotsButtons: FC<Props> = ({ user, refetch }) => {
  const { resolvePermissions } = useUserPermissions()
  const { currentUser } = useCurrentUserInfo()
  const { successNotification, errorNotification } = useQueryNotification()

  const canManageUsers = useMemo(
    () => resolvePermissions('user', 'manage'),
    []
  )

  const isUserCurrentUser = user?.id === currentUser.id
  const isAllowed = isUserCurrentUser || canManageUsers

  const userStateAction =
    user.state === UsersState.active
      ? UserStateAction.deactivate
      : UserStateAction.activate

  const handleUserState = async () => {
    try {
      await handleUserActivation(user.id, user.state === UsersState.active)
      successNotification(`User was successfully ${userStateAction}d`)
      refetch()
    } catch (error) {
      errorNotification('Sorry something happened')
    }
  }

  const dotMenuItems: DotsMenuItem[] = useMemo(() => {
    const arr: DotsMenuItem[] = []
    isAllowed &&
      arr.push({
        label: 'Fridge app QR code',
        onClick: () => {
          window.location.href = `/admin/mobiles?user_id=${user.id}`
        },
      })
    canManageUsers &&
      arr.push({
        label: user.state === UsersState.active ? 'Deactivate' : 'Activate',
        onClick: handleUserState,
      })
    return arr
  }, [user.state])

  const isDotsMenuAvailable = dotMenuItems.length > 0

  if (!isDotsMenuAvailable) return null

  return (
    <DotsMenu
      items={dotMenuItems}
      heavy
    />
  )
}
