import React, { FC, useEffect } from 'react'
import { PageLayoutWrapper } from '../../components/ui/PageLayoutWrapper'
import { Typography } from '@material-ui/core'
import { PaymentsTable } from './PaymentsTable'
import { PaymentsStatus, Payment, Filter } from './types'
import { BubbleSwitcher } from '../../components/BubbleSwitcher'
import { switcherData } from './utils'
import { PaymentFilters } from './PaymentFilters'
import {
  useSearchFieldState,
  SearchFieldRef,
} from '../../components/inputs/SearchField'
import { PaginationParams } from '../../hooks/usePaginatedQuery'
import { StyledPaper, PaymentSearchField, PaymentListContainer, StyledPagination } from './styles'

type Props = {
  payments: Payment[] | undefined;
  filters: Filter;
  setFilters: (filter: Filter) => void;
  isLoading: boolean;
  selectedPaymentsStatus: PaymentsStatus;
  setSelectedPaymentsStatus: (paymentStatus: PaymentsStatus) => void;
  loadPage: (page: number) => void;
  pagination: PaginationParams;
  resetSearchString: () => void;
  searchText: SearchFieldRef;
};

export const PaymentsPageView: FC<Props> = ({
  payments,
  filters,
  setFilters,
  isLoading,
  selectedPaymentsStatus,
  setSelectedPaymentsStatus,
  loadPage,
  pagination,
  resetSearchString,
  searchText,
}) => {
  const { searchString, handleFilterChange } = useSearchFieldState()

  useEffect(() => {
    setFilters({ ...filters, searchString })
  }, [searchString])

  const handlePageChange = (e: React.ChangeEvent<unknown>, value: number) => {
    loadPage(value)
  }

  const isPayments = payments && payments.length > 0

  return (
    <PageLayoutWrapper>
      <StyledPaper elevation={0}>
        <Typography variant="h3">Payments</Typography>
        <PaymentSearchField
          fullWidth
          name="searchString"
          onChange={handleFilterChange}
          placeholder="Search sender name, variable symbol or note."
          inputRef={searchText}
          key={filters.searchString}
          defaultValue={filters.searchString}
        />

        <BubbleSwitcher
          options={switcherData}
          active={selectedPaymentsStatus}
          setActive={setSelectedPaymentsStatus}
          isLoading={isLoading}
        />

        <PaymentListContainer>
          <PaymentFilters
            filters={filters}
            setFilters={setFilters}
            resetSearchString={resetSearchString}
          />
          <div>
            <Typography variant={'overline'}>Payments</Typography>
            <PaymentsTable
              filteredPayments={payments}
              isLoading={isLoading}
            />
          </div>
        </PaymentListContainer>

        {isPayments && (
          <StyledPagination
            count={pagination.count}
            page={pagination.page}
            onChange={handlePageChange}
          />
        )}
      </StyledPaper>
    </PageLayoutWrapper>
  )
}
