/* eslint-disable max-lines */
import React, { FC } from 'react'
import { FormLabel } from 'react-bootstrap'
import { FormikProps } from 'formik'
import { Alert } from '@material-ui/lab'
import { parseISO, format, isValid } from 'date-fns'
import 'react-modern-calendar-datepicker/lib/DatePicker.css'

import { Button } from '../../../../components/buttons/Button'
import { hasError, helperText } from '../../../../utils/helpers'
import { FormDatePicker } from '../../../../components/inputs/FormDatePicker'
import { RateBaseView } from './RateBaseView'
import { CorpSelect } from '../../../../components/inputs/CorpSelect'
import { COMPETENCIES, Competency, Rate, RateError, RateFormValues } from '../types'
import {
  CheckboxWrapper,
  LabelTextForCheckbox,
  StyledCheckbox,
} from '../../../ProjectsPage/components/AddNewProject/styles'
import { RatesTable } from '../RatesTable'
import { Project } from '../../../ProjectsPage/types'
import { StyledFormGrid } from '../styles'
import { User } from '../../../UsersPage/types'

type Props = {
  formikProps: FormikProps<RateFormValues>
  activeRate?: Rate
  competencies: Competency[]
  projects: Project[]
  error?: RateError
  isProjectTabActive?: boolean;
  user: User
}

export const CreateRateFormView: FC<Props> = ({
  formikProps,
  activeRate,
  competencies,
  projects,
  error,
  isProjectTabActive,
  user
}) => {
  const { isSubmitting, handleSubmit, setFieldValue, values, handleChange } =
    formikProps
  const formattedProjects = projects.map((project) => ({
    value: project.id,
    label: project.name,
  }))
  const containsProjectId = activeRate && 'projectId' in activeRate
  const showProjectInput = !!(
    (containsProjectId && activeRate?.projectId) ||
    (containsProjectId &&
      activeRate?.projectId === '' &&
      projects?.length)
  )

  return (
    <>
      <StyledFormGrid onSubmit={handleSubmit}>
        {error?.overlapping_components?.length && (
          <Alert severity="warning">
            Because of the &quot;valid from&quot; date you selected, some of the changes
            conflict with historical rates. If you continue, conflicting rates
            below will be deleted and replaced by your change. If you&apos;re not
            sure what to do, contact Ted Valenta.
            <br />
            This will be changed:
            <RatesTable
              rates={error.overlapping_components}
              hideActions
              competencies={competencies}
            />
            <CheckboxWrapper>
              <LabelTextForCheckbox
                control={
                  <StyledCheckbox
                    name="confirmed_conflicts"
                    color="primary"
                    onChange={handleChange}
                  />
                }
                label="I accept this."
              />
            </CheckboxWrapper>
          </Alert>
        )}
        <FormLabel>
          <FormDatePicker
            fullWidth
            required
            label='Validity from (inclusive)'
            inputVariant="outlined"
            name='valid_from'
            onChange={(date) => isValid(date) && setFieldValue('valid_from', format(date, 'yyyy-MM-dd'))}
            value={parseISO(format(new Date(values.valid_from), 'yyyy-MM-dd'))}
            error={hasError(formikProps, 'valid_from')}
            helperText={helperText(formikProps, 'valid_from')}
          />
        </FormLabel>
        {showProjectInput && (
          <CorpSelect
            values={formattedProjects}
            value={values.project_id}
            name="project_id"
            onChange={handleChange}
            label={'Project name'}
            helperText={helperText(formikProps, 'project_id')}
          />
        )}

        <RateBaseView
          rateTitle={COMPETENCIES.PRIMARY_COMPETENCY}
          formikProps={formikProps}
          competencies={competencies}
          activeRate={activeRate}
          user={user}
        />
        <RateBaseView
          rateTitle={COMPETENCIES.TEAM_LEADERSHIP}
          formikProps={formikProps}
          competencies={competencies}
          activeRate={activeRate}
          user={user}
        />
        {!isProjectTabActive && (
          <>
            <RateBaseView
              rateTitle={COMPETENCIES.SECOND_COMPETENCY}
              formikProps={formikProps}
              competencies={competencies}
              activeRate={activeRate}
              user={user}
            />
            <RateBaseView
              rateTitle={COMPETENCIES.ENGINEERING_BONUS}
              formikProps={formikProps}
              competencies={competencies}
              activeRate={activeRate}
              user={user}
            />

            <RateBaseView
              rateTitle={COMPETENCIES.TECH_LEADERSHIP}
              formikProps={formikProps}
              competencies={competencies}
              activeRate={activeRate}
              user={user}
            />
          </>
        )}
        <Button
          kind="primary"
          id="save-rates"
          type="submit"
          disabled={isSubmitting}
        >
          Save
        </Button>
      </StyledFormGrid>
    </>
  )
}
