import styled from 'styled-components'
import { theme } from '../../styles/muiThemes'

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  margin-bottom: 50px;

  ${theme.breakpoints.down('lg')} {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
`

export const ProjectDetailGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  align-items: baseline;

  ${theme.breakpoints.down('lg')} {
    grid-template-columns: 1fr;
    row-gap: 40px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`
