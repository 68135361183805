import React, { FC, memo } from 'react'
import {
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import { Project } from '../../bundles/ProjectsPage/types'
import styled, { css } from 'styled-components'
import { ExportToCSVModal } from '../../bundles/ExportToCSVModal/ExportToCSVModal'
import { SyncProjectButton } from './SyncProjectButton'
import { LinkToProjectReport } from './LinkToProjectReport'
import { useCurrentUserInfo } from '../CurrentUserProvider'
import {
  ProjectTableConfig,
  ProjectTableConfigItem,
  ProjectTableItem,
} from './types'
import { AssignUserToProject } from '../../bundles/AssignUserToProjectModal/AssignUserToProject'
import { Icon } from '../Icon'
import { InfoIcon } from '../Icon/icons/Info.icon'
import { getLinkToProjectDetail } from './utils'
import { atMaxWidth } from '../../styles/helpers'

type Props = {
  project: Project;
  tableConfig: ProjectTableConfig;
  groupedProject?: boolean;
};

export const ProjectRow: FC<Props> = memo((props) => {
  const { project, groupedProject, tableConfig } = props

  const { userPermissions, currentUser } = useCurrentUserInfo()
  const userCanManage =
    userPermissions?.project?.manage || userPermissions?.all?.manage

  const CellByType: FC<{ item: ProjectTableConfigItem }> = ({ item }) => {
    const cellOptions = {
      [ProjectTableItem.Tribe]: <TableCell>{project.tribe?.name}</TableCell>,

      [ProjectTableItem.Info]: (
        <TableCell>
          <Tooltip
            title={`Your Toggl project: ${project.togglProjectName}. Bills to: ${project.tribe?.name}.`}
          >
            <IconButton
              color="primary"
              size="small"
            >
              <Icon Component={InfoIcon} />
            </IconButton>
          </Tooltip>
        </TableCell>
      ),

      [ProjectTableItem.Join]: (
        <TableCell align="right">
          <AssignUserToProject
            projectId={project.id}
            userId={currentUser.id}
            projectName={project.name}
            buttonProps={{ style: { padding: '8px' } }}
          />
        </TableCell>
      ),

      [ProjectTableItem.TLActions]: (
        <TableCell align="right">
          {userCanManage && (
            <StyledGrid
              justify="flex-end"
              container
            >
              <SyncProjectButton
                projectId={project.id}
                projectName={project.name}
              />

              <LinkToProjectReport projectId={project.id} />

              <ExportToCSVModal projectId={+project.id} />
            </StyledGrid>
          )}
        </TableCell>
      ),
    }

    return cellOptions[item.type]
  }

  return (
    <TableRow key={project.id}>
      <NameCell
        $groupedProject={groupedProject}
      >
        <a href={getLinkToProjectDetail(project.id)}>{project.name}</a>
      </NameCell>

      {tableConfig.map((item) => (
        <CellByType
          item={item}
          key={item.type}
        />
      ))}
    </TableRow>
  )
})

export const NameCell = styled(TableCell) <{ $groupedProject?: boolean }>`
  max-width: 150px;
  ${(props) =>
    props.$groupedProject &&
    css`
      padding-left: 32px !important;
    `};

  &:hover {
    text-decoration: underline;
  }
`

const StyledGrid = styled(Grid)`
  ${atMaxWidth.desktop} {
    flex-wrap: nowrap;
  }
`
