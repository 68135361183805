import React, { FC } from 'react'
import { Skeleton } from '@material-ui/lab'
import { TableCell, TableRow } from '@material-ui/core'
import _ from 'lodash'

type Props = {
  rows: number;
  colspan: number;
};

export const TableBodySkeleton: FC<Props> = ({ rows, colspan }) => {
  const skeletonRows = _.range(rows)

  return (
    <>
      {skeletonRows.map((item) => (
        <TableRow key={item}>
          <TableCell colSpan={colspan}>
            <Skeleton
              animation="wave"
              style={{ height: '40px' }}
            />
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}
