import React, { FC, memo, useState } from 'react'
import { Grid, IconButton, TableCell, TableRow } from '@material-ui/core'
import { Project } from '../../bundles/ProjectsPage/types'
import { ProjectRow } from './ProjectRow'
import styled from 'styled-components'
import { colors } from '../../styles/colors'
import { ProjectsGroupType } from './utils'
import { ChevronIcon } from '../Icon/icons/Chevron.icon'
import { Icon } from '../Icon'
import { ProjectTableConfig } from './types'
import { RatesTable } from '../../bundles/UserDetailPage/rates/RatesTable'
import { Rate } from '../../bundles/UserDetailPage/rates/types'
import { getRatesSum } from '../../bundles/UserDetailPage/rates/utils'
import { BoldText } from '../../bundles/UserDetailPage/rates/styles'

type Props = {
  group: ProjectsGroupType;
  expandGroup: boolean;
  tableConfig: ProjectTableConfig;
  withRates?: boolean;
  showProjectCount?: boolean;
  openModal?: (rate?: Rate) => void;
  openEndValidityModal?: (rate?: Rate) => void;
  openDeleteRateModal?: (rate?: Rate) => void;
  billingCurrency?: string;
};

export const ProjectsGroup: FC<Props> = memo((props) => {
  const { group, expandGroup, tableConfig, withRates = false, showProjectCount = true, openModal, openEndValidityModal, openDeleteRateModal, billingCurrency } = props
  const [isExpanded, setExpanded] = useState(false)

  return (
    <>
      <Row onClick={() => setExpanded((prevState) => !prevState)}>
        <TableCell>
          <Grid
            container
            alignItems="center"
          >
            {group.name} {showProjectCount && <ProjectCount>{group.projectsCount}</ProjectCount>}
          </Grid>
        </TableCell>

        {tableConfig.length > 1 && <TableCell align="right" />}


        <TableCell>
          <Grid
            justify="flex-end"
            alignItems="center"
            container
          >
            {withRates && !isExpanded && <BoldText>{getRatesSum(group.projects as Rate[])} {billingCurrency}</BoldText>}
            <IconButton
              color="primary"
              size="small"
            >
              <StyledIcon
                Component={ChevronIcon}
                rotate={expandGroup || isExpanded}
              />
            </IconButton>
          </Grid>
        </TableCell>
      </Row>

      {withRates && group.projects.length && isExpanded ?
        <TableRow>
          <RatesTable
            rates={group.projects as Rate[]}
            showSum
            showDisabled
            openModal={openModal}
            openEndValidityModal={openEndValidityModal}
            openDeleteRateModal={openDeleteRateModal}
            billingCurrency={billingCurrency}
          />
        </TableRow>
        : (expandGroup || isExpanded) &&
        group.projects.map((project) => (
          <ProjectRow
            tableConfig={tableConfig}
            key={project.id}
            project={project as Project}
            groupedProject
          />
        ))}
    </>
  )
})

const ProjectCount = styled.span`
  width: 26px;
  height: 26px;
  padding-bottom: 1px;
  margin-left: 16px;
  border-radius: 13px;
  color: white;
  background-color: ${colors.brandBlue};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Row = styled(TableRow)`
  cursor: pointer;
`

export const StyledIcon = styled(Icon) <{ rotate: boolean }>`
  display: flex;
  justify-content: flex-end;
  transition: transform 0.3s;
  transform: rotate(${(props) => (props.rotate ? 0 : 180)}deg);
`
