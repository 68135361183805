import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const Reports: FC<IconSVGProps> = ({ width = 20, height = 42 }) => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.333 19.667A1.333 1.333 0 0117 21H2.333A1.334 1.334 0 011 19.667V2.333A1.333 1.333 0 012.333 1h9.448c.354 0 .693.14.943.39l5.22 5.22c.249.25.39.589.39.942v12.115z"
      stroke="#C8C6C5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.334 7.667H13a1.333 1.333 0 01-1.333-1.334V1M7.595 8.874L5.7 11.4a.667.667 0 01-1.005.07l-1.028-1.027M7.595 14.873L5.7 17.4a.667.667 0 01-1.005.07l-1.028-1.028M10.333 11.666h4M10.333 17h4"
      stroke="#C8C6C5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
