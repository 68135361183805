import { useCallback } from 'react'
import { useMutation } from 'react-query'
import { UseMutationOptions } from 'react-query/types/react/types'
import { fetchHeaders } from '../utils/apiHelpers'
import { useQueryNotification } from '../bundles/UserInfo/utils'

type ReturnTypes = ReturnType<typeof useMutation>;

export type Mutation<TVariables> = (variables: TVariables) => {
  method?: 'POST' | 'PUT' | 'DELETE';
  path: string;
  params?;
};

// TODO: investigate if this is does anything useful, try to simplify data fetching
// (or at least make this better to use)
export function useCustomMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  mutation: Mutation<TVariables>,
  options: UseMutationOptions<TData, TError, TVariables, TContext>,
  returnErrorBody = false
): ReturnTypes {
  const { errorNotification } = useQueryNotification()
  const mutationFn = useCallback(
    async (variables = {}) => {
      const { method, path, params } = mutation(variables)
      const fetchOptions = {
        method: method || 'POST',
        headers: fetchHeaders,
        body: params ? JSON.stringify(params) : undefined,
      }
      const response = await fetch(path, fetchOptions)
      if (response.ok) {
        return response
      } else if (response.status === 500) {
        errorNotification(
          'Backend is having issues :-(. We\'ll let you know as soon as it\'s fixed.'
        )
        return Promise.reject()
      } else {
        const parsedResponse = await response.json()

        // FIXME standardize error responses
        if (returnErrorBody) {
          return Promise.reject({ ...parsedResponse, status: response.status })
        } else {
          return Promise.reject({
            message: parsedResponse.message,
            status: response.status,
          })
        }
      }
    },
    [mutation]
  )

  return useMutation<
    TData,
    TError,
    TVariables,
    TContext
    //FIXME: find a way to remove this gitignore
    // @ts-ignore
  >(mutationFn, options)
}
