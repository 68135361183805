import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const PlaneIcon: FC<IconSVGProps> = ({
  width = 15,
  height = 14,
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 0.999329L0.260432 6.67499C0.179172 6.70929 0.110557 6.76796 0.0640511 6.8429C0.0175449 6.91785 -0.00456075 7.00537 0.000783082 7.09341C0.00612692 7.18145 0.038657 7.26567 0.0938863 7.33444C0.149116 7.40321 0.224323 7.45314 0.309134 7.47736L5.47707 8.91348L14 0.999329Z"
      stroke="#C8C6C5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M14 0.999329L11.6562 12.1315C11.6375 12.2187 11.5977 12.2999 11.5405 12.3683C11.4832 12.4366 11.4102 12.49 11.3276 12.5237C11.2451 12.5574 11.1556 12.5705 11.0669 12.5617C10.9781 12.553 10.8929 12.5228 10.8185 12.4736L5.47705 8.91348L14 0.999329Z"
      stroke="#C8C6C5"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M5.47705 8.9129V12.8578C5.47719 12.9271 5.50014 12.9945 5.54236 13.0495C5.58458 13.1046 5.64372 13.1441 5.71067 13.1622C5.77763 13.1803 5.84866 13.1758 5.91283 13.1495C5.977 13.1232 6.03073 13.0766 6.06574 13.0167L7.62483 10.3441"
      stroke="#C8C6C5"
      strokeLinecap="round"
      strokeLinejoin="round" />
  </svg>



)
