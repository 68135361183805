import React, { FC } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { Icon } from './Icon'
import { CSVIcon } from './Icon/icons/CSV.icon'
import { Button } from './buttons/Button'
import { PlusIcon } from './Icon/icons/Plus.icon'
import styled from 'styled-components'

export type ModalTriggerButtonType = 'normal' | 'icon' | 'iconText';

type Props = {
  buttonType?: ModalTriggerButtonType;
  buttonName?: string;
  onClick;
};

export const ModalTriggerButton: FC<Props> = ({
  buttonType = 'icon',
  onClick,
  buttonName,
}) => {
  const buttonOptions = {
    ['icon']: (
      <Tooltip title="Export report to CSV">
        <IconButton
          color="primary"
          size="small"
          onClick={onClick}
        >
          <Icon Component={CSVIcon} />
        </IconButton>
      </Tooltip>
    ),
    ['iconText']: (
      <Tooltip title="Export report to CSV">
        <Button
          kind="basicSecondary"
          onClick={onClick}
        >
          <CSVIcon />
          <ButtonText>{buttonName ? buttonName : 'Export'}</ButtonText>
        </Button>
      </Tooltip>
    ),
    ['normal']: (
      <Button
        kind="primary"
        onClick={onClick}
      >
        {buttonName ? buttonName : 'export'}
      </Button>
    ),
  }

  return buttonOptions[buttonType]
}


const ButtonText = styled.span`
  margin-left: 10.5px;
`
