import React, { FC } from 'react'
import styled from 'styled-components'
import {
  SearchField,
  SearchFieldRef,
} from '../../../components/inputs/SearchField'
import { Typography } from '@material-ui/core'
import { SearchInputType } from '../../../enum/index'
import { HandleFilterInputChange } from '../../../bundles/ShopAndDrinksPage/types'

type Props = {
  amountFromField: SearchFieldRef;
  amountToField: SearchFieldRef;
  handleAmountFromChange: HandleFilterInputChange;
  handleAmountToChange: HandleFilterInputChange;
};

export const AmountFilter: FC<Props> = ({
  amountFromField,
  amountToField,
  handleAmountFromChange,
  handleAmountToChange,
}) => {
  return (
    <>
      <StyledSearchContainer>
        <Typography variant={'overline'}>Amount from</Typography>
        <SearchField
          type={SearchInputType.number}
          name="amountFrom"
          inputProps={{ min: 0 }}
          onChange={handleAmountFromChange}
          inputRef={amountFromField}
        />
      </StyledSearchContainer>
      <StyledSearchContainer>
        <Typography variant={'overline'}>Amount to</Typography>
        <SearchField
          type={SearchInputType.number}
          name="amountTo"
          inputProps={{ min: 0 }}
          onChange={handleAmountToChange}
          inputRef={amountToField}
        />
      </StyledSearchContainer>
    </>
  )
}

const StyledSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`
