import { Slider, withStyles } from '@material-ui/core'

export const SpiritSliderStyles = withStyles({
  root: {
    color: '#000!important',
    height: 5,
    padding: '25px 0',
    marginBottom: 0,
  },
  thumb: {
    height: '21px!important',
    width: '2px!important',
    backgroundColor: '#000',
    marginTop: '-10px!important',
    marginLeft: '0!important',
    borderRadius: '0!important',
  },
  active: {},
  valueLabel: {
    left: -15,
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 5,
    marginTop: '-2px',
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#000!important',
  },
  mark: {
    backgroundColor: '#000!important',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markLabel: {
    color: '#000',
    transform: 'translate(-100%)',
    paddingTop: 10,
    '&[data-index="0"]': {
      transform: 'none',
    },
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider)
