import React from 'react'
import { FC } from 'react'
import { Project } from '../../bundles/ProjectsPage/types'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'
import { ProjectRow } from './ProjectRow'
import { ProjectsGroup } from './ProjectsGroup'
import { SortedProject, ProjectsGroupType } from './utils'
import { ProjectTableConfig } from './types'
import { ProjectsTableHead } from './ProjectsTableHead'
import { TableBodySkeleton } from '../skeletons/TableBodySkeleton'
import { atMaxWidth } from '../../styles/helpers'
import styled from 'styled-components'

type Props = {
  projects?: SortedProject[];
  searchString?: string;
  isLoading: boolean;
  tableConfig: ProjectTableConfig;
};

export const ProjectsTable: FC<Props> = (props) => {
  const { projects, searchString, isLoading, tableConfig } = props

  return (
    <TableContainer>
      <StyledTable>
        <ProjectsTableHead tableConfig={tableConfig} />

        <TableBody>
          {isLoading && (
            <TableBodySkeleton
              rows={30}
              colspan={3}
            />
          )}

          {projects?.map((project, key) =>
            project.group ? (
              <ProjectsGroup
                key={project.name + key}
                group={project as ProjectsGroupType}
                expandGroup={!!searchString}
                tableConfig={tableConfig}
              />
            ) : (
              <ProjectRow
                key={project.name + key}
                project={project as Project}
                tableConfig={tableConfig}
              />
            )
          )}

          {!projects?.length && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={4}
              >
                no projects
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </StyledTable>
    </TableContainer>
  )
}

const StyledTable = styled(Table)`
  .MuiTableCell-root {
    ${atMaxWidth.desktop} {
      padding: 5px;
    }
  }
`