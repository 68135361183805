import { Box, Typography } from '@material-ui/core'
import React, { FC, useState } from 'react'
import styled from 'styled-components'

import { Button } from '../../../components/buttons/Button'
import { useCurrentUserInfo } from '../../../components/CurrentUserProvider'
import { colors } from '../../../styles/colors'
import { atMinWidth } from '../../../styles/helpers'
import { getImageByKey } from '../../../utils/imageGetter'
import { QRCodeDialog } from './QRCodeDialog/QRCodeDialog'

export const HighBalanceWarning: FC = () => {
  const { currentUser } = useCurrentUserInfo()
  const [dialogOpened, setDialogOpened] = useState(false)
  const userBalance = `${currentUser.balance},-`

  const handleClick = () => setDialogOpened(true)

  return (
    <>
      <WarningWrapper>
        <PriceWrapper display="flex">
          <PriceInfoBox>
            <InfoText>Your balance is</InfoText>
            <Price>{userBalance}</Price>
          </PriceInfoBox>
          <Button
            kind="primary"
            onClick={handleClick}
          >
            Generate QR
          </Button>
        </PriceWrapper>
        <GangstaWrapper>
          <Box alignSelf="center">
            <WarningText>We suggest you pay it. Like now.</WarningText>
            <WarningText>Otherwise this guy will visit you.</WarningText>
          </Box>
          <img src={getImageByKey('dominik')} />
        </GangstaWrapper>
      </WarningWrapper>
      <QRCodeDialog
        setDialogOpened={setDialogOpened}
        dialogOpened={dialogOpened}
      />
    </>
  )
}

const WarningWrapper = styled.div`
  position: fixed;
  z-index: 2;
  background: ${colors.warning};
  display: flex;
  right: 0;
  left: 0;
  margin-left: 0px;
  justify-content: space-between;
  align-items: center;
  top: 64px;
  padding: 10px;

  ${atMinWidth.tablet} {
    top: 0;
    margin-left: 220px;
  }

  ${atMinWidth.desktopLarge} {
    padding: 0px;
  }
`

const PriceInfoBox = styled.div`
  ${atMinWidth.tablet} {
    padding: 0 40px 0 84px;
    text-align: right;
  }
`

const InfoText = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  line-height: 12px;
  margin-bottom: 4px;
`

const Price = styled.div`
  font-family: 'Ginto Nord', serif;
  font-size: 26px;
  line-height: 31px;

  ${atMinWidth.desktop} {
    font-size: 31px;
  }
`

const WarningText = styled(Typography)`
  width: 280px;
  font-size: 15px;
`

const GangstaWrapper = styled(Box)`
  display: none;
  ${atMinWidth.desktopLarge} {
    display: flex;
  }
`

const PriceWrapper = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  ${atMinWidth.desktopLarge} {
    width: auto;
  }
`
