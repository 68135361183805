import React, { FC } from 'react'
import { Skeleton } from '@material-ui/lab'
import { Variant } from '@material-ui/core/styles/createTypography'

type Props = {
  variant?: Variant;
};
export const TypographySkeleton: FC<Props> = ({ variant }) => {
  return (
    <Skeleton
      width={300}
      height={32}
    />
  )
}
