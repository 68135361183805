import axios from 'axios'
import ReactOnRails from 'react-on-rails'
import { saveAs } from 'file-saver'
import { csvRequestParams } from '../bundles/TogglReportPage/types'

const baseUrl =
  window.location.protocol + '//' + window.location.host + '/admin'

export const csrfToken = () => ReactOnRails.authenticityToken()

const api = axios.create({
  baseURL: baseUrl,
})

api.interceptors.request.use((config) => {
  config.headers.common['X-CSRF-Token'] = csrfToken()
  config.headers.common['Accept'] = 'application/json'
  config.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  return config
})

type ResponseHandlerProps<T> = {
  isLoading: boolean;
  data?: T;
  error?: any;
};

export type ResponseHandler<T> = {
  ({ isLoading, data, error }: ResponseHandlerProps<T>): void;
};

export const handleResponse = <T>(handler: ResponseHandler<T>) => {
  return {
    success: (data) => handler({ isLoading: false, data: data }),
    error: (error) => handler({ isLoading: false, error: error }),
  }
}

export function getReportData<T>(params: {}, handler: ResponseHandler<T>) {
  api
    .get('/reports', { params: params })
    .then(({ data }) => {
      handleResponse<T>(handler).success(data)
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('getReportData API error:', error)
      handleResponse<T>(handler).error(error)
    })
}

export function getCsvExport(params?: csvRequestParams) {
  api
    .get('/reports/export', { params: params, responseType: 'blob' })
    .then((response) => {
      const headerName = 'x-suggested-filename'
      const fileName = response.headers[headerName]
      saveAs(response.data, fileName)
    })
    // eslint-disable-next-line no-console
    .catch((error) => console.error(error))
}

export function getExportFromCsvModal(params?: csvRequestParams, url?: string) {
  api
    .get(url || '/reports/export', { params: params, responseType: 'blob' })
    .then((response) => {
      const headerName = 'content-disposition'
      const regExpFilename = /filename="(?<filename>.*)"/

      const filename: string | null =
        regExpFilename.exec(response.headers[headerName])?.groups?.filename ??
        null
      saveAs(response.data, filename)
    })
    // eslint-disable-next-line no-console
    .catch((error) => console.error(error))
}

export async function recalculateBalance(url?: string) {
  return await api.post(url || '/users/recalculate_balance')
}
