import { FormControlLabel, Box, TextField, Typography, Checkbox, ButtonGroup } from '@material-ui/core'
import styled from 'styled-components'

import { Button } from '../../../../components/buttons/Button'
import { FormSelect } from '../../../../components/inputs/FormSelect'
import { colors } from '../../../../styles/colors'
import { atMinWidth } from '../../../../styles/helpers'

export const StyledTextField = styled(TextField)`
  margin-bottom: 20px;
`

export const StyledFormSelect = styled(FormSelect)`
  margin: 16px 0;
`

export const FormPartTitle = styled(Box)`
  font-family: 'Ginto Normal Regular', serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  margin: 40px 0 20px;
`

export const LabelText = styled(Box)`
  color: ${colors.grey0};
  font-family: 'Ginto Normal Regular', serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 23px;
  text-transform: uppercase;
`

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin: 0;
  display: flex;
  justify-content: space-between;
`

export const ButtonWrapper = styled.div`
  display: grid;
  gap: 30px;
  margin-top: 40px;

  ${atMinWidth.mobile} {
    display: flex;
    direction: rtl;
    justify-content: space-between;
  }
`

export const StyledTitle = styled(Typography)`
  color: ${colors.brand};
  font-family: 'Ginto Normal Regular', serif;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 34px;
`

export const CheckboxWrapper = styled.div`
  align-items: center;
  display: flex;
`

export const LabelTextForCheckbox = styled(FormControlLabel)`
  color: #151B36;
  font-size: 16px;
  text-transform: none;
`

export const StyledCheckbox = styled(Checkbox)`
  color: ${colors.black};
  margin-right: 12px;
  padding: 0;
`

export const StyledButtonGroup = styled(ButtonGroup)`
  gap: 24px;
  margin-top: 33px;
`

export const StyledButton = styled(Button)`
  border-right-color: inherit !important;
`
