import React, { FC } from 'react'
import { UpdatableItemsGroup } from '../../../../components/ui/UpdatableFormField/UpdatableItemsGroup'
import { Enums, Project } from '../../types'
import { DataLine } from '../../../../types'
import { TextFieldData } from '../../../../types/UpdatableFormFieldData/TextFieldData'
import { SelectFieldData } from '../../../../types/UpdatableFormFieldData/SelectFieldData'
import { SwitchFieldData } from '../../../../types/UpdatableFormFieldData/SwitchFieldData'
import { DateFieldData } from '../../../../types/UpdatableFormFieldData/DateFieldData'

type Props = {
  project: Project;
  enums: Enums;
};

export const ReportingDataTab: FC<Props> = (props) => {
  const { project, enums } = props
  const items: DataLine[] = [
    {
      label: 'Invoiced by tribe',
      updatableFields: [
        new SelectFieldData(
          project.invoicedByTribeId,
          'invoiced_by_tribe_id',
          enums.tribes
        ),
      ],
    },
    {
      label: 'Category',
      updatableFields: [
        new SelectFieldData(project.category, 'category', enums.categories),
      ],
    },
    {
      label: 'Is production',
      updatableFields: [
        new SwitchFieldData(project.isProduction, 'is_production'),
      ],
    },
    {
      label: 'Premier contract ID',
      updatableFields: [
        new TextFieldData(
          project.premierContractId,
          'premier_contract_id',
          'number'
        ),
      ],
    },
    {
      label: 'Billing type',
      updatableFields: [
        new SelectFieldData(
          project.billingType,
          'billing_type',
          enums.billingTypes
        ),
      ],
    },
    {
      label: 'First billable date',
      updatableFields: [
        new DateFieldData(project.firstBillableDate, 'first_billable_date'),
      ],
    },
  ]
  return (
    <UpdatableItemsGroup
      items={items}
      updatePaths={[`/api/projects/${project.id}`]}
    />
  )
}
