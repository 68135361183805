import React, { FC } from 'react'
import { Box } from '@material-ui/core'
import QRCode from 'react-qr-code'
import styled from 'styled-components'
import Alert from '@material-ui/lab/Alert'

import { PaymentQRCodeData } from './types'

type Props = {
  data?: PaymentQRCodeData;
  isAmountEnabled: boolean;
  size?: number;
};

export const QRCodeBox: FC<Props> = ({ data, isAmountEnabled, size = 150 }) => {
  const noAmountInfo =
    !isAmountEnabled && 'Amount should be at least 1 CZK for generate QR code'

  if (data?.qr_data) {
    return (
      <QRCode
        size={size}
        value={data.qr_data}
      />
    )
  }

  return (
    <StyledBox size={size}>
      <Alert
        variant={'outlined'}
        severity={'info'}
      >
        {noAmountInfo}
      </Alert>
    </StyledBox>
  )
}

const StyledBox = styled(Box)<{ size: number }>`
  display: flex;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};

  > * {
    display: block;
    text-align: left;
  }
`
