import React, { FC, useMemo } from 'react'
import { Typography } from '@material-ui/core'
import { ProjectsTable } from '../../../components/ProjectsTable/ProjectsTable'
import {
  ProjectTableConfig,
  ProjectTableItem,
} from '../../../components/ProjectsTable/types'
import {
  getMemoizedSortedProjects,
  useProjectFiltering,
} from '../../../components/ProjectsTable/utils'
import { useQuery } from 'react-query'
import { BaseProject } from '../../ProjectsPage/types'
import { SearchField } from '../../../components/inputs/SearchField'
import { useTribes } from '../../../hooks/useTribes'
import { selectableTribes } from '../../../components/ProjectsTable/utils'
import { CorpSelect } from '../../../components/inputs/CorpSelect'
import { StyledContainer } from '../../ProjectsPage/components/ProjectFilter'

type Props = { userId: number };

export const ProjectsICanJoin: FC<Props> = (props) => {
  const { userId } = props

  const tableConfig: ProjectTableConfig = [
    { type: ProjectTableItem.Join, align: 'right' },
  ]

  const { data, isLoading, error } = useQuery<BaseProject[]>(
    `/api/users/${userId}/projects/assignable`
  )

  const sortedProjects = getMemoizedSortedProjects(data)

  const {
    searchString,
    filteredProjects,
    handleFilterChange,
    owningTribe,
    handleTribeChange,
  } = useProjectFiltering(sortedProjects)

  const { tribes } = useTribes()

  const tribesSelectable = useMemo(() => selectableTribes(tribes), [tribes])

  return (
    <>
      <Typography variant="h3">Projects I can join</Typography>

      <StyledContainer>
        <SearchField
          onChange={handleFilterChange}
          fullWidth
        />
        {tribesSelectable && (
          <CorpSelect
            values={tribesSelectable}
            value={owningTribe}
            onChange={handleTribeChange}
            label={'Tribe'}
          />
        )}
      </StyledContainer>

      <ProjectsTable
        tableConfig={tableConfig}
        projects={filteredProjects}
        isLoading={isLoading}
        searchString={searchString}
      />
    </>
  )
}
