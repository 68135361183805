import React, { FC, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Button } from '../../../components/buttons/Button'
import { useQueryNotification } from '../../UserInfo/utils'
import styled, { css } from 'styled-components'
import { atMinWidth } from '../../../styles/helpers'
import { useCustomMutation, Mutation } from '../../../hooks/useCustomMutation'
import { Box, CircularProgress } from '@material-ui/core'
import { colors } from '../../../styles/colors'

type Props = {
  userId: number;
};

export const ToggleSync: FC<Props> = ({ userId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const queryClient = useQueryClient()
  const { successNotification, errorNotification } = useQueryNotification()

  const createNewSubjectMutation: Mutation<void> = () => ({
    path: `/admin/users/${userId}/sync`,
    method: 'PUT',
  })

  const newSubjectMutation = useCustomMutation(
    createNewSubjectMutation,
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(`/api/users/${userId}`)
        setIsLoading(false)
        successNotification('Toggle was successfully synced')
      },
      onError: (err: Error) => {
        setIsLoading(false)
        errorNotification(err.message || 'Sorry, something happened')
      },
    },
    true
  )

  const syncToggle = async () => {
    setIsLoading(true)
    await newSubjectMutation.mutateAsync('')
  }

  return (
    <StyledBox>
      <StyledButton
        $color={isLoading ? colors.grey1 : colors.brand}
        kind={'primary'}
        onClick={syncToggle}
        disabled={isLoading}
      >
        Sync Toggl
      </StyledButton>
      {isLoading && <StyledCircularProgress size={30} />}
    </StyledBox>
  )
}

const StyledButton = styled(Button)<{ $color: string }>`
  ${(props) =>
    css`
      background-color: ${props.$color};
      font-size: 10px;
      ${atMinWidth.tablet} {
        font-size: 12px;
      }
    `}
`

const StyledCircularProgress = styled(CircularProgress)`
  color: ${colors.success};
  position: absolute;
  top: 20%;
  left: 41%;
`

const StyledBox = styled(Box)`
  position: relative;
`
