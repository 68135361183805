import { ReactNode } from 'react'
import { Avatar, FormControlLabel, IconButton, TextareaAutosize, Typography } from '@material-ui/core'
import styled from 'styled-components'

import { colors } from '../../styles/colors'
import { Button } from '../buttons/Button'
import { atMinWidth } from '../../styles/helpers'

export const Content = styled.div`
  min-width: 250px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  ${atMinWidth.desktopLarge} {
    row-gap: 55px;
  }
`

export const PostedNotesSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`

type NoteProps = {
  children: ReactNode;
  isPinned?: boolean;
}

export const Note = styled.div<NoteProps>`
  border: 1px solid ${colors.grey4};
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;

  ${atMinWidth.mobile} {
    padding: 24px;
  }

  ${({ isPinned }) => isPinned && `
    background-color: ${colors.brandYellow}30;
    border-color: ${colors.brandYellow};
  `}
`

export const NoteHeader = styled.div`
  display: flex;
  column-gap: 8px;
`

export const NoteDetails = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`

export const StyledAvatar = styled(Avatar)`
  width: 32px;
  height: 32px;
`

export const AuthorName = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
`

export const NoteTimestamp = styled(Typography)`
  width: fit-content;
  font-size: 12px;
  line-height: 1;
  color: ${colors.grey5};
`

export const NewNoteSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

export const NewNoteLabel = styled.div`
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  color: ${colors.grey0};
`

export const NewNoteTextareaAutosize = styled(TextareaAutosize)`
  width: 100%;
  background-color: ${colors.grey4};
  padding: 8px 12px;
  transition: background-color 0.1s ease-in-out;

  &:is(:focus, :not(:placeholder-shown)) {
    background-color: ${colors.white};
    outline: ${colors.grey4} solid 1px;
  }
`

export const EditTextareaAutosize = styled(TextareaAutosize)`
  width: calc(100% + 24px);
  background-color: ${colors.grey4};
  padding: 8px 12px;
  margin: -8px 0 0 -12px;
  transition: background-color 0.1s ease-in-out;

  &:is(:focus, :not(:placeholder-shown)) {
    background-color: ${colors.white};
    outline: ${colors.grey4} solid 1px;
  }
`

export const NewNoteButton = styled(Button)`
  align-self: flex-end;
  font-size: 14px;
  height: 44px;

  &:disabled {
    color: ${colors.grey0} !important;
  }
`

export const EditButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  ${atMinWidth.mobile} {
    flex-direction: row;
    column-gap: 8px;
    justify-content: flex-end;
  }
`

export const EditNoteButton = styled(Button)`
  &:disabled {
    color: ${colors.grey0} !important;
  }
`

export const NewNoteForm = styled.form`
  width: 100%;
`

export const EditNoteForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`

export const PinCheckbox = styled(FormControlLabel)`
  width: fit-content;
  margin-top: 0;

  span {
    font-size: 14px !important;
  }
`

export const Actions = styled.div`
  display: flex;
  margin-left: auto;
  gap: 16px;

  & > button {
    display: block;
    margin: -8px;
    flex-shrink: 0;
    height: fit-content;
    border-radius: 0;
  }
`

export const PinButton = styled(IconButton)`
  display: block;
  margin: -8px;
  flex-shrink: 0;
  height: fit-content;
`

export const StyledTypography = styled(Typography)`
  font-weight: 700;
`
