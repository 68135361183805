import React, { FC, useMemo } from 'react'
import styled from 'styled-components'
import { colors } from '../../../../styles/colors'
import { theme } from '../../../../styles/muiThemes'
import { useShopContext } from '../../ShopContext'
import { PurchaseButton } from './PurchaseButton'
import { ViewBagButton } from './ViewBagButton'

type Props = {
  drawerOpened: boolean;
  viewCart: () => void;
  purchase: () => void;
};
export const CartBottomLine: FC<Props> = (props) => {
  const { drawerOpened, viewCart, purchase } = props
  const { selectedProductsAmount, totalCost } = useShopContext()
  return useMemo(
    () => (
      <BottomLine active={selectedProductsAmount > 0 && !drawerOpened}>
        <ViewBagButton onClick={viewCart} />
        <Text>
          {selectedProductsAmount} Items for {totalCost} Kč
        </Text>
        <PurchaseButton onClick={purchase} />
      </BottomLine>
    ),
    [drawerOpened, selectedProductsAmount]
  )
}

const BottomLine = styled.div<{ active: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr 300px;
  grid-template-rows: 1fr;
  justify-items: start;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${colors.brandYellow};
  width: 100%;
  padding: 1em 3rem 1em calc(206px + 3rem);
  transition: 500ms;
  transform: ${(props) =>
    props.active === true ? 'translateY(0)' : 'translateY(100%)'};
  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr 200px;
  }
  ${theme.breakpoints.down('sm')} {
    padding: 1em;
    display: none;
  }
`

const Text = styled.span`
  font-family: 'Ginto Nord', serif;
  padding-right: 2em;
  justify-self: end;
  ${theme.breakpoints.down('md')} {
    justify-self: start;
  }
`
