import React, { FC, memo } from 'react'
import { ProductStatus } from './types'
import styled from 'styled-components'
import { SearchField, SearchFieldRef } from '../../components/inputs/SearchField'
import { CorpSelect } from '../../components/inputs/CorpSelect'
import { statusSelectable } from './utils'
import { Skeleton } from '@material-ui/lab'
import { HandleFilterInputChange } from '../../bundles/ShopAndDrinksPage/types'
import { atMinWidth } from '../../styles/helpers'

type Props = {
  isLoading: boolean;
  handleFilterChange: HandleFilterInputChange;
  selectedProductStatus: ProductStatus;
  setSelectedProductStatus: (selectedProductStatus: ProductStatus) => void;
  resetSearchString: () => void;
  searchField: SearchFieldRef;
};

export const ProductFilters: FC<Props> = ({
  isLoading,
  handleFilterChange,
  selectedProductStatus,
  setSelectedProductStatus,
  searchField,
}) => {
  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.target.value === ProductStatus.active
      ? setSelectedProductStatus(ProductStatus.active)
      : setSelectedProductStatus(ProductStatus.inactive)
  }

  if (isLoading)
    return (
      <StyledContainer>
        <Skeleton
          height={48}
          variant={'rect'} />
        <Skeleton
          height={48}
          variant={'rect'} />
      </StyledContainer>
    )

  return (
    <StyledContainer>
      <CorpSelect
        values={statusSelectable}
        value={selectedProductStatus}
        onChange={handleStatusChange}
        label={'Status'}
      />
      <SearchField
        fullWidth
        inputRef={searchField}
        onChange={handleFilterChange}
        placeholder="Search name or flavor"
      />
    </StyledContainer>
  )
}

export const StyledContainer = memo(styled.div`
  display: block;

  ${atMinWidth.desktop} {
    display: grid;
    align-items: flex-end;
    gap: 2em;
    grid-template-columns: 1fr 1fr;
  }
`)
