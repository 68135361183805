import { Account } from '../PaymentDetailsPage/types'
import { BaseUser } from '../../types/index'

export enum PaymentsStatus {
  ALL = 'All',
  UNPAIRED = 'Unpaired',
}

export type Transaction = {
  id: number;
  account: Account;
  amount: number;
  createdAt: string;
  direction: string;
  note: string;
  createdBy: BaseUser;
};

export type Payment = {
  id: number;
  counterpartyAccountName: string;
  counterpartyAccountNumber: string;
  amount: number;
  amountLeftToPair: number;
  createdAt: string;
  createdBy: BaseUser;
  note: string;
  pairedTo: string | null;
  source: string;
  variableSymbol: string;
  accountTransactions: Transaction[];
  transactionDate: string;
};

export type Filter = {
  searchString: string;
  page: number;
  pageCount: number;
  startDate: string;
  endDate: string;
  amountFrom: string;
  amountTo: string;
};
