import React, {
  createContext,
  FC,
  useContext,
  useMemo,
  useEffect,
} from 'react'
import { PageLayoutWrapper } from '../../components/ui/PageLayoutWrapper'
import { Typography } from '@material-ui/core'
import { ContentPaper } from '../../components/ContentPaper'
import { ProjectInfo } from '../ProjectInfo'
import { Project, PropsData } from '../ProjectInfo/types'
import { ProjectPageHeader } from './components/ProjectPageHeader'
import { ProjectUsers } from './components/ProjectUsers'
import styled from 'styled-components'
import { useQuery, useQueryClient } from 'react-query'
import { ProjectBudget } from './components/ProjectBudget'
import { ProjectDetailPageSkeleton } from './components/ProjectDetailPageSkeleton'
import ReactQueryKeys from '../../utils/ReactQueryKeys'
import { theme } from '../../styles/muiThemes'
import { BreadcrumbsNav } from '../../components/BreadcrumbsNav'
import { BreadcrumbsData } from '../../types/index'
import { Notes } from '../../components/Notes/Notes'
import { atMinWidth } from '../../styles/helpers'

const ProjectDetailContext = createContext<Project | undefined>(undefined)
export const useProjectDetailContext = (): { project: Project; refetch: () => Promise<void>; isActive: boolean } => {
  const project = useContext(ProjectDetailContext)

  if (!project) throw new Error('Project is undefined!')

  const queryKey = ReactQueryKeys.projectDetailQueryKey(project.id)
  const queryClient = useQueryClient()
  const refetch = async () => queryClient.refetchQueries(queryKey)
  const isActive = useMemo(() => project.state === 'active', [project])
  return { project, refetch, isActive }
}

type Props = {
  propsData: PropsData;
};

export const ProjectDetailPage: FC<Props> = ({ propsData }) => {
  const projectId = propsData.project.id
  const budgetYears = propsData.budgets.years
  const totalHours = propsData.budgets.totalHours
  const queryKey = ReactQueryKeys.projectDetailQueryKey(projectId)
  const { data: project, isLoading } = useQuery<Project>(queryKey)

  useEffect(() => {
    history.replaceState({}, '', window.location.pathname)
  }, [])

  if (isLoading) return <ProjectDetailPageSkeleton />

  const breadcrumbsLinks: BreadcrumbsData = {
    links: [
      {
        id: 1,
        title: 'Projects',
        href: '/admin/projects',
      },
    ],
    currentPageTitle: project?.name || 'Project',
  }

  return (
    <ProjectDetailContext.Provider value={project}>
      <StyledContainer>
        <BreadcrumbsNav data={breadcrumbsLinks} />
        <PageLayoutWrapper>
          <ProjectPageHeader />
          <ProjectPageStyledPaper>
            <ProjectBudget
              years={budgetYears}
              projectId={projectId}
              totalHours={totalHours}
            />
          </ProjectPageStyledPaper>
          <Columns>
            <LeftColumn>
              <ContentPaper>
                <Typography variant={'h3'}>Info</Typography>
                <ProjectInfo
                  id={projectId}
                  propsData={propsData}
                />
              </ContentPaper>
              <ContentPaper>
                <ProjectUsers />
              </ContentPaper>
            </LeftColumn>
            <NotesPaper>
              <Typography variant={'h3'}>Billing notes</Typography>
              <Notes notesQueryKey={`/api/projects/${String(projectId)}/notes`} />
            </NotesPaper>
          </Columns>
        </PageLayoutWrapper>
      </StyledContainer>
    </ProjectDetailContext.Provider>
  )
}

const Columns = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${theme.breakpoints.up('xl')} {
    flex-direction: row;
    align-items: start;
  }
`

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
`

const NotesPaper = styled(ContentPaper)`
  flex: 1;
  min-width: 0;
`

export const ProjectPageStyledPaper = styled(ContentPaper)`
  margin-top: 40px;
  margin-bottom: 40px;
`

const StyledContainer = styled.div`
  padding: 10px;
  ${atMinWidth.desktop} {
    padding: 0;
  }
`