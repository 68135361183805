import { Box, Dialog, Link } from '@material-ui/core'
import React from 'react'
import QRCode from 'react-qr-code'
import styled from 'styled-components'

import { Button } from '../../components/buttons/Button'
import { ContentPaper } from '../../components/ContentPaper'
import { colors } from '../../styles/colors'
import { TestingDevices } from './types'

type Props = {
  dialogOpened: boolean;
  setDialogOpened: (dialogOpened: boolean) => void;
  device: TestingDevices;
};

export const TestingDeviceQRCode: React.FC<Props> = ({
  dialogOpened,
  setDialogOpened,
  device,
}) => {
  const downloadQR = () => {
    const svg = document.getElementById('qrcode') as HTMLElement
    const svgData = new XMLSerializer().serializeToString(svg)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const img = new Image()
    img.onload = function () {
      canvas.width = img.width
      canvas.height = img.height
      ctx?.drawImage(img, 0, 0)
      const pngFile = canvas.toDataURL('image/png')

      const downloadLink = document.createElement('a')
      downloadLink.download = `${device.name}_QR_code`
      downloadLink.href = `${pngFile}`
      downloadLink.click()
    }

    img.src = 'data:image/svg+xml;base64,' + btoa(svgData)
  }

  return (
    <Dialog
      open={dialogOpened}
      onClose={() => setDialogOpened(false)}
    >
      <ContentPaper>
        <ContentWrapper>
          <Box mb="40px">
            This QR encodes the{' '}
            <StyledLink
              rel="noopener noreferrer"
              target="_blank"
              href={device.checkoutUrl}
            >
              checkout url
            </StyledLink>{' '}
            of this device.
          </Box>
          <QRCode
            id="qrcode"
            value={device.checkoutUrl}
          />
          <DownloadButton
            kind="secondary"
            onClick={downloadQR}
          >
            Download code
          </DownloadButton>
        </ContentWrapper>
      </ContentPaper>
    </Dialog>
  )
}

const ContentWrapper = styled.div`
  text-align: center;
`
const DownloadButton = styled(Button)`
  margin-top: 40px;
`

const StyledLink = styled(Link)`
  &:hover {
    color: ${colors.black};
    text-decoration: none;
  }
`
