import React, { FC } from 'react'
import styled from 'styled-components'
import { atMinWidth } from '../../styles/helpers'
import { useCurrentUserInfo } from '../../components/CurrentUserProvider'
import { PageLayoutWrapper } from '../../components/ui/PageLayoutWrapper'
import { ProjectsICanJoin } from './components/ProjectsICanJoin'
import { MyProjects } from './components/MyProjects'
import { ProjectsTablePaper } from '../../components/ProjectsTable/styledComponnets'

export const ProjectSectionOnDashBoard: FC = () => {
  const { currentUser } = useCurrentUserInfo()

  return (
    <PageLayoutWrapper>
      <Grid>
        <ProjectsTablePaper>
          <MyProjects userId={currentUser.id} />
        </ProjectsTablePaper>

        <ProjectsTablePaper>
          <ProjectsICanJoin userId={currentUser.id} />
        </ProjectsTablePaper>
      </Grid>
    </PageLayoutWrapper>
  )
}

const Grid = styled.div`
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr;

  ${atMinWidth.custom(1250)} {
    grid-template-columns: 1.2fr 1fr;
  }
`
