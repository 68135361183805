import { BaseUser } from '../../types'

export enum DevicesStatus {
  ALL = 'All',
  FREE = 'Free',
  BORROWED = 'Borrowed',
}

export type CurrentUser = {
  endDate: null | string;
  startDate: null | string;
  user: Pick<
    BaseUser,
    | 'firstName'
    | 'fullName'
    | 'id'
    | 'lastName'
    | 'name'
    | 'nickname'
    | 'slackAvatar'
  >;
};

export type TestingDevices = {
  brand: string;
  createdAt: Date | string;
  currentUser: CurrentUser;
  id: number;
  inventoryNumber: string;
  inventoryUrl: string;
  location: string | null;
  name: string;
  serial: string;
  sublocation: string;
  systemVersion: string;
  taken: boolean;
  updateAt: Date | string;
  testingDeviceUsers?: CurrentUser[];
  checkoutUrl: string;
};

export type NewTestingDevice =  Omit<
TestingDevices,
| 'testingDeviceUsers'
| 'id'
| 'currentUser'
| 'createdAt'
| 'updateAt'
| 'checkoutUrl'
>;

export type RowItems = {
  label: string;
  updatableFields: string;
};

export type RowProps = {
  id: number;
  label: string;
  value: string | null;
};

export interface ErrorTestingDevice extends TestingDevices {
  status: number;
  errors?: {
    brand?: string;
    name?: string;
    serial?: string;
  };
}
