import React, { ChangeEvent, FC } from 'react'
import styled from 'styled-components'
import { StyledSpiritSlider } from '../../SpiritSlider'
import { SliderFieldData } from '../../../../types/UpdatableFormFieldData/SliderFieldData'
import { UpdatableFormFieldElementProps } from '../UpdatableFormFieldElement'

export const UpdatableFormSlider: FC<
  UpdatableFormFieldElementProps<SliderFieldData>
> = (props) => {
  const { updatableField, formikProps, updateField } = props
  const valuetext = (value: number) => `${Math.round(value * 100)} %`
  const handleChange = (event: ChangeEvent<{}>, value: number | number[]) =>
    formikProps.setFieldValue(updatableField.fieldName, value)
  const value = formikProps.values[updatableField.fieldName] ?? 0
  return (
    <SliderContainer>
      <StyledSlider
        value={value}
        valueLabelDisplay="on"
        valueLabelFormat={valuetext}
        step={0.05}
        min={0}
        max={1}
        onChange={handleChange}
        onChangeCommitted={updateField}
      />
    </SliderContainer>
  )
}

const SliderContainer = styled.div`
  padding-right: 20px;
  padding-left: 10px;
`

const StyledSlider = styled(StyledSpiritSlider)`
  .PrivateValueLabel-circle-38 {
    min-width: 35px;
  }
`
