import React, { FC, useState } from 'react'
import { CorpSelect } from '../../../components/inputs/CorpSelect'
import { productCategorySelectable } from '../utils'
import { ConfirmationDialog } from './ConfirmationDialog'
import { useModalState } from '../../../components/ModalWrapper'
import { ProductForm } from './ProductForm'
import { FormikProps } from 'formik'
import { ProductCreate, ProductCategory } from '../types'

type Props = {
  formikProps: FormikProps<ProductCreate>;
  closeModal: () => void;
  selectedCategoryForm: ProductCategory;
  setSelectedCategoryForm: (selectedCategoryForm: ProductCategory) => void;
  productBarcodes: string[];
  setProductBarcodes: (productBarcodes: string[]) => void;
  setBarcodeValidationError: (barcodeValidationError: boolean) => void;
};

export const CreateProductForm: FC<Props> = ({
  formikProps,
  closeModal,
  selectedCategoryForm,
  setSelectedCategoryForm,
  productBarcodes,
  setProductBarcodes,
  setBarcodeValidationError,
}) => {
  const [categoryToBeChanged, setCategoryToBeChanged] =
    useState<ProductCategory>(ProductCategory.none)
  const [newBarcode, setNewBarcode] = useState('')
  const { resetForm, values } = formikProps
  const {
    isModalOpen: isToBeConfirmed,
    closeModal: closeConfirmModal,
    openModal: openConfirmModal,
  } = useModalState(false)

  const changedValues = Object.keys(values).filter((key) => {
    if (key != 'group') {
      return values[key] != null
    }
    return null
  })

  const handleCategoryChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCategoryToBeChanged(ProductCategory[e.target.value])
    changedValues.length !== 0
      ? openConfirmModal()
      : setSelectedCategoryForm(ProductCategory[e.target.value])
  }

  const handleConfirm = () => {
    resetForm()
    setSelectedCategoryForm(categoryToBeChanged)
    closeConfirmModal()
  }

  return (
    <>
      <ConfirmationDialog
        isToBeConfirmed={isToBeConfirmed}
        closeModal={closeConfirmModal}
        confirm={handleConfirm}
      />
      <CorpSelect
        values={productCategorySelectable}
        value={selectedCategoryForm}
        onChange={handleCategoryChange}
      />
      <ProductForm
        formikProps={formikProps}
        selectedProductCategory={selectedCategoryForm}
        newBarcode={newBarcode}
        productBarcodes={productBarcodes}
        setNewBarcode={setNewBarcode}
        setProductBarcodes={setProductBarcodes}
        setBarcodeValidationError={setBarcodeValidationError}
        closeModal={closeModal}
      />
    </>
  )
}
