import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { Contact } from '../types'
import {
  UpdatableGroupContainer,
  UpdatableGroupHeading,
  UpdatableItemsGroup,
} from '../../../components/ui/UpdatableFormField/UpdatableItemsGroup'
import { Alert } from '@material-ui/lab'
import styled from 'styled-components'
import { DataLine, SelectItem } from '../../../types'
import { TextFieldData } from '../../../types/UpdatableFormFieldData/TextFieldData'
import { AddNewContact } from './AddNewContact'
import { Loader } from 'react-feather'
import { getTextFieldType } from '../utils'
import { canUpdateUser } from './Tabs/UserInfoTab'

type ContactsResponse = {
  contacts: Contact[];
  enums: {
    contactTypes: SelectItem[];
  };
};

type Props = {
  userId: number;
};
export const Contacts: FC<Props> = ({ userId }) => {
  const queryKey = `/api/users/${userId}/contacts`
  const { data, isLoading } = useQuery<ContactsResponse>(queryKey)
  const userCanUpdate = canUpdateUser()

  const contacts: Contact[] = data?.contacts ?? []
  const updatableItems: DataLine[] = contacts.map((contact) => {
    const contactType = data!.enums.contactTypes.find(
      (type) => type.value === contact.contactType
    )
    const textFieldType = getTextFieldType(contact.contactType)
    const updatePath = queryKey + '/' + contact.id
    const textFieldData = new TextFieldData(
      contact.value,
      'value',
      textFieldType,
      { alternatePath: updatePath }
    )
    return {
      label: contactType?.label ?? '',
      updatableFields: [textFieldData],
      deleteIcon: userCanUpdate,
    }
  })

  if (isLoading) return <Loader />
  return (
    <UpdatableGroupContainer>
      <Container>
        <UpdatableGroupHeading>Contacts</UpdatableGroupHeading>
        {userCanUpdate && (
          <AddNewContact
            userId={userId}
            contactTypes={data!.enums.contactTypes}
          />
        )}
        <UpdatableItemsGroup
          updatePaths={[queryKey]}
          items={updatableItems}
          updatable={userCanUpdate}
        />
        {contacts.length === 0 && (
          <Alert
            variant={'outlined'}
            severity={'info'}
          >
            No contacts yet
          </Alert>
        )}
      </Container>
    </UpdatableGroupContainer>
  )
}

const Container = styled.div`
  margin-top: 2em;
  @media (min-width: 1441px) {
    margin-top: 0;
  }
`
