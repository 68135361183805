import React, { FC, useCallback, useMemo, useState } from 'react'
import { ProductCategory } from '../../types'
import {
  createCategoriesFilterInitState,
  filterSelectedCategoriesFromProducts,
  getProductCategories,
  getProductGroupsFilters,
  productsSearchFilter,
  showFilteredProducts,
  sortProductsByCategories,
  sortProductsToGroups,
} from '../../utils'
import { ProductsListView } from './ProductsListView'
import { useShopContext } from '../../ShopContext'
import { useSearchFieldState } from '../../../../components/inputs/SearchField'

type Props = {
  categories: ProductCategory[];
};

export const ProductsList: FC<Props> = (props) => {
  const { categories } = props
  const { products } = useShopContext()

  const initState = useMemo(
    () => createCategoriesFilterInitState(categories),
    [categories]
  )
  const [selectedCategories, setSelectedCategories] = useState(initState)
  const [selectedGroup, setSelectedGroup] = useState('')
  const { searchString, handleFilterChange, compare } = useSearchFieldState()

  const productsAfterSearch = useMemo(
    () => productsSearchFilter(products, compare),
    [searchString]
  )

  // group filtering
  const productsInGroups = useMemo(
    () => sortProductsToGroups(productsAfterSearch),
    [productsAfterSearch]
  )
  const productsAfterGroupSelection = useMemo(
    () =>
      selectedGroup ? productsInGroups[selectedGroup] : productsAfterSearch,
    [selectedGroup, productsInGroups]
  )

  // filtering by categories
  const productsInCategories = useMemo(
    () => sortProductsByCategories(productsAfterGroupSelection),
    [productsAfterGroupSelection]
  )

  const filteredProducts = useMemo(
    () =>
      filterSelectedCategoriesFromProducts(
        productsInCategories,
        selectedCategories
      ),
    [productsInCategories, selectedCategories]
  )

  // categories from filtered products
  const filteredCategories = useMemo(
    () => getProductCategories(productsInCategories),
    [productsInCategories]
  )

  const finalProducts = useMemo(
    () =>
      showFilteredProducts(selectedCategories)
        ? filteredProducts
        : productsAfterGroupSelection,
    [filteredProducts, productsAfterGroupSelection]
  )

  const productGroupsFilters = useMemo(
    () => getProductGroupsFilters(productsAfterSearch, productsInGroups),
    [productsAfterSearch, productsInGroups]
  )

  const handleCategoryChange = useCallback(
    (category: string, checked: boolean) =>
      setSelectedCategories({ ...selectedCategories, [category]: checked }),
    [selectedCategories]
  )

  const handleGroupFilterChange = useCallback(
    (selectedCategory: string) => setSelectedGroup(selectedCategory),
    []
  )

  return (
    <ProductsListView
      handleFilterChange={handleFilterChange}
      handleGroupFilterChange={handleGroupFilterChange}
      handleCategoryChange={handleCategoryChange}
      selectedGroup={selectedGroup}
      productGroupsFilters={productGroupsFilters}
      filteredCategories={filteredCategories}
      selectedCategories={selectedCategories}
      finalProducts={finalProducts}
      searchString={searchString}
    />
  )
}
