import React, { FC, useCallback, useMemo, useState } from 'react'
import { UserInProject, UsersState } from '../../../types'
import {
  CircularProgress,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import { RemoveUser } from '../../../components/Icon/icons/RemoveUser.icon'
import { addUserToProject, removeUserFromProject } from '../../../utils/apiHelpers'
import { useQueryNotification } from '../../UserInfo/utils'
import { useProjectDetailContext } from '../ProjectDetailPage'
import { userDetailUrl } from '../../../utils/urlHelpers'
import { TextFieldData } from '../../../types/UpdatableFormFieldData/TextFieldData'
import { ItemField } from '../../../components/ui/UpdatableFormField/ItemField'
import { SyncIcon } from '../../../components/Icon/icons/Sync.icon'
import { ChangeTribeModal } from './ChangeTribeModal/ChangeTribeModal'
import { ModalWrapper, useModalState } from '../../../components/ModalWrapper'
import { useUserPermissions } from '../../../components/CurrentUserProvider'
import { PlusIcon } from '../../../components/Icon/icons/Plus.icon'

type Props = {
  user: UserInProject;
  projectId: number;
  projectName: string;
  state: UsersState;
};
export const ProjectUserLine: FC<Props> = ({ user, projectId, projectName, state }) => {
  const { isModalOpen, closeModal, openModal } = useModalState()
  const [removingUser, setRemovingUser] = useState(false)
  const [addingUser, setAddingUser] = useState(false)
  const { refetch } = useProjectDetailContext()
  const { fullName, id, billsToTribe, clientRate } = user
  const { errorNotification, successNotification } = useQueryNotification()

  const removeUser = useCallback(async (userId: number) => {
    setRemovingUser(true)
    try {
      const result = await removeUserFromProject(userId, projectId)
      successNotification(result.message)
      await refetch()
      setRemovingUser(false)
    } catch (e) {
      errorNotification(e.getMessage())
    }
  }, [])

  const addUser = useCallback(async (userId: number) => {
    setAddingUser(true)
    try {
      const { togglProjectId, togglProjectName, tribeId } = user
      const result = await addUserToProject(userId, projectId, togglProjectId, togglProjectName, tribeId)
      successNotification(result.message)
      await refetch()
      setAddingUser(false)
    } catch (e) {
      errorNotification(e.getMessage())
    }
  }, [])

  const { resolvePermissions } = useUserPermissions()
  const canAssignUserToProject = useMemo(
    () => resolvePermissions('userInProject', 'unassignUser'),
    []
  )

  const isActiveUser = user.state === 'active'
  const useDetailPageUrl = userDetailUrl(user.id)

  return (
    <TableRow key={id}>
      <TableCell>
        <a href={useDetailPageUrl}>{fullName}</a>
      </TableCell>
      <TableCell align={'center'}>
        <ItemField
          updatableField={new TextFieldData(clientRate, 'clientRate', 'number', { alternatePath: `/api/users/${user.id}/user_in_projects/${projectId}`})}
          updatePath={`/api/users/${user.id}/user_in_projects/${projectId}`}
          error={''}
          noPadding={true}
          updatable={true}
        />
      </TableCell>
      <TableCell align={'right'}>{billsToTribe}</TableCell>
      <TableCell align={'right'}>
        {state === UsersState.active &&
          <>
            <Tooltip title="Change tribe">
              <IconButton onClick={() => !isModalOpen ? openModal() : null}>
                <SyncIcon />
              </IconButton>
            </Tooltip>
            <ModalWrapper
              isOpen={isModalOpen}
              closeModal={closeModal}
            >
              <ChangeTribeModal
                projectId={projectId}
                user={user}
                done={closeModal}
                refetchProject={refetch}
              />
            </ModalWrapper>
            <Tooltip title="Remove from project">
              <IconButton onClick={() => removeUser(id)}>
                {removingUser ? <CircularProgress size={22} /> : <RemoveUser />}
              </IconButton>
            </Tooltip>
          </>
        }
        {state !== UsersState.active && canAssignUserToProject && isActiveUser && (
          <Tooltip title="Add to project">
            <IconButton onClick={() => addUser(id)}>
              {addingUser ? <CircularProgress size={22} /> : <PlusIcon />}
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  )
}
