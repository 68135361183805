import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'
import { colors } from '../../../styles/colors'

export const SignInArrow: FC<IconSVGProps> = () => (
  <svg
    width="34"
    height="12"
    viewBox="0 0 34 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 6H33M33 6L28 1M33 6L28 11"
      stroke={colors.brand}
    />
  </svg>
)
