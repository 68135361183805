import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { Tabs, Tab, TabsProps, Box } from '@material-ui/core'

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}
const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

type TabHeading = string | { label: string; icon: ReactNode }

type Props = {
  headings: TabHeading[];
  tabsContent: ReactNode[];
  ariaLabel?: string;
  startTab?: number;
  variant?: TabsProps['variant'];
  scrollButtons?: TabsProps['scrollButtons'];
  setActiveTab?: (tab: number) => void;
};

export const TabsLayout: FC<Props> = ({
  headings,
  tabsContent,
  ariaLabel,
  startTab,
  setActiveTab,
  variant = 'scrollable',
  scrollButtons = 'desktop',
}) => {
  const [value, setValue] = React.useState(startTab || 0)
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
    setActiveTab && setActiveTab(newValue)
  }

  const ariaProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  })
  return (
    <>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label={ariaLabel || 'Tabs'}
        indicatorColor="primary"
        variant={variant}
        scrollButtons={scrollButtons}
      >
        {headings.map((heading, index) => {
          const headingHtml = typeof heading === 'string'
            ? heading
            : (
              <Box
                display="flex"
                alignItems="center"
                gridColumnGap="8px"
              >
                {heading.icon}
                {heading.label}
              </Box>
            )

          return (
            <StyledTab
              key={index}
              label={headingHtml}
              {...ariaProps(index)}
            />
          )
        })}
      </StyledTabs>
      {tabsContent.map((tabContent, index) => (
        <TabPanel
          key={index}
          value={value}
          index={index}
        >
          {tabContent}
        </TabPanel>
      ))}
    </>
  )
}

const StyledTabs = styled(Tabs)`
  & > .MuiTabs-scroller {
    width: 0;
  }
`

const StyledTab = styled(Tab)`
  min-width: 140px;
  &:focus {
    outline: none;
  }
`
