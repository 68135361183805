import { groupBy } from 'lodash'
import { Project, Tribe } from '../../bundles/ProjectsPage/types'
import { sortByKey } from '../../utils/helpers'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { debounce } from '@material-ui/core'
import { SelectItem } from '../../types'
import { StatusFilter } from '../../enum'
import { Rate } from '../../bundles/UserDetailPage/rates/types'

const PROJECT_BASE_URL = '/admin/projects'

export type SortedProject = ProjectsGroupType | Project

export type ProjectsGroupType = {
  name: string
  group: string
  projects: (Project | Rate)[]

  projectsCount: number
}

type Grouped = {
  [key: string]: Project[]
}

export const statusSelectable: SelectItem[] = [
  {
    value: StatusFilter.Active,
    label: 'Active',
  },
  {
    value: StatusFilter.Inactive,
    label: 'Inactive',
  },
]

export const selectableTribes = (tribes?: Tribe[]): SelectItem[] => {
  return tribes?.map((tribe) => ({ label: tribe.name, value: tribe.id })) ?? []
}

type GetMemoizedSortedProjects = (projects?: Project[]) => SortedProject[]
export const getMemoizedSortedProjects: GetMemoizedSortedProjects = (
  projects
) => {
  const getSortedProjects = (projects) => {
    const projectsWithoutGroup = projects.filter((project) => !project.group)
    const projectsWithGroup = projects.filter((project) => project.group)

    const grouped: Grouped = groupBy(projectsWithGroup, 'group')

    const groupsArray: ProjectsGroupType[] = Object.values(grouped).map(
      (group) => ({
        name: group[0].group,
        group: group[0].group,
        projects: group,
        projectsCount: group.length,
      })
    )

    const projectsWithGroups: SortedProject[] = [
      ...groupsArray,
      ...projectsWithoutGroup,
    ]

    sortByKey(projectsWithGroups, 'name')

    return projectsWithGroups
  }

  return (
    useMemo(() => projects && getSortedProjects(projects), [projects]) || []
  )
}

const tribeMatchesSearchTribe = (project, searchTribe: number) => {
  if (project.tribe) {
    return project.tribe.id === searchTribe || searchTribe === 0
  } else {
    return project.ownedByTribeId === searchTribe || searchTribe === 0
  }
}

type GetFilteredProjects = (
  projects: SortedProject[],
  searchString: string,
  searchTribe: number
) => SortedProject[]
export const getFilteredProjects: GetFilteredProjects = (
  projects,
  searchString,
  searchTribe
) => {
  const formatedSearchString = searchString.toLowerCase()
  const nameContainsSearchString = (project) =>
    project.name.toLowerCase().includes(formatedSearchString)

  return projects.reduce(
    (acc, current) => {
      if (
        nameContainsSearchString(current) &&
        tribeMatchesSearchTribe(current, searchTribe)
      ) {
        return [...acc, current]
      }

      if (current.group) {
        const { projects } = current as ProjectsGroupType
        const projectsMatching = projects.filter(
          (project) =>
            nameContainsSearchString(project) &&
            tribeMatchesSearchTribe(project, searchTribe)
        )

        if (projectsMatching.length) {
          return [
            ...acc,
            {
              ...current,
              projects: [...projectsMatching],
            },
          ]
        }
      }
      return acc
    },

    []
  )
}

export type HandleFilterInputChange = (
  event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
) => void
type UseProjectFiltering = (projects: SortedProject[]) => {
  filteredProjects: SortedProject[]
  handleFilterChange: HandleFilterInputChange
  handleTribeChange: HandleFilterInputChange
  searchString: string
  owningTribe: number
}
export const useProjectFiltering: UseProjectFiltering = (projects) => {
  const [searchString, setSearchString] = useState<string>('')
  const [owningTribe, setOwningTribe] = useState<number>(0)

  const debouncer = useCallback(
    debounce((searchValue: string) => {
      setSearchString(searchValue)
    }, 300),
    []
  )

  const handleFilterChange: HandleFilterInputChange = useCallback((event) => {
    if (event.target) {
      debouncer(event.target.value)
    } else {
      debouncer(event.toString())
    }
  }, [])

  const handleTribeChange: HandleFilterInputChange = useCallback((event) => {
    if (event.target) {
      setOwningTribe(parseInt(event.target.value))
    } else {
      setOwningTribe(Number(event))
    }
  }, [])

  const filteredProjects =
    searchString || owningTribe !== 0
      ? getFilteredProjects(projects, searchString, owningTribe)
      : projects

  return {
    searchString,
    owningTribe,
    filteredProjects,
    handleFilterChange,
    handleTribeChange,
  }
}

export const getLinkToProjectDetail = (id: number): string => {
  return `${PROJECT_BASE_URL}/${id}`
}

// TODO routing solution
export const redirectToProjectDetail = (id: number): string => {
  return (window.location.pathname = getLinkToProjectDetail(id))
}
