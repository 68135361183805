import React, { FC } from 'react'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { InputLabel } from '@material-ui/core'
import { KeyboardDatePickerProps } from '@material-ui/pickers/DatePicker/DatePicker'
import styled from 'styled-components'

type Props = KeyboardDatePickerProps & {
  name?: string;
  required?: boolean;
};
export const FormDatePicker: FC<Props> = (props) => {
  const {
    inputVariant,
    label,
    required,
    name,
    value,
    onChange,
    onBlur,
    ...rest
  } = props

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div>
        {label && (
          <StyledInputLabel htmlFor={name}>
            {label}
            {required && ' *'}
          </StyledInputLabel>
        )}
        <KeyboardDatePicker
          disableToolbar
          inputVariant={inputVariant}
          variant="inline"
          format="dd. MM. yyyy"
          margin="none"
          value={value}
          onChange={(date) => onChange(date)}
          onBlur={onBlur}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          {...rest}
        />
      </div>
    </MuiPickersUtilsProvider>
  )
}

const StyledInputLabel = styled(InputLabel)`
  margin-bottom: 4px;
`
