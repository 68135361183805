import React, { FC, useMemo } from 'react'
import { format, parseISO } from 'date-fns'
import { TableCell, TableRow, IconButton, Tooltip } from '@material-ui/core'
import styled from 'styled-components'
import { useTransactionContext } from './MyTransactionContext'
import { Transaction } from './types'
import { useUserPermissions } from '../../../components/CurrentUserProvider'
import { Mutation, useCustomMutation } from '../../../hooks/useCustomMutation'
import { useQueryNotification } from '../../UserInfo/utils'
import { SyncIcon } from '../../../components/Icon/icons/Sync.icon'

interface Props {
  transaction: Transaction
}

export const MyTransactionTableRow: FC<Props> = ({ transaction }) => {
  const {
    transactableId,
    createdAt,
    amount,
    transactableType,
    accountHolder,
    transactableUrl,
  } = transaction
  const { successNotification, errorNotification } = useQueryNotification()
  const { isAllTransactions, refetch, showActionColumn } = useTransactionContext()
  const { resolvePermissions } = useUserPermissions()

  const dateCreatedAt = format(parseISO(createdAt), ' dd.MM.yyyy, HH:MM')
  const amountValue = amount.toFixed(1)

  const showRevertAction = useMemo(() => {
    const isRevertable = transactableType === 'purchase'
    const userCanRevert = resolvePermissions('accountsTransaction', 'manage')
    return isRevertable && userCanRevert
  }, [resolvePermissions])

  const createRevertTransactionMutation: Mutation<{}> = () => ({
    path: `/admin/purchases/${transactableId}/revert`,
  })

  const revertTransactionMutation = useCustomMutation(
    createRevertTransactionMutation,
    {
      onSuccess: () => {
        successNotification('Purchase was reverted')
      },
      onError: () => {
        errorNotification(
          'Sorry, something happened :) let us know and we\'ll fix it'
        )
      },
      onSettled: () => {
        refetch()
      }
    }
  )

  const handleRevertTransaction = () => {
    revertTransactionMutation.mutate({})
  }

  return (
    <TableRow
      key={transactableId}
    >
      <TableCell><a href={transactableUrl}>{transactableId}</a></TableCell>
      <TableCell>{dateCreatedAt}</TableCell>
      {isAllTransactions && <TableCell>{accountHolder.fullName}</TableCell>}
      <TableCell> {transactableType}</TableCell>
      <TableCell>{amountValue}</TableCell>
      {showActionColumn && (
        <TableCell>
          {showRevertAction && (
            <Tooltip title="Revert transaction">
              <StyledIconButton
                onClick={(event) => {
                  event.stopPropagation()
                  handleRevertTransaction()
                }}
              >
                <SyncIcon />
              </StyledIconButton>
            </Tooltip>
          )}
        </TableCell>
      )}
    </TableRow>
  )
}

const StyledIconButton = styled(IconButton)`
  margin: -12px;
`
