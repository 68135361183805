import { useSnackbar } from 'notistack'
import { TextInputType } from './types'

type SubjectsEnum = {
  key: number;
  label: string;
};

// TODO add snackbar options to helpers
export const useQueryNotification = () => {
  const { enqueueSnackbar } = useSnackbar()
  return {
    successNotification: (message: string) => {
      enqueueSnackbar(message, {
        variant: 'success',
        preventDuplicate: true,
      })
    },
    errorNotification: (message: string) => {
      enqueueSnackbar(message, {
        variant: 'error',
        title: 'Update error',
        preventDuplicate: true,
        persist: false,
      })
    },
  }
}

export const getTextFieldType = (contactType: string): TextInputType => {
  const map = {
    emergency_contact_phone: 'tel',
    mobile_phone: 'tel',
    private_phone: 'tel',
    private_email: 'email',
  }
  if (contactType in map) return map[contactType]
  return 'text'
}
