import React, { FC, memo, useCallback, useMemo } from 'react'
import { Box, TableCell } from '@material-ui/core'
import { BubbleButton } from '../../../components/buttons/BubbleButton'
import styled, { css } from 'styled-components'
import { AmountOperation, BasketProduct } from '../types'
import { useShopContext } from '../ShopContext'

type Props = {
  basketProduct: BasketProduct;
};
export const ShopProductAmountSelection: FC<Props> = memo(
  ({ basketProduct }) => {
    const { handleAmountOperation, config } = useShopContext()
    const alreadyChosen = useMemo(
      () => basketProduct.amount > 0,
      [basketProduct.amount]
    )
    const addButtonDisabled = useMemo(
      () =>
        !config.allowsOutOfStockBuy ? !basketProduct.product.inStock : false,
      [basketProduct]
    )

    const decreaseAmount = useCallback(() => {
      handleAmountOperation(basketProduct.product.id, AmountOperation.MINUS)
    }, [basketProduct])

    const increaseAmount = useCallback(() => {
      handleAmountOperation(basketProduct.product.id, AmountOperation.PLUS)
    }, [basketProduct])

    return useMemo(
      () => (
        <SelectionTableCell align={'right'}>
          <StyledBox
            display={'flex'}
            flexWrap={'nowrap'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <VisibilitySwitcher $visible={alreadyChosen}>
              <RoundedBubbleButton
                color="primary"
                variant={'outlined'}
                onClick={decreaseAmount}
              >
                -
              </RoundedBubbleButton>
            </VisibilitySwitcher>

            <VisibilitySwitcher $visible={alreadyChosen}>
              <Amount>{basketProduct.amount}</Amount>
            </VisibilitySwitcher>

            <RoundedBubbleButton
              color="primary"
              variant={alreadyChosen ? 'outlined' : 'contained'}
              disabled={addButtonDisabled}
              onClick={increaseAmount}
            >
              +
            </RoundedBubbleButton>
          </StyledBox>
        </SelectionTableCell>
      ),
      [basketProduct.amount]
    )
  }
)

const SelectionTableCell = memo(styled(TableCell)`
  font-size: 1em;
  padding-right: 0;
`)

const StyledBox = memo(styled(Box)`
  font-size: 1em;
`)

const VisibilitySwitcher = memo(styled.div<{ $visible: boolean }>`
  opacity: 1;
  line-height: 1;
  font-size: 1em;
  ${(props) =>
    !props.$visible &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`)

// TODO Make this global option
const RoundedBubbleButton = memo(styled(BubbleButton)`
  min-width: 0;
  border-radius: 50%;
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  padding: 1em !important;
  margin-right: 0 !important;
`)

const Amount = memo(styled.div`
  font-size: 1.2em;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  min-width: 2em;
`)
