import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const DocumentIcon: FC<IconSVGProps> = ({
  width = 20,
  height = 20,
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9444 17.2225C16.9444 17.5172 16.8274 17.7998 16.619 18.0082C16.4106 18.2166 16.128 18.3337 15.8333 18.3337H3.61111C3.31643 18.3337 3.03381 18.2166 2.82544 18.0082C2.61706 17.7998 2.5 17.5172 2.5 17.2225V2.7781C2.5 2.48342 2.61706 2.2008 2.82544 1.99243C3.03381 1.78406 3.31643 1.66699 3.61111 1.66699H11.4844C11.7789 1.66706 12.0613 1.78402 12.2696 1.99218L16.6193 6.34181C16.8274 6.5501 16.9444 6.83251 16.9444 7.12699V17.2225Z"
      stroke="#14141F"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M16.9442 7.22255H12.4998C12.2051 7.22255 11.9225 7.10548 11.7141 6.89711C11.5057 6.68874 11.3887 6.40612 11.3887 6.11144V1.66699"
      stroke="#14141F"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M7.99599 8.22852L6.41673 10.3337C6.36895 10.3973 6.30807 10.4499 6.2382 10.488C6.16832 10.526 6.09109 10.5486 6.01173 10.5543C5.93237 10.5599 5.85272 10.5484 5.77818 10.5206C5.70365 10.4927 5.63596 10.4492 5.57969 10.393L4.72266 9.53666"
      stroke="#14141F"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M7.99599 13.2285L6.41673 15.3337C6.36895 15.3973 6.30807 15.4499 6.2382 15.488C6.16832 15.526 6.09109 15.5486 6.01173 15.5543C5.93237 15.5599 5.85272 15.5484 5.77818 15.5206C5.70365 15.4927 5.63596 15.4492 5.57969 15.393L4.72266 14.5359"
      stroke="#14141F"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M10.2773 10.5557H13.6107"
      stroke="#14141F"
      strokeLinecap="round"
      strokeLinejoin="round" />
    <path
      d="M10.2773 15H13.6107"
      stroke="#14141F"
      strokeLinecap="round"
      strokeLinejoin="round" />
  </svg>
)

