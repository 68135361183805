import React, { FC } from 'react'
import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { NavigationItemIcon } from '../../../components/Icon/NavigationItenIcon'
import styled, { css } from 'styled-components'
import { colors } from '../../../styles/colors'
import { isActive } from '../utils'

type NavGroupItem = {
  label: string;
  path: string;
  show: boolean;
  icon: string;
};

export type NavGroupProps = {
  groupName: string;
  items: NavGroupItem[];
};
export const NavGroup: FC<NavGroupProps> = ({ items, groupName }) => {
  const visibleItems = items.filter((item) => item.show == true)

  return (
    <ItemsGroup>
      <GroupName variant={'overline'}>{groupName}</GroupName>
      <List>
        {visibleItems.map((item) => (
          <StyledLink
            key={item.label}
            href={item.path}
          >
            <MyListItem
              button
              $active={isActive(item.path)}
            >
              <MyListItemIcon>
                <NavigationItemIcon iconName={item.icon} />
              </MyListItemIcon>

              <ItemLabel>{item.label}</ItemLabel>
            </MyListItem>
          </StyledLink>
        ))}
      </List>
    </ItemsGroup>
  )
}

const ItemsGroup = styled.div``

const GroupName = styled(Typography)`
  color: ${colors.grey1};
  padding-left: 1.5em;
`

const StyledLink = styled(Link)`
  color: inherit !important;
  display: flex;
  align-items: center;
  text-decoration: none !important;
`

const ItemLabel = styled(ListItemText)`
  > span {
    font-size: 14px;
  }
`

const MyListItemActiveState = css`
  color: white !important;
  background-color: rgba(255, 255, 255, 0.05) !important;
  svg path {
    stroke: white;
  }
`

const MyListItem = styled(ListItem)<{ $active: boolean }>`
  color: ${colors.grey1};
  transition: 300ms;
  font-size: 1em;
  padding: 0.5em 1.5em;
  svg path {
    transition: 300ms;
  }
  ${(props) => props.$active === true && MyListItemActiveState}
  &:hover {
    ${MyListItemActiveState}
  }
`

const MyListItemIcon = styled(ListItemIcon)`
  min-width: 35px;
`
