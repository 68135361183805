import React, { FC } from 'react'
import { AllProjects } from './icons/AllProjects.icon'
import { Default } from './icons/Default.icon'
import { DashboardIcon } from './icons/Dashboard.icon'
import { MyProjectsIcon } from './icons/MyProjects.icon'
import { AllUsersIcon } from './icons/AllUsers.icon'
import { TestingDevices } from './icons/TestingDevices.icon'
import { FoodAndDrinksIcon } from './icons/FoodAndDrinks.icon'
import { Reports } from './icons/Reports.icon'
import { MyPurchases } from './icons/MyPurchases.icon'
import { PaymentsIcon } from './icons/Payments.icon'
import { CreditCard } from './icons/CreditCard.icon'

type Props = {
  iconName: string;
};
export const NavigationItemIcon: FC<Props> = (props) => {
  const icons = {
    reports: <Reports />,
    'my-projects': <MyProjectsIcon />,
    'my-purchases': <MyPurchases />,
    'all-projects': <AllProjects />,
    'all-users': <AllUsersIcon />,
    'testing-devices': <TestingDevices />,
    'food-and-drinks': <FoodAndDrinksIcon />,
    'credit-card': <CreditCard />,
    dashboard: <DashboardIcon />,
    payments: <PaymentsIcon />,
  }

  const handleIconName = (iconName: string) => {
    if (iconName in icons) return icons[iconName]
    else return <Default />
  }

  return handleIconName(props.iconName)
}
