import React, { FC } from 'react'
import { IconButton } from '@material-ui/core'
import styled from 'styled-components'
import { Formik, Form, FieldArray, FormikValues } from 'formik'
import { useCustomMutation, Mutation } from '../../../hooks/useCustomMutation'
import { FormikSubmitHandler } from '../../../types'
import { useQueryClient } from 'react-query'
import { TransactableType, AccountSelectValues } from '../types'
import { getValidTransactions } from '../utils'
import { Payment } from '../../PaymentsPage/types'
import { useQueryNotification } from '../../UserInfo/utils'
import { FormContent } from './FormContent'

type Props = {
  payment: Payment;
  accountsSelectValues: AccountSelectValues[] | undefined;
  isLoading: boolean;
  closeModal: () => void;
};

export const NewTransaction: FC<Props> = ({
  payment,
  accountsSelectValues,
  isLoading,
  closeModal,
}) => {
  const queryClient = useQueryClient()
  const { id: paymentId } = payment

  const initValues = {
    note: '',
    transactable_type: TransactableType.payment,
    transactable_id: paymentId,
    transactions: [
      { account_id: '', amount: '' },
      { account_id: '', amount: '' },
      { account_id: '', amount: '' },
    ],
  }

  const { successNotification } = useQueryNotification()

  const createNewTransactionMutation: Mutation<FormikValues> = (initVals) => ({
    path: '/api/transactions',
    method: 'POST',
    params: initVals,
  })

  const newSubjectMutation = useCustomMutation(
    createNewTransactionMutation,
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(`/api/payments/${paymentId}`)
        await queryClient.refetchQueries('/api/payments')
        successNotification('Payment has been successfully paired')
        closeModal()
      },
    },
    true
  )

  const formikSubmit: FormikSubmitHandler<FormikValues> = async (
    values,
    { setErrors }
  ) => {
    const postValues = {
      note: values.note,
      transactable_type: TransactableType.payment,
      transactable_id: Number(paymentId),
      transactions: getValidTransactions(values.transactions) || [],
    }
    return await newSubjectMutation.mutateAsync(postValues).catch((err) => {
      setErrors(err.errors)
    })
  }

  return (
    <Formik
      initialValues={initValues}
      onSubmit={formikSubmit}
    >
      {(formikProps) => (
        <Form>
          <FieldArray
            name="transactions"
            render={(arrayHelpers) => (
              <FormContent
                formikProps={formikProps}
                payment={payment}
                accountsSelectValues={accountsSelectValues}
                closeModal={closeModal}
                arrayHelpers={arrayHelpers}
                isLoading={isLoading}
              />
            )}
          />
        </Form>
      )}
    </Formik>
  )
}

export const StyledIconButton = styled(IconButton)`
  padding: 18px 0 0 0;
  align-self: center;
  left: 0px;
`
