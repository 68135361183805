import React from 'react'
import { FC } from 'react'
import { colors } from '../../../styles/colors'
import { IconSVGProps } from '../Icon'

export const MoneyShieldIcon: FC<IconSVGProps> = ({
  width = 24,
  height = 24,
  color = colors.grey1,
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6879_23126)">
      <path
        d="M9.75 13.5C9.75 13.945 9.88196 14.38 10.1292 14.75C10.3764 15.12 10.7278 15.4084 11.139 15.5787C11.5501 15.749 12.0025 15.7936 12.439 15.7068C12.8754 15.62 13.2763 15.4057 13.591 15.091C13.9057 14.7763 14.12 14.3754 14.2068 13.939C14.2936 13.5025 14.249 13.0501 14.0787 12.639C13.9084 12.2278 13.62 11.8764 13.25 11.6292C12.88 11.382 12.445 11.25 12 11.25C11.555 11.25 11.12 11.118 10.75 10.8708C10.38 10.6236 10.0916 10.2722 9.92127 9.86104C9.75098 9.4499 9.70642 8.99751 9.79323 8.56105C9.88005 8.12459 10.0943 7.72368 10.409 7.40901C10.7237 7.09434 11.1246 6.88005 11.561 6.79323C11.9975 6.70642 12.4499 6.75097 12.861 6.92127C13.2722 7.09157 13.6236 7.37996 13.8708 7.74997C14.118 8.11998 14.25 8.55499 14.25 9"
        stroke={`${color}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M12 5.25V6.75"
        stroke={`${color}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M12 15.75V17.25"
        stroke={`${color}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M22.5 9.375C22.5059 12.4313 21.4684 15.3981 19.5591 17.7848C17.6498 20.1714 14.9831 21.8348 12 22.5C9.01694 21.8348 6.35025 20.1714 4.44096 17.7848C2.53167 15.3981 1.49417 12.4313 1.50002 9.375V4.125C1.50002 3.42881 1.77659 2.76113 2.26887 2.26884C2.76115 1.77656 3.42883 1.5 4.12502 1.5H19.875C20.5712 1.5 21.2389 1.77656 21.7312 2.26884C22.2235 2.76113 22.5 3.42881 22.5 4.125V9.375Z"
        stroke={`${color}`}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_6879_23126">
        <rect
          width="24"
          height="24"
          fill="white" />
      </clipPath>
    </defs>
  </svg>

)
