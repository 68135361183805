import React, { FC, useState } from 'react'
import { Button, Dialog, TextField, Typography } from '@material-ui/core'
import { ContentPaper } from '../../../components/ContentPaper'
import { FormikSubmitHandler, SelectItem } from '../../../types'
import { FormSelect } from '../../../components/inputs/FormSelect'
import { Formik } from 'formik'
import { Mutation, useCustomMutation } from '../../../hooks/useCustomMutation'
import { useQueryClient } from 'react-query'
import { DialogForm } from './AddNewContract'
import { useQueryNotification } from '../utils'
import { AddNewButton } from './Tabs/ContractsTab'
import { hasError, helperText } from '../../../utils/helpers'
import { NewSubject } from '../types'

type Props = {
  userId: number;
  subjectTypes: SelectItem[];
};
export const AddNewSubject: FC<Props> = ({ userId, subjectTypes }) => {
  const [dialogOpened, setDialogOpened] = useState(false)
  const queryClient = useQueryClient()
  const { successNotification } = useQueryNotification()

  const initVals: NewSubject = {
    subjectType: 'employee',
    birthCertificateNumber: '',
    businessIdentificationNumber: '',
  }

  const createNewSubjectMutation: Mutation<NewSubject> = (vals) => ({
    path: `/api/users/${userId}/contract_subjects`,
    method: 'POST',
    params: vals,
  })

  const newSubjectMutation = useCustomMutation<Response, any, NewSubject>(
    createNewSubjectMutation,
    {
      onSuccess: async (data, variables) => {
        await queryClient.refetchQueries(
          `/api/users/${userId}/contract_subjects`
        )
        await queryClient.refetchQueries(`/api/users/${userId}/contracts`)
        successNotification('Subjekt vytvořen')
        setDialogOpened(false)
      },
    },
    true
  )

  const formikSubmit: FormikSubmitHandler<NewSubject> = (
    values,
    { setErrors }
  ) => {
    newSubjectMutation.mutateAsync(values).catch((err) => {
      setErrors(err.errors)
    })
  }

  return (
    <>
      <Dialog
        open={dialogOpened}
        onClose={() => setDialogOpened(false)}
      >
        <ContentPaper>
          <Typography variant={'h3'}>New contract subject</Typography>
          <Formik
            initialValues={initVals}
            onSubmit={formikSubmit}
          >
            {(formikProps) => {
              const { subjectType, birthCertificateNumber, businessIdentificationNumber } = formikProps.values
              return (
                <DialogForm>
                  <FormSelect
                    required
                    label={'Subject type'}
                    value={subjectType}
                    name={'subjectType'}
                    values={subjectTypes ?? []}
                    error={hasError(formikProps, 'subjectType')}
                    helperText={helperText(formikProps, 'subjectType')}
                  />
                  {subjectType === 'employee' && (
                    <TextField
                      fullWidth
                      required
                      value={birthCertificateNumber}
                      name={'birthCertificateNumber'}
                      label={'Birth certificate no.'}
                      onChange={formikProps.handleChange}
                      error={hasError(formikProps, 'birthCertificateNumber')}
                      helperText={helperText(formikProps, 'birthCertificateNumber')}
                    />
                  )}
                  {(subjectType === 'contractor' ||
                    subjectType === 'company') && (
                    <TextField
                      fullWidth
                      required
                      value={businessIdentificationNumber}
                      name={'businessIdentificationNumber'}
                      label={'Business identification no.'}
                      onChange={formikProps.handleChange}
                      error={hasError(formikProps, 'businessIdentificationNumber')}
                      helperText={helperText(formikProps, 'businessIdentificationNumber')}
                    />
                  )}
                  <Button
                    fullWidth
                    variant={'contained'}
                    color={'primary'}
                    onClick={formikProps.submitForm}
                  >
                    Create
                  </Button>
                </DialogForm>
              )
            }}
          </Formik>
        </ContentPaper>
      </Dialog>
      <AddNewButton
        variant={'outlined'}
        color={'primary'}
        size={'small'}
        onClick={() => setDialogOpened(true)}
      >
        New subject
      </AddNewButton>
    </>
  )
}
