import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { SearchField } from './inputs/SearchField'
import { CorpSelect } from './inputs/CorpSelect'
import { SelectItem } from '../types'
import { StyledContainer } from '../bundles/ProjectsPage/components/ProjectFilter'
import { handleQueryParams } from '../utils/urlHelpers'

type Props = {
  handleFilterChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string
  ) => void;
  handleTribeChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | string
  ) => void;
  tribes?: SelectItem[];
  owningTribe: number;
  searchString: any;
};

export const TribeFilter: FC<Props> = (props) => {
  const { handleFilterChange, handleTribeChange, tribes, owningTribe } = props
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    const params = new URLSearchParams(document.location.search)
    const tribe = params.get('tribe')
    const searchedString = params.get('search')
    if (tribe) {
      handleTribeChange(tribe)
    }
    if (searchedString) {
      setInputValue(searchedString)
      handleFilterChange(searchedString)
    }
  }, [])

  return (
    <StyledContainer>
      {tribes && (
        <CorpSelect
          values={tribes}
          value={owningTribe}
          onChange={(
            e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            handleTribeChange(e)
            handleQueryParams(e, 'tribe')
          }}
          label={'Tribe'}
        />
      )}
      <SearchField
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value)
          handleFilterChange(e)
          handleQueryParams(e, 'search')
        }}
      />
    </StyledContainer>
  )
}
