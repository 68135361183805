import { Button } from '../../../components/buttons/Button'
import { Table, TableCell, TableHead, TableRow, AccordionSummary, Typography } from '@material-ui/core'
import { CorpSelect } from '../../../components/inputs/CorpSelect'
import { FormLabel } from 'react-bootstrap'
import { FormGrid } from '../../../components/ui/FormGrid'
import styled from 'styled-components'
import { colors } from '../../../styles/colors'
import { atMinWidth } from '../../../styles/helpers'


export const NormalText = styled.span`
  text-transform: none;
`

export const CenteredButton = styled(Button)`
  margin-top: 24px;
  margin-bottom: 8px;
`

export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`

export const StyledTable = styled(Table)`
  width: 100%;
  th, td {
    vertical-align: middle;
  }
`

export const StyledTableHeader = styled(TableHead)`
  width: 100%;

  th {
    border: none;
  }
`

export const StyledTableRow = styled(TableRow)<{$isInactive?: boolean}>`
  height: 52px;

  td, button {
    ${({ $isInactive }) => $isInactive && 'color: #C8C6C5;' }
  }
`

export const StrongTableCell = styled(TableCell)`
  font-weight: 700;
`

export const StyledIcon = styled.span`
  svg {
    width: 16px;
    margin-left: 4px;
    vertical-align: text-top;
  }
`

export const StyledButton = styled(Button)`
  min-width: auto;
  padding: 0;
`

export const BoldText = styled.p`
  font-weight: 700;
  line-height: 26.4px;
  margin-bottom: 0;
`

export const StyledAccordionSummary = styled(AccordionSummary)`
  padding-right: 5px;
  padding-left: 5px;
`

export const DetailWrapper = styled.div`
  width: 100%;
`

export const StyledCorpSelect = styled(CorpSelect)`
  margin-bottom: 20px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`

export const StyledFormLabel = styled(FormLabel)`
  ${atMinWidth.tablet} {
    width: 50%;
  }
  ${atMinWidth.desktop} {
    width: 25%;
  }
`

export const StyledEmpty = styled.p`
  padding: 2rem;
`

export const StyledFormGrid = styled(FormGrid)`
  margin-top: 24px;
`

export const StyledTitle = styled(Typography)`
  font-family: 'Ginto Normal Regular', serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.04em;
  color: ${colors.brand};
`
