import React, { FC } from 'react'
import { Formik } from 'formik'
import { parseISO, format, isValid } from 'date-fns'

import { FormDatePicker } from '../../../components/inputs/FormDatePicker'
import { ButtonWrapper, FormWrapper, StyledFormLabel } from './styles'
import { useQueryNotification } from '../../UserInfo/utils'
import { useCustomMutationWithCreatedId } from '../../../hooks/useCustomMutationWithCreatedId'
import { FormikSubmitHandler } from '../../../types'
import { RatesTable } from './RatesTable'
import { Rate } from './types'
import { hasError, helperText } from '../../../utils/helpers'
import { Button } from '../../../components/buttons/Button'

interface Props {
    done?: () => void;
    rate: Rate | undefined;
    userId: number;
    refetchRates?: () => void;
}

export const EndValidityModal: FC<Props> = ({ rate, userId, refetchRates, done }) => {
  const { successNotification, errorNotification } = useQueryNotification()

  const endValidityMutation = useCustomMutationWithCreatedId<
        Response,
        any,
        { valid_to: string }
    >(
      (vals) => ({
        path: `/api/users/${userId}/rates/${rate?.id}/end_component`,
        method: 'POST',
        params: vals
    
      }),
      {
        onSuccess: async () => {
          successNotification('End validity saved successfully')
          refetchRates && refetchRates()
          done && done()
        },
        onError: (error) => {
          errorNotification(error.error)
        },
      },
      true
    )

  const formikSubmit: FormikSubmitHandler<{ valid_to: Date }> = (values, { setErrors }) => {
    const formattedValues = {valid_to: format(new Date(values.valid_to), 'yyyy-MM-dd')}
    endValidityMutation.mutateAsync(formattedValues).catch((err) => {
      setErrors(err?.errors)
    })
  }

  return (
    <FormWrapper>
      <h2>End validity</h2>
      {rate && <RatesTable
        rates={[rate]}
        hideActions />}
      <Formik
        initialValues={{valid_to: new Date()}}
        onSubmit={formikSubmit}
      >
        {(formikProps) => (
          <>
            <StyledFormLabel>
              <FormDatePicker
                fullWidth
                required
                label={'End validity on (inclusive)'}
                name={'valid_to'}
                value={parseISO(format(new Date(formikProps.values.valid_to), 'yyyy-MM-dd'))}
                onChange={(date) => isValid(date) && formikProps.setFieldValue('valid_to', date)}
                error={hasError(formikProps, 'valid_to')}
                helperText={helperText(formikProps, 'valid_to')}
              />
            </StyledFormLabel>

            <ButtonWrapper>
              <Button
                kind="primary"
                id="endValidity"
                type="submit"
                onClick={formikProps.submitForm}
                disabled={!formikProps.isValid}
              >
                Save
              </Button>
            </ButtonWrapper>
          </>
        )}
      </Formik>
    </FormWrapper>

  )
}