import React from 'react'
import { DataRow } from '../types'
import { formatSum } from '../utils'
import { TableCell, TableRow } from '@material-ui/core'

export const ReportTableRow = ({
  user_name,
  project_name,
  description,
  sum,
}: DataRow) => {
  return (
    <TableRow>
      <TableCell>{user_name || project_name}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell align="right">{formatSum(sum)}</TableCell>
    </TableRow>
  )
}
