import React, { FC } from 'react'
import { ProductCategory, ProductCreate } from '../types'
import { ProductFormFields } from '../components/ProductFormFields'
import { BarCodeContainer } from '../components/BarCodeContainer'
import { isFridgeSelected } from '../utils'
import { FormikProps } from 'formik'
import { Button } from '../../../components/buttons/Button'
import styled from 'styled-components'

type Props = {
  formikProps: FormikProps<ProductCreate>;
  selectedProductCategory: ProductCategory;
  newBarcode: string;
  setNewBarcode: (newBarcode: string) => void;
  productBarcodes: string[];
  setProductBarcodes: (productBarcodes: string[]) => void;
  setBarcodeValidationError: (barcodeValidationError: boolean) => void;
  closeModal: () => void;
  productId?: number;
};

export const ProductForm: FC<Props> = ({
  formikProps,
  selectedProductCategory,
  newBarcode,
  setNewBarcode,
  productBarcodes,
  setProductBarcodes,
  setBarcodeValidationError,
  closeModal,
  productId,
}) => {
  const { submitForm } = formikProps

  return (
    <>
      <ProductFormFields
        formikProps={formikProps}
        selectedProductCategory={selectedProductCategory}
      />
      {isFridgeSelected(selectedProductCategory) && (
        <BarCodeContainer
          newBarcode={newBarcode}
          setNewBarcode={setNewBarcode}
          productBarcodes={productBarcodes}
          setProductBarcodes={setProductBarcodes}
          setBarcodeValidationError={setBarcodeValidationError}
          productId={productId}
        />
      )}
      <ButtonContainer>
        <Button
          kind={'primary'}
          onClick={submitForm}
        >
          Submit
        </Button>
        <Button
          kind={'secondary'}
          onClick={() => {
            closeModal()
          }}
        >
          Cancel
        </Button>
      </ButtonContainer>
    </>
  )
}

const ButtonContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
`
