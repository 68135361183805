import React, { FC } from 'react'
import { IconSVGProps } from '../Icon'

export const TestingDevices: FC<IconSVGProps> = ({
  width = 20,
  height = 42,
}) => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.667 13.667v4.905A2.428 2.428 0 019.237 21H3.43A2.429 2.429 0 011 18.572V4.762a2.43 2.43 0 012.43-2.428H5M1 17h10.667"
      stroke="#C8C6C5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 11.667A5.333 5.333 0 1013 1a5.333 5.333 0 000 10.667z"
      stroke="#C8C6C5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 3.667v2.666h2.667"
      stroke="#C8C6C5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
