/* eslint-disable max-lines */
import React, { FC, useEffect, useState } from 'react'
import { BreadcrumbsData } from '../../../types'
import { BreadcrumbsNav } from '../../../components/BreadcrumbsNav'
import { Button } from '../../../components/buttons/Button'
import { BudgetsTable } from './BudgetsTable'
import { PageLayoutWrapper } from '../../../components/ui/PageLayoutWrapper'
import { ContentPaper } from '../../../components/ContentPaper'
import { HeaderContainer, StyledTypography, ButtonsContainer, StyledWrapper, StyledCorpSelect, StyledFormLabel, StyledTextField } from './styles'
import { Budget, BudgetData } from '../types'
import { useQuery } from 'react-query'
import { Mutation, useCustomMutation } from '../../../hooks/useCustomMutation'
import { useQueryNotification } from '../../UserInfo/utils'
import { GENERIC_BE_ERROR_MSG } from '../../../constants'
import { ModalWrapper, useModalState } from '../../../components/ModalWrapper'
import { DiscardChangesModal } from './DiscardChangesModal'

type Props = {
  budgetYears: Budget[];
  projectName: string;
  budgetPoolSize: number;
  projectId: number;
}

export const BudgetsPage: FC<Props> = ({ budgetYears, projectName, budgetPoolSize, projectId }) => {
  const [activeYear, setActiveYear] = useState<number>(new Date().getFullYear())
  const [potentialYear, setPotentialYear] = useState<number>()
  const [budgets, setBudgets] = useState<number[]>()
  const [poolSize, setPoolSize] = useState(budgetPoolSize)
  const [defaultBudgets, setDefaultBudgets] = useState<number[]>()
  const { successNotification, errorNotification } = useQueryNotification()
  const [reset, setReset] = useState<boolean>()
  const [confirmedDiscard, setConfirmedDiscard] = useState<boolean>()

  const { data: budgetData, isLoading: isBudgetsLoading, refetch: refetchBudgets } = useQuery<BudgetData>(
    `/api/budgets/${projectId}/${activeYear}`
  )

  const years = budgetYears.map(budgetRow => ({ label: budgetRow.year, value: budgetRow.year })).reverse()

  const breadcrumbsLinks: BreadcrumbsData = {
    links: [
      {
        id: 1,
        title: 'Projects',
        href: '/admin/projects',
      },
      {
        id: 2,
        title: projectName,
        href: `/admin/projects/${projectId}`,
      },

    ],
    currentPageTitle: 'Set budget',
  }

  useEffect(() => {
    if (confirmedDiscard) {
      closeModal()
      potentialYear && setActiveYear(potentialYear)
      setBudgets([])
      setReset(true)
    }
  }, [confirmedDiscard])

  useEffect(() => {
    if (!isBudgetsLoading && budgetData) {
      const clearedBudgets = budgetData.hoursBudget.map(budget => budget ? budget : 0)
      setBudgets(clearedBudgets)
      setDefaultBudgets(clearedBudgets)
    }
  }, [budgetData])

  const budgetsForActiveYear = `/api/budgets/${projectId}/${activeYear}`
  const newBudgetMutation: Mutation<Budget> = (values) => ({
    path: budgetsForActiveYear,
    method: 'PUT',
    params: values,
  })

  const changeBudgetMutation = useCustomMutation<Response, any, Budget>(
    newBudgetMutation,
    {
      onSuccess: async () => {
        successNotification('Budget was saved')
        setReset(true)
        refetchBudgets()
        setReset(false)
      },
      onError: (error) => {
        if (error.status != 422) {
          errorNotification(
            error.error ?? GENERIC_BE_ERROR_MSG
          )
        }
      },
    },
    true
  )

  const {isModalOpen, closeModal, openModal} = useModalState(false)

  const setBudgetsData = (index: number, value: string, replace = false, newBudgets?: number[]) => {
    if (replace) {
      setBudgets(newBudgets)
    } else {
      const dataOld = budgets ? Array.from(budgets) : []
      if (dataOld.length) {
        dataOld[index] = Number(value)
        setBudgets(dataOld)
      }
    }
  }

  const saveForm = () => {
    const correctValues = budgets?.map(budget => budget ?? 0)
    changeBudgetMutation.mutateAsync({
      poolSize,
      hoursBudget: correctValues
    })
  }

  const handleChangeYear = (event) => {
    const clearedBudgets = budgetData?.hoursBudget.map(budget => budget ? budget : 0) ?? []
    const activeYear = Number(event.target.value)

    if (budgets?.toString() !== clearedBudgets.toString() && !isModalOpen) {
      openModal()
      setPotentialYear(activeYear)
    } else {
      setActiveYear(activeYear)
      setBudgets([])
      setReset(true)
      setConfirmedDiscard(false)
    }
  }

  return (
    <>
      <BreadcrumbsNav
        data={breadcrumbsLinks}
        isLoading={isBudgetsLoading} />
      <PageLayoutWrapper>
        <HeaderContainer>
          <StyledTypography variant="h3">Set budget</StyledTypography>
          <ButtonsContainer>
            <Button
              kind='primary'
              onClick={saveForm}>Save</Button>
            <Button
              kind='basicSecondary'
              onClick={() => setReset(true)}>Cancel</Button>
          </ButtonsContainer>
        </HeaderContainer>

        <ContentPaper>
          <StyledWrapper>
            <StyledCorpSelect
              values={years}
              value={activeYear}
              onChange={(event) => handleChangeYear(event)}
            />

            <StyledFormLabel>Max hour pool size</StyledFormLabel>
            <StyledTextField
              fullWidth
              required
              defaultValue={budgetPoolSize}
              name={'budgetPoolSize'}
              type={'text'}
              onChange={(event) => setPoolSize(Number(event.target.value))}
            />
            <BudgetsTable
              projectId={projectId}
              data={budgetData}
              budgets={budgets}
              activeYear={activeYear}
              loading={isBudgetsLoading}
              setBudgetsData={setBudgetsData}
              defaultBudgets={defaultBudgets}
              reset={reset}
            />

            <ModalWrapper
              isOpen={isModalOpen}
              closeModal={closeModal}
            >
              <DiscardChangesModal
                setConfirmedDiscard={setConfirmedDiscard}
              />
            </ModalWrapper>

          </StyledWrapper>
        </ContentPaper>
      </PageLayoutWrapper>
    </>
  )
}

